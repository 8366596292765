import React, { useState } from 'react';

const Pagination = ({ totalRecords, perPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(totalRecords / perPage);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  };

  const paginationItems = () => {
    const pages = [];
    const visiblePages = 10;
    let startPage, endPage;

    if (pageCount <= visiblePages) {
      startPage = 1;
      endPage = pageCount;
    } else {
      const leftHalf = Math.floor(visiblePages / 2);
      const rightHalf = Math.ceil(visiblePages / 2) - 1;
      if (currentPage <= leftHalf) {
        startPage = 1;
        endPage = visiblePages;
      } else if (currentPage + rightHalf >= pageCount) {
        startPage = pageCount - visiblePages + 1;
        endPage = pageCount;
      } else {
        startPage = currentPage - leftHalf;
        endPage = currentPage + rightHalf;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  // Calculate the range of records currently being displayed
  const firstRecordIndex = (currentPage - 1) * perPage + 1;
  const lastRecordIndex = Math.min(currentPage * perPage, totalRecords);
  
  return (
    <div className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
      <div className="text-xs+">{`${firstRecordIndex} - ${lastRecordIndex} of ${totalRecords} entries`}</div>

      <ol className="pagination">
        <li className={`rounded-l-full bg-slate-150 dark:bg-navy-500 ${currentPage === 1 ? 'opacity-50' : ''}`}>
          <button
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
            className="flex size-8 items-center justify-center rounded-full text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                />
                </svg>
          </button>
        </li>

        {currentPage > 1 + 5 && (
          <li className="bg-slate-150 dark:bg-navy-500">
            <button onClick={() => changePage(1)} className="flex h-8 min-w-[2rem] items-center justify-center rounded-full px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">1</button>
          </li>
        )}
        {currentPage > 1 + 6 && <li className="bg-slate-150 dark:bg-navy-500">...</li>}

        {paginationItems().map(pageNumber => (
          <li key={pageNumber} className="bg-slate-150 dark:bg-navy-500">
            <button
              onClick={() => changePage(pageNumber)}
              disabled={pageNumber === currentPage}
              className={`flex h-8 min-w-[2rem] items-center justify-center rounded-full px-3 leading-tight transition-colors ${pageNumber === currentPage ? 'bg-primary text-white' : 'hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80'} dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90`}
            >
              {pageNumber}
            </button>
          </li>
        ))}

        {currentPage < pageCount - 6 && <li className="bg-slate-150 dark:bg-navy-500">...</li>}
        {currentPage < pageCount - 5 && (
          <li className="bg-slate-150 dark:bg-navy-500">
            <button onClick={() => changePage(pageCount)} className="flex h-8 min-w-[2rem] items-center justify-center rounded-full px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">{pageCount}</button>
          </li>
        )}

        <li className={`rounded-r-full bg-slate-150 dark:bg-navy-500 ${currentPage === pageCount ? 'opacity-50' : ''}`}>
          <button
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === pageCount}
            className="flex size-8 items-center justify-center rounded-full text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                />
                </svg>
          </button>
        </li>
      </ol>
    </div>
  );
};

export default Pagination;
