import React, { useState, useEffect, useRef } from "react";
import { AgentDashboardservice } from "../../services/agent/agentDashboardservice";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, formatDate, formatName } from '../../helpers/Helpers';
import CustomLoader from './customloader';
import { graphData } from "../../services/agent/agentDashboardservice";
import CustomDatePicker from './CustomDatePicker';
import ReactApexChart from "react-apexcharts";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";

import {FetchservicFee} from '../../services/agent/agent-SellTickets-service';
const AgentDashboard = () => {
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [userData, setUserData] = useState([]);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await AgentDashboardservice(currentPage, searchText);
        console.log(response, "response");
        setData(response);
        const currency = response.user.currency.symbol;
        setCurrency(currency);
  
        setOrderData(response.orders);
        setUserData(response.user.currency.symbol);

        localStorage.setItem('currency', currency);
      } catch (error) {
        // console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };

    fetchData();
  }, [currentPage, searchText]);
  const [graphDate, setGraphDate] = useState([]);
  const [paymentValue, setPaymentValue] = useState([]);
  const currentYear = new Date().getFullYear();
 
  const [TicketData, setTicket] = useState([]);
  const [EventData, setEvent] = useState([]);
 
  const [totalPaymentCount, setTotalPaymentCount] = useState('');
  const [startDataFromApi, setStartDataFromApi] = useState('');
  const [endDataFromApi, setEndDataFromApi] = useState('');
  const [graphDataSelection, setGraphDataSelection] = useState({
    'start_date': new Date(currentYear, 0, 1),
    'end_date': new Date(currentYear, 11, 31),
    'selected_option': 'months'
  });
   // Define a callback function to update graphDataSelection
  const handleDateChange = (startDate, endDate, selectedOption) => {

     
    setGraphDataSelection({
      'start_date': startDate,
      'end_date': endDate,
      'selected_option': selectedOption
    });
  };
  
  
   useEffect(() => {

    
    setChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }],
      xaxis: { ...prevOptions.xaxis, categories: graphDate }
    }));

    setLineChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }]
    }));

    

  }, [paymentValue, graphDate]);
  useEffect(() => {
   
    const fetchGraphData = async () => {
   
      try {
        const response = await graphData(graphDataSelection);
        if (response) { 
 
          if (response && response.date) {
            setGraphDate(response.date);
          } 
  
          if (response && response.total_payment) {
            setPaymentValue(response.total_payment);
          }

          if (response && response.total_payment) {
            setPaymentValue(response.total_payment);
          }

          if (response) {
            setTotalPaymentCount(response.total_payment_count);
          }
          if (response && response.startDate) {
            setStartDataFromApi(response.startDate);
          }
          if (response && response.endDate) {
            setEndDataFromApi(response.endDate);
          }

        }
      }
      catch (error) { 
        //  console.error("Error fetching data: ", error);
       }
     
    }; 
    
    fetchGraphData();
  
  }, [graphDataSelection]);
  const [lineChartOptions, setLineChartOptions] = useState({
    colors: ["#4467EF"],
    chart: {
      height: 60,
      type: "line",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: "Sales",
        data: [654, 820, 102, 540, 154, 614],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: -20,
        bottom: -10,
      },
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  });

  const [chartOptions, setChartOptions] = useState({
    colors: ["#4C4EE7", "#0EA5E9"],
    series: [
      {
        name: "Sales",
        data: [28, 45, 35, 50, 32, 55, 23, 60, 28, 45, 35, 50],
      },
      {
        name: "Profit",
        data: [14, 25, 20, 25, 12, 20, 15, 20, 14, 25, 20, 25],
      },
    ],
    chart: {
      height: 255,
      type: "bar",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: "90%",
        columnWidth: "35%",
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        hideOverlappingLabels: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: -10,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 850,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "55%",
            },
          },
        },
      },
    ],
  });
  const formatNumber = (value) => {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) ? numericValue.toFixed(2) : '0.00';
  };
  const formatValue = (value, symbol) => {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) ? `${symbol} ${numericValue.toFixed(2)}` : `${symbol} 0.00`;
  };
  const exportChartToPDF = () => {
    html2canvas(document.querySelector("#chart")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
  
      // Calculate the PDF page width and desired image width (50% of the PDF page width)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth * 0.5; // 50% of the PDF page width
      
      // Calculate the scaled image height to maintain the aspect ratio
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      
      // Center the image horizontally: (PDF Page Width - Image Width) / 2
      const x = (pdfWidth - imgWidth) / 2;
      const y = 0; // Adjust this value if you want to add a top margin
  
      // Add image to PDF
      pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
  
      // Save the PDF
      pdf.save("graph.pdf");
    });
  };
  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%'}}
        className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  // console.log(OrderData, 'OrderData');
  return (
    <>
    
    <SEO
    title="Agent Dashboard - eticketing"
  />
      <main ref={ref} style={{ width: '100%', height: '100%'}}
         className="main-content w-full pb-8">
        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-8">
            <div className="flex items-center justify-between space-x-2">
              <h2 className="text-base font-medium tracking-wide text-slate-800 line-clamp-1 dark:text-navy-100">
                Sales Overview
              </h2>
              <div
                id="sales-tab"
                className="is-scrollbar-hidden overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                <div className="tabs-list flex p-1">
                <CustomDatePicker
                  graphSelection={graphDataSelection}
                  onDateChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-7">
              <div className="mt-4 flex shrink-0 flex-col items-center sm:items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-8 text-info"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                  />
                </svg>
                <div className="mt-4">
                  <div className="flex items-center space-x-1">
                    <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                       {/* { formatCurrency(totalPaymentCount, 'STRIPE', 'USD') } */}
                       {symbol} {formatNumber(totalPaymentCount)}
                    </p> 
                  </div>
                 
                </div>
                <div className="mt-3 flex items-center space-x-2">
                  <div className="ax-transparent-gridline w-28" id="charts">
                    <ReactApexChart
                      options={lineChartOptions}
                      series={lineChartOptions.series}
                      type="line"
                      height={60}
                    />
                  </div>
                 
                </div>
                {/* <button onClick={exportChartToPDF} className="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4.5 text-slate-400 dark:text-navy-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                    />
                  </svg>
                  <span> Download Report</span>
                </button> */}
              </div>
              <div className="ax-transparent-gridline grid w-full grid-cols-1">
                <ReactApexChart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={255}
                />
              </div>
            </div>
          </div>
           
          <div className="col-span-12 lg:col-span-4">
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {data.total_event}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-primary dark:text-accent" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+">Total Events</p>
              </div>
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {data.available_tickets}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+">Available Tickets</p>
              </div>
              {/* <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
          <div className="flex justify-between">
            <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
              143
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-warning" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <p className="mt-1 text-xs+">Total Sold Tickets</p>
        </div>
        <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
          <div className="flex justify-between">
            <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
              651
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-info" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
            </svg>
          </div>
          <p className="mt-1 text-xs+">Total Sale</p>
        </div> */}
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {formatValue(data.total_cash_diposit, data?.user?.currency?.symbol)}

                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+">Total Cash Deposit</p>
              </div>
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {formatValue(data.total_cash, data?.user?.currency?.symbol)}

                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-error" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+">Cash in Hand</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card col-span-12 lg:col-span-12 mt-6 mx-6">
          <div className="grid grid-cols-1 gap-y-4 pb-3 sm:grid-cols-1">
            <div className="card pb-4">
              <div className="my-3 flex h-8 items-center justify-between px-4 sm:px-5">
                <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
                Recent Orders 
                </h2>

              </div>
              <div>
                <div className="mt-5">
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center h-96">
                        <CustomLoader
                          visible={true}
                          height={80}
                          width={80}
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius={1}
                        />
                      </div>
                    </div>
                  ) : OrderData.length === 0 ? (
                    <div className="text-center text-gray-500 mt-8">
                      <h1 className="xl text-3xl">No records found.</h1>
                    </div>
                  ) : (

                    <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                      <table className="is-zebra w-full text-left table-auto">
                        <thead>
                          <tr>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              ORDER ID
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              CUSTOMER NAME
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              EVENT NAME
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              DATE
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              TICKETS SOLD
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              PAYMENT
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              PAYMENT TYPE
                            </th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {OrderData.map((item, index) => (
                            <tr
                              key={index}
                              className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                            >
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {item.order_id}
                              </td>
                              <td className="whitespace-nowrap  px-4 py-3 sm:px-5">
                                {item.name + ' ' + item.last_name || 'N/A'}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {item.event_name}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {formatDate(item.updated_at)}
                              </td>

                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {item.quantity + ' ticket'}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {/* {item?.payment_type === 'Free' ? (
                                  'Free'
                                ) : (
                                  item?.payment_type === 'Complimentary' ? (
                                    'Complimentary'
                                  ) :
                                    item?.payment_type === 'CASH' ? (

                                      formatCurrency(
                                        item?.payment,
                                        item?.payment_type,
                                        'TTD $'
                                      )
                                    ) :
                                      (
                                        formatCurrency(
                                          item?.payment,
                                          item?.payment_type,
                                          item?.currency?.symbol ?? '$'
                                        )
                                      )
                                )} */}
                                {item?.payment_type === 'Complimentary' || item?.payment_type === 'Free'
                                                                ? '0'
                                                                : `${symbol} ${(item.payment ?? 0).toFixed(2)}`
                                                            }

                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {item.payment_type}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <Link to={`/agent/orders`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 12s3-8 10-8 10 8 10 8-3 8-10 8-10-8-10-8z"></path>
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div></div>

      </main>


    </>
  );
};


export default AgentDashboard;