     import React from "react";
import { BlogListFetch,DeleteblogList } from "../../../services/admin/blogservice";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import SEO from "../../../hooks/seo";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import { API_ENDPOINTS }  from '../../../services/admin/api'
import useResizeObserver from "../../../hooks/useResizeObserver";

const BlogList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [pageLoading, setPageLoading] = useState(false);
    const location = useLocation();
    const message = location.state && location.state.message;
    const [messageShown, setMessageShown] = useState(false);

    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const fetchData = async () => {
            setLoading(true);
            try {
                const response = await BlogListFetch(currentPage, searchText);
                
                if (response && response.data) {
                    
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
        }
        
        setPageLoading(true);
    };
     const showDeleteConfirmation = async (callback) => {
        try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            await callback();
            Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
        }
    } catch (error) {
        console.error('Error in showDeleteConfirmation:', error);
        Swal.fire('Error!', 'Failed to delete the item.', 'error');
    }
};

    const handleDeleteClick = async (id) => {
        showDeleteConfirmation(async () => {
            try {
                const response = await DeleteblogList(id);
                
            
                if (response.status === 200) {
                    alert('OK');
                    const responseData = await response.json();
                    if (responseData.success) {
                        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
                        fetchData();
                    } else {
                    
                        Swal.fire('Error!', 'Failed to delete the item.', 'error');
                        fetchData();
                    }
                } else {
                    
                    Swal.fire('Error!', 'Failed to delete the item.', 'error');
                    fetchData();
                }
            } catch (error) {
                fetchData();
                console.error('Error deleting item:', error);
                Swal.fire('Error!', error.message, 'error');
            }
        });
    }
      if (message && !messageShown) {
              
          setMessageShown(true); 
             Swal.fire('Update!',message, 'success');
                }
    
    useEffect(() => {
        fetchData();
    }, [currentPage, searchText,message]);
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchText(searchText);
          setCurrentPage(1);
        }, 300); // Adjust the delay as needed
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_BLOG_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_BLOG_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
    const onButtonClickCreate = () => {
        navigate('/admin/blog/create');
    }
     if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    return (
        <>
        <SEO
        title="Blog List - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
         
            <PageHeader
                title="Blog List"
                onButtonClick={onButtonClickCreate}
                showCreateButton={true}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Blog Rows"
                         showSearch={true}
                         showExportButtons={true}
                        onSearchChange={handleSearchChange}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Image
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Title
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Status
                                        </th>
                                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                <div className="avatar flex size-30">
                                                    <img
                                                        className="mask is-squircle"
                                                        src={item.imagePath ? item.imagePath + item.image : '../assets/img/categories/concert.jpg'}
                                                        alt="Category"
                                                        style={{ borderRadius: '0.25rem' }}
                                                    />
                                                </div>
                                            </td> 
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            <p
                                                    dangerouslySetInnerHTML={{ __html: item.title || 'NULL' }}
                                                />
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.status === 1 ? (
                            <div className="badge space-x-2.5 px-0 text-success">
                            <div className="size-2 rounded-full bg-current" />
                            <span>Active</span>
                            </div>
                        ) : (
                            <div className="badge space-x-2.5 px-0 text-error">
                                    <div className="size-2 rounded-full bg-current" />
                                    <span>Inactive</span>
                                    </div>
                        )}
                                            </td>
                                            <td className="whitespace-nowrap px-4 pt-6 pb-4 sm:px-5 flex items-center space-x-4">
                                                <Link className='btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25' to={`/admin/blog/${item.id}/edit`}>
                                                     <i className="fa fa-edit" />
                                                </Link>
                                                <Link className="btn size-8 p-0 text-error hover:bg-error/20 focus:bg-error/20 active:bg-error/25" onClick={() => handleDeleteClick(item.id)}>
                                                <i className="fa fa-trash-alt" />
                                                    </Link>
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                      )}
                        <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                                     </div>
                                   
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default BlogList;
