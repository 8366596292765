import React from 'react';

const Home = () => {
  return (
    <div className="flex w-full pt-20 flex-col items-center text-center bg-gray-100">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Welcome to Kanoo eTicketing App
      </h1>
      <a 
        href="/login" // Replace with your actual login route
        className="px-6 py-2 bg-blue-600 text-white text-lg rounded hover:bg-blue-700 transition duration-300"
      >
        Login
      </a>
    </div>
  );
};
 
export default Home;
