import axios from 'axios';
import { API_ENDPOINTS } from './api';

// export const ScannerTickkets = async (currentPage,searchText) => {
  
//   try {
//     const authToken = localStorage.getItem('authToken');
//     let url = API_ENDPOINTS.SCANNER_TICKKETS + `?page=${currentPage}`;
    
//     if (searchText && searchText.trim() !== '') {
//       url += `&search=${searchText}`;
//     }
    
//     const response = await axios.get(url, {
//       headers: {
//         Authorization: `Bearer ${authToken}` // Include the auth token in the header
//       }
//     });
    
//     return response.data;
//   } catch (error) {
//     console.error('scanner error:', error);
//     // Check if the error is a 401 Unauthorized
//     if (error.response && error.response.status === 401) {
//       // Navigate to the login page
//       window.location.href = '/login';

//     }
//     // Rethrow the error for further handling (e.g., showing an error message in the UI)
//     throw error;
//   }
// };



export const ScannerTickkets = async (currentPage,searchText,advanceSearchText) => {
  
    try {
      const authToken = localStorage.getItem('authToken');
 
      const searchParam = searchText !== '' ? searchText : '';  
      const advance_SearchText = advanceSearchText ? `?${advanceSearchText}` : '';

       
      let combinedParam = '';
      if (searchParam && advanceSearchText) {
          combinedParam = `${searchParam}${advanceSearchText.startsWith('?') ? '' : '&'}${advanceSearchText}`;
      } else {
          combinedParam = searchParam + advanceSearchText;
      } 
      
      if (combinedParam.includes('eventName=') && combinedParam.match(/eventName=/g).length > 1) {
          combinedParam = combinedParam.replace(/&eventName=\w*/, '&');
      } 
      
      console.log('combinedParam',combinedParam);
     


      let url = API_ENDPOINTS.SCANNER_TICKKETS + `?page=${currentPage}&${combinedParam}`;
      
      if (searchText && searchText.trim() !== '') {
        url += `&search=${searchText}`;
      }
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}` // Include the auth token in the header
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('scanner error:', error);
      // Check if the error is a 401 Unauthorized
      if (error.response && error.response.status === 401) {
        // Navigate to the login page
        window.location.href = '/login';
  
      }
      // Rethrow the error for further handling (e.g., showing an error message in the UI)
      throw error;
    }
  };
