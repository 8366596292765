import axios from 'axios';
import { API_ENDPOINTS } from '../api';

export const agentServices = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    let url = `${API_ENDPOINTS.AGENT_CASH_TOTAL}?page=${currentPage}`;
    
    if (searchText && searchText.trim() !== '') {
      url += `&search=${encodeURIComponent(searchText)}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });

    return response.data;
  } catch (error) {
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';
    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};

export const agentServicesCashHandling = async (currentPage,searchText) => {
  
  let url = `${API_ENDPOINTS.AGENT_CASH_HANDLING}?page=${currentPage}`;

  if (searchText && searchText.trim() !== '') {
    url += `&search=${encodeURIComponent(searchText)}`;
  }
    
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
export const agentServicesDetail = async (currentPage,searchText, id = 0) => {
   let url = `${API_ENDPOINTS.AGENT_CASH_TOTAL_DETAIL  + '/' + id }?page=${currentPage}`;

  if (searchText && searchText.trim() !== '') {
    url += `&search=${encodeURIComponent(searchText)}`;
  }
  try {
    const authToken = localStorage.getItem('authToken');

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};


export const submitCashDepositForm = async (formData) => {
  try { 
    
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS.AGENT_CASH_HANDLING_DEPOSIT, formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};



export const agentProfile = async () => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.AGENT_Profile ,  {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};

export const agentProfileUpdate = async (formData) => {
  try { 
    
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS. AGENT_Profile_UPDATE, formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};