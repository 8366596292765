import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { AgentAllCategory } from "../../../services/agent/agentPosService";
import './CategorySwiper.css';
import CustomLoader from '.././customloader';
const CategorySwiper = ({ handleClick }) => {

  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [categories, setcategories] = useState([]);
  const [events, setEvents] = useState([]);



  const fetchAllCate = async () => {
    setLoading(true);
    try {
      const response = await AgentAllCategory();

      setcategories(response.categories);

    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };



  useEffect(() => {
    fetchAllCate();

  }, []);

  const firstCategory = {
    id: 'all',
    name: 'All Categories',
    imageSrc: '/images/allcategories.png'
  };

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const userInfoString = localStorage.getItem("AgentSellTicket_userInfo");
    if (userInfoString) {
      const userInfoData = JSON.parse(userInfoString);
      setUserInfo(userInfoData);
    }
  }, []);



  return (
    <div>
      {loading ? (
        <CustomLoader
          visible={true}
          height={80}
          width={80}
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius={1}
        />
      ) : (

    


        <div>
          
          <div className="flex items-center  mb-5">
 
        </div>
          <Swiper
            slidesPerView="auto"
            spaceBetween={10}
            lazy={true}
            className="mySwiper"


          >

            <SwiperSlide key={'000'} data-id={'000'} className="card mySwiperCard" onClick={() => handleClick('0')}>
              <div className="flex flex-col items-center rounded-lg px-2 py-4 text-slate-600 dark:text-navy-100" style={{ cursor: 'pointer' }}>
                <img
                  className="w-12"
                  src={firstCategory.imageSrc}
                  alt={firstCategory.name}
                  style={{ height: '73px', objectFit: 'contain' }}

                />
                <h3 className="pt-2 font-medium tracking-wide line-clamp-1">
                  {firstCategory.name}
                </h3>
              </div>
            </SwiperSlide>

            {categories.map((category) => {

              const gradient = `linear-gradient(${category.color.replace(/^\[|\]$/g, '')})`;

              return (
                <SwiperSlide
                  style={{ backgroundImage: gradient }}
                  key={category.id}
                  data-id={category.id}
                  className="card mySwiperCard"
                  onClick={() => handleClick(category.id)}
                >
                  <div className="flex flex-col items-center rounded-lg px-2 py-4 text-slate-600 dark:text-navy-100" style={{ cursor: 'pointer' }}>
                    <img
                      className="w-12"
                      src={`${category.imagePath}/${category.image}`}
                      alt={category.name}
                      style={{ height: '73px', objectFit: 'contain' }}
                    />
                    <h3 className="pt-2 font-medium tracking-wide line-clamp-1 text-white	">
                      {category.name}
                    </h3>
                  </div>
                </SwiperSlide>
              );
            })}

          </Swiper>

        </div>
      )}
    </div>
  );
};

export default CategorySwiper;
