import React from "react";
import PageHeader from "../../../hooks/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import SEO from "../../../hooks/seo";
import { useState, useEffect } from "react";
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useRef } from 'react';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { submitFaq } from "../../../services/admin/faqsservice";
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";

registerPlugin(FilePondPluginImagePreview);

const schema = yup.object().shape({
  question: yup.string().required('Question is required'),
  answer: yup.string().required('Answer is required'),


  // Add more validation rules as needed
});


const FaqCreate = () => {

  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  const filePondRef = useRef(null);


  const handleFileChange = (event) => {

    const file = event.target.files[0];
    setValue('image', file);
  };


  const statusOptions = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };




  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {


      const response = await submitFaq(formData);

      console.log(response);

      if (response.status === 200) {
        // toast.success(response.data.message); 
        // navigate('/admin/faq', { state: { message: response.data.message } });

        Swal.fire({
          title: 'Good Job',
          text: response.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked 'OK'
            navigate('/admin/faq', { state: { message: response.msg } });
          }
        });

      }
      e.target.reset();
      //navigate('/login', { state: { message: 'Registration successful. Now you can log in with your credentials.' } });
    } catch (error) {
      console.error('Registration failed:', error);

      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onBackButtonClick = () => {
    navigate('/admin/faq');
  }


  return (
    <>
      <SEO
        title="Add Faq - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <ToastContainer />
        <PageHeader
          title="Add Faq"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />

        <div className="mt-4 grid grid-cols-12   transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add FAQ
                    </h4>
                  </div>

                </div>
              </div>


              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block">
                    <span>Question</span>
                    <Controller
                      name="question"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                              } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Question"
                            type="text"
                            {...field}
                          />
                          {fieldState.error && (
                            <p className="text-red-500">{fieldState.error.message}</p>
                          )}
                        </>
                      )}
                    />
                  </label>
                </div>


                <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block">
                    <span>Answer</span>
                    <Controller
                      name="answer"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <textarea
                            className={`form-textarea mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                              } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Answer"
                            rows={10} // Set the number of rows
                            {...field}
                          />
                          {fieldState.error && (
                            <p className="text-red-500">{fieldState.error.message}</p>
                          )}
                        </>
                      )}
                    />

                  </label>
                </div>

                <div className="space-y-4 p-4 sm:p-5" id="category">

                  <button
                    type="submit"
                    className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FaqCreate;
