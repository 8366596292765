import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api';
import Swal from 'sweetalert2';


export const CustomerReportCashFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const postData = {
      page: currentPage,
      search: searchText,
    };

    const response = await axios.post(API_ENDPOINTS.CASH_CUSTOMER_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const CashOrganizationReportFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const postData = {
      page: currentPage,
      search: searchText,
    };

    const response = await axios.post(API_ENDPOINTS.CASH_ORGANIZATION_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const CashRevenueReportsFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const postData = {
      page: currentPage,
      search: searchText,
    };

    const response = await axios.post(API_ENDPOINTS.CASH_REVENUE_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const CashSettlementReportFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const response = await axios.post(
      API_ENDPOINTS.CASH_SETTLEMENT_REPORT_LIST,
      {
        page: currentPage,
        search: searchText,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const CashAgentSettlementFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.AGENT_SETTLEMENT_REPORTS_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const AgentSettlementDetailFetch = async (currentPage,searchText, id ) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.AGENT_SETTLEMENT_DETAIL_FETCH_LIST +`${id}`+ `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const PaymentHistoryFetch = async (currentPage,searchText, id ) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.PAYMENT_HISTORY_FETCH_LIST +`${id}`+ `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const submitStatus = async (id, status) => {
  const response = await fetch(`${API_ENDPOINTS.STATUS_CHANGE_SUBMIT}${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status }),
  });
  return response;
};


// export const VeiwSettlementDetailFetch = async (currentPage,searchText,id) => {
  
//   try {
//       const authToken = localStorage.getItem('authToken');
//       const searchParam = searchText ? `&search=${searchText}` : '';
//         const response = await axios.get(API_ENDPOINTS.ADMIN_VEIW_SETTLEMENT_DETAIL_FETCH_LIST +`${id}`+ `?page=${currentPage}${searchParam}`, {
//       headers: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('scanner error:', error);
//     throw error;
//   }
// };