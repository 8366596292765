import React from "react";
import { UserListFetch } from "../../../services/admin/userservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect,useRef } from "react";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import { API_ENDPOINTS } from '../../../services/admin/api';
import useResizeObserver from "../../../hooks/useResizeObserver";

const UsersList = () => {
   
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [pageLoading, setPageLoading] = useState(false);
    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
    
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await UserListFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);

                    const uniqueUsers = response.data.reduce((unique, user) => {
                        const existingUser = unique.find(u => u.id === user.id);
                        if (!existingUser) {
                            unique.push(user);
                        } else {
                            existingUser.role_name = Array.from(new Set([
                                ...(Array.isArray(existingUser.role_name) ? existingUser.role_name : [existingUser.role_name]),
                                ...(Array.isArray(user.role_name) ? user.role_name : [user.role_name])
                            ]));
                        }
                        return unique;
                    }, []);

                    setData(uniqueUsers);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };


        fetchData();
    }, [currentPage, searchText]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
            setCurrentPage(1);
        }, 300); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_USER_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_USER_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };


    const onButtonClickCreate = () => {
        navigate('/admin/users/create');
    }

     
 if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#bd387a"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }

   
    return (
        <>
        <SEO
        title="Users List - eticketing"
      />

        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
 
        <PageHeader
          title="Users List"
          onButtonClick={onButtonClickCreate}
          showCreateButton={true}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Users Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              {data.length === 0 ? (
                <div className="text-center text-gray-500 mt-8">
                  <h1 className="xl text-3xl">No records found.</h1>
                </div>
              ) : (
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                <table className="is-hoverable w-full text-left">
                                    <thead>
                                        <tr>
                                            <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                #
                                            </th>
                                            <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Name
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Phone
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Role
                                            </th>
                                            <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                            >
                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    {item.id}
                                                </td>
                                                <td className="flex whitespace-nowrap px-4 py-3 sm:px-5">
                                                    <img
                                                        src={`https://api.tikkets.com/public/images/upload/${item.image || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AA_black_image.jpg&psig=AOvVaw2iqqWrVS1zRNXczEEi_BiW&ust=1706592400807000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPj8oquy_oMDFQAAAAAdAAAAABAD'}`}
                                                        alt="image" className="mr-3 w-12 h-12 object-cover rounded-full" />
                                                    <div className="flex flex-col">
                                                        <div className="font-bold">{item.first_name + ' ' + item.last_name || 'N/A'}</div>
                                                        <div className="text-gray-500">{item.email || 'N/A'}</div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    {item.phone || 'N/A'}
                                                </td>
                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    {Array.isArray(item.role_name) ? (
                                                        <div className="badge space-x-2.5 text-secondary dark:text-secondary-light">
                                                            {item.role_name.map((role, index) => (
                                                                <span key={index} className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15">
                                                                    {role}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="badge space-x-2.5 text-secondary dark:text-secondary-light">
                                                            <span className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15">
                                                                {item.role_name || 'N/A'}
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>





                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">


                                                {item.id === 1 ? (
                                                // Render item.role_name only if item.id is "admin"
                                                    <></>
                                                ) : (
                                                    <Link className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25" to={`/admin/users/${item.id}/edit`}>
                                                    <i className="fa fa-edit" />
                                                </Link>
                                                )} 

                                                    
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
 
                         
                   </div>
                        )}
                           <Pagination
                                totalRecords={totalRecords}
                                perPage={recordsPerPage}
                                onPageChange={handlePageChange}
                            />
                    </div>
                </div>
            </div>
     
</main>
</>
    );
};

export default UsersList;
