import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../hooks/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import SEO from "../../../hooks/seo";
import { submitAppUser } from "../../../services/admin/appUserservice";
import { useNavigate, useLocation } from "react-router-dom";
import CustomLoader from '../Loaderfiles/customloader';
import {Link}  from 'react-router-dom';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';


const schema = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  status: yup.number().required('Status is required'),
 
 //  role: yup.array().required("Role is required"),
 // 
 //  agent_commission_type: yup.string().required("Commission Type is required"),
 //  agent_commission: yup.string().required("Agent Commission is required"),
 //  country: yup.string().required("Country is required"),
});

const UserCreate = () => {
  const [formErrors, setFormErrors] = useState([]);

  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
      
  });
    
  const [options, setOptions] = useState([]);
  const [organizations_Options, setOrganizations_Options] = useState([]);
  const [currencies_Options, setCurrencies_Options] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

   const statusOptions = [
      { value:'0',label: 'Inactive' },
      { value:'1',label: 'Active' },
    ]; 
   
  
       
 
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      minHeight: "unset",
    }),
  };


     const handleFileChange = (fileItems) => {
      if (fileItems.length > 0) {
        const image = fileItems[0].file;
        setProfileImage(image);
      } else {
        setProfileImage(null);
      }
    };
   
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {
     
      if (profileImage) {
        formData.image = profileImage;
      }
      const response = await submitAppUser(formData);

      // toast.success(response.data.message);
      // navigate("/admin/app-users", { state: { message: response.data.message } });

         Swal.fire({
          title: 'Good Job',
          text: response.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              navigate('/admin/app-users', { state: { message: response.msg } });
            }
          });



    } catch (error) {


      const errorMessage = error && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An error occurred while submitting the role. Please try again.';
        
          
         Swal.fire({
          title: 'Error',
          text: errorMessage || 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });

         
      console.error("Registration failed:", error);
      if (error.response && error.response.status === 422) {
        // 422 Unprocessable Entity
        console.log("Error details:", error.response.data);
      } else {
        // Handle other errors
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          const errorsArray = Object.entries(error.response.data.error).flatMap(
            ([key, value]) => value.map((v) => `${key}: ${v}`)
          );
          console.log(errorsArray);
        } else {
          // Fallback error message
          console.log("An unexpected error occurred.");
        }
      }
    } 
    
    finally {

      setIsLoading(false);
    }
  };
  const onBackButtonClick = () => {
        navigate('/admin/app-users');
    }
  return (
    <>
    <SEO
    title="Add App User - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <ToastContainer />
      <PageHeader
        title="Add App User"
        showBackButton={true}
        onBackButtonClick={onBackButtonClick}
      />
      <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12 lg:col-span-12">
          <div className="card">
            <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
              <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add App User
                    </h4>
                  </div>
                   

                </div>
            </div>

             {loading ? (
                        <div className="flex justify-center items-center">
                          <div className="flex justify-center items-center h-96">
                            <CustomLoader
                              visible={true}
                              height={80}
                              width={80}
                              color="#4FA94D"
                              ariaLabel="tail-spin-loading"
                              radius={1}
                            />
                          </div>
                        </div>
                      ) : (

                <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Full Name</span>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter Full Name"
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      {errors.name && (
                        <p className="text-red-500">
                          {errors.name.message}
                        </p>
                      )}
                    </label>
                  </div>
                </div>

                 
                 <div className="flex-1">
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Email</span>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter email"
                            type="email"
                            {...field}
                          />
                        )}
                      />
                      {errors.email && (
                        <p className="text-red-500">{errors.email.message}</p>
                      )}
                    </label>
                  </div>
                </div>
              </div>



                 <div className="flex space-x-4">
                <div className="flex-1">
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block">
                        <span>Password</span>
                        <div className="relative">
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Enter Password"
                                type={showPassword ? "text" : "password"} // Show/hide password based on state
                                {...field}
                              />
                            )}
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            onClick={togglePasswordVisibility} // Toggle password visibility when clicked
                          >
                            {showPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 mt-2 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 mt-2 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z" />
                              </svg>
                            )}
                          </button>
                        </div>
                        {errors.password && (
                          <p className="text-red-500">{errors.password.message}</p>
                        )}
                      </label>
                  </div>
                </div>

                 
                 <div className="flex-1">
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                     <label className="block">
                            <span>Status</span>
                            


                               <Controller
                                  name="status"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={statusOptions}
                                      isSearchable={true}
                                      className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                      onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                                      styles={customStyles}
                                      value={statusOptions.find(option => option.value === field.value)}
                                      placeholder="Select Status"
                                    />
                                  )}
                                />

                       


                            {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                          </label>
                  </div>
                </div>
              </div>
                <div className="flex space-x-4">
                <div className="flex-1">
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                   
                    <FilePond
                   className="mt-2"
                              allowMultiple={false}
                              files={profileImage ? [profileImage] : []}
                              onupdatefiles={handleFileChange}
                              labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                              acceptedFileTypes={['image/*']}
                              name="image"
                            />
                        
                      {errors.image && <p className="text-red-500">{errors.image.message}</p>}
                  </div>
                </div>

                 
                  
              </div>




 
              
 

        

              <div className="space-y-4 p-4 sm:p-5">
              <button
                  type="submit"
                  className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>

                       )}
            
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default UserCreate;
