import React, { useState, useEffect, useRef } from "react";
import { EventCodeList } from "../../../services/eventcodelistservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";


const Eventcodelist = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [pageLoading, setPageLoading] = useState(false);
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ');
    };


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await EventCodeList(currentPage, searchText);

            if (response && response.data) {
                setTotalRecords(response.recordsTotal);
                setData(response.data);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
        setPageLoading(true);
    };
    const prevPageRef = useRef();
    const prevSearchTextRef = useRef();

    useEffect(() => {

        if (currentPage !== prevPageRef.current || debouncedSearchText !== prevSearchTextRef.current) {
            console.log('Call Fetch Data');
            fetchData();
        }

        prevPageRef.current = currentPage;
        prevSearchTextRef.current = debouncedSearchText;

    }, [currentPage, debouncedSearchText]);


    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
        alert('CSV Export');
    };

    const handleExportPDF = () => {
        alert('PDF Export');

    };
    if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }

    return (
        <>
            <SEO
                title="Private Access Codes List- eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <PageHeader
                    title="Private Access Codes List"

                    showCreateButton={false}
                />
                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableTools
                            title="Private Access Codes"
                            onSearchChange={handleSearchChange}
                            showSearch={true}
                            showExportButtons={false}
                            onExportCSV={handleExportCSV}
                            onExportPDF={handleExportPDF}
                        />
                        <div className="lg:mt-5 md:mt-5 mt-16">
                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                {loading ? (
                                    <div className="flex justify-center items-center">
                                        <div className="flex justify-center items-center h-96">
                                            <CustomLoader
                                                visible={true}
                                                height={80}
                                                width={80}
                                                color="#4fa94d"
                                                ariaLabel="tail-spin-loading"
                                                radius={1}
                                            />
                                        </div>
                                    </div>
                                ) : data.length === 0 ? (
                                    <div className="text-center text-gray-500 mt-8">
                                        <h1 className="xl text-3xl">No records found.</h1>
                                    </div>
                                ) : (
                                    <table className="is-hoverable w-full text-left">
                                        <thead>
                                            <tr>
                                                <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    #
                                                </th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Event
                                                </th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Access Code
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Type
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Created at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                                >
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {index + 1 || 'N/A'}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        <p dangerouslySetInnerHTML={{ __html: truncateWords(item.event_name, 5) || 'NULL' }} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {item.code || 'N/A'}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        <h5>
                                                            <span className={`inline-block px-2 py-1  font-semibold rounded-full ${item.is_used === 0 ? 'bg-yellow-500 text-white' : 'bg-green-500 text-white'} m-1`}>
                                                                {item.is_used === 0 ? 'Unused' : 'Used'}
                                                            </span>
                                                        </h5>
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {formatDate(item.created_at)}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                )}
                                <Pagination
                                    totalRecords={totalRecords}
                                    perPage={recordsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Eventcodelist;
