import Dashboard from "../pages/admin/dashboard/dashboard";
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "../components/admin/Sidebar";
import Header from "../components/admin/Header";
import RoleList from "../pages/admin/role/rolelist";
import Events from "../pages/admin/events/eventsList";
import EventCountriesList from "../pages/admin/event-country/eventCountriesList";
import EditEventCountries from "../pages/admin/event-country/edit";
import UsersList from "../pages/admin/user/userlist";
import AppUsersList from "../pages/admin/app_user/userlist";
import UserView from "../pages/admin/app_user/userView";
import OrdersList from "../pages/admin/order/orderlist";
import OrderInvoice from "../pages/admin/order/order-invoice";
import ShowOrdersList from "../pages/admin/order/show-order-list";
import CategoryList from "../pages/admin/category/categorylist";
import ScannedTicketsList from "../pages/admin/scannedtickets/scannedticketslist";
import BlogList from "../pages/admin/blog/bloglist";
import BlogCreate from "../pages/admin/blog/blogCreate";
import BlogEdit from "../pages/admin/blog/blogEdit";
import CouponsList from "../pages/admin/coupons/couponslist";
import CouponsCreate from "../pages/admin/coupons/couponCreate";
import CouponsEdit from "../pages/admin/coupons/couponsEdit";
import BannerList from "../pages/admin/desktopBanner/bannerlist";
import BannerCreate from "../pages/admin/desktopBanner/bannerCreate";
import BannerEdit from "../pages/admin/desktopBanner/bannerEdit";
import MobileBannerList from "../pages/admin/mobileBanner/bannerlist";
import MobileBannerCreate from "../pages/admin/mobileBanner/bannerCreate";
import MobileBannerEdit from "../pages/admin/mobileBanner/bannerEdit";
import UserreviewList from "../pages/admin/user-review/UserReviewList";
import CustomerReport from "../pages/admin/card-report/CustomerReport";
import OrganizationReport from "../pages/admin/card-report/OrganizationReport";
import CardRevenueReports from "../pages/admin/card-report/CardRevenueReports";
import ContactQueries from "../pages/admin/contact-queries/contactqueries";
import Language from "../pages/admin/Language/Language";
import FAQs from "../pages/admin/FAQs/FAQs";
import FAQCreate from "../pages/admin/FAQs/faqCreate";
import FAQEdit from "../pages/admin/FAQs/faqEdit";
import PageCreate from "../pages/admin/Pages/pageCreate";
import PageEdit from "../pages/admin/Pages/pageEdit";
import PageList from "../pages/admin/Pages/pageList";
import Feedback from "../pages/admin/Feedback/feedback";
import CreateFeedback from "../pages/admin/Feedback/create-feedback";
import CashAgentSettlement from "../pages/admin/cash-report/cash-agent-settlement";
import CustomerReportCash from "../pages/admin/cash-report/CustomerReportCash";
import OrganizationReportCash from "../pages/admin/cash-report/OrganizationReportCash";
import RevenueReportCash from "../pages/admin/cash-report/RevenueReportCash";
import AgentSettlementDetail from "../pages/admin/cash-report/AgentSettlementDetail";
import SettlementReportsCash from "../pages/admin/cash-report/settlementReportCash";
import VeiwSettlementDetail from "../pages/admin/card-report/veiw-settlement-detail";
import SettlementReports from "../pages/admin/card-report/settlementReports";
import VeiwAdminEvent from "../pages/admin/events/veiwAdminEvent";
import VeiwEventTickets from "../pages/admin/events/veiwEventTickets";
import TicketCreate from "../pages/admin/events/ticketCreate";
import TicketEdit from "../pages/admin/events/ticketEdit";
import CreateEvent from "../pages/admin/events/createevents";
import EditEvent from "../pages/admin/events/editEvent";
import EventGallery from "../pages/admin/events/eventgallery";
import RoleCreate from "../pages/admin/role/roleCreate";
import RoleEdit from "../pages/admin/role/roleEdit";
import UserCreate from "../pages/admin/user/userCreate";
import AdminProfile from "../pages/admin/user/adminProfile";
import AppUserCreate from "../pages/admin/app_user/userCreate";
import UserEdit from "../pages/admin/user/userEdit";
import CategoryCreate from "../pages/admin/category/categoryCreate";
import CategoryEdit from "../pages/admin/category/categoryEdit";
import GetCode from "../pages/admin/order/get-code";
import EditFeedback from "../pages/admin/Feedback/editFeedback";
import NotFoundPage from "../pages/admin/Not-Found/NotFoundPage";
import VeiwSettlementDetailCash from "../pages/admin/cash-report/VeiwSettlementDetailCash";
import ScanOrdersDetail from "../pages/admin/events/ScanOrdersDetail";
import EventOrdersDetail from "../pages/admin/events/EventOrdersDetail";
import SettingsPage from "../pages/admin/setting/SettingsPage";
import { UserProvider } from "../hooks/UserContext";
import { SettingProvider } from "../hooks/SettingContext";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <Sidebar />
            <Header />
            <NotFoundPage />
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <>
            <Sidebar />
            <Header />
            <Dashboard />
          </>
        }
      />
      <Route
        path="/eventgallery/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <EventGallery />
          </>
        }
      />
      <Route
        path="/roles"
        element={
          <>
            <Sidebar />
            <Header />
            <RoleList />
          </>
        }
      />
      <Route
        path="/users"
        element={
          <>
            <Sidebar />
            <Header />
            <UsersList />
          </>
        }
      />
      <Route
        path="/eventOrders/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <EventOrdersDetail />
          </>
        }
      />
      <Route
        path="/scanList/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <ScanOrdersDetail />
          </>
        }
      />
      <Route
        path="/app-users"
        element={
          <>
            <Sidebar />
            <Header />
            <AppUsersList />
          </>
        }
      />
      <Route
        path="/view-user/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <UserView />
          </>
        }
      />
      <Route
        path="/orders"
        element={
          <>
            <Sidebar />
            <Header />
            <OrdersList />
          </>
        }
      />
      <Route
        path="/order-invoice/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <OrderInvoice />
          </>
        }
      />
      <Route
        path="/get-code/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <GetCode />
          </>
        }
      />
      <Route
        path="/categories"
        element={
          <>
            <Sidebar />
            <Header />
            <CategoryList />
          </>
        }
      />
      <Route
        path="/get-code/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <GetCode />
          </>
        }
      />{" "}
      <Route
        path="/get-code/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <GetCode />
          </>
        }
      />
      <Route
        path="/scanned-tickets"
        element={
          <>
            <Sidebar />
            <Header />
            <ScannedTicketsList />
          </>
        }
      />
      <Route
        path="/blogs"
        element={
          <>
            <Sidebar />
            <Header />
            <BlogList />
          </>
        }
      />
      <Route
        path="/blog/create"
        element={
          <>
            <Sidebar />
            <Header />
            <BlogCreate />
          </>
        }
      />
      <Route
        path="blog/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <BlogEdit />
          </>
        }
      />
      <Route
        path="/coupons"
        element={
          <>
            <Sidebar />
            <Header />
            <CouponsList />
          </>
        }
      />
      <Route
        path="/coupon/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <CouponsEdit />
          </>
        }
      />
      <Route
        path="/coupon/create"
        element={
          <>
            <Sidebar />
            <Header />
            <CouponsCreate />
          </>
        }
      />
      <Route
        path="/desktop-banner"
        element={
          <>
            <Sidebar />
            <Header />
            <BannerList />
          </>
        }
      />
      <Route
        path="/mobile-banner"
        element={
          <>
            <Sidebar />
            <Header />
            <MobileBannerList />
          </>
        }
      />
      <Route
        path="/banner/mobile-create"
        element={
          <>
            <Sidebar />
            <Header />
            <MobileBannerCreate />
          </>
        }
      />
      <Route
        path="/banner/create"
        element={
          <>
            <Sidebar />
            <Header />
            <BannerCreate />
          </>
        }
      />
      <Route
        path="desktop-banner/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <BannerEdit />
          </>
        }
      />
      <Route
        path="mobile-banner/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <MobileBannerEdit />
          </>
        }
      />
      <Route
        path="/user-reviews"
        element={
          <>
            <Sidebar />
            <Header />
            <UserreviewList />
          </>
        }
      />
      <Route
        path="/customer-report-card"
        element={
          <>
            <Sidebar />
            <Header />
            <CustomerReport />
          </>
        }
      />
      <Route
        path="/organization-report-card"
        element={
          <>
            <Sidebar />
            <Header />
            <OrganizationReport />
          </>
        }
      />
      <Route
        path="/revenue-report-card"
        element={
          <>
            <Sidebar />
            <Header />
            <CardRevenueReports />
          </>
        }
      />
      <Route
        path="/cash-report/settlement-agent"
        element={
          <>
            <Sidebar />
            <Header />
            <CashAgentSettlement />
          </>
        }
      />
      <Route
        path="/feedback"
        element={
          <>
            <Sidebar />
            <Header />
            <Feedback />
          </>
        }
      />
      <Route
        path="/create-feedback"
        element={
          <>
            <Sidebar />
            <Header />
            <CreateFeedback />
          </>
        }
      />
      <Route
        path="/feedback/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <EditFeedback />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <Sidebar />
            <Header />
            <FAQs />
          </>
        }
      />
      <Route
        path="/faq/create"
        element={
          <>
            <Sidebar />
            <Header />
            <FAQCreate />
          </>
        }
      />
      <Route
        path="/faq/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <FAQEdit />
          </>
        }
      />
      <Route
        path="/pageList"
        element={
          <>
            <Sidebar />
            <Header />
            <PageList />
          </>
        }
      />
      <Route
        path="/page/create"
        element={
          <>
            <Sidebar />
            <Header />
            <PageCreate />
          </>
        }
      />
      <Route
        path="/page/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <PageEdit />
          </>
        }
      />
      <Route
        path="/contact-queries"
        element={
          <>
            <Sidebar />
            <Header />
            <ContactQueries />
          </>
        }
      />
      <Route
        path="/language"
        element={
          <>
            <Sidebar />
            <Header />
            <Language />
          </>
        }
      />
      <Route
        path="/customer-report-cash"
        element={
          <>
            <Sidebar />
            <Header />
            <CustomerReportCash />
          </>
        }
      />
      <Route
        path="/organization-report-cash"
        element={
          <>
            <Sidebar />
            <Header />
            <OrganizationReportCash />
          </>
        }
      />
      <Route
        path="/revenue-report-cash"
        element={
          <>
            <Sidebar />
            <Header />
            <RevenueReportCash />
          </>
        }
      />
      <Route
        path="/settlement-report-cash"
        element={
          <>
            <Sidebar />
            <Header />
            <SettlementReportsCash />
          </>
        }
      />
      <Route
        path="/settlement-report-card"
        element={
          <>
            <Sidebar />
            <Header />
            <SettlementReports />
          </>
        }
      />
      <Route
        path="/view-settlement/card/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <VeiwSettlementDetail />
          </>
        }
      />
      <Route
        path="/agent/view/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <AgentSettlementDetail />
          </>
        }
      />
      <Route
        path="/view-event/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <VeiwAdminEvent />
          </>
        }
      />
      <Route
        path="/:id/:slug/tickets"
        element={
          <>
            <Sidebar />
            <Header />
            <VeiwEventTickets />
          </>
        }
      />
      <Route
        path="/:id/ticket/create"
        element={
          <>
            <Sidebar />
            <Header />
            <TicketCreate />
          </>
        }
      />
      <Route
        path="/ticket/edit/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <TicketEdit />
          </>
        }
      />
      <Route
        path="/profile"
        element={
          <>
            <UserProvider>
              <Sidebar />
              <Header />
              <AdminProfile />
            </UserProvider>
          </>
        }
      />
      <Route
        path="/create-role"
        element={
          <>
            <Sidebar />
            <Header />
            <RoleCreate />
          </>
        }
      />
      <Route
        path="/roles/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <RoleEdit />
          </>
        }
      />
      <Route
        path="/users/create"
        element={
          <>
            <Sidebar />
            <Header />
            <UserCreate />
          </>
        }
      />
      <Route
        path="/users/profile"
        element={
          <>
            <Sidebar />
            <Header />
            <AdminProfile />
          </>
        }
      />
      <Route
        path="/app-user/create"
        element={
          <>
            <Sidebar />
            <Header />
            <AppUserCreate />
          </>
        }
      />
      <Route
        path="/users/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <UserEdit />
          </>
        }
      />
      <Route
        path="/category/create"
        element={
          <>
            <Sidebar />
            <Header />
            <CategoryCreate />
          </>
        }
      />
      <Route
        path="/category/:id/edit"
        element={
          <>
            <Sidebar />
            <Header />
            <CategoryEdit />
          </>
        }
      />
      <Route
        path="/events"
        element={
          <>
            <Sidebar />
            <Header />
            <Events />
          </>
        }
      />
      <Route
        path="/event-country"
        element={
          <>
            <Sidebar />
            <Header />
            <EventCountriesList />
          </>
        }
      />
      <Route
        path="/event-country/edit/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <EditEventCountries />
          </>
        }
      />
      <Route
        path="/create-event"
        element={
          <>
            <Sidebar />
            <Header />
            <CreateEvent />
          </>
        }
      />
      <Route
        path="/editEvent/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <EditEvent />
          </>
        }
      />
      <Route
        path="/view-settlement/cash/:id"
        element={
          <>
            <Sidebar />
            <Header />
            <VeiwSettlementDetailCash />
          </>
        }
      />
      <Route
        path="/settings"
        element={
          <>
            <UserProvider>
              {/* <SettingProvider> */}
              <Sidebar />
              <Header />
              <SettingsPage />
              {/* </SettingProvider> */}
            </UserProvider>
          </>
        }
      />
    </Routes>
  );
};

export default AdminRoutes;
