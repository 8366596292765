import React from "react";
import { OrganizationReportFetch } from "../../../services/admin/customer-report-service";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import TableHeader from "../../../hooks/TableHeader";
import { useState, useEffect, useRef } from "react";
import SEO from "../../../hooks/seo";
import { formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import useResizeObserver from "../../../hooks/useResizeObserver";

const CustomerReport = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [yesOrnot, setyesOrnot] = useState(false);
    const [text, setText] = useState('');
    const [advanced_Search, setAdvancedSearch] = useState(false);

    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await OrganizationReportFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, searchText]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
            setCurrentPage(1);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const handleSearchChange = (e) => {

        setText(e.target.value);
    };
    const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_CARD_ORGANIZATION_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_CARD_ORGANIZATION_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }

    const handleAdvancedSearch = () => {

        setAdvancedSearch(!advanced_Search)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Get the form data
        const formData = new FormData(event.target);

        // Initialize an empty string for the query parameters
        let queryString = '&';

        // Loop through form data entries and append them to the query string
        for (const [name, value] of formData.entries()) {
            queryString += `${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
        }

        // Remove the trailing '&' character
        queryString = queryString.slice(0, -1);
        console.log(queryString);
        setSearchText(queryString);

    };


    const handleReset = () => {
        setSearchText('');
    };

    const search_Text = () => {
        setSearchText(text);
        setyesOrnot(true);
    };
    const resetSearch = () => {
        setSearchText('');
        setText('');
        setyesOrnot(false);

        const inputElement = document.getElementById('inptserch');
        if (inputElement) {
            inputElement.value = ''; // Resetting the value of the input field
        }

    };

    return (
        <>
            <SEO
                title="Organization Report - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
                <PageHeader
                    title="Organization Report"
                    onButtonClick={onButtonClickCreate}
                    showCreateButton={false}
                />

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableHeader
                            //title="Customer Report Rows"
                            onSearchChange={handleSearchChange}
                            showSearch={true}
                            showExportButtons={true}
                            onExportCSV={handleExportCSV}
                            onExportPDF={handleExportPDF}
                            onText={yesOrnot}
                            onsearch_Text={search_Text}
                            onResetSearch={resetSearch}
                            advancedSearch={handleAdvancedSearch}
                            showAdvanceSearch={false}
                        />

                        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
                            {advanced_Search && (
                                <div className="col-span-12 lg:col-span-12 mt-4">
                                    <form onSubmit={handleFormSubmit} className="space-y-4">
                                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                                            <div className="mb-4 lg:mb-0">
                                                <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Organization  Name</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="customer-name"
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Enter Organization  name"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 lg:mb-0">
                                                <label htmlFor="start-date" className="block mb-2 text-sm font-medium dark:text-white">Start Date</label>
                                                <div className="flex" style={{ 'height': '41px' }}>
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10v4m0 0v4m0-4h4m-4 0H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="date"
                                                        id="start-date"
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Select Start Date"
                                                        name="start_date"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 lg:mb-0">
                                                <label htmlFor="end-date" className="block mb-2 text-sm font-medium dark:text-white">End Date</label>
                                                <div className="flex " style={{ 'height': '41px' }}>
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10v4m0 0v4m0-4h4m-4 0H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="date"
                                                        id="end-date"
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Select End Date"
                                                        name="end_date"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-end">
                                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white btn focus:bg-blue-700 ml-2">Apply</button>

                                            <button
                                                type="reset"
                                                className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </form>
                                </div>



                            )}
                        </div>

                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableTools
                            title="Organization Report Rows"

                        // onSearchChange={handleSearchChange}
                        // onExportCSV={handleExportCSV}
                        // onExportPDF={handleExportPDF}
                        // showSearch={true}
                        // showExportButtons={true}
                        />
                        <div className="lg:mt-5 md:mt-5 mt-16a">
                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                {loading ? (
                                    <div className="flex justify-center items-center">
                                        <div className="flex justify-center items-center h-96">
                                            <CustomLoader
                                                visible={true}
                                                height={80}
                                                width={80}
                                                color="#4fa94d"
                                                ariaLabel="tail-spin-loading"
                                                radius={1}
                                            />
                                        </div>
                                    </div>
                                ) : data.length === 0 ? (
                                    <div className="text-center text-gray-500 mt-8">
                                        <h1 className="xl text-3xl">No records found.</h1>
                                    </div>
                                ) : (
                                    <table className="is-hoverable w-full text-left">
                                        <thead>
                                            <tr>
                                                <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Organization Name
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                                                    Phone
                                                </th>
                                                <th className="whitespace-nowrap text-center bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Total Events
                                                </th>
                                                <th className="whitespace-nowrap text-center bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Tickets / Sold
                                                </th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                                                    Registered at
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="border-b border-gray-200 dark:border-b-navy-500 hover:bg-gray-100 dark:hover:bg-gray-800 transition"
                                                >
                                                    <td className="px-6 py-4">
                                                        <div className="flex items-center">
                                                            <div>
                                                                <p className="text-sm font-medium text-gray-900">
                                                                    {item.first_name + ' ' + item.last_name || 'N/A'}
                                                                </p>
                                                                <p className="text-xs text-gray-500">{item.email}</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="px-6 py-4 text-center">
                                                        {item.phone || 'NULL'}
                                                    </td>
                                                    <td className="px-6 py-4 text-center ">
                                                        {item.total_events}
                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        {item.sold_tickets}
                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        {formatDate(item.created_at)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>



                                    </table>
                                )}
                                <Pagination
                                    totalRecords={totalRecords}
                                    perPage={recordsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default CustomerReport;
