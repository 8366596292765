import React, { useState, useEffect , useRef} from "react";
import { useParams,  Link } from "react-router-dom";
import PageHeader from "../../hooks/PageHeader";
import { AgentOrderInvoiceService } from "../../services/agent/agentorderservice";
import { formatDate, formatCurrency } from "../../helpers/Helpers";
import CustomLoader from './customloader';
import { useNavigate } from 'react-router-dom';
import useResizeObserver from "./../../hooks/useResizeObserver";
import { API_ENDPOINTS } from './../../services/api'
import SEO from "../../hooks/seo";


function OrdersInvoice() {

  const { id } = useParams();
  const [Invoice, setInvoice] = useState([]);
  const [EvtData, setEvtData] = useState([]);
  const [Customerdata, setCustomer] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
 const [pageLoading, setPageLoading] = useState(false);
 const [downloadClicked, setDownloadClicked] = useState(false);


  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await AgentOrderInvoiceService(id);
      if (response && response.order) {
        setInvoice(response.order);
        setEvtData(response.order.event);
        setCustomer(response.order.customer);
        setTicket(response.order.ticket);
        setTicketData(response.order.ticket_data)
      } else {
        setEvtData([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handlePrint = () => {
    const contentToPrint = document.getElementById('printSection').innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = contentToPrint;
    window.print();
    document.body.innerHTML = originalContent;
  };
  const handleDownload = async () => {
    const urlWithTokenAndId = `${API_ENDPOINTS.DOWNLOAD_INVOICE_DATA}/${id}`;
    
    setDownloadClicked(true);
  
    try {
       const response = await fetch(urlWithTokenAndId);
  
       if (response.ok) {
         window.location.href = urlWithTokenAndId;
      } else {
        console.error("Failed to download file. Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading file: ", error);
    } finally {
       setDownloadClicked(false);
    }
  }
   if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}}
              className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
 const onBackButtonClick = () => {
        navigate('/agent/orders');
    }


  return (
    <>
       <SEO
    title="Order Invoice - eticketing"
  />
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      ) : Invoice.length === 0 ? (
        <div className="text-center text-gray-500 mt-8">
          <h1 className="xl text-3xl">No records found.</h1>
        </div>
      ) : (
        <main ref={ref} style={{ width: '100%', height: '100%'}}
          class="main-content w-full px-[var(--margin-x)] pb-8">
             <PageHeader
        title="Order Invoice"
        showBackButton={true}
        onBackButtonClick={onBackButtonClick}
      />
          <div class="flex items-center justify-between py-5 lg:py-6">
            <h2
              class="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50 lg:text-2xl"
            >
            </h2>

            <div class="flex">
              <button
                onClick={handlePrint}
                class="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:h-9 sm:w-9"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="size-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
              </button>
              <button
            onClick={handleDownload}
            className="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:h-9 sm:w-9"
          >
            {downloadClicked ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="black" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25"/><path fill="black" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
              >
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                <path d="M12 15l-4-4h3V8h2v3h3l-4 4z" fill="currentColor" />
              </svg>
            )}
          </button>
            </div>
          </div>
          <div id="printSection">
          <div class="grid grid-cols-1">
            <div class="card px-5 py-12 sm:px-18">
              <div>
                <div class="flex justify-between items-center">
                  <div class="text-center sm:text-left">
                    <h2 class="text-2xl font-semibold uppercase text-primary dark:text-accent-light mb-3">
                      <img src="https://api.tikkets.com/public/images/upload/65dd99257c6fc.png" alt="Kanoo Logo" width="120" height="120" />
                    </h2>
                    <div class="space-y-1 pt-2">
                      <p>15 Grenada Avenue.</p>
                      <p>Federation Park</p>
                      <p>Trinidad and Tobago</p>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div class="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
              <div class="flex flex-col justify-between sm:flex-row">
                <div class="text-center sm:text-left">
                  <h2
                    class="text-2xl font-semibold uppercase text-slate-600 dark:text-navy-100"
                  >
                    invoice
                  </h2>
                  <div class="space-y-1 pt-2">
                    <p>Order : <span class="font-semibold">{Invoice.order_id || 'N/A'}</span></p>
                    <p>
                    Order Date: <span class="font-semibold">{formatDate(Invoice.created_at)}</span>
                    </p>
                    <p>
                      Event Name: <span class="font-semibold">{EvtData.name}</span>
                    </p>
                  </div>
                </div>
                <div class="mt-4 text-center sm:mt-0 sm:text-right">
                  <p
                    class="text-lg font-medium text-slate-600 dark:text-navy-100"
                  >
                    Invoiced To:
                  </p>
                  <div class="space-y-1 pt-2">
                    {Customerdata ? `${Customerdata.name || 'N/A'} ${Customerdata.last_name || 'N/A'}` : 'N/A'}

                    <p>{Customerdata ? `${Customerdata.email || 'N/A'}` : 'N/A'}</p>
                  </div>
                </div>
              </div>
              <div class="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto mt-5">
                <table className="is-hoverable w-full text-center bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        #
                      </th>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Ticket Name
                      </th>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Ticket Number
                      </th>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Price
                      </th>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Ticket Type
                      </th>
                      <th className="whitespace-nowrap bg-slate-50 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Code
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    {Object.keys(ticketData).length > 0 ? (
                      Object.values(ticketData).map((ticket_data, index) => (
                        <tr key={index} className="bg-gray-100 dark:bg-gray-700">
                          <td className="px-4 py-3">{index + 1}</td>
                          <td className="px-4 py-3">{ticket.name || 'N/A'}</td>
                          <td className="px-4 py-3">{ticket_data.ticket_number || 'N/A'}</td>
                          <td className="px-4 py-3">
                          {Invoice?.payment_type === 'Complimentary' || Invoice?.payment_type === 'Free'
                                                                ? '0'
                                                                : `${Invoice.curruncry === 'JMD' ? 'JMD$' : Invoice.curruncry} ${(ticket?.local_currency_price ?? 0).toFixed(2)}`
                                                            }
                           
                            
                          </td>
                          <td className="px-4 py-3">

                              {Invoice?.payment_type}
                              </td>
                          <td className="px-4 py-3">
                            <Link to={`/agent/get-code/${ticket_data.id}`}>
                              <button
                                className="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:h-9 sm:w-9"
                              >
                                <svg
                                  className="h-6 w-6 text-gray-500"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" />
                                  <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />
                                  <path d="M4 17v1a2 2 0 0 0 2 2h2" />
                                  <path d="M16 4h2a2 2 0 0 1 2 2v1" />
                                  <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />
                                  <rect x="5" y="11" width="1" height="2" />
                                  <line x1="10" y1="11" x2="10" y2="13" />
                                  <rect x="14" y="11" width="1" height="2" />
                                  <line x1="19" y1="11" x2="19" y2="13" />
                                </svg>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="px-4 py-3 text-center">No tickets available</td>
                      </tr>
                    )}

                  </tbody>
                </table>
              </div>
              <div class="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
              <div class="flex flex-col justify-between sm:flex-row">
                <div class="text-center sm:text-left">
                  <p
                    class="text-lg font-medium text-slate-600 dark:text-navy-100"
                  >
                    Payment Method: <br /> {Invoice.payment_type}
                  </p>
                  <div class="space-y-1 pt-2">
                    <p class="font-medium">{ticket.type}</p>
                  </div>
                  <p className="mt-3">Approval code:- {Invoice.approval_code}</p>
                </div>
                <div class="mt-4 text-center sm:mt-0 sm:text-right">
                  <p
                    class="text-lg font-medium text-slate-600 dark:text-navy-100"
                  >
                    Total:
                  </p>
                  <div class="space-y-1 pt-2">
                <p>Subtotal : <span class="font-medium">
               
                {Invoice?.payment_type === 'Complimentary' || Invoice?.payment_type === 'Free'
                                                            ? '0'
                                                            : `${Invoice.curruncry === 'JMD' ? 'JMD$' : Invoice.curruncry} ${(Invoice.sub_total ?? 0).toFixed(2)}`
                                                        }
                  </span></p>
                <p>Coupon Discount : <span class="font-medium">(-) {(Invoice.coupon_discount ?? 0).toFixed(2)}
                </span></p>
                <p class="text-lg text-primary dark:text-accent-light">
                  Total: <span class="font-medium">
                  {Invoice?.payment_type === 'Complimentary' || Invoice?.payment_type === 'Free'
                                                            ? '0'
                                                            : `${Invoice.curruncry === 'JMD' ? 'JMD$' : Invoice.curruncry} ${(Invoice.sub_total ?? 0).toFixed(2)}`
                                                        }
                  </span>
                </p>
              </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </main>
      )}
    </>
  )
}

export default OrdersInvoice