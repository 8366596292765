import React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../hooks/PageHeader";
import { useState, useEffect } from "react";
import { useRef } from 'react';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { Blog_Create } from "../../../services/admin/blogservice";
import { submitBlog } from "../../../services/admin/blogservice";
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import ReactQuill from "react-quill";
const schema = yup.object().shape({
  title: yup.string().required('title is required'),
  status: yup.number().required('Status is required'),
  category_id: yup.number().required('category is required'),
  //image: yup.mixed().required('File is required'),
  description: yup.string().required('Description is required'),

  // Add more validation rules as needed
});



const BlogCreate = () => {

  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });




  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const filePondRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Blog_Create();
        setIsLoading(false);



        if (response && response.category) {
          const categoryData = response.category;

          const categoryOption = categoryData.map(category => ({
            value: category.id,
            label: category.name,
          }));

          setOptions(categoryOption);

        }
      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);
        toast.success('This is a success message');
      } finally {
        setLoading(false);

      }
      setPageLoading(true);
    };

    fetchData();
  }, []);



  const handleFileChange = (fileItems) => {
    if (fileItems.length > 0) {
      const image = fileItems[0].file;
      setProfileImage(image);
    } else {
      setProfileImage(null);
    }
  };





  const statusOptions = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };




  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {
      if (!profileImage) {
        Swal.fire({
            title: 'Error',
            text: 'Profile image is required. Please upload an image to proceed.',
            icon: 'error',
            confirmButtonColor: '#a23896',
            confirmButtonText: 'OK',
            showCloseButton: false
        });
        setIsLoading(false);
        return; 
    }
      if (profileImage) {
        formData.image = profileImage;
      }

      const response = await submitBlog(formData);


      if (response.status === 201) {
        // toast.success(response.data.message);
        // navigate('/admin/blogs', { state: { message: response.data.message } });


        Swal.fire({
          title: 'Good Job',
          text: response.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked 'OK'
            navigate('/admin/blogs', { state: { message: response.msg } });
          }
        });

      }
      e.target.reset();
      //navigate('/login', { state: { message: 'Registration successful. Now you can log in with your credentials.' } });
    } catch (error) {
      console.error('Registration failed:', error);


      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });


      if (error.response && error.response.status === 422) {
        // 422 Unprocessable Entity
        console.log('Error details:', error.response.data);

      } else {
        // Handle other errors
        if (error.response && error.response.data && error.response.data.error) {
          const errorsArray = Object.entries(error.response.data.error).flatMap(([key, value]) => value.map(v => `${key}: ${v}`));
          console.log(errorsArray);
        } else {
          // Fallback error message
          console.log('An unexpected error occurred.');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4FA94D"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  const onBackButtonClick = () => {
    navigate('/admin/blogs');
  }


  return (
    <>
      <SEO
        title="Add Blog - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <ToastContainer />
        <PageHeader
          title="Add Blog"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add Blog
                    </h4>
                  </div>

                </div>
              </div>

              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : (

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Title</span>
                      <Controller
                        name="title"
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <input
                              className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                              placeholder="Enter title"
                              type="text"
                              {...field}
                            />
                            {fieldState.error && (
                              <p className="text-red-500">{fieldState.error.message}</p>
                            )}
                          </>
                        )}
                      />
                    </label>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Category</span>
                          <Controller
                            name="category_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={options}
                                isSearchable={true}
                                className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                onChange={(selected) => setValue('category_id', selected ? selected.value.toString() : null)}
                                styles={customStyles}
                                value={options.find((option) => option.value === field.value)}
                                placeholder="Select Category"
                              />
                            )}
                          />
                          {errors.category_id && <p className="text-red-500">{errors.category_id.message}</p>}
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Status</span>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={statusOptions}
                                isSearchable={true}
                                className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                                styles={customStyles}
                                value={statusOptions.find((option) => option.value === field.value)}
                                placeholder="Select Status"
                              />
                            )}
                          />
                          {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                        </label>
                      </div>
                    </div>
                  </div>


                  <div className="space-y-4 p-4 sm:p-5" id="category">

                    <FilePond
                      className="mt-2"
                      allowMultiple={false}
                      files={profileImage ? [profileImage] : []}
                      onupdatefiles={handleFileChange}
                      labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                      acceptedFileTypes={['image/*']}
                      name="image"
                    />



                  </div>



                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">  Description  </label>  
                      {/* <span>Description</span> */}
                      <Controller
                          name="description"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <ReactQuill
                                id="description"
                                value={field.value || ''}
                                onChange={(content) => {
                                  const isEmpty = !content || content === '<p><br></p>';
                                  field.onChange(isEmpty ? '' : content); // Update the form field with React Quill content
                                }}
                                style={{ height: '150px', marginBottom: '40px' }}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2,3,4, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                  ],
                                }}
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'link',
                                  'image',
                                ]}
                                placeholder="Write something..."
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                            </>
                          )}
                        />


                 
                  </div>



                  <div className="space-y-4 p-4 sm:p-5" id="category">

                    <button
                      type="submit"
                      className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center space-x-2">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>


                    {/* <button
                          type="submit"
                          className="btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                          Submit
                        </button> */}
                  </div>
                </form>
              )}

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BlogCreate;
