
const API_BASE_URL = 'https://api.tikkets.com/public/api/'; 
const API_BASE_URL_FOR_EXPORT = 'https://api.tikkets.com/public/organizer/';
const API_BASE_URL_FOR_EXPORT_Agent = 'https://api.tikkets.com/public/agent/';
export const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}login`,
  forget_Password: `${API_BASE_URL}forget-password`,
  ORG_DASHBOARD_DATA: `${API_BASE_URL}organization/home`,
  REGISTER: `${API_BASE_URL}register`,
  SCANNERS_LIST: `${API_BASE_URL}scanner`,
  OrgSCANNERS: `${API_BASE_URL}scanner`,
  CSV_EVENT_eventOrders:`${API_BASE_URL_FOR_EXPORT}export-event-orders-csv/`,
  PDF_EVENT_eventOrders:`${API_BASE_URL_FOR_EXPORT}export-event-orders-pdf/`,

  ScannerEdit: `${API_BASE_URL}scanner/edit/`,
  DELETE_SCANNER_LIST: `${API_BASE_URL}scanner/destroy/`,
  Store_Scanner: `${API_BASE_URL}scanner/update/`,

  SCANNERS_STATUS: `${API_BASE_URL}scanner/block`,
  ORG_ORDERS_LIST: `${API_BASE_URL}orders`, 
  EVENT_LIST: `${API_BASE_URL}events`,
  search_Users: `${API_BASE_URL}organization/user/search`,
  search_event: `${API_BASE_URL}organization/user/event/search`,
  AGENT_SALES: `${API_BASE_URL}organization/agent_sales`,
  agent_SearchEvents: `${API_BASE_URL}agent/event-with-category`,
  CREATE_WindowTICKETDATA: `${API_BASE_URL}agent/agent_purchase_ticket`,
  AgentsearchUsers: `${API_BASE_URL}agent/user/search`,
  AgentsearchUsersPos: `${API_BASE_URL}agent/user/searchPos`,
  TicketsSellAgent: `${API_BASE_URL}agent/assign_Ticket_paid_new`, 
  allcategoryAgent: `${API_BASE_URL}agent/allcategories`,
  allEventAgent: `${API_BASE_URL}agent/event-with-category`,
  fetchEventCate: `${API_BASE_URL}agent/event-with-category`,
  fetchEventTickets: `${API_BASE_URL}agent/event-tickets/`,
  AgentsearchEvent: `${API_BASE_URL}agent/user/event/search`,
  GENERATE_EVENT_CODE: `${API_BASE_URL}organization/event-codes`,
  EVENT_CODE_LIST: `${API_BASE_URL}organization/event-codes-list`,
  ORG_DELETE_ADMIN_GALLERY: `${API_BASE_URL}remove-image/`,
  ORG_EVENTS_FETCH_GALLERY: `${API_BASE_URL}event-gallery/`,
  ORG_EVENTS_Submit_GALLERY: `${API_BASE_URL}organization/add-event-gallery`,
  SCANNER_TICKKETS: `${API_BASE_URL}scan-list`,
  ORG_GET_CODE:`${API_BASE_URL}get-code/`,
  CREATE_SCANNER: `${API_BASE_URL}scanner/store`,
  CREATE_EVENTS: `${API_BASE_URL}events/create`,
  ORG_EVENTS_LIST_DELETE: `${API_BASE_URL}events/destroy/`,
  ORDER_INVOICE: `${API_BASE_URL}order-invoice-print/`,
  GENERATE_EVENT: `${API_BASE_URL}organization/event-codes-generate`,
  DOWNLOAD_CSV: `${API_BASE_URL}organization/event-codes/download-codes`,
  AGENT_SALES_DETAILS: `${API_BASE_URL}organization/agentOrdersDetail`,
  FETCH_USER: `${API_BASE_URL}complimentary-tickets/create`,
  CHECKAVAILABLECOMPLIMENTARY: `${API_BASE_URL}checkAvailableComplimentaryTicket`,
  CREATECOMPLIMENTTARY: `${API_BASE_URL}assign-complimentary-ticket`,
  SCANNER_SEARCH: `${API_BASE_URL}events/scanner/search`,
  createEventOrg:`${API_BASE_URL}events/create`,
  STORE_EVENT: `${API_BASE_URL}events/store`,
  UPDATE_SINGLE_EVENT: `${API_BASE_URL}events/update/:id`,
  FETCH_SINGLE_EVENT: `${API_BASE_URL}events/:id/edit`,
  ORG_DASHBOARD_DATA_GRAPH: `${API_BASE_URL}organization/dashboard_graph_data`,
  Agent_DASHBOARD_DATA_GRAPH: `${API_BASE_URL}agent/dashboard_graph_data`,
  EVENT_Tickets: `${API_BASE_URL}`,
  ORG_TICKETS_SORTING: `${API_BASE_URL}update-sorting/tickets`,
  DELETE_ORG_EVENTS: `${API_BASE_URL}ticket/delete/`,
  AGENT_EVENT_TICKETS: `${API_BASE_URL}agent/event-tickets`,
  StoreProfile: `${API_BASE_URL}organizer/update-profile`,
  ORG_VEIW_Event_LIST: `${API_BASE_URL}eventOrders/`,
  ORG_VEIW_SCAN_LIST: `${API_BASE_URL}scanList/`,

  orgProfile: `${API_BASE_URL}organizer/profile`,
  ORG_VEIW_EVENT_LIST: `${API_BASE_URL}events/`,
   ORG_Edit_ticket: `${API_BASE_URL}ticket/edit/`,
   DOWNLOAD_INVOICE_DATA: `${API_BASE_URL}order-ticket-print`,
  ORG_UpdateTickets: `${API_BASE_URL}ticket/update/`,
  ORG_StoreTickets: `${API_BASE_URL}ticket/`,
  AGENT_DASHBOARD_LIST: `${API_BASE_URL}agent/home`,
  AGENT_FETCH_Fee: `${API_BASE_URL}agent/fee-calculation`,
  TICKET_CREATE: `${API_BASE_URL}fetch/`,
  ORG_FETCH_GALLERY: `${API_BASE_URL}my-gallery`,
  ORG_DELETE_GALLERY: `${API_BASE_URL}remove-my-image/`,
  FETCHDETAILS_DATA: `${API_BASE_URL}organization/top_records`,
  ORG_DASHBOARD_DATA_DATE:`${API_BASE_URL}organization/ticket_sale_percentage`,
  // Csv&&PDF api endpoints
  CSV_AGENT_SALE: `${API_BASE_URL_FOR_EXPORT}export-agentsales-csv`,
  PDF_AGENT_SALE: `${API_BASE_URL_FOR_EXPORT}export-agentsales-pdf`,
  CSV_AGENT_SALE_DETAIL: `${API_BASE_URL_FOR_EXPORT}export-agentsalesdetails-csv`,
  PDF_AGENT_SALE_DETAIL: `${API_BASE_URL_FOR_EXPORT}export-agentsalesdetails-pdf`,
  CSV_GERNATE_EVENT_CODE: `${API_BASE_URL_FOR_EXPORT}export-event-codes-csv`,
  PDF_GERNATE_EVENT_CODE: `${API_BASE_URL_FOR_EXPORT}export-event-codes-pdf`,
  CSV_ORDER: `${API_BASE_URL_FOR_EXPORT}export-orders-csv`,
  PDF_ORDER : `${API_BASE_URL_FOR_EXPORT}export-orders-pdf`,
  CSV_EVENT: `${API_BASE_URL_FOR_EXPORT}export-events-csv`,
  PDF_EVENT : `${API_BASE_URL_FOR_EXPORT}export-events-pdf`,
  CSV_SCANNER: `${API_BASE_URL_FOR_EXPORT}export-scanners-csv`,
  PDF_SCANNER : `${API_BASE_URL_FOR_EXPORT}export-scanners-pdf`,
  AGENT_CSV_CASHTOTAL_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-cashtotal-csv`,
  AGENT_PDF_CASHTOTAL_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-cashtotal-pdf`,
  AGENT_CSV_CASHHandle_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-cashhandling-csv`,
  AGENT_PDF_CASHTOTAL_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-cashhandling-pdf`,
  PDF_DASHBOARD: `${API_BASE_URL_FOR_EXPORT}export-upcoming-event-pdf`,
  CSV_DASHBOARD: `${API_BASE_URL_FOR_EXPORT}export-upcoming-event-csv`,
  CSV_SCANNED_TICKETS: `${API_BASE_URL_FOR_EXPORT}export-scanned-tickets-csv`,
  PDF_SCANNED_TICKETS: `${API_BASE_URL_FOR_EXPORT}export-scanned-tickets-pdf`,
  AGENT_CSV_ORDER_LIST_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-orders-csv`,
  AGENT_ORDER_LIST_EXPORT_PDF: `${API_BASE_URL_FOR_EXPORT_Agent}export-orders-pdf`,
  AGENT_PDF_CASHHandle_EXPORT: `${API_BASE_URL_FOR_EXPORT_Agent}export-orders-pdf`,

  // agent api endpoints
  PROFILE_DETAIL: `${API_BASE_URL}profile`,
  AGENT_ORDERS_LIST: `${API_BASE_URL}agent/orders`,
  AGENT_ORDER_INVOICE: `${API_BASE_URL}agent/order-invoice/`,
  AGENT_GET_CODE: `${API_BASE_URL}agent/get-code/`,
  AGENT_CASH_TOTAL: `${API_BASE_URL}agent/tickets/cash_totals`,
  AGENT_CASH_TOTAL_DETAIL: `${API_BASE_URL}agent/tickets/cash_totals_detail`,
  AGENT_CASH_HANDLING: `${API_BASE_URL}agent/tickets/cash_handling`,
  AGENT_CASH_HANDLING_DEPOSIT: `${API_BASE_URL}agent/tickets/add-deposit`,
  CREATE_SELLTICKETDATA: `${API_BASE_URL}agent/assign_Ticket_paid`,
  AGENT_CHECK_AVAILABLE_TICKET: `${API_BASE_URL}agent/check-available-ticket`,
  AGENT_CREATECOMPLIMENTTARY: `${API_BASE_URL}assign-complimentary-ticket`,
  AGENT_FETCH_USER: `${API_BASE_URL}agent/tickets/create`,
  AGENT_CHECK_ticketPrice: `${API_BASE_URL}agent/single-ticket-price`,
  AGENT_Profile: `${API_BASE_URL}agent/profile`,
  AGENT_Profile_UPDATE: `${API_BASE_URL}agent/update-profile`,


  
  //admin
  ADMIN_EVENTS:`${API_BASE_URL}admin/events`,
  createEvent:`${API_BASE_URL}admin/events/create`,
  StoreEvent:`${API_BASE_URL}admin/events/store`,
  editEvent:`${API_BASE_URL}admin/events/`,

};

export default API_ENDPOINTS;
