import QRCode from 'qrcode.react';
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { GetCodeFetch } from "../../../services/orderinvoiceservice";
import PageHeader from "../../../hooks/PageHeader";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";


const ORGGetCode = () => {
    const { id } = useParams();
    const [Invoice, setInvoice] = useState([]);
    const [EvtData, setEvtData] = useState([]);
    const [organizer, setOrganizer] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const authToken = localStorage.getItem("authToken");
    const [pageLoading, setPageLoading] = useState(false);
    const navigate = useNavigate();
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
    const fetchData = async (id) => {
        setLoading(true);
        try {
            const response = await GetCodeFetch(id);

            if (response && response.ticket) {
                setInvoice(response.ticket.order);
                setEvtData(response.ticket.order.event);
                setOrganizer(response.ticket.order.organization);
                setCustomer(response.ticket.order.customer);
                setTicket(response.ticket);

            } else {
                setEvtData([]);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
        setPageLoading(true);
    };
    const formatDate = (dateString) => {
        const formattedDate = new Date(dateString).toLocaleString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
        return formattedDate;
    };
    useEffect(() => {
        fetchData(id);
    }, [id]);
    if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    const onBackButtonClick = () => {
        navigate(-1);
    }


    return (
        <>
            <SEO
                title="Get Code - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                class="main-content w-full px-[var(--margin-x)] pb-8 pt-8">
                <PageHeader
                    showBackButton={true}
                    onBackButtonClick={onBackButtonClick}
                />
                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-center h-96">
                            <CustomLoader
                                visible={true}
                                height={80}
                                width={80}
                                color="#4fa94d"
                                ariaLabel="tail-spin-loading"
                                radius={1}
                            />
                        </div>
                    </div>
                ) : Invoice.length === 0 ? (
                    <div className="text-center text-gray-500 mt-8">
                        <h1 className="xl text-3xl">No records found.</h1>
                    </div>
                ) : (
                    <>
                        <div class="grid grid-cols-1">
                            <div class="card px-5 py-12 sm:px-18">
                                <div>
                                    <div class="text-center sm:text-left">
                                        <h2
                                            class="text-3xl font-semibold uppercase text-gray dark:text-accent-light"
                                        >
                                            e-Ticket
                                        </h2>
                                    </div>
                                </div>
                                <div className="my-4 mx-auto mr-[48px] max-w-md flex items-center justify-end pr-4">

                                </div>


                                <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
                                <div className="grid lg:grid-cols-3 grid-cols-1">
                                    <div className="lg:text-start text-center">
                                        <h2
                                            class="text-2xl font-semibold uppercase text-slate-600 dark:text-navy-100"
                                        >
                                            {EvtData.name}
                                        </h2>
                                        <div className="space-y-1 pt-2">
                                            <p className='text-black text-base'><span className='font-semibold'>Start Date:</span> {formatDate(EvtData.start_time)}</p>
                                            <p className='text-black text-base'><span className='font-semibold'>End Date:</span> {formatDate(EvtData.end_time)}</p>
                                            <p className='text-black text-base w-96'><span className='font-semibold'>Address:</span> {EvtData.address || 'N/A'}</p>
                                            <p className='text-black text-base'><span className='font-semibold'>Organizer:</span> {organizer.first_name + ' ' + organizer.last_name}</p>
                                        </div>
                                    </div>
                                    <div className="mt-8 text-center lg:mt-0  ">
                                        <p
                                            class="text-xl font-medium text-black"
                                        >
                                            Ticket: {ticket.ticket_number || 'N/A'}
                                        </p>
                                        <div class="space-y-1 pt-2">
                                            <div>
                                                {customer && (
                                                    <>
                                                        <p className="text-black text-xl font-semibold">{customer.name ? `${customer.name} ${customer.last_name || ''}` : 'N/A'}</p>
                                                        <p className="text-lg font-medium text-black">Payment Method: {Invoice.payment_type || 'N/A'}</p>
                                                    </>
                                                )}
                                      
                                            </div>

                                        </div>
                                        {ticket.scanned_at !== null && (
                                        <p className="text-lg font-medium text-black">
                                            Scanned at: {formatDate(ticket.scanned_at)}
                                        </p>
                                        )}
                                    </div>
                                    <div className="lg:mt-0 mt-8 flex lg:justify-end lg:items-end justify-center items-center">
                                        <QRCode
                                            value={ticket.ticket_number}
                                            style={{ width: '150px', height: '150px' }}
                                        />

                                    </div>
                                </div>
                                <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
                            </div>
                        </div>
                    </>
                )}
            </main>
        </>
    )
}

export default ORGGetCode;