import axios from "axios";
import { API_ENDPOINTS } from "../admin/api";
import Swal from "sweetalert2";

export const EventListFetch = async (currentPage, searchText, EventValue) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_EVENTS +
        `?page=${currentPage}` +
        `&search=${searchText}` +
        `&event_value=${EventValue}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};
export const EventGalleryFetch = async (id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      `${API_ENDPOINTS.ADMIN_EVENTS_FETCH_GALLERY}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const EventGallerySubmit = async (formData) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.post(
      API_ENDPOINTS.ADMIN_EVENTS_Submit_GALLERY,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data", // Assuming formData is multipart/form-data
        },
      }
    );
    return response;
  } catch (error) {
    console.error("event error:", error);
    throw error;
  }
};

export const EventOrderList = async (currentPage, searchText, id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText ? `&search=${searchText}` : "";
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_VEIW_Event_LIST +
        `${id}` +
        `?page=${currentPage}${searchParam}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};
export const ScanOrderList = async (currentPage, searchText, id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText ? `&search=${searchText}` : "";
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_VEIW_SCAN_LIST +
        `${id}` +
        `?page=${currentPage}${searchParam}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const EventTickets = async (currentPage, searchText, id, slug) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_EVENT_Tickets +
        id +
        `/` +
        slug +
        "/tickets" +
        `?page=${currentPage}` +
        `&search=${searchText}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const getScanner = async () => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(API_ENDPOINTS.getSCANNERS, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const createEvent = async () => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(API_ENDPOINTS.createEvent, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("event error:", error);
    throw error;
  }
};
// export const submitEventData = async (formData) => {
//   try {
//     const formDataToSend = new FormData();
//     formDataToSend.append('image', formData.image);
//     formDataToSend.append('desktop_image', formData.desktop_image);
//     formDataToSend.append('currency_id', formData.currency_id);
//     for (const key in formData) {
//       if (key !== 'image' && key !== 'desktop_image') {
//         formDataToSend.append(key, formData[key]);
//       }
//     }
//
//
//       formData.scanner_id.forEach((scannerId) => {
//         formDataToSend.append('scanner_id[]', scannerId);
//       });
//     const authToken = localStorage.getItem('authToken');
//     const response = await fetch(API_ENDPOINTS.CREATE_EVENTS, {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${authToken}`,
//       },
//       body: formDataToSend,
//     });
//
//     if (!response.ok) {
//       throw new Error('Failed to submit event data');
//     }
//
//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error('Error submitting event data:', error);
//     throw error;
//   }
// };

export const storeEvent = async (formData) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios({
      method: "post",
      url: API_ENDPOINTS.StoreEvent,
      headers: {
        Authorization: `Bearer ${authToken}`, // Ensure authToken is defined and available
      },
      data: formData,
    });

    return response.data;
  } catch (error) {
    const errorMessages = Object.values(error.response.data.error).flatMap(
      (fieldErrors) => fieldErrors
    );
    const errorMessage = errorMessages.join(", ");
    Swal.fire({
      icon: "error",
      title: "Error",
      text: errorMessage,
      showCloseButton: true,
      closeButtonAriaLabel: "Close",
    });
    throw error;
  }
};

export const searchScanner = async (scanner_name) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.SCANNER_SEARCH + "?scanner_name=" + scanner_name,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const submitEventData = async (formDataToSend) => {
  try {
    console.log("API Request Data:", formDataToSend);

    const authToken = localStorage.getItem("authToken");
    const response = await fetch(API_ENDPOINTS.StoreEvent, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formDataToSend,
    });

    console.log("API Response:", response);

    if (!response.ok) {
      throw new Error("Failed to submit event data");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error submitting event data:", error);
    throw error;
  }
};

export const VeiwEventListFetch = async (searchText, id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText ? `&search=${searchText}` : "";
    const response = await axios.get(
      API_ENDPOINTS.VEIW_EVENT_LIST + `${id}` + `${searchParam}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const updateEvent = async (formData, event_id) => {
  try {
    const updatedUrl = API_ENDPOINTS.ADMIN_UPDATE_SINGLE_EVENT + event_id;

    const authToken = localStorage.getItem("authToken");
    const response = await axios({
      method: "post",
      url: updatedUrl,
      headers: {
        Authorization: `Bearer ${authToken}`, // Ensure authToken is defined and available
      },
      data: formData,
    });

    return response.data;
  } catch (error) {
    const errorMessages = Object.values(error.response.data.error).flatMap(
      (fieldErrors) => fieldErrors
    );
    const errorMessage = errorMessages.join(", ");
    Swal.fire({
      icon: "error",
      title: "Error",
      text: errorMessage,
      showCloseButton: true,
      closeButtonAriaLabel: "Close",
    });

    throw error;
  }
};

export const deleteTicket = async (id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_DELETE_ADMIN_EVENTS + id,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error deleting ticket:", error);
    throw error;
  }
};
export const deleteEventGallery = async (eventId, imageName) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_DELETE_ADMIN_GALLERY + imageName + "/" + eventId,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting gallery:", error);
    throw error;
  }
};

export const deleteEvent = async (id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      API_ENDPOINTS.ADMIN_EVENTS_LIST_DELETE + id,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error deleting ticket:", error);
    throw error;
  }
};

export const fetchEvent = async (event_id) => {
  try {
    const updatedUrl = API_ENDPOINTS.FETCH_SINGLE_EVENT.replace(
      ":id",
      event_id
    );
    const authToken = localStorage.getItem("authToken");
    const response = await axios({
      method: "get",
      url: updatedUrl,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error submitting event data:", error);
    throw error;
  }
};
