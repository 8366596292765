import React from "react";
import { UserReviewFetch } from "../../../services/admin/user-review-service";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS }  from '../../../services/admin/api'
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";

 
const UserReviewList = () => {
     
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
     const [pageLoading, setPageLoading] = useState(false);

     {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await UserReviewFetch(currentPage, searchText);
                
                if (response && response.data) {
                    
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                    //setCustomer(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };

        fetchData();
    }, [currentPage, searchText]);
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchText(searchText);
          setCurrentPage(1);
        }, 300); // Adjust the delay as needed
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
          const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_REVEIW_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };

    const handleExportPDF = () => {
         const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_REVEIW_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
     if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
 
    return (
        <>
        <SEO
        title="User Review List - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="User Review List"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Users Review Rows"
                        onSearchChange={handleSearchChange}
                         showSearch={true}
                         showExportButtons={true}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                         
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                         <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Customer
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Event Name
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Message
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Rate
                                        </th>
                                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                      {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            {item.user?.name + ' ' + item.user?.last_name || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            {item.event?.name || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            {item.message || 'N/A'}
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                               {Array.from({ length: 5 }).map((_, index) => (
                                                        <span
                                                            key={index}
                                                            className={`text-2xl ${index < item.rate ? 'text-yellow-500' : 'text-gray-400'
                                                                }`}
                                                        >
                                                            &#9733;
                                                        </span>
                                                    ))}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                
                                                <Link className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25">
                                                    <i className="fa fa-edit" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                 
                            </table>
                     )}
                        <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                        </div>
                                    
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default UserReviewList;
