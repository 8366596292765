import React, { useState, useEffect, useRef } from "react";
import {
  searchScanner,
  fetchEvent,
  updateEvent,
  getScanner,
} from "../../../services/admin/eventservice";
import { CreateScannerData } from "../../../services/scannerServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster, toast } from "react-hot-toast";
import PageHeader from "../../../hooks/PageHeader";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TagsInput from "react-tagsinput";
import SEO from "../../../hooks/seo";
import "react-tagsinput/react-tagsinput.css";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import CustomLoader from "../Loaderfiles/customloader";
import debounce from "lodash.debounce";
import useResizeObserver from "../../../hooks/useResizeObserver";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';

const ScannerUpdater = ({ defaultScanners }) => {
  const { setFieldValue } = useFormikContext();

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  useEffect(() => {
    const scannerIds = defaultScanners.map((scanner) => scanner.value);
    setFieldValue("scanner_id", scannerIds);
  }, [defaultScanners, setFieldValue]);

  return null; // This component doesn't render anything
};

const SignupSchema = Yup.object({
  name: Yup.string()
    .required("Event Name is required")
    .max(250, "Event Name must not exceed 250 characters"),
  category_id: Yup.string().required("Category is required"),
  start_time: Yup.date()
  .required("Event Start Time is required"),
  end_time: Yup.date()
  .min(Yup.ref('start_time'), "End Date must be after Start Date")
  .required("Event End Time is required"),

  scanner_id: Yup.array().min(1, "Scanner required"),

  currency_id: Yup.string().required("Local Currency required"),
  maximumPeople: Yup.number().required("Maximum people allowed is required"),
  complimentaryTickets: Yup.number().required(
    "Number of complimentary Tickets is required"
  ),
  eventType: Yup.string().required("Event type is required"),
  address: Yup.string().when("eventType", (eventType, schema) => {
    return eventType[0] === "offline"
      ? schema.required("Address is required for offline events")
      : schema.notRequired();
  }),

  latitude: Yup.string().when("eventType", (eventType, schema) => {
    return eventType[0] === "offline"
      ? schema.required("Latitude is required for offline events")
      : schema.notRequired();
  }),
  appImage: Yup.array()
  .test('image-size', 'App Image must be at least 1600x900', async function(value) {
    if (this.parent.$isImageUploaded && value) {
      const dimensions = await Promise.all(value.map(async (image) => {
        try {
          return await getImageDimensions(image);
        } catch (error) {
          console.error('Error loading image:', error);
          return { width: 0, height: 0 };  
        }
      }));
      return dimensions.every(({ width, height }) => width >= 1600 && height >= 900);
    }
    return true;  
  }),

webImage: Yup.array()
  .test('image-size', 'Website Image must be at least 1600x900', async function(value) {
    if (this.parent.$isImageUploaded && value) {
      const dimensions = await Promise.all(value.map(async (image) => {
        try {
          return await getImageDimensions(image);
        } catch (error) {
          console.error('Error loading image:', error);
          return { width: 0, height: 0 };  
        }
      }));
      return dimensions.every(({ width, height }) => width >= 1600 && height >= 900);
    }
    return true;  
  }),

  longitude: Yup.string().when("eventType", (eventType, schema) => {
    return eventType[0] === "offline"
      ? schema.required("Longitude is required for offline events")
      : schema.notRequired();
  }),
  status: Yup.string().required("Status is required"),
  tags: Yup.array()
    .of(Yup.string())
    .required("Tags is required")
    .min(1, "At least one tag is required"),

  description: Yup.string().required("Description is required"),
}).test(function (values) {
  return true;
});
const getImageDimensions = (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      let width = 1600;
      let height = 900;
      if (aspectRatio > 16 / 9) {
        height = Math.floor(width / aspectRatio);
      } else {
        width = Math.floor(height * aspectRatio);
      }

      resolve({ width, height });
    };
    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
    img.src = URL.createObjectURL(image);
  });
};
const EditEvent = () => {
  const [scanners, setScanners] = useState([]);
  const [inputValue, setInputValue] = useState("");
  registerPlugin(FilePondPluginImagePreview);
  const [webImage, setwebImage] = useState("");
  const [desktopImage, setdesktopImage] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [eventType, setEventType] = useState("offline");
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [address, setAddress] = useState();
  
  const [singleEvent, setSingleEvent] = useState(null);
  const [selectedScannerOptions, setSelectedScannerOptions] = useState([]);
  const statusOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  let { id } = useParams();


  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);

  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };

  // Use the custom hook
  useResizeObserver(ref, handleResize);

  const initialValues = {
    name: singleEvent?.name || "",
    is_vat_accept: singleEvent?.is_vat_accept || 1,
    category_id: singleEvent?.category_id || "",
    currency_id: singleEvent?.currency_id || "",

    start_time: singleEvent?.start_time
      ? new Date(singleEvent.start_time)
      : null,
    end_time: singleEvent?.end_time ? new Date(singleEvent.end_time) : null,
    status: singleEvent?.status,
    scanner_id: singleEvent?.scanner_id
      ? singleEvent.scanner_id.split(",").map((id) => parseInt(id.trim(), 10))
      : [],

    complimentaryTickets: singleEvent?.complimentary_tickets?.toString() ?? "",
    tags: singleEvent?.tags?.split(",").map((tag) => tag.trim()),
    description: singleEvent?.description || "",
    maximumPeople: singleEvent?.people?.toString() ?? "",
    exclusive: singleEvent?.exclusive || 0,
    security: singleEvent?.security || 0,

    appImage: [],
    webImage: [],
    address: singleEvent?.address || "",
    latitude: singleEvent?.lat || "",
    longitude: singleEvent?.lang || "",
    eventType:
      singleEvent?.type === "offline"
        ? "offline"
        : singleEvent?.type === "online"
        ? "online"
        : "",
  };

  const [defaultScanners, setDefaultScanners] = useState([]);

  useEffect(() => {
    if (singleEvent !== null) {
      const singleEventScannerIds =
        singleEvent?.scanner_id?.length > 0 &&
        singleEvent?.scanner_id
          ?.split(",")
          .map((id) => parseInt(id.trim(), 10)); // Convert string IDs to integers

      console.log(singleEventScannerIds, "Rashid here");
      if (singleEventScannerIds) {
        const selectedScanners = scanners.filter((scanner) =>
          singleEventScannerIds.includes(scanner.value)
        );

        if (defaultScanners) {
          // Check if defaultScanners is not null
          const newScanners = [
            ...selectedScanners, // Add new selected scanners
            ...defaultScanners.filter(
              (defaultScanner) =>
                !selectedScanners.some(
                  (selectedScanner) =>
                    selectedScanner.value === defaultScanner.value
                )
            ), // Add only those defaultScanners not already included in selectedScanners
          ];

          setDefaultScanners(newScanners);
        } else {
          setDefaultScanners(selectedScanners);
        }
      }

      setAddress(singleEvent.address);
    }
  }, [singleEvent]);

  const [isInclusive, setInclusive] = useState(true);
  const handleEventTypeChange = (type) => {
    setEventType(type);
  };

  const setScannersList = (value) => {
    initialValues.scanner_id = value;
  };
  const validateForm = async (values) => {
    let isValid = true;

    // Validation logic for webImage aspect ratio
    if (values.webImage && values.webImage.length > 0) {
        const file = values.webImage[0];
        if (file.type.startsWith('image/') && file.type !== 'image/gif') {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            await new Promise((resolve) => {
                img.onload = () => resolve();
            });
            const aspectRatio = img.width / img.height;
            if (aspectRatio !== 16 / 9) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please upload a 1600 x 900 aspect ratio Website image',
                    icon: 'error',
                    confirmButtonColor: '#a23896',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    showCloseButton: false
                });
                isValid = false;
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Please upload a valid web image file',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
            isValid = false;
        }
    }

    // Validation logic for appImage aspect ratio
    if (values.appImage && values.appImage.length > 0) {
        const file = values.appImage[0];
        if (file.type.startsWith('image/') && file.type !== 'image/gif') {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            await new Promise((resolve) => {
                img.onload = () => resolve();
            });
            const aspectRatio = img.width / img.height;
            if (aspectRatio !== 16 / 9) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please upload a 1600 x 900 aspect ratio App image',
                    icon: 'error',
                    confirmButtonColor: '#a23896',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    showCloseButton: false
                });
                isValid = false;
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Please upload a valid app image file',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
            isValid = false;
        }
    }

    // Additional validation logic for other form fields if needed
    // ...

    return isValid;
};

const slugify = (text) => {
    if (!text) return '';
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
};

const handleSubmit = async (values) => {
    setIsLoading(true);  
    try {
        const isValid = await validateForm(values);  
        if (!isValid) {
            setIsLoading(false);  
            return;  
        }

        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (key === "webImage" && values[key].length > 0) {
                formData.append(key, values[key][0]);
            } else if (key === "appImage" && values[key].length > 0) {
                formData.append(key, values[key][0]);
            } else {
                formData.append(key, values[key]);
            }
        });

     
        const response = await updateEvent(formData, id);
        if (response && response.success === true) {
            Swal.fire({
                title: 'Good Job',
                text: "Event Successfully Created!",
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3b82f6',
                cancelButtonColor: '#22c55e',
                confirmButtonText: 'Event List',
                cancelButtonText: 'Manage Tickets',
                showCloseButton: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/admin/events', { state: { message: response.msg } });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    const eventId = response.event.id;
                    const title = values.name || 'untitled';
                    navigate(`/admin/${eventId}/${slugify(title)}/tickets`);
                }
            });
        }
    } catch (error) {
        setIsLoading(false); 
        
    }
};
  // const handleSubmit = async (values) => {
  //   setIsLoading(true);
  //   const slugify = (text) => {
  //       if (!text) return '';
  //       return text.toString().toLowerCase()
  //           .replace(/\s+/g, '-')           // Replace spaces with -
  //           .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
  //           .replace(/\-\-+/g, '-')         // Replace multiple - with single -
  //           .replace(/^-+/, '')             // Trim - from start of text
  //           .replace(/-+$/, '');            // Trim - from end of text
  //   };
  //   const formData = new FormData();
  //   Object.keys(values).forEach(key => {
  //       if (key === "webImage" && values[key].length > 0) {
  //           const file = values[key][0];
  //           if (file.type.startsWith('image/') && file.type !== 'image/gif') {
  //               const img = new Image();
  //               img.src = URL.createObjectURL(file);
  //               img.onload = async () => {
  //                   const aspectRatio = img.width / img.height;
  //                   if (aspectRatio === 16 / 9) {
  //                       formData.append(key, file);
  //                       try {
  //                           const startTime = new Date(values.start_time);
  //                           const endTime = new Date(values.end_time);
  //                           if (endTime <= startTime) {
  //                               Swal.fire({
  //                                   title: 'Error',
  //                                   text: 'End time must be later than start time',
  //                                   icon: 'error',
  //                                   confirmButtonColor: '#a23896',
  //                                   cancelButtonColor: '#d33',
  //                                   confirmButtonText: 'OK',
  //                                   showCloseButton: false
  //                               });
  //                               setIsLoading(false);
  //                               return;
  //                           }
  //                           // Check if end time is more than start time
  //                           if (endTime < startTime) {
  //                               Swal.fire({
  //                                   title: 'Error',
  //                                   text: 'End time cannot be earlier than start time',
  //                                   icon: 'error',
  //                                   confirmButtonColor: '#a23896',
  //                                   cancelButtonColor: '#d33',
  //                                   confirmButtonText: 'OK',
  //                                   showCloseButton: false
  //                               });
  //                               setIsLoading(false);
  //                               return;
  //                           }
  //                           const response = await updateEvent(formData, id);
  //                           if (response && response.success === true) {
  //                               Swal.fire({
  //                                   title: 'Good Job',
  //                                   text: "Event Successfully Created!",
  //                                   icon: 'success',
  //                                   showCancelButton: true,
  //                                   confirmButtonColor: '#3b82f6',
  //                                   cancelButtonColor: '#22c55e',
  //                                   confirmButtonText: 'Event List',
  //                                   cancelButtonText: 'Manage Tickets',
  //                                   showCloseButton: false
  //                               }).then((result) => {
  //                                   if (result.isConfirmed) {
  //                                       navigate('/admin/events', { state: { message: response.msg } });
  //                                   } else if (result.dismiss === Swal.DismissReason.cancel) {
  //                                       const eventId = response.event.id;
  //                                       const title = values.name || 'untitled';
  //                                       navigate(`/admin/${eventId}/${slugify(title)}/tickets`);
  //                                   }
  //                               });
  //                           }
  //                       } catch (error) {
  //                           setIsLoading(false);
  //                           // Handle other errors if any
  //                       }
  //                   } else {
  //                       Swal.fire({
  //                           title: 'Error',
  //                           text: 'Please upload a 1600 x 900 aspect ratio Website image',
  //                           icon: 'error',
  //                           confirmButtonColor: '#a23896',
  //                           cancelButtonColor: '#d33',
  //                           confirmButtonText: 'OK',
  //                           showCloseButton: false
  //                       });
  //                       setIsLoading(false);
  //                       return;
  //                   }
  //               };
  //           } else {
  //               Swal.fire({
  //                   title: 'Error',
  //                   text: 'Please upload a valid image file',
  //                   icon: 'error',
  //                   confirmButtonColor: '#a23896',
  //                   cancelButtonColor: '#d33',
  //                   confirmButtonText: 'OK',
  //                   showCloseButton: false
  //               });
  //               setIsLoading(false);
  //               return;
  //           }
  //       }  else if (key === "appImage" && values[key].length > 0) {
  //         formData.append(key, values[key][0]);
  //     } else {
  //           formData.append(key, values[key]);
  //       }
  //   });
  // };

  const fetchScannerData = async (value) => {
    if (value && value.length > 3) {
      try {
        const scanner = await searchScanner(value);
        const transformedOptions = scanner.scanners.map((item) => ({
          value: item.id,
          label: item.first_name + " " + item.last_name,
        }));
        setScanners(transformedOptions);

        setSelectedScannerOptions(scanner.scanner_id);
      } catch (error) {
        console.error("Error fetching scanner data:", error);
      }
    }
  };

  const debouncedFetch = debounce((value) => {
    fetchScannerData(value);
  }, 200);

  useEffect(() => {
    debouncedFetch(inputValue);
    return () => {
      debouncedFetch.cancel();
    };
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const fetchSingleEventData = async () => {
    try {
      setLoading(true);
      const response = await fetchEvent(id);

      const transformedOptions = response.scanner.map((item) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
      }));

      setScanners(transformedOptions);
      console.log(response.event);
      setSingleEvent(response.event);
      setCurrencyOptions(response.currencies);
      setCategoryOptions(response.category);

      setwebImage(response.event.imagePath + "/" + response.event.image);
      setdesktopImage(
        response.event.imagePath + "/" + response.event.desktop_image
      );

      const event_type_from_api =
        response.event?.type === "offline"
          ? "offline"
          : response.event?.type === "online"
          ? "online"
          : "";
      setEventType(event_type_from_api);
    } catch (error) {
      console.error("Error fetching scanner data:", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  useEffect(() => {
    fetchSingleEventData();
    // fetch_ScannerData();
  }, []);

  const fetch_ScannerData = async () => {
    try {
      const scanner = await getScanner();

      const transformedOptions = scanner.data.map((item) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
      }));

      setScanners(transformedOptions);
    } catch (error) {
      console.error("Error fetching scanner data:", error);
    }
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const defaultCountry = 'US';
  const handleCountryChange = (country) => {

    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };


  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useForm();

  const onBackButtonClick = () => {
    navigate('/admin/events');
  }

  const onSubmitScanner = async (formDataScanner) => {
    console.log(formDataScanner);

    try {
      setLoading(true);
      const result = await CreateScannerData(formDataScanner);
      setShowModal(false);
      reset();
      if (result.success) {
        fetchSingleEventData();
        const transformedOptions = {
          value: result.scanners.id,
          label: result.scanners.first_name + " " + result.scanners.last_name,
        };
        setDefaultScanners([transformedOptions, ...defaultScanners]);
         setScanners([transformedOptions, ...scanners]);
         
        toast.success("Submit Successfully", { position: "top-right" });
        setTimeout(() => {}, 1000);
      } else {
        const errorData = await result.json();
        const errorMessage = errorData.error
          ? Object.values(errorData.error).flat().join(" ")
          : "Unknown error";
        toast.error(errorMessage, { position: "top-center" });
      }
    } catch (error) {
      toast.error(`${error.message}`, { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };

  if (!pageLoading) {
    return (
      <main  ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4FA94D"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  return (
    <>
      <SEO title="Edit Event - eticketing" />
      <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
        <Toaster />
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center h-96">
              <CustomLoader
                visible={true}
                height={80}
                width={80}
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius={1}
              />
            </div>
          </div>
        ) : categoryOptions.length === 0 ? (
          <></>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ errors, touched, setFieldValue, values, resetForm }) => {
              // Corrected handleSelect function
              const handleSelect = async (value) => {
                try {
                  const results = await geocodeByAddress(value);
                  const latLng = await getLatLng(results[0]);
                  setCoordinates(latLng);
                  // Now using the setFieldValue correctly
                  setFieldValue("address", value);

                  setAddress(value);
                  setFieldValue("latitude", latLng.lat.toString());
                  setFieldValue("longitude", latLng.lng.toString());
                } catch (error) {
                  console.error("Error", error);
                }
              };

              return (
                <Form className="">
                  <PageHeader
                    title="Edit Event"
                    showCreateButton={false}
                    showSubmitButton={false}
                    onSubmitButtonClick={handleSubmit}
                    showBackButton={true}
                    onBackButtonClick={onBackButtonClick}
                  />
                  {Object.keys(errors).map(fieldName => (
                          (errors[fieldName] && touched[fieldName]) && (
                            <div className="error-messages mt-2 mb-2" key={fieldName}>
                              <div className="alert flex items-center justify-between overflow-hidden rounded-lg border border-error text-error">
                                <div className="flex">
                                  <div className="bg-error p-3 text-white">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="size-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="px-4 py-3 sm:px-5">
                                    {errors[fieldName]}
                                    <br />
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          )
                        ))}
                  <ScannerUpdater defaultScanners={defaultScanners} />

                  <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="tabs flex flex-col">
                          <div className="p-4 sm:p-5">
                            <div className="space-y-5">
                              <label className="block">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Title
                                </span>
                                <Field
                                  name="name" // specify the field name
                                  className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                  placeholder="Enter post title"
                                  type="text"
                                />
                                {/* If you want to display errors for this field */}
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </label>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Description
                                </span>
                                <div className="mt-1.5 w-full">
                                  <ReactQuill
                                    id="description"
                                    name="description"
                                    style={{ height: '150px', marginBottom: '40px' }}
                                    value={values.description || ""}
                                    onChange={(content) => {
                                      const isEmpty =
                                        !content || content === "<p><br></p>";
                                      setFieldValue(
                                        "description",
                                        isEmpty ? "" : content
                                      );
                                    }}
                                    modules={{
                                      toolbar: [
                                        [{ header: [1, 2, false] }],
                                        [
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strike",
                                          "blockquote",
                                        ],
                                        [
                                          { list: "ordered" },
                                          { list: "bullet" },
                                        ],
                                        ["link", "image"],
                                        ["clean"],
                                      ],
                                    }}
                                    formats={[
                                      "header",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strike",
                                      "blockquote",
                                      "list",
                                      "bullet",
                                      "link",
                                      "image",
                                    ]}
                                    placeholder="Write something..."
                                  />
                                     {/* <Field
                                      as="textarea"
                                      id="description"
                                      className="block p-2.5 w-full text-sm text-gray-900 bg-White rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      name="description"
                                      rows="4"
                                      placeholder="Enter event description"
                                    /> */}
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-3">
                                {webImage && (
                                  <img
                                    src={webImage} // Assuming webImage is a File object with a preview property
                                    alt="Web Image"
                                    style={{
                                      maxWidth: "60%",
                                      maxHeight: "200px",
                                    }} // Adjust styling as needed
                                  />
                                )}

                                {/* Rest of your components, e.g., FilePond */}
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-3">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  App Image
                                </span>
                                <div className="filepond fp-bordered fp-grid [--fp-grid:2]">
                                  <FilePond
                                    onupdatefiles={(fileItems) => {
                                      setFieldValue(
                                        "appImage",
                                        fileItems.map(
                                          (fileItem) => fileItem.file
                                        )
                                      );
                                    }}
                                    allowMultiple={false}
                                    name="appImage"
                                    labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                                  />
                                  <ErrorMessage
                                    name="appImage"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-3">
                                {desktopImage && (
                                  <img
                                    src={desktopImage} // Assuming webImage is a File object with a preview property
                                    alt="Web Image"
                                    style={{
                                      maxWidth: "60%",
                                      maxHeight: "200px",
                                    }} // Adjust styling as needed
                                  />
                                )}

                                {/* Rest of your components, e.g., FilePond */}
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-5">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Website Image
                                </span>
                                <div className="filepond fp-bordered fp-grid mt-1.5 [--fp-grid:2]">
                                  <FilePond
                                    onupdatefiles={(fileItems) => {
                                      setFieldValue(
                                        "webImage",
                                        fileItems.map(
                                          (fileItem) => fileItem.file
                                        )
                                      );
                                    }}
                                    allowMultiple={false}
                                    name="webImage"
                                    labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                                  />
                                  <ErrorMessage
                                    name="webImage"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                {/* Maximum People */}
                                <label className="block">
                                  <span>Maximum People</span>
                                  <span className="relative mt-1.5 flex">
                                    <Field
                                      name="maximumPeople"
                                      type="number"
                                      min="0"
                                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      placeholder="Maximum People"
                                    />
                                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                      <i className="far fa-user text-base" />
                                    </span>
                                  </span>
                                  <ErrorMessage
                                    name="maximumPeople"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </label>

                                {/* Complimentary Tickets */}
                                <label className="block">
                                  <span>Complimentary Tickets</span>
                                  <span className="relative mt-1.5 flex">
                                    <Field
                                      name="complimentaryTickets"
                                      type="text"
                                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      placeholder="Complimentary Tickets"
                                    />
                                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                      <i className="far fa-user text-base" />
                                    </span>
                                  </span>
                                  <ErrorMessage
                                    name="complimentaryTickets"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </label>
                              </div>

                              <div className="flex justify-start space-x-2">
                                <button
                                  type="button"
                                  className={`btn space-x-2 ${
                                    eventType === "offline"
                                      ? "bg-slate-700 text-white"
                                      : "bg-slate-500 text-white"
                                  } font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90`}
                                  onClick={() => {
                                    setEventType("offline");
                                    setFieldValue("eventType", "offline");
                                  }}>
                                  <span>Venue</span>
                                </button>
                                <button
                                  type="button"
                                  className={`btn space-x-2 ${
                                    eventType === "online"
                                      ? "bg-slate-700 text-white"
                                      : "bg-slate-500 text-white"
                                  } font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90`}
                                  onClick={() => {
                                    setEventType("online");
                                    setFieldValue("eventType", "online");
                                  }}>
                                  <span>Online Event</span>
                                </button>
                                <ErrorMessage
                                  name="eventType"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </div>

                              {eventType === "offline" && (
                                <>
                                  <label className="block">
                                    <span className="font-medium text-slate-600 dark:text-navy-100">
                                      Enter Address
                                    </span>
                                    <PlacesAutocomplete
                                      value={address}
                                      onChange={setAddress}
                                      onSelect={handleSelect}>
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div>
                                          <input
                                            {...getInputProps({
                                              placeholder: "Enter address...",
                                              className:
                                                "form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent",
                                            })}
                                          />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const style = {
                                            backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                                          };
                                          return (
                                            <div {...getSuggestionItemProps(suggestion, { style })}>
                                              {suggestion.description}
                                            </div>
                                          );
                                        })}
                                      </div>

                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                    <ErrorMessage
                                      name="address"
                                      component="div"
                                      className="text-red-500 text-sm"
                                    />
                                  </label>

                                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                    {/* latitude */}
                                    <label className="block">
                                      <span>Latitude</span>
                                      <span className="relative mt-1.5 flex">
                                        <Field
                                          name="latitude"
                                          type="text"
                                          readOnly
                                          className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                          placeholder="Latitude"
                                        />
                                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                          <i className="far fa-user text-base" />
                                        </span>
                                      </span>
                                      <ErrorMessage
                                        name="latitude"
                                        component="div"
                                        className="text-red-500 text-sm"
                                      />
                                    </label>
                                    {/* latitude */}
                                    <label className="block">
                                      <span>Longitude</span>
                                      <span className="relative mt-1.5 flex">
                                        <Field
                                          name="longitude"
                                          type="text"
                                          readOnly
                                          className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                          placeholder="Longitude"
                                        />
                                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                          <i className="far fa-user text-base" />
                                        </span>
                                      </span>
                                      <ErrorMessage
                                        name="longitude"
                                        component="div"
                                        className="text-red-500 text-sm"
                                      />
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                      <div className="card space-y-5 p-4 sm:p-5">
                        <label className="block">
                          <span className="inline-block font-medium text-slate-600 dark:text-navy-100">
                            Select Scanners
                          </span>

                          <span
                            onClick={() => setShowModal(true)}
                            className="inline-block ml-2 float-right font-medium text-slate-600 dark:text-navy-100 cursor-pointer text-blue-500">
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24">
                              <path
                                fill-rule="evenodd"
                                d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4.2a1 1 0 1 0-2 0V11H7.8a1 1 0 1 0 0 2H11v3.2a1 1 0 1 0 2 0V13h3.2a1 1 0 1 0 0-2H13V7.8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </span>

                          <Select
                            name="scanner_id"
                            options={scanners}
                            value={defaultScanners}
                            onInputChange={setInputValue}
                            isMulti
                            onChange={(selectedOptions) => {
                              setDefaultScanners(selectedOptions);
                              const selectedScannerIds = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFieldValue("scanner_id", selectedScannerIds);
                            }}
                            isLoading={inputValue && scanners.length === 0}
                            placeholder="Select Scanner"
                            className=" mt-1.5 lg:w-full   rounded-lg  bg-white  py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />

                          <ErrorMessage
                            name="scanner_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>

                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Category
                          </span>
                          <Select
                            name="category_id"
                            options={categoryOptions}
                            value={categoryOptions.find(
                              (option) => option.value === values.category_id
                            )}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "category_id",
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                            placeholder="Select Category"
                            className="mt-1.5 lg:w-full rounded-lg bg-white  py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />

                          <ErrorMessage
                            name="category_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Tags{" "}
                            <small>
                              Please press 'Enter' or ',' to add tags.
                            </small>
                          </span>
                          <TagsInput
                            value={values.tags || []} // Ensure a valid value is always set
                            onChange={(newTags) =>
                              setFieldValue("tags", newTags)
                            }
                            addKeys={["Enter", ","]}
                            inputProps={{
                              placeholder: "Add tags...",
                              name: "tags",
                            }}
                            className="mt-1 p-2 lg:w-full w-80 border rounded-lg focus:outline-none focus:border-blue-500"
                          />

                          <ErrorMessage
                            name="tags"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label>
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Start Date
                          </span>
                          <span className="relative mt-1.5 flex">
                            <DatePicker
                              dateFormat="MM/dd/yyyy HH:mm:ss"
                              showTimeSelect
                              timeFormat="HH:mm:ss"
                              name="start_time"
                              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={values.start_time} // Assuming `values.start_time` holds the current value
                              onChange={(date) => {
                                // Update start_time value
                                setFieldValue('start_time', date);
                                
                                // If end_time is before start_time, reset it
                                if (values.end_time < date) {
                                  setFieldValue('end_time', date);
                                }
                              }}
                            />
                          </span>
                          <ErrorMessage
                            name="start_time"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label>
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            End Date
                          </span>
                          <span className="relative mt-1.5 flex">
                            <DatePicker
                              dateFormat="MM/dd/yyyy HH:mm:ss"
                              showTimeSelect
                              timeFormat="HH:mm:ss"
                              name="end_time"
                              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={values.end_time}
                              minDate={values.start_time} // Set minDate to start_time
                              minTime={
                                values.start_time
                                  ? new Date(values.start_time.getTime() + 60000) // If start_time is defined, set minTime to start_time + 1 minute
                                  : null // Otherwise, set minTime to null
                              }
                              maxTime={
                                values.start_time
                                  ? new Date(values.start_time.getTime() + 86399000) // If start_time is defined, set maxTime to start_time + 23 hours, 59 minutes, and 59 seconds
                                  : null // Otherwise, set maxTime to null
                              }
                              onChange={(date) => {
                                 if (date < values.start_time) {
                                   date = values.start_time;
                                }
                                setFieldValue('end_time', date);
                              }}
                            />
                          </span>
                          <ErrorMessage
                            name="end_time"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Local Currency
                          </span>
                          <Select
                            name="currency_id"
                            options={currencyOptions}
                            value={currencyOptions.find(
                              (option) => option.value === values.currency_id
                            )}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "currency_id",
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                            placeholder="Select Local Currency"
                            className="mt-1.5 lg:w-full rounded-lg bg-white  py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />

                          <ErrorMessage
                            name="currency_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Status
                          </span>
                          <Select
                            name="status"
                            options={statusOptions}
                            value={statusOptions.find(
                              (option) => option.value === values.status
                            )}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "status",
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                            placeholder="Select Status"
                            className="mt-1.5 lg:w-full rounded-lg bg-white py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />

                          <ErrorMessage
                            name="local_currency"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                          <label className="inline-flex items-center space-x-2">
                            <span className="text-xs text-slate-400 dark:text-navy-300">
                            {values.security === 0 ? 'Public access' : 'Private access'}
                            </span>

                            <input
                              onChange={() =>
                                setFieldValue(
                                  "security",
                                  values.security === 1 ? 0 : 1
                                )
                              }
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="security"
                              checked={values.security === 1} // assuming 1 is the default value
                            />

                            <ErrorMessage
                              name="security"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </label>


                          <label className="inline-flex items-center space-x-2">
                            <span className="text-xs text-slate-400 dark:text-navy-300">
                              {values.is_vat_accept === 1 ? 'Vat Exempt' : 'Vat not Exempt'}
                            </span>

                            <input
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="exclusive"
                              checked={values.is_vat_accept === 1} // checked if value is 1
                              onChange={() => setFieldValue('is_vat_accept', values.is_vat_accept === 1 ? 0 : 1)} // toggle between 1 and 0
                            />

                            <ErrorMessage
                              name="is_vat_accept"
                              component="div"
                              className="text-red-500 text-sm" 
                            />
                          </label>



                          {/* <label className="inline-flex items-center space-x-2">
                            <span className="text-xs text-slate-400 dark:text-navy-300">
                              {values.exclusive === 0
                                ? "Exclusive Event"
                                : "Inclusive Event"}
                            </span>

                            <input
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="exclusive"
                              checked={values.exclusive === 1} // assuming 1 is the default value
                              onChange={() =>
                                setFieldValue(
                                  "exclusive",
                                  values.exclusive === 1 ? 0 : 1
                                )
                              }
                            />

                            <ErrorMessage
                              name="exclusive"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </label> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <button
                        type="submit"
                        className={`btn bg-slate-500 text-white ${
                          isLoading ? "cursor-not-allowed" : ""
                        }`}
                        disabled={isLoading}>
                        {isLoading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div
                              className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                              role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}

        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <form
                  onSubmit={handleFormSubmit(onSubmitScanner)}
                  className="mt-5">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <h3 className="text-lg font-semibold">Add Scanner</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}>
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <div className="lg:flex mt-5 lg:pl-0 pl-5 ">
                        <div className="lg:pr-2  w-1/2">
                          <label
                            htmlFor="first_name"
                            className="block text-sm font-medium text-gray-700">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register("first_name", {
                              required: "Please enter first name.",
                            })}
                          />
                          {errors.first_name && (
                            <p className="text-red-500">
                              {errors.first_name.message}
                            </p>
                          )}
                        </div>
                        <div className="lg:pl-2  lg:mt-0 mt-8  w-1/2">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register("last_name", {
                              required: "Please enter last name.",
                            })}
                          />
                          {errors.last_name && (
                            <p className="text-red-500">
                              {errors.last_name.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="lg:flex mt-8 lg:pl-0 pl-5">
                        <div className="lg:pl-0 lg:pr-2 w-1/2">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register("email", {
                              required: "Please enter email.",
                            })}
                          />
                          {errors.email && (
                            <p className="text-red-500">
                              {errors.email.message}
                            </p>
                          )}
                        </div>
                        <div className="lg:pl-2 w-1/2 lg:mt-0 mt-8">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700">
                            Phone
                          </label>
                          {/* <input */}
                          {/*   type="text" */}
                          {/*   id="phone" */}
                          {/*   name="phone" */}
                          {/*   placeholder="Phone" */}
                          {/*   className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" */}
                          {/*   {...register("phone", { */}
                          {/*     required: "Please enter phone number.", */}
                          {/*   })} */}
                          {/* /> */}

                       <PhoneInput
                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Enter Phone"
                      value={countryCode + phoneNumber}
                      country={defaultCountry}
                      defaultCountry={defaultCountry}
                      onChange={(value) => {

                          
                        if (value) {
                          // setPhoneNumber(value.slice(countryCode.length)); // Remove country code from input
                          // field.onChange(value.slice(countryCode.length));
                          setValue("phone", value)  
                        }
                      }}
                      onCountryChange={handleCountryChange}
                    />

                  {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
                        </div>
                      </div>

                      <div className="mt-8 lg:pl-0 pl-5">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          className="mt-1 p-2 lg:w-full w-80 border  rounded-lg focus:outline-none focus:border-blue-500"
                          {...register("password", {
                            required: "Please enter password.",
                          })}
                        />
                        {errors.password && (
                          <p className="text-red-500">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                      <div className="mt-8 lg:pl-0 pl-5">
                        <label className="block">
                          <span className="block text-sm font-medium text-gray-700">
                            Status
                          </span>
                          <select
                            className="mt-1 p-2 lg:w-full w-80 border  rounded-lg focus:outline-none focus:border-blue-500"
                            {...register("status")}>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}>
                        Close
                      </button>

                      <button
                        type="submit"
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        disabled={loading}>
                        {loading ? (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </main>
    </>
  );
};
 
export default EditEvent