import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const fetchUsersAndEvents = async (authToken) => {
  try {
     authToken = authToken || localStorage.getItem('authToken');

    const response = await axios.get(API_ENDPOINTS.FETCH_USER, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response.data.users,'abcd');
    if (Array.isArray(response.data.users) && Array.isArray(response.data.events)) {
      const users = response.data.users.map((item) => ({ value: item.id, label: item.name }));
        
       const events = response.data.events.map((item) => ({ value: item.id, label: item.name }));

      return { users, events };
    } else {
      console.error('API response does not contain an array of users or events.');
      return { users: [], events: [] };
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return { users: [], events: [] };
  }
};

export const checkAvailableComplimentaryTicket = async (eventId) => {
    try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(`${API_ENDPOINTS.CHECKAVAILABLECOMPLIMENTARY}?event_id=${eventId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error checking available complimentary tickets:', error);
    return 0;
  }
};

// export const searchUsers = async (inputValue) => {
//     try {
//     const authToken = localStorage.getItem('authToken');
//     const response = await axios.get(`${API_ENDPOINTS.search_Users}?username=${inputValue}`, {
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         'Content-Type': 'application/json',
//       },
//     });

//     return response.data;
//   } catch (error) {
//     console.error('Error checking available complimentary tickets:', error);
//     return 0;
//   }
// };

export const createComplimentaryTickets = async (formData) => {
    try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(`${API_ENDPOINTS.CREATECOMPLIMENTTARY}`, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating complimentary tickets:', error);
    throw error;
  }
};

export const searchUsers = async (inputValue) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.search_Users+"?username="+inputValue,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const searchEvent = async (inputValue) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.search_event+"?event="+inputValue,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
