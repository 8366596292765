import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { getProfileDetails } from './services/profileService';
 
const ProtectedRoute = ({ children, role }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole');

    // Check if token and role are valid before proceeding to profile details check
    if (!authToken || userRole !== role) {
      navigate('/401', { replace: true, state: { from: location.pathname } });
      return; // Early return to avoid further execution
    }

    getProfileDetails()
        .then(data => {
            
        if (!data || Object.keys(data).length === 0 || !data.role) {
          navigate('/401');
        } else {
          // Assuming you want to check if the user's role matches the expected role
          if (data.role === role) {
            setIsAuthorized(true); // User is authorized
          } else {
            // Role does not match, handle accordingly
            navigate('/401');
          }
        }
      })
      .catch(error => {
        console.error(error.message); // Log the error
        navigate('/401');  
      })
      .finally(() => setIsCheckingAuth(false)); // Ensure we update the auth checking state
  }, [navigate]);

  if (isCheckingAuth) {
    return <div>Loading...</div>; 
  }

  if (!isAuthorized) {
    // This will not be reached if navigate is called in useEffect
    // but is here as a fallback
    return <Navigate to="/401" replace state={{ from: location.pathname }} />;
  }

  return children;
};

export default ProtectedRoute;
