import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Import flatpickr styles

const CustomDatePickerticket = ({ graphSelection, onDateChange }) => {
  // console.log(graphSelection,'graphTicketSelection-ladin');
  const inputRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [defaultStartDate, setDefaultStartDate] = useState(
    graphSelection.start_date ? new Date(graphSelection.start_date) : new Date()
  );
  const [defaultEndDate, setDefaultEndDate] = useState(
    graphSelection.end_date ? new Date(graphSelection.end_date) : new Date()
  );
  
  let flatpickrInstance = null;

  useEffect(() => { 

    onDateChange(defaultStartDate,defaultEndDate);

  }, []);

  useEffect(() => {
    if (inputRef.current) {
      flatpickrInstance = flatpickr(inputRef.current, {
        mode: 'range',
        dateFormat: 'Y-m-d',
        defaultDate: [defaultStartDate, defaultEndDate],
        onClose: (selectedDates) => {
          onDateChange(selectedDates[0], selectedDates[1]);
        }
      });
    }

    return () => {
      // Cleanup flatpickr instance on unmount
      if (flatpickrInstance) {
        flatpickrInstance.destroy();
      }
    };
  }, [defaultStartDate, defaultEndDate]);

  return (
    <label className="relative flex">
      <input
        ref={inputRef}
        className="form-input peer w-3 rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
        placeholder="Choose date..."
        type="text"
        readOnly // Ensure the input field is read-only to prevent manual input
      />
      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="size-5 ml-2 transition-colors duration-200"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </span>
    </label>
  );
};

export default CustomDatePickerticket;
