import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../hooks/PageHeader";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import { User_Create } from "../../../services/admin/userservice";
import { submitUser } from "../../../services/admin/userservice";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../Loaderfiles/customloader";
import SEO from "../../../hooks/seo";
import Swal from "sweetalert2";
import useResizeObserver from "../../../hooks/useResizeObserver";
import "react-phone-number-input/style.css"; // Import CSS
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import { createEvent } from "../../../services/admin/eventservice";

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  phone: yup.string().required("Phone Number is required"),
  //Phone: yup.number().required('Phone is required'),
  role: yup.array().required("Role is required"),
  //org_id: yup.string().required('Organization is required'),
  // agent_commission_type: yup.string().required("Commission Type is required"),
  // agent_commission: yup.string().required("Agent Commission is required"),
  // country: yup.string().required("Country is required"),
});
const UserCreate = () => {
  const [formErrors, setFormErrors] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone: "",
      agent_commission_type: "",
      org_id: [],
      agent_commission: "",
      country: "",
      agent_commission_about: "",
      // role: [], // Initialize as empty for multi-select
    },
  });

  const [options, setOptions] = useState([]);
  const [Currencies, setCurrencies] = useState([]);
  const [organizations_Options, setOrganizations_Options] = useState([]);
  const [currencies_Options, setCurrencies_Options] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const defaultCountry = "US";
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode("+" + countryCallingCode);
    } else {
      setCountryCode("");
    }
  };

  const commissionTypeOptions = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];
  const commissionAboutOptions = [
    { value: "per_ticket", label: "Per Ticket" },
    { value: "per_order", label: "Per Order" },
  ];

  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await User_Create();

        setIsLoading(false);
        const mappedOptions = response.roles.map((role) => ({
          value: role.id,
          label: role.name,
        }));

        setOptions(mappedOptions);

        const organizationsOptions = response.organizations.map((role) => ({
          value: role.id,
          label: role.name,
        }));

        setOrganizations_Options(organizationsOptions);

        const currenciesOptions = response.currencies.map((currency) => ({
          value: currency.id,
          label: currency.country,
        }));

        setCurrencies_Options(currenciesOptions);
      } catch (error) {
        toast.error(error.message);
        console.error("Error fetching data: ", error);
        toast.success("This is a success message");
      } finally {
      }
    };

    fetchData();
  }, []);
  const fetchEventData = async () => {
    try {
      const response = await createEvent();

      setCurrencies(response.currencies);
    } catch (error) {
      console.error("Error fetching scanner data:", error);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      minHeight: "unset",
    }),
  };
  let selectedRoleIds = [];
  const selectedRoles = watch("role");

  if (selectedRoles) {
    selectedRoleIds = selectedRoles.map((role) => role.value);
  }
  const [showCountryField, setShowCountryField] = useState(false);

  const Selectedroles = watch("role");

  useEffect(() => {
    if (Selectedroles) {
      const selectedRoleIds = Selectedroles.map((role) => role.value);

      const isAdminSelected = selectedRoleIds.includes(1);

      const isAgentSelected = selectedRoleIds.includes(4);

      if (isAdminSelected && isAgentSelected) {
        setShowCountryField(false);
      } else {
        setShowCountryField(isAdminSelected);
      }
    }
  }, [Selectedroles]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const selectedPermissionValues = formData.role.map(
        (option) => option.value
      );
      const submitData = {
        ...formData,
        roles: selectedPermissionValues,
      };

      if (submitData.roles.includes(3) && !submitData.roles.includes(2)) {
        if (
          submitData.hasOwnProperty("org_id") &&
          submitData.org_id.length == 0
        ) {
          Swal.fire({
            title: "Please select an organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          return false;
        }
      }

      const response = await submitUser(submitData);
      if (response.status === 201) {
        Swal.fire({
          title: "Good Job",
          text: "Submitted Successfully",
          icon: "success",
          confirmButtonColor: "#A23896",
          confirmButtonText: "OK",
          showCloseButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/admin/users", {
              state: { successMessage: "Submitted Successfully" },
            });
          }
        });
      } else {
        console.error("Submission Error:", response.error);
      }
    } catch (error) {
      console.error("Submission Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onBackButtonClick = () => {
    navigate("/admin/users");
  };
  if (options.length === 0) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  return (
    <>
      <SEO title="Add User - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <Toaster position="top-right" reverseOrder={false} />
        <PageHeader
          title="Add User"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add User
                    </h4>
                  </div>
                </div>
              </div>
              {/* 
             {loading ? (
                        <div className="flex justify-center items-center">
                          <div className="flex justify-center items-center h-96">
                            <CustomLoader
                              visible={true}
                              height={80}
                              width={80}
                              color="#4FA94D"
                              ariaLabel="tail-spin-loading"
                              radius={1}
                            />
                          </div>
                        </div>
                      ) : ( */}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>First Name</span>
                        <Controller
                          name="first_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="First Name"
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        {errors.first_name && (
                          <p className="text-red-500">
                            {errors.first_name.message}
                          </p>
                        )}
                      </label>
                    </div>
                  </div>

                  {/* Second Column (col-6) */}
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Last Name</span>
                        <Controller
                          name="last_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Last Name"
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        {errors.last_name && (
                          <p className="text-red-500">
                            {errors.last_name.message}
                          </p>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Email</span>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Enter email"
                              type="email"
                              {...field}
                            />
                          )}
                        />
                        {errors.email && (
                          <p className="text-red-500">{errors.email.message}</p>
                        )}
                      </label>
                    </div>
                  </div>

                  {/* Second Column (col-6) */}
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block relative">
                        <span>Password</span>
                        <div className="relative flex items-center">
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                {...field}
                              />
                            )}
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 mt-2 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 mt-2 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                        {errors.password && (
                          <p className="text-red-500">
                            {errors.password.message}
                          </p>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Phone</span>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Enter Phone"
                              value={countryCode + phoneNumber}
                              country={defaultCountry}
                              defaultCountry={defaultCountry}
                              onChange={(value) => {
                                if (value) {
                                  setPhoneNumber(
                                    value.slice(countryCode.length)
                                  ); // Remove country code from input
                                  field.onChange(
                                    value.slice(countryCode.length)
                                  );
                                  setValue("phone", value);
                                }
                              }}
                              onCountryChange={handleCountryChange}
                            />
                          )}
                        />
                        {errors.phone && (
                          <p className="text-red-500">{errors.phone.message}</p>
                        )}
                      </label>
                    </div>
                  </div>

                  {/* Second Column (col-6) */}
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Role</span>

                        <Controller
                          name="role"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isMulti
                              options={options}
                              className="select2form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                              onChange={(selected) =>
                                setValue("role", selected)
                              }
                              styles={customStyles}
                              placeholder="Select user role"
                              isSearchable={true}
                            />
                          )}
                        />

                        {errors.role && (
                          <p className="text-red-500">{errors.role.message}</p>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                {showCountryField && (
                  <div className="flex-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Country</span>

                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => {
                            console.log("field.value:", field.value);
                            return (
                              <Select
                                {...field}
                                options={Currencies}
                                className="select2form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                onChange={(selectedOption) => {
                                  console.log(
                                    "selectedOption:",
                                    selectedOption
                                  );
                                  setValue(
                                    "country",
                                    selectedOption ? selectedOption.value : ""
                                  );
                                }}
                                styles={customStyles}
                                placeholder="Select user Country"
                                isSearchable={true}
                                value={Currencies.find(
                                  (option) => option.value === field.value
                                )}
                              />
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {selectedRoleIds.length === 1 && selectedRoleIds.includes(1) ? (
                  <div></div>
                ) : (
                  <div>
                    {selectedRoleIds.length === 1 &&
                    selectedRoleIds.includes(2) ? (
                      <div>
                        <div className="flex-1">
                          <div className="space-y-4 p-4 sm:p-5" id="category">
                            <label className="block">
                              <span>Organization Commission</span>

                              <Controller
                                name="org_commission"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Organization Commission"
                                    type="number"
                                    step="0.1"
                                    min="0"
                                    {...field}
                                  />
                                )}
                              />

                              {errors.org_commission && (
                                <p className="text-red-500">
                                  {errors.org_commission.message}
                                </p>
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="space-y-4 p-4 sm:p-5" id="category">
                            <label className="block">
                              <span>Dollar Amount</span>

                              <Controller
                                name="org_dollar_amount"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Dollar Amount"
                                    type="number"
                                    step="0.1"
                                    min="0"
                                    {...field}
                                  />
                                )}
                              />

                              {errors.org_dollar_amount && (
                                <p className="text-red-500">
                                  {errors.org_dollar_amount.message}
                                </p>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {selectedRoleIds.includes(3) &&
                        selectedRoleIds.length === 1 ? (
                          <div className="flex-1">
                            <div className="space-y-4 p-4 sm:p-5" id="category">
                              <label className="block">
                                <span>Organization</span>

                                <Controller
                                  name="org_id"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={organizations_Options}
                                      isSearchable={true}
                                      className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                      onChange={(selected) =>
                                        setValue(
                                          "org_id",
                                          selected
                                            ? selected.value.toString()
                                            : null
                                        )
                                      }
                                      styles={customStyles}
                                      value={commissionTypeOptions.find(
                                        (option) => option.value === field.value
                                      )}
                                      placeholder="Select Organization"
                                    />
                                  )}
                                />

                                {errors.org_id && (
                                  <p className="text-red-500">
                                    {errors.org_id.message}
                                  </p>
                                )}
                              </label>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex space-x-4">
                              {!selectedRoleIds.includes(2) &&
                                selectedRoleIds.includes(3) && (
                                  <div className="flex-1">
                                    <div
                                      className="space-y-4 p-4 sm:p-5"
                                      id="category"
                                    >
                                      <label className="block">
                                        <span>Organization </span>

                                        <Controller
                                          name="org_id"
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              options={organizations_Options}
                                              isSearchable={true}
                                              className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                              onChange={(selected) =>
                                                setValue(
                                                  "org_id",
                                                  selected
                                                    ? selected.value.toString()
                                                    : null
                                                )
                                              }
                                              styles={customStyles}
                                              value={commissionTypeOptions.find(
                                                (option) =>
                                                  option.value === field.value
                                              )}
                                              placeholder="Select Organization"
                                            />
                                          )}
                                        />

                                        {errors.org_id && (
                                          <p className="text-red-500">
                                            {errors.org_id.message}
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                )}

                              {selectedRoleIds.includes(4) && (
                                <div className="flex-1">
                                  <div
                                    className="space-y-4 p-4 sm:p-5"
                                    id="category"
                                  >
                                    <label className="block">
                                      <span>Commission Type</span>
                                      <Controller
                                        name="agent_commission_type"
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            options={commissionTypeOptions}
                                            isSearchable={true}
                                            className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            onChange={(selected) =>
                                              setValue(
                                                "agent_commission_type",
                                                selected
                                                  ? selected.value.toString()
                                                  : null
                                              )
                                            }
                                            styles={customStyles}
                                            value={commissionTypeOptions.find(
                                              (option) =>
                                                option.value === field.value
                                            )}
                                            placeholder="Select Commission Type"
                                          />
                                        )}
                                      />
                                      {errors.agent_commission_type && (
                                        <p className="text-red-500">
                                          {errors.agent_commission_type.message}
                                        </p>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              )}
                              {selectedRoleIds.includes(4) && (
                                <div className="flex-1">
                                  <div
                                    className="space-y-4 p-4 sm:p-5"
                                    id="category"
                                  >
                                    <label className="block">
                                      <span>Commission About</span>
                                      <Controller
                                        name="agent_commission_about"
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            options={commissionAboutOptions}
                                            isSearchable={true}
                                            className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            onChange={(selected) =>
                                              setValue(
                                                "agent_commission_about",
                                                selected
                                                  ? selected.value.toString()
                                                  : null
                                              )
                                            }
                                            styles={customStyles}
                                            value={commissionAboutOptions.find(
                                              (option) =>
                                                option.value === field.value
                                            )}
                                            placeholder="Select Commission About"
                                          />
                                        )}
                                      />
                                      {errors.agent_commission_about && (
                                        <p className="text-red-500">
                                          {
                                            errors.agent_commission_about
                                              .message
                                          }
                                        </p>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            {selectedRoleIds.includes(4) && (
                              <div className="flex space-x-4">
                                <div className="flex-1">
                                  <div
                                    className="space-y-4 p-4 sm:p-5"
                                    id="category"
                                  >
                                    <label className="block">
                                      <span>Agent Commission</span>

                                      <Controller
                                        name="agent_commission"
                                        control={control}
                                        render={({ field }) => (
                                          <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Enter Agent Commission"
                                            type="text"
                                            {...field}
                                          />
                                        )}
                                      />

                                      {errors.agent_commission && (
                                        <p className="text-red-500">
                                          {errors.agent_commission.message}
                                        </p>
                                      )}
                                    </label>
                                  </div>
                                </div>

                                <div className="flex-1">
                                  <div className="space-y-4 p-4 sm:p-5">
                                    <label className="block">
                                      <span>Country</span>

                                      <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            options={currencies_Options}
                                            isSearchable={true}
                                            className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            onChange={(selected) =>
                                              setValue(
                                                "country",
                                                selected
                                                  ? selected.value.toString()
                                                  : null
                                              )
                                            }
                                            styles={customStyles}
                                            value={currencies_Options.find(
                                              (option) =>
                                                option.value === field.value
                                            )}
                                            placeholder="Select Country"
                                          />
                                        )}
                                      />

                                      {errors.country && (
                                        <p className="text-red-500">
                                          {errors.country.message}
                                        </p>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="space-y-4 p-4 sm:p-5">
                  <button
                    type="submit"
                    className={`btn bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                      isLoading ? "cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div
                          className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                          role="status"
                        ></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserCreate;
