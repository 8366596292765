import React, { useState, useEffect, useRef } from "react";
import { agentServicesDetail } from "../../services/agent/agentServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../hooks/Pagination";
import PageHeader from "../../hooks/PageHeader";
import TableTools from "../../hooks/TableTools";
import { formatCurrency, formatDate, formatName } from '../../helpers/Helpers';
import StatCard from "../../hooks/StatCard";
import CustomLoader from './customloader';
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";



const CashTotalDetail = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [recordsPerPage, setRecordsPerPage ] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [totalCash, setTotalCash] = useState(0);
  const [totalCashDeposit, setTotalCashDeposit] = useState(0);
  const [totalCashInHand, setTotalCashInHand] = useState(0);

  const { id } = useParams();
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try { 
        const response = await agentServicesDetail(currentPage, searchText, id);
         
        if (response && response.order_detail.data) {
            setTotalRecords(response.order_detail.total);
            setRecordsPerPage(response.order_detail.per_page);


            setData(response.order_detail.data);

            setTotalCash(response.total_cash);
            setTotalCashDeposit(response.total_cash_deposit);
            setTotalCashInHand(response.cash_in_hand);
        } else {
            
            setTotalRecords(0);
            setData([]);
        }
        
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
       setPageLoading(true);
    }; 

    fetchData();
  }, [currentPage, searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    alert('CSV Export');
  };

  const handleExportPDF = () => {
    alert('PDF Export');

  };
  const onButtonClickCreate = () => {
    alert('apply');
  }
  if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
  return (
    <>
    <SEO
    title="Cash Total Detail - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Cash Total Detail" 
        onButtonClick={onButtonClickCreate}
              showCreateButton={false}
              onBackButtonClick={() => navigate(-1)}
              showBackButton
      /> 
      <div class="grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-3 lg:gap-6">
        <StatCard
          title="Total Sale"
          value={`${symbol} ${totalCash.toFixed(2)}`}
        />

        <StatCard
          title="Total Cash Deposit"
          value={`${symbol} ${parseFloat(totalCashDeposit).toFixed(2)}`}
        />

         <StatCard
          title="Cash in Hand"
          value={`${symbol} ${totalCashInHand.toFixed(2)}`}
        />
      </div> 
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        {/* Hoverable Rows */}
        <div className="card px-4 pb-4 sm:px-5">
          <TableTools
            title="Cash Rows"
            showSearch={false}
              showExportButtons={false}
            onSearchChange={handleSearchChange}
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
          />
          <div className="mt-5">
            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                     #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Order Id		
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Tickets Name	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Tickets type	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Customer Name	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Total Number	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Tickets Price	
                    </th>                  
                    <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                    >
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap  px-4 py-3 sm:px-5">
                       
                        {item.order_id}
                       
                      
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.tickets_name}
                      </td>
                     <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                     {item.status_additional === 0 ? 'Paid' : 'FREE'}

                    </td> 
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.user_name}
                    </td> 
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.ticket_number}
                    </td> 
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                  
                    {`${symbol} ${item.ticket_price.toFixed(2)}`}


                    </td>       
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {formatDate(item.created_at)}
                    </td> 
                
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalRecords={totalRecords}
              perPage={recordsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default CashTotalDetail;
