import axios from 'axios';
import { API_ENDPOINTS } from '../api';



export const AgentDashboardservice = async (currentPage,searchText) => {
  
    try {
      const authToken = localStorage.getItem('authToken');
      let url = API_ENDPOINTS.AGENT_DASHBOARD_LIST + `?page=${currentPage}`;
      
      if (searchText && searchText.trim() !== '') {
        url += `&search=${encodeURIComponent(searchText)}`;
      }
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`  
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('scanner error:', error);
       throw error;
    }
  };
  export const graphData = async (selectionData) => {
    try { 
      const authToken = localStorage.getItem('authToken');
      const response = await axios.post(API_ENDPOINTS.Agent_DASHBOARD_DATA_GRAPH, selectionData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json', 
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('scanner error:', error);
      throw error;
    }
  };