import axios from "axios";
import { API_ENDPOINTS } from "../admin/api";
import Swal from "sweetalert2";

export const ExportCsv = async (searchText = "") => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText
      ? `&search=${encodeURIComponent(searchText)}`
      : "";
    const url = `${API_ENDPOINTS.CSV_USER_EXPORT}?${searchParam}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    });

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "text/csv" });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = "export.csv"; // Set the file name for the download

    // Append the link, click it, and then remove it from the DOM
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL Object
    URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error("Export CSV error:", error);
    throw error;
  }
};

export const UserListFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText ? `&search=${searchText}` : "";
    const response = await axios.get(
      API_ENDPOINTS.USER_LIST + `?page=${currentPage}${searchParam}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};
export const User_Create = async () => {
  try {
    const authToken = localStorage.getItem("authToken");

    const response = await axios.get(API_ENDPOINTS.CreateUser, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const Admin_profile = async () => {
  try {
    const authToken = localStorage.getItem("authToken");

    const response = await axios.get(API_ENDPOINTS.AdminProfile, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const User_Edit = async (id) => {
  try {
    const authToken = localStorage.getItem("authToken");

    const response = await axios.get(API_ENDPOINTS.UserEdit + id + "/edit", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const submitUser = async (formDataToSend) => {
  try {
    console.log("API Request Data:", formDataToSend);
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (
        formDataToSend[key] instanceof File ||
        formDataToSend[key] instanceof Blob
      ) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

    const response = await axios.post(API_ENDPOINTS.StoreUser, formData, {
      headers,
    });
    // Check if the response is okay (status code 2xx)
    if (response.status === 201) {
      console.log("Response:", response);
      return response;
    } else {
      console.error("Failed to submit user data. Status:", response.status);
      throw new Error("Failed to submit user data");
    }
  } catch (error) {
    let errorMessage = "An error occurred";
    if (error.response && error.response.data && error.response.data.error) {
      const { email, password } = error.response.data.error;
      if (email) {
        errorMessage += `\n${email}`;
      }
      if (password) {
        errorMessage += `\n${password}`;
      }
    }
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
      confirmButtonColor: "#A23896",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showCloseButton: false, // Ensure the close button is shown
    });
  }
};

export const updateProfile = async (formDataToSend) => {
  try {
    console.log("API Request Data:", formDataToSend);
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (
        formDataToSend[key] instanceof File ||
        formDataToSend[key] instanceof Blob
      ) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

    try {
      const response = await axios.post(API_ENDPOINTS.StoreProfile, formData, {
        headers,
      });
      //return response;
      // Check if the response is okay (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        // Process the successful response

        return responseData;
      } else {
        // Handle the case where the response status is not okay
        const errorData = response.data; // Access the error details from response.data
        console.error("Failed to submit event data. Status:", response.status);
        console.error("Error Details:", errorData);
        return errorData;
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the request
      console.error("Error:", error.message);
      return error;
    }
  } catch (error) {
    console.error("Error preparing FormData:", error);
    return error;
  }
};

export const UpdateUser = async (formDataToSend, id) => {
  try {
    console.log("API Request Data:", formDataToSend);
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("_method", "PUT");
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (
        formDataToSend[key] instanceof File ||
        formDataToSend[key] instanceof Blob
      ) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

    try {
      const response = await axios.post(
        API_ENDPOINTS.UserUpdate + id,
        formData,
        { headers }
      );
      return response;
      // Check if the response is okay (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        // Process the successful response
        console.log("Response:", responseData);
        return responseData;
      } else {
        // Handle the case where the response status is not okay
        const errorData = response.data; // Access the error details from response.data
        console.error("Failed to submit event data. Status:", response.status);
        console.error("Error Details:", errorData);
        return "Failed to submit event data";
      }
    } catch (error) {
      let errorMessage = "An error occurred";
      if (error.response && error.response.data && error.response.data.error) {
        const { email, password } = error.response.data.error;
        if (email) {
          errorMessage += `\n${email}`;
        }
        if (password) {
          errorMessage += `\n${password}`;
        }
      }
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#A23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    }

    // try {
    //   const response = await axios.post(API_ENDPOINTS.StoreUser, formData, { headers });
    //   // Process the response as needed
    //   console.log('Response:', response);
    //
    //   if (!response.ok) {
    //     return Error('Failed to submit event data');
    //   }
    //   const responseData = await response.json();
    //   return responseData;
    // } catch (error) {
    //   console.error('Error submitting event data:', error);
    //   return error;
    // }
  } catch (error) {
    console.error("Error preparing FormData:", error);
    return error;
  }
};
