import React, { useState, useEffect,useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logoImage from "../assets/images/app-logo.svg";
import { forgetPassword } from '../services/authService';
import FormErrorPopup from '../hooks/FormErrorPopup';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import CustomLoader from './customloader';
import logo from '../assets/images/logo.png';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import Select from 'react-select';
import SEO from "../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../hooks/useResizeObserver";
// Yup validation schema
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const ForgetPage = () => {
  const navigate = useNavigate();
   
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
 
   const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);

  
 
  const handleSubmit = async (values, setSubmitting) => {
    console.log(values.email);
       setIsLoading(true);
    try {
      const response = await forgetPassword(values.email);
      console.log(response);
     

     


      if (response.success) {
                   Swal.fire({
            title: 'Good Job',
            text: 'Your new password send in your email check email and login with that password',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#a23896',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              navigate('/login');
            }
          });
        }else{
            setIsLoading(false);
      Swal.fire({
        title: 'Error',
        text:  'Invalid email ID',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });
        }
     


       
      //navigate('/login', { state: { message: 'Registration successful. Now you can login with your credentials.' } });
    } catch (error) {
   
      console.error('Registration failed:', error);

      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });


      if (error.response && error.response.data && error.response.data.error) {
        const errorsArray = Object.entries(error.response.data.error).flatMap(([key, value]) => value.map(v => `${key}: ${v}`));
        setFormErrors(errorsArray);
      } else {
        // Fallback error message
        setFormErrors(['An unexpected error occurred.']);
      }
      setIsErrorPopupOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  // Function to close the error popup
  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    setFormErrors([]);
  };

  if (pageLoading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
        <CustomLoader
          visible={true}
          height={80}
          width={80}
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius={1}
        />
      </div>
    );
  }
  return (
    <>
      <SEO
      title="Forget Password - eticketing"
    />
      <FormErrorPopup
        isOpen={isErrorPopupOpen}
        errors={formErrors}
        onClose={closeErrorPopup}
      />

      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <a href="#" className="flex items-center space-x-2">
        <img className="w-40 h-12 mx-auto" src={logo} alt="logo" />
        </a>
      </div>
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img
            className="w-full"
            id="hero-image-light"
            src="images/illustrations/dashboard-check.svg"
            alt="image"
          />
          <img
            className="w-full hidden"
            id="hero-image-dark"
            src="images/illustrations/dashboard-check-dark.svg"
            alt="image"
          />
        </div>
      </div>
      
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700  lg:max-w-xl">
        <div className="flex w-full max-w-sm grow flex-col justify-center py-5">
          <div className="text-center">
            <img
              className="mx-auto size-16 lg:hidden"
              src={logoImage}
              alt="logo"
            />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                Welcome Back
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                Please sign in to continue
              </p>
            </div>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ errors, touched,setFieldValue  }) => (
              <Form className="flex flex-col justify-center mt-16 ">

                {/* <div class="grid grid-cols-12 ">
                  <label class="block sm:col-span-8">
                    <span>Email Address</span>
                    <div class="relative mt-1.5 flex">
                      <input
                        class="form-input peer  rounded-lg border border-slate-300 bg-transparent  py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Email address"
                        type="text"
                      />
                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa-regular fa-envelope text-base"></i>
                      </span>
                    </div>
                  </label>
                  
                </div>    */}

                <div class="grid grid-cols-1  sm:grid-cols-12 ">
                  <label class="block col-span-12">
                    <span>Email Address</span>
                    <div class="relative mt-1.5 flex">
                       
                       <Field
                        name="email"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Email"
                        type="email"
                      />

                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa-regular fa-envelope text-base"></i>
                      </span>

                    
                    </div>
                      {touched.email && errors.email && (
                    <div className="text-red-500">{errors.email}</div>
                  )}
                  </label>
                  
                </div>
              
                <div className="mt-4 space-y-4">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn  h-10 w-full bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                      
                       {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Reset Password"
                    )}


                    
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <a href="#">Privacy Notice</a>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
          <a href="#">Term of service</a>
        </div>
      </main>

    </>
  );
};

export default ForgetPage;
