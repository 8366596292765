import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { CategoryListFetch } from "../../../services/admin/categoryservice";
import { OrderListFetch } from "../../../services/admin/orderservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import TableHeader from "../../../hooks/TableHeader";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from "../Loaderfiles/customloader";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "react-tailwindcss-datepicker";
import { API_ENDPOINTS } from "../../../services/admin/api";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks,
  format,
} from "date-fns";
import SEO from "../../../hooks/seo";

const OrdersList = ({ field }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [data, setData] = useState([]);
  const [Filterdata, setFilterdata] = useState([]);
  const [Categorydata, setDataCategory] = useState([]);
  const [Currencydata, setCurrencydata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [text, setText] = useState("");
  const [yesOrnot, setyesOrnot] = useState(false);
  const [advanced_Search, setAdvancedSearch] = useState(false);
  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const prevSearchFilterdata = useRef();
  const [showCustomRangePicker, setShowCustomRangePicker] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [debouncedFilterdata, setDebouncedFilterdata] = useState(Filterdata);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeValue, setTimeValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await OrderListFetch(
          currentPage,
          searchText,
          Filterdata
        );
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setCurrencydata(response.countries);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };
    if (
      currentPage !== prevPageRef.current ||
      debouncedSearchText !== prevSearchTextRef.current ||
      debouncedFilterdata !== prevSearchFilterdata.current
    ) {
      fetchData();
    }
    // Update the previous values
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
    prevSearchFilterdata.current = Filterdata;
  }, [currentPage, debouncedSearchText, Filterdata]);
  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      try {
        const response = await CategoryListFetch();
        if (response && response.data) {
          setDataCategory(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };

    fetchCategoryData();
  }, []);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setDebouncedFilterdata(Filterdata);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText, Filterdata]);
  const handleSearchChange = (e) => {
    setText(e.target.value);
  };
  // const handleExportCSV = () => {
  //     console.log(Filterdata,'Filterdata');

  //     const token = localStorage.getItem('authToken');
  //     const urlWithToken = API_ENDPOINTS.CSV_ORDER_LIST_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
  //     window.location.href = urlWithToken;
  // };
  const handleExportCSV = () => {
    console.log(Filterdata, "Filterdata");

    const token = localStorage.getItem("authToken");
    let filterDataQuery = "";

    // Convert Filterdata object into an array of filter parameters
    if (typeof Filterdata === "object" && Filterdata !== null) {
      const filters = Object.entries(Filterdata)
        .filter(([key, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
          if (key === "name") {
            // Replace 'name' with 'customer_name'
            return `customer_name=${encodeURIComponent(value)}`;
          }
          return `${key}=${encodeURIComponent(value)}`;
        });
      filterDataQuery = filters.join("&");
      if (Filterdata.customer_name) {
        filterDataQuery += `&customer_name=${encodeURIComponent(
          Filterdata.customer_name
        )}`;
      }
    } else {
      console.warn("Filterdata is not an object or is null.");
      // Handle this case based on your requirements
    }

    let urlWithToken =
      API_ENDPOINTS.CSV_ORDER_LIST_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    if (
      Filterdata &&
      Filterdata.date &&
      Filterdata.date.startDate &&
      Filterdata.date.endDate
    ) {
      urlWithToken += `&start_date=${encodeURIComponent(
        Filterdata.date.startDate
      )}&end_date=${encodeURIComponent(Filterdata.date.endDate)}`;
    }
    if (filterDataQuery) {
      window.location.href = urlWithToken + "&" + filterDataQuery;
    } else {
      window.location.href = urlWithToken;
    }
  };

  const handleExportPDF = () => {
    console.log(Filterdata, "Filterdata");

    const token = localStorage.getItem("authToken");
    let filterDataQuery = "";
    let urlWithToken =
      API_ENDPOINTS.PDF_ORDER_LIST_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);

    // Convert Filterdata object into an array of filter parameters
    if (typeof Filterdata === "object" && Filterdata !== null) {
      const filters = Object.entries(Filterdata)
        .filter(([key, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
          if (key === "name") {
            // Replace 'name' with 'customer_name'
            return `customer_name=${encodeURIComponent(value)}`;
          }
          return `${key}=${encodeURIComponent(value)}`;
        });
      filterDataQuery = filters.join("&");

      // If customer_name is present in Filterdata, include it in the filterDataQuery
      if (Filterdata.customer_name) {
        filterDataQuery += `&customer_name=${encodeURIComponent(
          Filterdata.customer_name
        )}`;
      }
    } else {
      console.warn("Filterdata is not an object or is null.");
      // Handle this case based on your requirements
    }

    // Include start_date and end_date if present in Filterdata
    if (
      Filterdata &&
      Filterdata.date &&
      Filterdata.date.startDate &&
      Filterdata.date.endDate
    ) {
      urlWithToken += `&start_date=${encodeURIComponent(
        Filterdata.date.startDate
      )}&end_date=${encodeURIComponent(Filterdata.date.endDate)}`;
    }

    // Redirect to the generated URL
    if (filterDataQuery) {
      window.location.href = urlWithToken + "&" + filterDataQuery;
    } else {
      window.location.href = urlWithToken;
    }
  };

  // const handleExportPDF = () => {
  //     const token = localStorage.getItem('authToken');
  //     const urlWithToken = API_ENDPOINTS.PDF_ORDER_LIST_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
  //     window.location.href = urlWithToken;
  // };
  const formatCurrency = (payment, paymentType, currencySymbol) => {
    let paymentValue;

    if (
      paymentType === "STRIPE" ||
      paymentType === "CASH" ||
      paymentType === "Zing Cashless"
    ) {
      paymentValue = Number(payment).toFixed(2);
    } else if (paymentType === "FREE") {
      paymentValue = "0.00";
    } else {
      paymentValue = "Complementary";
    }

    return `${currencySymbol} ${paymentValue}`;
  };
  if (!pageLoading) {
    return (
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onSubmit = async (data) => {
    setFilterdata(data);
    setLoading(true); // Optionally, set loading state to true while fetching data
    try {
      const response = await OrderListFetch(currentPage, searchText, data);
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false); // Optionally, set loading state to false after fetching data
    }
  };
  const handleReset = async () => {
    reset();
    setSearchText("");
    setFilterdata([]);

    const closeButton = document.querySelector(
      ".absolute.right-0.h-full.px-3.text-gray-400.focus\\:outline-none.disabled\\:opacity-40.disabled\\:cursor-not-allowed"
    );
    if (closeButton) {
      closeButton.click();
      setAdvancedSearch(!advanced_Search);
    }
    const mainButton = document.querySelector(".ease-out");
    if (mainButton) {
      mainButton.classList.add("hidden");
    }

    setLoading(true); // Optionally, set loading state to true while fetching data
    try {
      const response = await OrderListFetch(currentPage, "", {}); // Fetch data with empty search text and filterdata
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false); // Optionally, set loading state to false after fetching data
    }
  };

  const onButtonClickCreate = () => {
    navigate("/admin/create-role");
  };
  const handleCustomRange = (range) => {
    const today = new Date();
    switch (range) {
      case "this_week":
        setStartDate(startOfWeek(today));
        setEndDate(endOfWeek(today));
        setCustomRange(true);
        break;
      case "last_week":
        const lastWeek = subWeeks(today, 1);
        setStartDate(startOfWeek(lastWeek));
        setEndDate(endOfWeek(lastWeek));
        setCustomRange(true);
        break;
      case "this_month":
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        setCustomRange(true);
        break;
      case "last_month":
        const lastMonth = subMonths(today, 1);
        setStartDate(startOfMonth(lastMonth));
        setEndDate(endOfMonth(lastMonth));
        setCustomRange(true);
        break;
      default:
        setStartDate(null);
        setEndDate(null);
        setCustomRange(true);
        break;
    }
  };

  // const handleCustomRangeChange = (event) => {
  //     const range = event.target.value;
  //     if (range === 'custom') {
  //         setShowCustomRangePicker(true);
  //         setCustomRange(false);
  //     } else {
  //         handleCustomRange(range);
  //         setShowCustomRangePicker(false);
  //     }
  // };

  // const handleValueChange = (newValue) => {
  //      setTimeValue(newValue);
  // }

  // const handleReset = () => {
  //     setSearchText('');
  // };

  const search_Text = () => {
    setSearchText(text);
    setyesOrnot(true);
  };

  const resetSearch = async () => {
    setSearchText("");
    setText("");
    setyesOrnot(false);

    const inputElement = document.getElementById("inptserch");
    if (inputElement) {
      inputElement.value = "";
    }

    await fetchDataWithFilters("");
  };

  const handleAdvancedSearch = () => {
    setAdvancedSearch(!advanced_Search);
  };

  const fetchDataWithFilters = async (name, startDate, endDate) => {
    setLoading(true);
    try {
      const response = await OrderListFetch(currentPage, name, timeValue);
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);

        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  // const handleFormSubmit = (event) => {
  //     event.preventDefault();

  //     const formData = new FormData(event.target);
  //     const name = formData.get('name');

  //     fetchDataWithFilters(name);
  //     // fetchDataWithFilters(timeValue);
  // };

  return (
    <>
      <SEO title="Order List - eticketing" />
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <PageHeader
          title="Order List"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />

        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
          <div className="card px-4 pb-4 sm:px-5">
            <TableHeader
              //title="Customer Report Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
              onText={yesOrnot}
              onsearch_Text={search_Text}
              onResetSearch={resetSearch}
              advancedSearch={handleAdvancedSearch}
              showAdvanceSearch={true}
            />

            <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
              {advanced_Search && (
                <div className="col-span-12 lg:col-span-12 mt-4">
                  <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-3">
                        <div className="flex mb-3 lg:mb-0">
                          <div className="w-3/5 mr-4">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="customer-name"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Customer Name
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                      </svg>
                                    </span>
                                    <input
                                      {...field}
                                      type="text"
                                      id="customer-name"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.name ? "border-red-500" : ""
                                      }`}
                                      placeholder="Enter Customer name"
                                    />
                                  </div>
                                  {errors.name && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {errors.name.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-3/5 mr-4">
                            <Controller
                              name="order_id"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="order-id"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Order ID
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      {...field}
                                      type="text"
                                      id="order-id"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.order_id ? "border-red-500" : ""
                                      }`}
                                      placeholder="Enter Order ID"
                                    />
                                  </div>
                                  {errors.order_id && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {errors.order_id.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-3/5 mr-4">
                            <Controller
                              name="user_id"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="user-id"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    User ID
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      {...field}
                                      type="text"
                                      id="user-id"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.user_id ? "border-red-500" : ""
                                      }`}
                                      placeholder="Enter User ID"
                                    />
                                  </div>
                                  {errors.user_id && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {errors.user_id.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full mr-4">
                        <Controller
                          name="payment_type"
                          control={control}
                          render={({ field }) => (
                            <div className="mb-3 lg:mb-0">
                              <label
                                htmlFor="payment_type"
                                className="block mb-2 text-sm font-medium dark:text-white"
                              >
                                Payment Type
                              </label>
                              <div className="flex">
                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                  <svg
                                    class="h-4 w-4 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                    />
                                  </svg>
                                </span>
                                <select
                                  {...field}
                                  id="payment_type"
                                  className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                    errors.payment_type ? "border-red-500" : ""
                                  }`}
                                >
                                  <option value="">Select Payment type</option>
                                  <option value="Scotia">Scotia</option>
                                  <option value="CASH">Cash</option>
                                  <option value="Complimentary">
                                    Complimentary
                                  </option>
                                </select>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-3">
                      <div className="col-span-3">
                        <div className="flex mb-3 lg:mb-0">
                          <div className="w-3/5 mr-4 mt-6">
                            <Controller
                              name="event_name"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="event_name"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Event Name
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        {" "}
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                          x="4"
                                          y="5"
                                          width="16"
                                          height="16"
                                          rx="2"
                                        />{" "}
                                        <line x1="16" y1="3" x2="16" y2="7" />{" "}
                                        <line x1="8" y1="3" x2="8" y2="7" />{" "}
                                        <line x1="4" y1="11" x2="20" y2="11" />{" "}
                                        <line x1="10" y1="16" x2="14" y2="16" />
                                      </svg>
                                    </span>
                                    <input
                                      {...field}
                                      type="text"
                                      id="event_name"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.user_id ? "border-red-500" : ""
                                      }`}
                                      placeholder="Enter Event name"
                                    />
                                  </div>
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-3/5 mr-4 mt-6">
                            <Controller
                              name="date"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="customer-name"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Date
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        {" "}
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="12" cy="12" r="9" />{" "}
                                        <polyline points="12 7 12 12 15 15" />
                                      </svg>
                                    </span>
                                    <Datepicker
                                      {...field}
                                      selected={field.value} // Assuming your Datepicker component uses "selected" prop for value
                                      onChange={(date) => field.onChange(date)} // Ensure the onChange function updates the form field value
                                      showShortcuts={true}
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.date ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-3">
                        <div className="flex mb-3 lg:mb-0">
                          <div className="w-3/5 mr-4 mt-6">
                            <Controller
                              name="country"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="order-id"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Country
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        {" "}
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="12" cy="12" r="9" />{" "}
                                        <line
                                          x1="3.6"
                                          y1="9"
                                          x2="20.4"
                                          y2="9"
                                        />{" "}
                                        <line
                                          x1="3.6"
                                          y1="15"
                                          x2="20.4"
                                          y2="15"
                                        />{" "}
                                        <path d="M11.5 3a17 17 0 0 0 0 18" />{" "}
                                        <path d="M12.5 3a17 17 0 0 1 0 18" />
                                      </svg>
                                    </span>
                                    <select
                                      {...field}
                                      id="country"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.user_id ? "border-red-500" : ""
                                      }`}
                                    >
                                      <option value="">Select Country</option>
                                      {Currencydata.map((currncys) => (
                                        <option
                                          key={currncys.id}
                                          value={currncys.id}
                                        >
                                          {currncys.country}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-3/5 mr-4 mt-6">
                            <Controller
                              name="categories"
                              control={control}
                              render={({ field }) => (
                                <div className="mb-3 lg:mb-0">
                                  <label
                                    htmlFor="Category"
                                    className="block mb-2 text-sm font-medium dark:text-white"
                                  >
                                    Category
                                  </label>
                                  <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                      <svg
                                        class="h-4 w-4 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                        />
                                      </svg>
                                    </span>
                                    <select
                                      {...field}
                                      id="Category"
                                      className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        errors.user_id ? "border-red-500" : ""
                                      }`}
                                    >
                                      <option value="">Select Category</option>
                                      {Categorydata.map((category) => (
                                        <option
                                          key={category.id}
                                          value={category.id}
                                        >
                                          {category.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full mr-4 mt-6">
                        <Controller
                          name="ticket_type"
                          control={control}
                          render={({ field }) => (
                            <div className="mb-3 lg:mb-0">
                              <label
                                htmlFor="ticket_type"
                                className="block mb-2 text-sm font-medium dark:text-white"
                              >
                                Ticket type
                              </label>
                              <div className="flex">
                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                  <svg
                                    class="h-4 w-4 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                                    />
                                  </svg>
                                </span>
                                <input
                                  {...field}
                                  type="text"
                                  id="ticket_type"
                                  className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                    errors.user_id ? "border-red-500" : ""
                                  }`}
                                  placeholder="Enter Ticket type"
                                />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end ">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white mt-6 btn focus:bg-blue-700 ml-2"
                      >
                        Apply
                      </button>
                      <button
                        type="reset"
                        onClick={handleReset}
                        className="btn bg-gray-300 mt-6 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Order Rows"
              // onSearchChange={handleSearchChange}
              // showSearch={true}
              // showExportButtons={true}
              // onExportCSV={handleExportCSV}
              // onExportPDF={handleExportPDF}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <div className="overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="is-hoverable w-full text-left">
                      <thead className="text-center">
                        <tr>
                          <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Order ID
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Customer Name
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Event Name
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Date
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Sold Tickets
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Order Price
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Payout Amount
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Tickets Commission
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Org Vat
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Service Fees
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Processing Fees
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            User Vat
                          </th>

                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Total Paid
                          </th>
                          {/* <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Payment
                                                </th> */}
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Payment Type
                          </th>
                          <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {data.map((item, index) => (
                          <tr
                            key={index}
                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                          >
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {`#` + item.id}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.name && item.last_name
                                ? `${
                                    item.name.charAt(0).toUpperCase() +
                                    item.name.slice(1)
                                  } ${
                                    item.last_name.charAt(0).toUpperCase() +
                                    item.last_name.slice(1)
                                  }`.trim()
                                : "N/A"}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.event_name
                                    ? item.event_name.length > 50
                                      ? `${item.event_name.slice(0, 50)}...`
                                      : item.event_name
                                    : "NULL",
                                }}
                              />
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {formatDate(item.created_at)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.quantity}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.sub_total ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.order_price ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.admin_commission ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.org_vat ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.web_commission ?? 0).toFixed(2)}
                            </td>
                           
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.processing_fee ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry ?? ""}{" "}
                              {(item.user_vat ?? 0).toFixed(2)}
                            </td>
                            {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                                        {item.curruncry === 'JMD' ? 'JMD$' : item.curruncry ?? ''} {(item.payment ?? 0).toFixed(2)}
                                                        </td> */}
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item?.payment_type === "Complimentary" ||
                              item?.payment_type === "Free"
                                ? "0"
                                : `${
                                    item.curruncry === "JMD"
                                      ? "JMD$"
                                      : item.curruncry ?? ""
                                  } ${(item.payment ?? 0).toFixed(2)}`}
                              {/* {item?.payment === 'Free' ? (
                                                            '0'
                                                        ) : (
                                                            item?.payment_type === 'Complimentary' ? (
                                                                '0'
                                                            ) : (
                                                                formatCurrency(
                                                                    item?.payment,
                                                                    item?.payment_type,
                                                                    item?.agent_id ? item.currency_code : (item?.currency?.symbol ?? 'US$')
                                                                )
                                                            )
                                                        )} */}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item?.payment_type}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              <Link to={`/admin/order-invoice/${item.id}`}>
                                <svg
                                  class="h-6 w-6 text-blue-500 ml-8"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  {" "}
                                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                  <circle cx="12" cy="12" r="2" />{" "}
                                  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />{" "}
                                  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" />
                                </svg>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default OrdersList;
