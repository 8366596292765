import React, { useState, useEffect,useRef } from "react";
import PageHeader from "../../../hooks/PageHeader";
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import SEO from "../../../hooks/seo";
import { yupResolver } from '@hookform/resolvers/yup';
import { Role_Create } from "../../../services/admin/roleservice";
import { submitRole } from "../../../services/admin/roleservice";
import { useNavigate} from 'react-router-dom';
import CustomLoader from '../Loaderfiles/customloader';
import useResizeObserver from "../../../hooks/useResizeObserver";
import Swal from 'sweetalert2';
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  permissions: yup.array().required('Permissions are required'),
});


const RoleCreate = () => {

  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Role_Create();
        setIsLoading(false);
      
        if (response && response.permissions) {
          const permissionsData = response.permissions;

          const apiData = permissionsData.map(permission => ({
            value: permission.id,
            label: permission.name,
          }));

          setOptions(apiData);

        }
      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);
        toast.success('This is a success message');
      } finally {
        setLoading(false);
          
      }
      setPageLoading(true);
  };

    fetchData();
  }, []);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset', // Remove the default minHeight
    }),
  };
const onSubmit = async (data, e) => {
  setIsLoading(true);

  try {
    const selectedPermissionValues = data.permissions.map(option => option.value);
    const formData = {
      ...data,
      permissions: selectedPermissionValues,
    }

    const response = await submitRole(formData);

    Swal.fire({
      title: 'Good Job',
      text: response.message,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#a23896',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      showCloseButton: false // Ensure the close button is shown
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'OK'
        navigate('/admin/roles', { state: { message: response.msg } });
      }
    });

  } catch (error) {
    console.log('Error:', error);
    const errorMessage = error?.response?.data?.error;

    Swal.fire({
      title: 'Error',
      text: errorMessage || 'An error occurred',
      icon: 'error',
      confirmButtonColor: '#a23896',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      showCloseButton: false // Ensure the close button is shown
    });
  } finally {
    setIsLoading(false);
  }
};


if (!pageLoading) {
  return (
      <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
          <div className="flex justify-center items-center">
              <div className="flex justify-center items-center h-96">
                  <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4FA94D"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                  />
              </div>
          </div>
      </main>
  );
}


const onBackButtonClick = () => {
        navigate('/admin/roles');
    }


  return (
    <>
    <SEO
    title="Add Roles - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Add Roles"
        showBackButton={true}
        onBackButtonClick={onBackButtonClick}
      />
       <Toaster position="top-right" reverseOrder={false} />
      <div className="mt-4 grid grid-cols-12   transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12 lg:col-span-12">
          <div className="card">
            <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add Roles
                    </h4>
                  </div>
                   

                </div>
              </div>

            </div>

            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                         <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : (


            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-4 p-4 sm:p-5" id="category">
                <label className="block">
                  <span>Name</span>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Username"
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                </label>
              </div>

              <div className="space-y-4 p-4 sm:p-5" id="category">
                <label className="block">
                  <span>Permissions</span>


                  <Controller
                    name="permissions"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={options}
                        className="select2form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-3 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                        onChange={(selected) => setValue('permissions', selected)}
                        styles={customStyles}
                        isSearchable={true} 
                        
                      />
                    )}
                  />
                  {errors.permissions && <p className="text-red-500">{errors.permissions.message}</p>}
                </label>
              </div>

              <div className="space-y-4 p-4 sm:p-5" id="category">
                <button
                  type="submit"
                  className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>

              </div>
            </form>
                            )}
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default RoleCreate;
