import React, { createContext, useContext, useEffect, useState } from 'react';
import {  SettingFetch} from "../services/admin/settingservice";

const SettingContext = createContext();

export const useSetting = () => {
  const context = useContext(SettingContext);
  if (!context) {
    console.warn('useSetting must be used within a SettingProvider');
    return { settings: { logo: '', favicon: '' } }; // Default settings
  }
  return context;
};

export const SettingProvider = ({ children }) => {
  const [settings, setSettings] = useState(() => {
    const storedSettings = JSON.parse(localStorage.getItem('settings'));
    return storedSettings || { logo: '', favicon: '' };
  });

  useEffect(() => {
    localStorage.setItem('settings', JSON.stringify(settings));
  }, [settings]); 

  const SettingfetchData = async () => {
     try {
      const response = await SettingFetch();

      if (response) {
        setSettings(response.setting);
       }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
     }
   };

  useEffect(() => {
    SettingfetchData();
  }, []);

  const updateSettings = (newSettings) => {
    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings, ...newSettings };
      localStorage.setItem('settings', JSON.stringify(updatedSettings));
      return updatedSettings;
    });
  };

  return (
    <SettingContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingContext.Provider>
  );
};
