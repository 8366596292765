import React, { useEffect, useState, useRef } from "react";
import { AgentSettlementDetailFetch, PaymentHistoryFetch,submitStatus } from "../../../services/admin/cash-report-service";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import SEO from "../../../hooks/seo";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import useResizeObserver from "../../../hooks/useResizeObserver";
import { API_ENDPOINTS } from '../../../services/admin/api'
import StatCard from "../../../hooks/AdminStatCard";
import Swal from 'sweetalert2';

const AgentSettlementDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [image, setImage] = useState([]);
  const [PaymentData, setPAYData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [secondCurrentPage, setsecondCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(1);
  const [payTotalRecords, setpayTotalRecords] = useState(1);
  const [CurrentCard, setCurrentCard] = useState([]);
  const [CurrencyDatapay, SetCurrencyDatapay] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemStatus, setSelectedItemStatus] = useState({});
  const [selectedId, setSelectedId] = useState({});
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [totalCardLoading, setTotalCardLoading] = useState(true);
  const [totalCard, SettotalCard] = useState([]);
  const navigate = useNavigate();
  const recordsPerPage = 10;


  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlesecondPageChange = (pageNumber) => {
    setsecondCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {

      setLoading(true);
      try {
        const response = await AgentSettlementDetailFetch(currentPage, searchText, id);
        if (response && response.data) {

          setCurrentCard(response.user.currency.symbol);
          SettotalCard(response);
          setTotalRecords(response.recordsTotal);
          setData(response.data);

        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
        setTotalCardLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchText, id]);
  const fetchDatapay = async () => {

    setIsLoading(true);
    try {
      const response = await PaymentHistoryFetch(secondCurrentPage, searchText, id);
      if (response && response.data) {
        setpayTotalRecords(response.data.last_page);
        setPAYData(response.data.data);
        SetCurrencyDatapay(response.user.currency.symbol);
        setImage(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDatapay();
  }, [secondCurrentPage, searchText, id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
      setsecondCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleExportCSV = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.CSV_AGENT_SETT_DETAIL_EXPORT + id + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const handleExportPDF = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.PDF_AGENT_SETT_DETAIL_EXPORT + id + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  const openModal = (imageSrc, id , status) => {
    setSelectedItem(imageSrc);
    setSelectedId(id);
    setSelectedItemStatus(status);
    setShowModal(true);
  };
  const handleConfirmation = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to confirm the payment.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
         sendStatusToAPI(id, 1);
      }
    });
  };
  
  const handleReject = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reject the payment.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reject'
    }).then((result) => {
      if (result.isConfirmed) {
         sendStatusToAPI(id, 2);
      }
    });
  };
  const sendStatusToAPI = async (id, status) => {
    try {
      const response = await submitStatus(id, status);
      if (response.ok) {
        handleSuccess();
        closeModal();
        fetchDatapay();
      } else {
        handleError('Error submitting status');
      }
    } catch (error) {
      console.error('Error:', error);
      handleError('Network error');
    }
  };
  const handleSuccess = () => {
  Swal.fire({
    title: 'Success',
    text: 'Status submitted successfully',
    icon: 'success',
    confirmButtonText: 'OK',
  });
};

const handleError = (errorMessage) => {
  Swal.fire({
    title: 'Error',
    text: errorMessage,
    icon: 'error',
    confirmButtonText: 'OK',
  });
};
  const onButtonClickCreate = () => {
    navigate('/admin/create-role');
  };
 
  return (
    <>
      <SEO
        title="Agent Orders - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <PageHeader
          title="Agent Orders"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        {totalCard.length === 0 ? (
     <div className="flex justify-center items-center h-96">
      <CustomLoader
        visible={true}
        height={80}
        width={80}
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius={1}
      />
    </div>
  ) : (
    <>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-5 lg:gap-6">
        <StatCard
          title="Total Transactions"
          value={totalCard.recordsTotal}
          svg={
            <g>
              <path stroke="none" d="M0 0h24v24H0z"/>
              <polyline points="3 17 9 11 13 15 21 7" />
              <polyline points="14 7 21 7 21 14" />
            </g>
          }
          svgColor="red-500"
          textColor="gray"
        />
        <StatCard
            title="Total Deposited"
            value={CurrentCard+' '+ (typeof totalCard.totalPaymentPaid === 'number' ? totalCard.totalPaymentPaid.toFixed(2) : 0)}
            svg={
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            }
            svgColor="primary"
            textColor="gray"

          />
          <StatCard
            title="Total Tickets Sold"
            value={CurrentCard +' '+ (totalCard.soldTicketsAmount ? totalCard.soldTicketsAmount.toFixed(2) : 0)}
            svg={
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            }
            svgColor="primary"
            textColor="gray"
          />
          <StatCard
            title="Total Commission Earned"
            value={CurrentCard +' '+ (totalCard.earnedBalance ? totalCard.earnedBalance.toFixed(2) : 0)}
            svg={
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            }
            svgColor="primary"
            textColor="gray"
          />
        </div>
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Agent Orders"
              onSearchChange={handleSearchChange}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
              showSearch={true}
              showExportButtons={true}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Event
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Date
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total Tickets
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Amount
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Commission
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                        >
                          <td className="flex whitespace-nowrap px-4 py-3 sm:px-5">
                            <img
                              src={`${item.event.imagePath + item.event.image || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AA_black_image.jpg&psig=AOvVaw2iqqWrVS1zRNXczEEi_BiW&ust=1706592400807000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPj8oquy_oMDFQAAAAAdAAAAABAD'}`}
                              alt="image" className="mr-3 w-12 h-12 object-cover rounded-full" />
                            <div className="flex flex-col flex-grow">
                              <div className="font-bold">{item.event.name || 'N/A'}</div>
                              <div className="text-gray-500">
                              {item.event.address ? item.event.address.split(' ').slice(0, 10).join(' ') : 'Online Event'}
                           
                            </div>

                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <div className="flex-grow">
                              {formatDate(item.updated_at)}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.quantity + ' tickets'}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {formatCurrency(item.payment, item.payment_type, item.event.currency.symbol)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">

                            {formatCurrency(item.agent_commission, item.payment_type, item.event.currency.symbol) || `${item.event.currency.symbol} 0`}

                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>

            </div>
          </div>
        </div>
        <div className="mt-9">
          <PageHeader
            title="View Agent Payment History"
            onButtonClick={onButtonClickCreate}
            showCreateButton={false}
          />

          <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
            <div className="card px-4 pb-4 sm:px-5">
              <TableTools
                title="View Agent Payment History"

                onExportCSV={handleExportCSV}
                onExportPDF={handleExportPDF}
                showSearch={false}
                showExportButtons={true}
              />
              <div className="lg:mt-5 md:mt-5 mt-16">
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                  {Loading ? (
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center h-96">
                        <CustomLoader
                          visible={true}
                          height={80}
                          width={80}
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius={1}
                        />
                      </div>
                    </div>
                  ) : PaymentData.length === 0 ? (
                    <div className="text-center text-gray-500 mt-8">
                      <h1 className="xl text-3xl">No records found.</h1>
                    </div>
                  ) : (
                    <table className="is-hoverable w-full text-left">
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Proof Of Payment
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Date
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Amount
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Status
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {PaymentData.map((item, index) => (
                          <tr
                            key={index}
                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                          >
                            <td className="flex whitespace-nowrap px-4 py-3 sm:px-5">
                              <img
                                src={`${`https://api.tikkets.com/public/payments/` + item.pop || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AA_black_image.jpg&psig=AOvVaw2iqqWrVS1zRNXczEEi_BiW&ust=1706592400807000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPj8oquy_oMDFQAAAAAdAAAAABAD'}`}
                                alt="image" className="mr-3 w-12 h-12 object-cover rounded-full" />
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              <div className="flex-grow">
                                {formatDate(item.created_at)}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {CurrencyDatapay+' '+ (item.amount ? item.amount.toFixed(2) : 0)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.status === 0 ? (
                                <div className="badge space-x-2.5 px-0 text-pending">
                                  <div className="size-2 rounded-full bg-current" />
                                  <span>Pending</span>
                                </div>
                              ) : item.status === 1 ? (
                                <div className="badge space-x-2.5 px-0 text-success">
                                  <div className="size-2 rounded-full bg-current" />
                                  <span>Confirmed</span>
                                </div>
                              ) : (
                                <div className="badge space-x-2.5 px-0 text-error">
                                  <div className="size-2 rounded-full bg-current" />
                                  <span>Cancelled</span>
                                </div>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <Link onClick={() => openModal(item.pop, item.id,item.status)}><svg class="h-6 w-6 text-gray-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="2" />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg></Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  )}
                  <Pagination
                    totalRecords={payTotalRecords}
                    perPage={recordsPerPage}
                    onPageChange={handlesecondPageChange}
                  />
                </div>
                {showModal && (
  <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-hidden">
    <div
      className="absolute inset-0 bg-slate-900/60 backdrop-blur transition-opacity duration-300"
      onClick={closeModal}
    ></div>
    <div className="relative max-w-xl bg-white rounded-lg overflow-hidden shadow-xl" id="Wdthimg">
      <div className="flex justify-end p-4">
        <button
          className="text-gray-500 hover:text-gray-800"
          onClick={closeModal}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <a href={`https://api.tikkets.com/public/payments/${selectedItem}`} target="_blank" rel="noopener noreferrer">
        <img
          src={`https://api.tikkets.com/public/payments/${selectedItem}`}
          alt="Image"
          className="w-full h-auto max-h-80 object-contain mx-auto cursor-pointer"  
        />
      </a>
      <div className="p-6 text-center">
        <p className="mb-4 text-md font-bold text-gray-600">
          Please confirm before proceeding.
        </p>
        <div className="flex justify-between mt-6 space-x-4">
         {selectedItemStatus === 0 ? (
          <>
          <button
            className="btn bg-gradient-to-r from-fuchsia-600 to-pink-600 font-medium text-white"
            onClick={() => handleConfirmation(selectedId)}
          >
            Confirmed
          </button>
          <button
            className="btn btn-reject font-medium text-white bg-gradient-to-r from-red-500 to-red-600"
            onClick={() => handleReject(selectedId)}
          >
            Reject
          </button>
           </>
          ) : null}
          <button
            className="btn btn-channel font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}



              </div>
            </div>
          </div>
        </div>
        </>
  )}
      </main>
    </>
  );
};

export default AgentSettlementDetail;
