import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

export const submitGeneralSubmit = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.StoreSetting, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitAgentSubmit = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_AGENT_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
    const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitSupportSetting = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_SUPPORT_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitPayment = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_PAYMENT_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitMailNotification = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_MAIL_NOTIFICATION_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitOrganizerSubmit = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_Organizer_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const submitonSubmitVerification = async (formDataToSend) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
  
      Object.keys(formDataToSend).forEach((key) => {
        if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
          formData.append(key, formDataToSend[key]);
        } else if (Array.isArray(formDataToSend[key])) {
          formDataToSend[key].forEach((value, index) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else {
          formData.append(key, formDataToSend[key]);
        }
      });
  
      const response = await axios.post(API_ENDPOINTS.STORE_Verification_SETTINGS, formData, { headers });
  
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error('Failed to submit setting data');
      }
    } catch (error) {
      const errorMessages = Object.values(error.response.data.error).flatMap(fieldErrors => fieldErrors);
      const errorMessage = errorMessages.join(', ');
      throw new Error(errorMessage || 'Failed to submit setting data');
    }
  };
  export const SettingFetch = async () => {
  
    try {
        const authToken = localStorage.getItem('authToken');
          const response = await axios.get(API_ENDPOINTS.GET_SETTINGS, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('scanner error:', error);
      throw error;
    }
  };