import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const ORGDashboardData = async (startDate, endDate) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(`${API_ENDPOINTS.ORG_DASHBOARD_DATA}`, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error; 
  }
}; 
export const ORGDashboardDataDate = async (startDate, endDate, selectedOption) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
       const response = await axios.post(`${API_ENDPOINTS.ORG_DASHBOARD_DATA_DATE}?start_date=${formattedStartDate}&end_date=${formattedEndDate}&selectedOption=${selectedOption}`, {}, {

      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    console.log(response,'ladin');
    return response;
  } catch (error) {
    console.error('scanner error:', error);
    throw error; 
  }
}; 
// export const ORGDashboardDetailData = async () => {
//   try {
//     const authToken = localStorage.getItem('authToken');
//     const response = await axios.get(API_ENDPOINTS.ORG_DASHBOARD_Detail_DATA, {
//       headers: {
//         Authorization: `Bearer ${authToken}`  
//       }
//     });
//     return response;
//   } catch (error) {
//     console.error('scanner error:', error);
//     throw error; 
//   }
// }; 
export const fetchDetailData = async () => {
  try {
    // Retrieve the authorization token from local storage
    const authToken = localStorage.getItem('authToken');

    // Make sure authToken is available before making the request
    if (!authToken) {
      throw new Error('Authorization token not found.');
    }
  
    const response = await fetch(API_ENDPOINTS.FETCHDETAILS_DATA, {
      headers: {
        Authorization: `Bearer ${authToken}` // Pass the authorization token in the header
      }
    });

    const jsonData = await response.json();
    return {
      topCountries: jsonData.topRecords.topCountries,
      userPayments: jsonData.topRecords.userPayments,
      topOrders: jsonData.topRecords.topOrders
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
  
export const graphData = async (selectionData) => {
  try { 
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS.ORG_DASHBOARD_DATA_GRAPH, selectionData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};