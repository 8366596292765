export const formatCurrency = (number, payment_type, currencySymbol = 'USD') => {
  // Attempt to convert to a float
  const num = parseFloat(number);

  // Check if the conversion result is NaN or the original input isn't a number
  if (isNaN(num)) {
      return '';
  }

  // Update currency symbol for Stripe payments
  if (payment_type === 'STRIPE') { 
      currencySymbol = 'USD $';
  }

  // Format the number
  return currencySymbol + ' ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

 

export const formatDate = (date, locale = 'en-US') => {
  // If the date is not already a Date object, try to convert it
  const dateObj = date instanceof Date ? date : new Date(date);

  // Check if the dateObj is a valid date
  if (isNaN(dateObj.getTime())) {
    return 'Invalid Date';
  }

  // Use Intl.DateTimeFormat to format the date and time
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // Use 24-hour format, change to true for 12-hour format
  }).format(dateObj);
};



export const formatName = (firstName, lastName, middleName = '') => {
  // Capitalize the first letter of each name part
  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  // Apply capitalization and concatenate the names
  return [firstName, middleName, lastName].filter(Boolean).map(capitalize).join(' ');
};