import React, { useState, useEffect, useRef, useReducer } from "react";
import PinKeyboardComponent from "./PinKeyboardComponent"; 
import UserPage from "./pos/UserPageComponent";
import PosTickets from "./PosTickets";
import Swal from "sweetalert2";
import { searchUsersPos } from '../../services/agent/agent-SellTickets-service';
import CustomLoader from "./customloader";
const SellTickets_two = ({ initialPageNumber }) => {
  
  const [pageNumber, setpageNumber] = useState(initialPageNumber || 1);
  const [userData, setUserData] = useState(1);
  const [loading, setLoading] = useState(false); // Initialize loading state


  
  const [user_Info, set_UserInfo] = useState(null);

  useEffect(() => {
    const userInfo_String = localStorage.getItem("AgentSellTicket_userInfo");
    if (userInfo_String) {
      const userInfo_Data = JSON.parse(userInfo_String);
      set_UserInfo(userInfo_Data);
    }
  }, []);


  const onSubmitHandler = async (input) => {
    const words = input.split('');

    if (words.length === 5) {
      setLoading(true); // Set loading to true when searching for the user
      console.log(input, 'Submit handler logic');
      await SearchUser(input); // Await for the search operation
      setLoading(false); // Reset loading after search operation completes
    }
  };

  const SearchUser = async (inputValue) => {
    try {
        const response = await searchUsersPos(inputValue);
        if (response.data && response.data.success === true) {
            setpageNumber(2);
            setUserData(response.data.appuser);
        } else {
            Swal.fire({
                title: 'Error',
                text: 'The user you\'re looking for does not exist',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            });
        }
    } catch (error) {
        console.error('Error fetching options:', error);
        Swal.fire({
            title: 'Error',
            text: 'An error occurred while searching for the user.',
            icon: 'error',
            confirmButtonColor: '#a23896',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            showCloseButton: false // Ensure the close button is shown
        });
    }
  };

  return (
    <main
    style={{ width: "100%", height: "100%" }}
    className="main-content pos-app w-full px-[var(--margin-x)] pb-6 transition-all duration-[.25s]"
  >
    {loading ? ( // Render loader if loading is true
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* Your loader component */}
        <CustomLoader
          visible={true}
          height={80}
          width={80}
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius={1}
        />
      </div>
    ) : ( // Otherwise, render main content based on pageNumber
      <>
        {pageNumber === 1 && <PinKeyboardComponent onSubmit={onSubmitHandler} />}
        {pageNumber === 2 && <UserPage userDataInfo={userData} pageNumber={pageNumber} setpageNumber={setpageNumber} />}
        {pageNumber === 3 && <PosTickets  pageNumber={pageNumber} setpageNumber={setpageNumber} user_Info={user_Info}/>}
      </>
    )}
  </main>
  );
};

export default SellTickets_two;
