const API_BASE_URL = "https://api.tikkets.com/public/api/";
const API_BASE_URL_FOR_EXPORT = "https://api.tikkets.com/public/admin/";

export const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}login`,
  ADMIN_DASHBOARD_DATA_GRAPH: `${API_BASE_URL}admin/dashboard_graph_data`,
  ADMIN_DASHBOARD_DATA: `${API_BASE_URL}admin/home`,
  ADMIN_EVENTS: `${API_BASE_URL}admin/events`,
  createEvent: `${API_BASE_URL}admin/events/create`,
  EVENT_COUNTRIES_LIST: `${API_BASE_URL}admin/event/countries`,
  EDIT_EVENT_COUNTRY: `${API_BASE_URL}admin/event/country/`,
  UPDATE_EVENT_COUNTRY: `${API_BASE_URL}admin/update/country`,

  StoreEvent: `${API_BASE_URL}admin/events/store`,
  getSCANNERS: `${API_BASE_URL}scanner`,
  ROLE_LIST: `${API_BASE_URL}admin/roles`,
  ADMIN_VEIW_Event_LIST: `${API_BASE_URL}admin/eventOrders/`,
  USER_LIST: `${API_BASE_URL}admin/users`,
  AdminProfile: `${API_BASE_URL}admin/profile`,
  StoreProfile: `${API_BASE_URL}admin/update-profile`,
  App_USER_LIST: `${API_BASE_URL}admin/app-user`,
  ADMIN_DELETE_ADMIN_GALLERY: `${API_BASE_URL}remove-image/`,
  ADMIN_EVENTS_FETCH_GALLERY: `${API_BASE_URL}admin/event-gallery`,
  ADMIN_EVENTS_Submit_GALLERY: `${API_BASE_URL}admin/add-event-gallery`,
  ViewAppUser: `${API_BASE_URL}admin/app-user/view-user/`,
  ORDER_LIST: `${API_BASE_URL}admin/orders`,
  CATEGORY_LIST: `${API_BASE_URL}admin/category`,
  CATEGORY_SORTING: `${API_BASE_URL}update-sorting/category`,
  SCANNED_TICKER: `${API_BASE_URL}admin/scan-list`,
  BLOG_LIST: `${API_BASE_URL}admin/blog`,
  Blog_Create: `${API_BASE_URL}admin/blog/create`,
  StoreBlog: `${API_BASE_URL}admin/blog`,
  Blog_Edit: `${API_BASE_URL}admin/blog/`,
  update_Blog: `${API_BASE_URL}admin/blog/`,
  COUPONS_LIST: `${API_BASE_URL}admin/coupon`,
  CouponCreate: `${API_BASE_URL}admin/coupon/create`,
  Store_Coupon: `${API_BASE_URL}admin/coupon`,
  CouponEdit: `${API_BASE_URL}admin/coupon/`,
  Update_Coupon: `${API_BASE_URL}admin/coupon/`,
  BANNER_LIST: `${API_BASE_URL}admin/banner`,
  StoreBanner: `${API_BASE_URL}admin/banner`,
  EditBanner: `${API_BASE_URL}admin/banner/`,
  UpdateBanner: `${API_BASE_URL}admin/banner/`,

  MobileBANNER_LIST: `${API_BASE_URL}admin/mobile-banner`,
  MobileStoreBanner: `${API_BASE_URL}admin/mobile-banner`,
  MobileEditBanner: `${API_BASE_URL}admin/mobile-banner/`,
  MobileUpdateBanner: `${API_BASE_URL}admin/mobile-banner/`,
  USER_REVIW_LIST: `${API_BASE_URL}admin/user-review`,
  CUSTOMER_REPORT_LIST: `${API_BASE_URL}admin/card-report/customer`,
  ORGANIZATION_REPORT_LIST: `${API_BASE_URL}admin/card-report/organization`,
  REVENUE_REPORT_LIST: `${API_BASE_URL}admin/card-report/revenue`,
  CONTACT_Queries_LIST: `${API_BASE_URL}admin/contact-queries`,
  LANGUAGE_LIST: `${API_BASE_URL}admin/language`,
  FAQS_LIST: `${API_BASE_URL}admin/faq`,
  StoreFaq: `${API_BASE_URL}admin/faq`,
  EditFaq: `${API_BASE_URL}admin/faq/`,
  Update_Faq: `${API_BASE_URL}admin/faq/`,
  PAGE_LIST: `${API_BASE_URL}admin/page`,
  STORE_PAGE: `${API_BASE_URL}admin/page`,
  EDIT_PAGE: `${API_BASE_URL}admin/page/`,
  UPDATE_PAGE: `${API_BASE_URL}admin/page/`,
  DELETE_PAGE: `${API_BASE_URL}admin/page/`,
  FEEDBACK_LIST: `${API_BASE_URL}admin/feedback`,
  AGENT_SETTLEMENT_REPORTS_LIST: `${API_BASE_URL}admin/agent-report/settlement`,
  SETTLEMENT_REPORT_LIST: `${API_BASE_URL}admin/card-report/settlement`,
  CASH_CUSTOMER_REPORT_LIST: `${API_BASE_URL}admin/cash-report/customer`,
  CASH_ORGANIZATION_REPORT_LIST: `${API_BASE_URL}admin/cash-report/organization`,
  CASH_REVENUE_REPORT_LIST: `${API_BASE_URL}admin/cash-report/revenue`,
  CASH_SETTLEMENT_REPORT_LIST: `${API_BASE_URL}admin/cash-report/settlement`,
  VIEW_SETTLEMENT_DETAIL: `${API_BASE_URL}admin/view-settlement/card/`,
  AGENT_SETTLEMENT_DETAIL_FETCH_LIST: `${API_BASE_URL}admin/agent/view/`,
  PAYMENT_HISTORY_FETCH_LIST: `${API_BASE_URL}admin/agent/view-payment-history/`,
  ORDER_INVOICE: `${API_BASE_URL}admin/order-invoice/`,
  VEIW_EVENT_LIST: `${API_BASE_URL}admin/events/`,
  TICKET_CREATE: `${API_BASE_URL}admin/events/`,
  StoreCategory: `${API_BASE_URL}admin/category`,
  EditCategory: `${API_BASE_URL}admin/category/`,
  UpdateCategory: `${API_BASE_URL}admin/category/`,
  CreateUser: `${API_BASE_URL}admin/users/create`,
  App_CreateUser: `${API_BASE_URL}admin//app-user/store`,
  StoreUser: `${API_BASE_URL}admin/users`,
  StoreAppUser: `${API_BASE_URL}admin/app-user/store`,
  UserUpdate: `${API_BASE_URL}admin/users/`,
  UserEdit: `${API_BASE_URL}admin/users/`,
  ROLE_Create: `${API_BASE_URL}admin/roles/create`,
  StoreSetting: `${API_BASE_URL}admin/save-general-setting`,
  GET_SETTINGS: `${API_BASE_URL}admin/setting`,
  STORE_AGENT_SETTINGS: `${API_BASE_URL}admin/save-agent-setting`,
  STORE_Organizer_SETTINGS: `${API_BASE_URL}admin/save-organization-setting`,
  STORE_Verification_SETTINGS: `${API_BASE_URL}admin/save-verification-setting`,
  STORE_PAYMENT_SETTINGS: `${API_BASE_URL}admin/save-payment-setting`,
  STORE_MAIL_NOTIFICATION_SETTINGS: `${API_BASE_URL}admin/save-mail-setting`,
  STORE_SUPPORT_SETTINGS: `${API_BASE_URL}admin/support-setting`,
  Role_Edit: `${API_BASE_URL}admin/roles/`,
  StoreRolet: `${API_BASE_URL}admin/roles`,
  UpdateRolet: `${API_BASE_URL}admin/roles`,
  DELECT_CONTACT_QUERIES: `${API_BASE_URL}admin/delete_query/`,
  DELECT_FAQ: `${API_BASE_URL}admin/faq/`,
  DeleteAppUser: `${API_BASE_URL}admin/app-user/block-user/`,
  DELETE_BANNER_LIST: `${API_BASE_URL}admin/banner/`,
  MobileDELETE_BANNER_LIST: `${API_BASE_URL}admin/banner/`,
  DELETE_COUPONS_LIST: `${API_BASE_URL}admin/coupon/`,
  DELETE_BLOG_LIST: `${API_BASE_URL}admin/blog/`,
  ADMIN_UPDATE_SINGLE_EVENT: `${API_BASE_URL}admin/events/update/`,
  SUBMIT_FEEDBACK: `${API_BASE_URL}admin/feedback`,
  EditFeedbackData: `${API_BASE_URL}admin/feedback/`,
  updateFeedbackData: `${API_BASE_URL}admin/feedback/`,
  ADMIN_EVENT_Tickets: `${API_BASE_URL}admin/`,
  TICKETS_SORTING: `${API_BASE_URL}update-sorting/tickets`,
  StoreTickets: `${API_BASE_URL}admin/ticket/`,
  Edit_ticket: `${API_BASE_URL}admin/ticket/edit/`,
  UpdateTickets: `${API_BASE_URL}admin/ticket/update/`,
  GET_CODE: `${API_BASE_URL}admin/get-code/`,
  SCANNER_SEARCH: `${API_BASE_URL}admin/events/scanner/search`,
  STATUS_CHANGE_SUBMIT: `${API_BASE_URL}admin/agent/payment-status/`,
  FETCH_SINGLE_EVENT: `${API_BASE_URL}admin/events/:id/edit`,
  ADMIN_DELETE_ADMIN_EVENTS: `${API_BASE_URL}admin/ticket/delete/`,
  ADMIN_DELETE_ADMIN_EVENTS: `${API_BASE_URL}admin/ticket/delete/`,
  ADMIN_EVENTS_LIST_DELETE: `${API_BASE_URL}admin/events/destroy/`,
  ADMIN_VEIW_SCAN_LIST: `${API_BASE_URL}admin/scanList/`,
  DOWNLOAD_INVOICE_DATA: `${API_BASE_URL}order-ticket-print`,
  // ADMIN_VEIW_SETTLEMENT_DETAIL_FETCH_LIST: `${API_BASE_URL}admin/agent/view/`,

  //Export URLS
  CSV_USER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-users-csv`,
  PDF_USER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-users-pdf`,
  CSV_BANNER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-banner-csv`,
  PDF_BANNER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-banner-pdf`,
  CSV_ROLE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-roles-csv`,
  PDF_ROLE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-roles-pdf`,
  PDF_ORDER_LIST_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-orders-pdf`,
  CSV_ORDER_LIST_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-orders-csv`,
  CSV_CATEGORY_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-categories-csv`,
  PDF_CATEGORY_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-categories-pdf`,
  CSV_EVENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-events-csv`,
  PDF_EVENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-events-pdf`,
  PDF_SCANNED_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-events-pdf`,
  CSV_SCANNED_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-events-pdf`,
  PDF_BLOG_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-blogs-pdf`,
  CSV_BLOG_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-blogs-csv`,
  PDF_COUPON_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-coupons-pdf`,
  CSV_COUPON_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-coupons-csv`,
  PDF_REVEIW_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-review-pdf`,
  CSV_REVEIW_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-review-csv`,
  PDF_FEEDBACK_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-feedback-pdf`,
  CSV_FEEDBACK_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-feedback-csv`,
  PDF_FAQ_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-faqs-pdf`,
  CSV_FAQ_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-faqs-csv`,
  PDF_CONTACT_QUERIES_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-contact-queries-pdf`,
  CSV_CONTACT_QUERIES_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-contact-queries-csv`,
  PDF_LANGUAGE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-language-pdf`,
  CSV_LANGUAGE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-language-csv`,
  PDF_SCANNED_TICKETS_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-scanned-tickets-pdf`,
  CSV_SCANNED_TICKETS_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-scanned-tickets-csv`,
  CSV_APP_USER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-appusers-csv`,
  PDF_APP_USER_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-appusers-pdf`,
  CSV_CUSTOMER_REPORT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-customer-report-csv`,
  PDF_CUSTOMER_REPORT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-customer-report-pdf`,
  CSV_CARD_ORGANIZATION_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-organization-report-csv`,
  PDF_CARD_ORGANIZATION_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-organization-report-pdf`,
  CSV_CARD_REVENUE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-revenue-report-csv`,
  PDF_CARD_REVENUE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-revenue-report-pdf`,
  CSV_CARD_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-settlement-report-csv`,
  PDF_CARD_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-settlement-report-pdf`,
  PDF_CASH_ORGANIZATION_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-organization-report-pdf`,
  CSV_CASH_ORGANIZATION_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-organization-report-csv`,
  CSV_CASH_customer_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-customer-report-csv`,
  PDF_CASH_customer_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-customer-report-pdf`,
  CSV_CASH_REVENUE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-revenue-report-csv`,
  PDF_CASH_REVENUE_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-revenue-report-pdf`,
  CSV_CASH_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-settlement-report-csv`,
  PDF_CASH_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-settlement-report-pdf`,
  CSV_CASH_AGENT_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-agent-settlement-report-csv`,
  PDF_CASH_AGENT_SETTLEMENT_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-agent-settlement-report-pdf`,
  CSV_AGENT_SETT_DETAIL_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-agent-settlement-detail-csv/`,
  PDF_AGENT_SETT_DETAIL_EXPORT: `${API_BASE_URL_FOR_EXPORT}export-cash-agent-settlement-detail-pdf/`,
};

export default API_ENDPOINTS;
