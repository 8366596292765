import React from "react";
import { ScannedTicketsFetch } from "../../../services/admin/scannedticketsservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import TableHeader from "../../../hooks/TableHeader";
import Datepicker from "react-tailwindcss-datepicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    eventName: yup.string(),
    paymentType: yup.string(),
    ticketType: yup.string(),
    ticketNumber: yup.string(),
    country: yup.string(),
    couponCode: yup.string(),
    categories: yup.string(),
});

// const initialDateValue = {
//     startDate: new Date(),
//     endDate: new Date(new Date().setMonth(11))
// };
const initialDateValue = null;

const ScannedTicketsList = () => {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [advanceSearchText, setadvanceSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [pageLoading, setPageLoading] = useState(false);
    const [text, setText] = useState('');
    const [yesOrnot, setyesOrnot] = useState(false);
    const [advanced_Search, setAdvancedSearch] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [customRange, setCustomRange] = useState(false);
    const [showCustomRangePicker, setShowCustomRangePicker] = useState(false);

    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);


    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });


    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }

    const search_Text = () => {

        setSearchText('&eventName=' + text);
        setyesOrnot(true);
    };
    const resetSearch = () => {
        setSearchText('');
        reset();
        setText('');
        setyesOrnot(false);

        const inputElement = document.getElementById('inptserch');
        if (inputElement) {
            inputElement.value = ''; // Resetting the value of the input field
        }

    };

    const [Datavalue, setdatavalue] = useState(initialDateValue);

    const handleReset = () => {
        setSearchText('');
        setadvanceSearchText([]);
        reset();
        setdatavalue(null);
        const closeButton = document.querySelector('.absolute.right-0.h-full.px-3.text-gray-400.focus\\:outline-none.disabled\\:opacity-40.disabled\\:cursor-not-allowed');
        if (closeButton) {
            closeButton.click();
        }
        const mainButton = document.querySelector('.ease-out');
        const transition = document.querySelector('.transition-all');
        const span = document.querySelector('.rrrr');

        console.log(mainButton);
        if (mainButton) {
            mainButton.classList.add('hidden');
            setAdvancedSearch(!advanced_Search);
        }



        if (transition) {
            transition.addEventListener('focus', () => {
                transition.style.boxShadow = 'none';
                transition.style.borderColor = 'transparent';
            });

            transition.addEventListener('active', () => {
                transition.style.boxShadow = 'none';
                transition.style.borderColor = 'transparent';
            });
        }

    };
    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setdatavalue(newValue);
    };

    const handleFormSubmit = (data) => {
        console.log(data);
        const mergedData = Datavalue ? [...Object.entries(data), ...Object.entries(Datavalue)] : Object.entries(data);

        const result = mergedData
            .filter(([_, value]) => {

                return value !== undefined && typeof value === 'string' && value.trim() !== "";
            })
            .map(([key, value]) => `${key}=${value.trim()}`)
            .join('&');

        console.log(result);
        setCurrentPage(1);
        setadvanceSearchText(result);
    };


    const handleAdvancedSearch = () => {

        setAdvancedSearch(!advanced_Search)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                console.log(advanceSearchText);
                // return false
                const response = await ScannedTicketsFetch(currentPage, searchText, advanceSearchText);

                if (response && response.data) {

                    setTotalRecords(response.recordsTotal, advanceSearchText);
                    setData(response.data);
                    const categoriesOptions = response.categories.map(category => ({
                        id: category.id,
                        name: category.name
                    }));
                    setCategories(categoriesOptions);

                    const countriesOptions = response.countries.map(country => ({
                        id: country.id,
                        name: country.country
                    }));
                    setCountries(countriesOptions);
                    console.log(countriesOptions);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };

        fetchData();
    }, [currentPage, searchText, advanceSearchText]);
    const handleSearchChange = (e) => {
        // setSearchText(e.target.value);
        setText(e.target.value);
    };
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
            setCurrentPage(1);
        }, 300); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);
    const handleExportCSV = () => {


        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_SCANNED_TICKETS_EXPORT + "?token=" + encodeURIComponent(token) + '&' + advanceSearchText;
        window.location.href = urlWithToken;
    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_SCANNED_TICKETS_EXPORT + "?token=" + encodeURIComponent(token) + '&' + advanceSearchText;
        window.location.href = urlWithToken;

    };

    if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
    return (
        <>
            <SEO
                title="Scanned Tickets List - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
                <PageHeader
                    title="Scanned Tickets List"
                    onButtonClick={onButtonClickCreate}
                    showCreateButton={false}
                />

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableHeader
                            //title="Customer Report Rows"
                            onSearchChange={handleSearchChange}
                            showSearch={true}
                            showExportButtons={true}
                            onExportCSV={handleExportCSV}
                            onExportPDF={handleExportPDF}
                            onText={yesOrnot}
                            onsearch_Text={search_Text}
                            onResetSearch={resetSearch}
                            advancedSearch={handleAdvancedSearch}
                            showAdvanceSearch={true}
                        />

                        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">

                            {advanced_Search && (
                                <div className="col-span-12 lg:col-span-12 mt-4">
                                    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
                                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

                                            {/* Event Name */}
                                            <div className="mb-3">
                                                <label htmlFor="event-name" className="block mb-2 text-sm font-medium dark:text-white">Event Name</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/userSvg.svg" alt="Custom Icon" className="w-6 h-6" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="event-name"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.eventName ? 'border-red-500' : ''}`}
                                                        placeholder="Enter Event Name"
                                                        {...register('eventName')}
                                                    />
                                                </div>

                                            </div>


                                            <div className="mb-3">
                                                <label htmlFor="event-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/userSvg.svg" alt="Custom Icon" className="w-6 h-6" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="customer_name"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.eventName ? 'border-red-500' : ''}`}
                                                        placeholder="Enter Customer Name"
                                                        {...register('customer_name')}
                                                    />
                                                </div>

                                            </div>



                                            {/* Payment Type */}
                                            <div className="mb-3">
                                                <label htmlFor="payment-type" className="block mb-2 text-sm font-medium dark:text-white">Payment Type</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/money.svg" alt="Custom Icon" className="w-6 h-6" />
                                                    </span>
                                                    <select
                                                        id="payment-type"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentType ? 'border-red-500' : ''}`}
                                                        {...register('paymentType')}
                                                    >
                                                        <option value="">Select Payment Type</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Stripe">Stripe</option>
                                                        <option value="Complimentary">Complimentary</option>
                                                    </select>
                                                </div>
                                            </div>


                                            {/* Ticket Type */}
                                            <div className="mb-3">
                                                <label htmlFor="ticket-type" className="block mb-2 text-sm font-medium dark:text-white">Ticket Type</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/ticket.svg" alt="Custom Icon" className="w-6 h-6" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="ticket-type"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ticketType ? 'border-red-500' : ''}`}
                                                        placeholder="Enter Ticket Type"
                                                        {...register('ticketType')}
                                                    />
                                                </div>

                                            </div>



                                            <div className="mb-3">
                                                <label htmlFor="ticket-number" className="block mb-2 text-sm font-medium dark:text-white">Order Id</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/number.svg" alt="Custom Icon" className="w-4 h-4" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="ticket-number"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ticketNumber ? 'border-red-500' : ''}`}
                                                        placeholder="Enter order Id"
                                                        {...register('orderId')}

                                                    />
                                                </div>

                                            </div>


                                            {/* Ticket Number */}
                                            {/* <div className="mb-3">
                                                <label htmlFor="ticket-number" className="block mb-2 text-sm font-medium dark:text-white">Ticket Number</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/number.svg" alt="Custom Icon" className="w-4 h-4" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="ticket-number"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ticketNumber ? 'border-red-500' : ''}`}
                                                        placeholder="Enter Ticket Number"
                                                        {...register('ticketNumber')}
                                                    />
                                                </div>
                                                {errors.ticketNumber && <p className="text-red-500 text-sm mt-1">Error Message</p>}
                                            </div> */}

                                            {/* Country */}
                                            <div className="mb-3">
                                                <label htmlFor="country" className="block mb-2 text-sm font-medium dark:text-white">Country</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/flag.svg" alt="Country Icon" className="w-5 h-5" />
                                                    </span>
                                                    <select
                                                        id="country"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.country ? 'border-red-500' : ''}`}
                                                        name="country"
                                                        {...register('country')}
                                                    >
                                                        <option value="">Select a Country</option>
                                                        {countries.map(country => (
                                                            <option key={country.id} value={country.id}>{country.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>

                                            {/* Used Coupon/Access Code */}
                                            {/* <div className="mb-3">
                                                <label htmlFor="coupon-code" className="block mb-2 text-sm font-medium dark:text-white">Used Coupon/Access Code</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/coupon.svg" alt="Custom Icon" className="w-5 h-5" />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="coupon-code"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.couponCode ? 'border-red-500' : ''}`}
                                                        placeholder="Enter Coupon/Access Code"
                                                        {...register('couponCode')}
                                                    />
                                                </div>
                                                {errors.couponCode && <p className="text-red-500 text-sm mt-1">Error Message</p>}
                                            </div> */}

                                            {/* Categories */}
                                            <div className="mb-3">
                                                <label htmlFor="categories" className="block mb-2 text-sm font-medium dark:text-white">Categories</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/category.svg" alt="Category Icon" className="w-5 h-5" />
                                                    </span>
                                                    <select
                                                        id="categories"
                                                        className={`rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.categories ? 'border-red-500' : ''}`}
                                                        name="categories"
                                                        {...register('categories')}
                                                    >
                                                        <option value="">Select a Category</option>
                                                        {categories.map(category => (
                                                            <option key={category.id} value={category.id}>{category.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>


                                            {/* Datepicker for Date */}
                                            <div className="mb-3 lg:mb-0" style={{ width: '500px' }}>
                                                <label htmlFor="date" className="block mb-2 text-sm font-medium dark:text-white">Date</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <img src="/images/date.svg" alt="Custom Icon" className="w-5 h-5" />
                                                    </span>
                                                    <Datepicker
                                                        primaryColor={"blue"}
                                                        value={Datavalue}
                                                        name="date"
                                                        onChange={handleValueChange}

                                                        showShortcuts={true}
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />



                                                </div>
                                            </div>

                                            {/* Datepicker for Customer Name */}
                                            {showCustomRangePicker && (
                                                <div className="mb-3 lg:mb-0">
                                                    <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                    <div className="flex">
                                                        <span className="awan inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                            <img src="/images/customer.svg" alt="Custom Icon" className="w-5 h-5" />
                                                        </span>
                                                        <Datepicker
                                                            value={Datavalue}
                                                            onChange={handleValueChange}
                                                            name="date"

                                                            showShortcuts={true}
                                                            style={{ backgroundColor: '#F9FAFB', important: 'true' }} // Important: 'true'
                                                            className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        </div>





                                        {/* Submit and Reset Buttons */}
                                        <div className="flex justify-end rrrr">
                                            <button type="submit" className="  bg-blue-500 hover:bg-blue-700 text-white btn focus:bg-blue-700 ml-2">Apply</button>
                                            <button
                                                type="reset"
                                                className=" btn bg-gray-300 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </button>
                                            <span className="daaaaaaa"></span>
                                        </div>
                                    </form>
                                </div>
                            )}

                        </div>

                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableTools
                            title="Scanned Tickets Rows"
                        // onSearchChange={handleSearchChange}
                        //  showSearch={true}
                        //  showExportButtons={true}
                        // onExportCSV={handleExportCSV}
                        // onExportPDF={handleExportPDF}
                        />
                        <div className="lg:mt-5 md:mt-5 mt-16">

                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                {loading ? (
                                    <div className="flex justify-center items-center">
                                        <div className="flex justify-center items-center h-96">
                                            <CustomLoader
                                                visible={true}
                                                height={80}
                                                width={80}
                                                color="#4fa94d"
                                                ariaLabel="tail-spin-loading"
                                                radius={1}
                                            />
                                        </div>
                                    </div>
                                ) : data.length === 0 ? (
                                    <div className="text-center text-gray-500 mt-8">
                                        <h1 className="xl text-3xl">No records found.</h1>
                                    </div>
                                ) : (
                                    <table className="is-hoverable w-full text-left">
                                        <thead>
                                            <tr>
                                                <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    ORDER ID
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    EVENT NAME
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    CUSTOMER NAME
                                                </th>
                                                <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    SCANNED AT
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                                >
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {item.order_id || 'N/A'}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        <p
                                                            dangerouslySetInnerHTML={{ __html: item.name || 'NULL' }}
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {item.user_name || item.last_name ? `${item.user_name} ${item.last_name}` : 'N/A'}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {formatDate(item.scanned_at)}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                <Pagination
                                    totalRecords={totalRecords}
                                    perPage={recordsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ScannedTicketsList;
