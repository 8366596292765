import React, { useEffect, useState, useRef } from "react";
import DefaultImage from "../../assets/images/200x200.png";
import logoImage from "../../assets/images/app-logo.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Admin_profile } from "../../services/admin/userservice";
import { useUser } from "../../hooks/UserContext";
import { useSetting } from "../../hooks/SettingContext";
import { SettingFetch } from "../../services/admin/settingservice";
import { Helmet } from "react-helmet";

const Dropdown = ({ title, links, isOpen, toggleDropdown }) => {
  return (
    <div className="relative inline-block mt-4 mb-4">
      <button
        onClick={toggleDropdown}
        className="focus:outline-none text-blue-500 hover:text-blue-700 dark:text-gray-300 dark:hover:text-gray-100"
      >
        {title}
      </button>
      {isOpen && (
        <ul className="absolute bg-white dark:bg-gray-800 shadow-md py-2 mt-1 rounded-md transition-all duration-300 ease-in-out">
          {links.map((link, index) => (
            <li key={index}>
              <Link
                className="block px-4 py-2 text-sm tracking-wide outline-none transition-colors duration-300 ease-in-out text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100"
                to={link.to}
                onClick={toggleDropdown}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Sidebar = () => {
  const { user } = useUser();
  const [profileWindow, setProfileWindow] = React.useState(false);
  const navigate = useNavigate();
  const { settings, updateSettings } = useSetting();
  const [newLogo, setNewLogo] = useState("");
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdown1Ref = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_1, setIsOpen_1] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Get subrole from local storage
    const subrole = localStorage.getItem("subrole");

    // Check if subrole is 'admin'
    setIsAdmin(subrole === "admin");
  }, []);

  const fetchDataadmin = async () => {
    try {
      const response = await Admin_profile();
      setLoading(false);

      setData(response.user);
    } catch (error) {
      //console.error("Error fetching data: ", error);
    } finally {
    }
  };
  useEffect(() => {
    fetchDataadmin();
  }, []);
  const SettingfetchData = async () => {
    setLoading(true);
    try {
      const response = await SettingFetch();

      if (response) {
        //setSetting(response.setting);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    SettingfetchData();
  }, []);
  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const closeDropdown_1 = () => {
    setIsOpen_1(false);
  };

  const isActiveLink = (pathname) => location.pathname === pathname;

  const toggleDropdown_1 = () => {
    setIsOpen_1(!isOpen);
  };

  //   function toggleProfileBox() {
  //   var profileBox = document.getElementById('profile-box');
  //   profileBox.classList.toggle('hidden'); // Assuming you have a 'hidden' class for hiding the element
  // }

  const toggleProfileWindow = () => {
    setProfileWindow((prevState) => !prevState);
  };
  const toggleSidebar = () => {
    const body = document.body;
    const sidebar = document.querySelector(".sidebar");
    body.classList.toggle("is-sidebar-open");
    sidebar.classList.toggle("is-sidebar-open");
  };
  const handleLogout = () => {
    logout(navigate);
  };
  const logout = (navigate) => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("permissions");
    navigate("/login");
  };

  const [profileDropdown, setProfileDropdown] = useState(false);

  const toggleProfileBox = () => {
    setProfileDropdown(!profileDropdown);
  };

  useEffect(() => {
    if (isDropdownOpen1 && dropdown1Ref.current) {
      dropdown1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isDropdownOpen1]);

  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
      existingFavicon.href = `https://api.tikkets.com/public/images/upload/${settings.favicon}`;
    }
  }, [settings.favicon]);

  const DropdownItem_1 = ({ href, text }) => (
    <li>
      <Link
        to="/admin/customer-report-cash"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/customer-report-cash") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Customer Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/organization-report-cash"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/organization-report-cash") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Organization Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/revenue-report-cash"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/revenue-report-cash") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Revenue Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/settlement-report-cash"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/settlement-report-cash") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Settlement Report</span>
        </div>
      </Link>

      <Link
        to="/admin/cash-report/settlement-agent"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/cash-report/settlement-agent")
            ? "text-blue-600"
            : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Agent Settlement Reports</span>
        </div>
      </Link>
    </li>
  );

  const DropdownItem = ({ href, text }) => (
    <li>
      <Link
        to="/admin/customer-report-card"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/customer-report-card") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Customer Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/organization-report-card"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/organization-report-card") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Organization Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/revenue-report-card"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/revenue-report-card") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Revenue Reports</span>
        </div>
      </Link>

      <Link
        to="/admin/settlement-report-card"
        activeClassName="text-primary"
        className={`nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
          isActiveLink("/admin/settlement-report-card") ? "text-blue-600" : ""
        }`}
      >
        <div className="flex items-center space-x-2">
          <span>Settlement Report</span>
        </div>
      </Link>
    </li>
  );

  return (
    <>
      <Helmet>
        <script>
          {`
            var existingFavicon = document.querySelector("link[rel*='icon']");
            if (existingFavicon) {
              existingFavicon.href = "${
                "https://api.tikkets.com/public/images/upload/" +
                settings.favicon
              }";
            }
          `}
        </script>
      </Helmet>

      <div class="sidebar sidebar-panel print:hidden">
        <div class="flex h-full grow flex-col border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-750">
          <div className="flex items-center px-4 pt-8 justify-between">
            {" "}
            {/* Changed justify-center to justify-between */}
            <Link to="/admin/dashboard">
              <img
                className="w-24 md:w-40 object-cover"
                src={`https://api.tikkets.com/public/images/upload/${settings.logo}`}
                alt="logo"
              />
            </Link>
            <button
              onClick={toggleSidebar}
              className="sidebar-close btn size-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>

          <div
            class="nav-wrapper mt-5 h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
            data-simplebar
          >
            <div class="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
            <ul class="flex flex-1 flex-col px-4 font-inter">
              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/dashboard") ? "text-blue-600" : ""
                  }`}
                  to="/admin/dashboard"
                >
                  <i class="lni lni-grid-alt mr-2"></i>
                  Dashboard
                </Link>
              </li>

              <li>
                <Link
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  class={`nav-link flex items-center   p-2 tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/roles") ? "text-blue-600" : ""
                  }`}
                  to="/admin/roles"
                >
                  <img
                    src="/images/iconsNew/roles.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />{" "}
                  {/* Fixed 'scr' to 'src' */}
                  Roles
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/users") ? "text-blue-600" : ""
                  }`}
                  to="/admin/users"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/users.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />{" "}
                  {/* Fixed 'scr' to 'src' */}
                  Users
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/orders") ? "text-blue-600" : ""
                  }`}
                  to="/admin/orders"
                >
                  <i class="lni lni-delivery mr-2 w-17 text-lg"></i>
                  Orders
                </Link>
              </li>

              <li>
                <Link
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/categories") ? "text-blue-600" : ""
                  }`}
                  to="/admin/categories"
                >
                  <img
                    src="/images/iconsNew/category.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Categories
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/events") ? "text-blue-600" : ""
                  }`}
                  to="/admin/events"
                >
                  <i class="lni lni-calendar mr-2 w-17 text-lg"></i>
                  Events
                </Link>
              </li>

              {isAdmin && (
                <li>
                  <Link
                    data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                    data-active-class="font-medium text-primary dark:text-accent-light"
                    className={`nav-link flex items-center p-2 tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                      isActiveLink("/admin/event-country")
                        ? "text-blue-600"
                        : ""
                    }`}
                    to="/admin/event-country"
                  >
                    <i className="lni lni-world mr-2 w-17 text-lg"></i>
                    Event Countries
                  </Link>
                </li>
              )}

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/app-users") ? "text-blue-600" : ""
                  }`}
                  to="/admin/app-users"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/users.svg"
                    className="w-17 h-auto mr-1"
                    alt="Roles Icon"
                  />
                  App Users
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/scanned-tickets")
                      ? "text-blue-600"
                      : ""
                  }`}
                  to="/admin/scanned-tickets"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/scannerTickets.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Scanned Tickets
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/blogs") ? "text-blue-600" : ""
                  }`}
                  to="/admin/blogs"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/blog.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Blogs
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/coupons") ? "text-blue-600" : ""
                  }`}
                  to="/admin/coupons"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/coupon.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Coupons
                </Link>
              </li>
              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/desktop-banner") ? "text-blue-600" : ""
                  }`}
                  to="/admin/desktop-banner"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/banners.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Desktop Banners
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/mobile-banner") ? "text-blue-600" : ""
                  }`}
                  to="/admin/mobile-banner"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/banners.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Mobile Banners
                </Link>
              </li>
              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/user-reviews") ? "text-blue-600" : ""
                  }`}
                  to="/admin/user-reviews"
                >
                  <i class="lni lni-stats-up mr-2"></i>
                  Reveiws
                </Link>
              </li>
              <li
                className={`ac nav-parent ${
                  openDropdown === "card" ? "is-active_svg" : ""
                }`}
              >
                <button
                  className="ac-trigger flex w-full items-center p-2 tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  onClick={() => toggleDropdown("card")}
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/cardsReport.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  <span>Card Report</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`size-4 text-slate-400 transition-transform ease-in-out ${
                      openDropdown === "card" ? "rotate-90" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
                {openDropdown === "card" && (
                  <ul className="ac-panel">
                    <DropdownItem
                      href="pages-onboarding-1.html"
                      text="Onboarding v1"
                    />
                    {/* Add more items as needed */}
                  </ul>
                )}
              </li>

              <li
                className={`ac nav-parent ${
                  openDropdown === "cash" ? "is-active_svg" : ""
                }`}
              >
                <button
                  className="ac-trigger flex w-full items-center p-2 tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  onClick={() => toggleDropdown("cash")}
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/cashReports.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  <span>Cash Reports</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`size-4 text-slate-400 transition-transform ease-in-out ${
                      openDropdown === "cash" ? "rotate-90" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
                {openDropdown === "cash" && (
                  <ul className="ac-panel">
                    <DropdownItem_1
                      href="pages-onboarding-1.html"
                      text="Onboarding v1"
                    />
                    {/* Add more items as needed */}
                  </ul>
                )}
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/feedback") ? "text-blue-600" : ""
                  }`}
                  to="/admin/feedback"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/feedBack.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Feedback
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/faq") ? "text-blue-600" : ""
                  }`}
                  to="/admin/faq"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/faq.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/pageList") ? "text-blue-600" : ""
                  }`}
                  to="/admin/pageList"
                >
                  <svg
                    class="h-4 w-4 text-gray-500 mr-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />{" "}
                    <polyline points="13 2 13 9 20 9" />
                  </svg>
                  Pages
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/contact-queries")
                      ? "text-blue-600"
                      : ""
                  }`}
                  to="/admin/contact-queries"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/contect.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Contact Queries
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center   p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/language") ? "text-blue-600" : ""
                  }`}
                  to="/admin/language"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/lang.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Language
                </Link>
              </li>

              <li>
                <Link
                  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link flex items-center  p-2  tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink("/admin/settings") ? "text-blue-600" : ""
                  }`}
                  to="/admin/settings"
                >
                  <img
                    style={{ width: "20px" }}
                    src="/images/iconsNew/settings.svg"
                    className="w-17 mr-1"
                    alt="Roles Icon"
                  />
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* App Header Wrapper*/}
      <nav className="header print:hidden">
        {/* App Header  */}
        <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
          {/* Header Items */}
          <div className="flex w-full items-center justify-between">
            {/* Left: Sidebar Toggle Button */}
            <div className="size-7">
              <button
                onClick={toggleSidebar}
                className="sidebar-toggle ml-0.5 flex size-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80"
              >
                <span />
                <span />
                <span />
              </button>
            </div>
            {/* Right: Header buttons */}
            <div className="-mr-1.5 flex items-center space-x-2">
              {/* Mobile Search Toggle */}
              <button className="mobile-searchbar-show btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5.5 text-slate-500 dark:text-navy-100"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>

              <button
                id="profile-ref"
                className="avatar size-10"
                onClick={toggleProfileBox}
              >
                <img
                  className="rounded-full"
                  src={
                    user ? user : data.imagePath ? data.imagePath : DefaultImage
                  }
                  alt="avatar"
                />
                <span className="absolute right-0 size-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
              </button>

              {profileDropdown && (
                <div className="absolute top-full right-0 w-48 bg-white border border-slate-150 shadow-md dark:bg-navy-700 dark:border-navy-600">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
                      <Link
                        href="/admin/profile"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                      >
                        <div className="flex size-8 items-center justify-center rounded-lg bg-warning text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                          </svg>
                        </div>
                        <div>
                          <Link to="/admin/profile">
                            <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                              Profile
                            </h2>
                          </Link>

                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Your profile setting
                          </div>
                        </div>
                      </Link>
                    </li>

                    <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
                      <button
                        onClick={handleLogout}
                        className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                        <span>Logout</span>
                      </button>
                    </li>
                    {/* Add more links as needed */}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
