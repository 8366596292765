import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const ScannerList = async (currentPage,searchText) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    let url = API_ENDPOINTS.SCANNERS_LIST + `?page=${currentPage}`;
    
    if (searchText && searchText.trim() !== '') {
      url += `&search=${encodeURIComponent(searchText)}`;
    }
    
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
     throw error;
  }
};

export const ScannerStatus = async (userId) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.SCANNERS_STATUS + `/${userId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
     throw error;
  }
};

export const CreateScannerData = async (formData) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(API_ENDPOINTS.CREATE_SCANNER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorData = await response.json();
      const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
      throw new Error(`Failed to update data: ${errorMessage}`);
    }
  } catch (error) {
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    throw new Error(`An error occurred: ${error.message}`);
  }
};


export const Scanner_Edit = async (id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      
        const response = await axios.get(API_ENDPOINTS.ScannerEdit+id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};




export const DeletescannerList = async (id) => {

  try {
    const authToken = localStorage.getItem('authToken');

    const response = await axios.get(API_ENDPOINTS.DELETE_SCANNER_LIST + id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const UpdateScanner = async (formDataToSend,id) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });
    try {
      const response = await axios.post(API_ENDPOINTS.Store_Scanner+id, formData, { headers });
      // Process the response as needed
      console.log('Response:', response.data);

          return response;
      if (!response.ok) {
        throw new Error('Failed to submit event data');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error submitting event data:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error preparing FormData:', error);
    throw error;
  }
};

