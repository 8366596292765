import axios from "axios";
import { API_ENDPOINTS } from "./api";

export const login = async (email, password) => {
  try {
    const response = await axios.post(API_ENDPOINTS.LOGIN, {
      email,
      password,
    });

    // Check if the response includes the token and role
    if (response.data && response.data.token && response.data.role) {
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("userRole", response.data.role);
      localStorage.setItem("subrole", response.data.subrole);
      localStorage.setItem(
        "permissions",
        JSON.stringify(response.data.permissions)
      );
    } else {
      throw new Error(
        "Authentication token or role is missing in the response"
      );
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};

export const register = async (
  first_name,
  last_name,
  email,
  password,
  organizationName,
  phone,
  countryName
) => {
  try {
    const response = await axios.post(API_ENDPOINTS.REGISTER, {
      first_name,
      last_name,
      email,
      password,
      organizationName,
      phone,
      countryName,
    });

    // You might want to handle the response differently for registration
    // For example, you might not get a token immediately after registration
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

export const forgetPassword = async (email) => {
  try {
    const response = await axios.post(API_ENDPOINTS.forget_Password, {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    // Return the error message or response data
    throw error.response
      ? error.response.data
      : error.message || "An unknown error occurred";
  }
};
