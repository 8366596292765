import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import DefaultImage from '../../assets/images/200x200.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../helpers/Helpers";
import axios from "axios";
import { ORGDashboardData, graphData, fetchDetailData,ORGDashboardDataDate } from "../../services/orgDashboardservice";
import TableTools from "./../../hooks/TableTools";
import { API_ENDPOINTS } from './../../services/api';
import { TailSpin } from "react-loader-spinner";
import CustomDatePicker from './CustomDatePicker';
import CustomDatePickerticket from './DatePicker';
import CustomLoader from './Loaderfiles/customloader';
import SEO from "../../hooks/seo";
import useResizeObserver from "./../../hooks/useResizeObserver";
import { useUser } from './../../hooks/UserContext';


const OrgDashboard = () => {
  const [data, setData] = useState([]);
  const [TicketData, setTicket] = useState([]);
  const [Tickets_percentage, setTickets_percentage] = useState([]);
  const [EventData, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [totalPaymentCount, setTotalPaymentCount] = useState([]);
  const [topCountrie, setTopCountries] = useState([]);
  const [UserPayments, setUserPayments] = useState([]);
  const [TopOrders, setTopOrders] = useState([]);
  const [startDataFromApi, setStartDataFromApi] = useState('');
  const [endDataFromApi, setEndDataFromApi] = useState('');
  const { user } = useUser();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
   {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const [page, setPage] = useState(1);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ORGDashboardData();
      if (response && response) {
        setData(response);
        setEvent(response.events);
        setTicket(response.master)
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  // const fetchDetail = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await ORGDashboardDetailData();
  // const jsonData = await response.json();
  // console.log(jsonData,'ladinawan');
  // setTopCountries(jsonData.topRecords.topCountries);
  // setUserPayments(jsonData.topRecords.userPayments);
  // setTopOrders(jsonData.topRecords.topOrders);
  //     
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   } finally {
  //     setLoading(false);
  //   }
  //    setPageLoading(true);
  // };
  // useEffect(() => {
  //   fetchDetail();
  // }, []);
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const data = await fetchDetailData();
      setTopCountries(data.topCountries);
      setUserPayments(data.userPayments);
      setTopOrders(data.topOrders);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const [graphDate, setGraphDate] = useState([]);
  const [paymentValue, setPaymentValue] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [graphDataSelection, setGraphDataSelection] = useState({
    'start_date': new Date(currentYear, currentMonth, 1),
    'end_date': new Date(currentYear, currentMonth, 31),
    'selected_option': 'days'
  });
 
  const [graphTicketSelection, setGraphTicketSelection] = useState({
    'start_date': new Date(currentYear, currentMonth, 1),
    'end_date': new Date(currentYear, currentMonth, 31),
    'selected_option': 'year'
  });
  
  const handleDateChange = (startDate, endDate, selectedOption) => {
    setGraphDataSelection({
      'start_date': startDate,
      'end_date': endDate,
      'selected_option': selectedOption
    });
  };
  const handleDateTicketChange = (startDate, endDate, selectedOption) => {
    
    setGraphTicketSelection({
      'start_date': startDate,
      'end_date': endDate,
      'selected_option': selectedOption
    });

    ORGDashboardDataDate(startDate, endDate, selectedOption)
      .then(data => {
        setTickets_percentage(data.data.master.tickets_percentage);
      })
      .catch(error => {
        // Handle errors if necessary
      });
  };
 
  

  useEffect(() => {


    setChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }],
      xaxis: { ...prevOptions.xaxis, categories: graphDate }
    }));

    setLineChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }]
    }));



  }, [paymentValue, graphDate]);

  
  const slugify = (text) => {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')         
      .replace(/[^\w-]+/g, '') 
      .replace(/--+/g, '-')  
      .replace(/^-+/, '')   
      .replace(/-+$/, '');  
  }
  useEffect(() => {

    const fetchGraphData = async () => {

      try {
        const response = await graphData(graphDataSelection);
        if (response) {

          if (response && response.date) {
            setGraphDate(response.date);
          }

          if (response && response.total_payment) {
            setPaymentValue(response.total_payment);
          }

          if (response && response.total_payment) {
            setPaymentValue(response.total_payment);
          }

          if (response) {
            setTotalPaymentCount(response.total_payment_count);
          }
          if (response && response.startDate) {
            setStartDataFromApi(response.startDate);
          }
          if (response && response.endDate) {
            setEndDataFromApi(response.endDate);
          }

        }
      }
      catch (error) {
        console.error("Error fetching data: ", error);
      }

    };

    fetchGraphData();

  }, [graphDataSelection]);


  const handleExportCSV = () => {

    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.CSV_DASHBOARD + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const handleExportPDF = () => {

    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.PDF_DASHBOARD + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;

  };

  const [lineChartOptions, setLineChartOptions] = useState({
    colors: ["#4467EF"],
    chart: {
      height: 60,
      type: "line",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: "Sales",
        data: [654, 820, 102, 540, 154, 614],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: -20,
        bottom: -10,
      },
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  });

  const [radialOptions, setRadialOptions] = useState({
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        }
      },
    },
    labels: ['Ticket Sales'],
  })
  const [chartOptions, setChartOptions] = useState({
    colors: ["#4C4EE7", "#0EA5E9"],
    series: [
      {
        name: "Sales",
        data: [28, 45, 35, 50, 32, 55, 23, 60, 28, 45, 35, 50],
      },
      {
        name: "Profit",
        data: [14, 25, 20, 25, 12, 20, 15, 20, 14, 25, 20, 25],
      },
    ],
    chart: {
      height: 255,
      type: "bar",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: "90%",
        columnWidth: "35%",
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        hideOverlappingLabels: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: -10,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 850,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "55%",
            },
          },
        },
      },
    ],
  });

  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
 
  const exportChartToPDF = () => {
    html2canvas(document.querySelector("#chart")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();

      // Calculate the PDF page width and desired image width (50% of the PDF page width)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth * 0.5; // 50% of the PDF page width

      // Calculate the scaled image height to maintain the aspect ratio
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      // Center the image horizontally: (PDF Page Width - Image Width) / 2
      const x = (pdfWidth - imgWidth) / 2;
      const y = 0; // Adjust this value if you want to add a top margin

      // Add image to PDF
      pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

      // Save the PDF
      pdf.save("graph.pdf");
    });
  };


  return (
    <>
      <SEO
        title="Organizer Dashboard - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full pb-8">
        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-8">
            <div className="card mt-12 bg-gradient-to-l from-pink-300 to-indigo-400 p-5 sm:mt-0 sm:flex-row via-purple-300 h100 items-center">
              <div className="flex justify-center sm:order-last">
                <img className="-mt-16 h-40 w-40 sm:mt-0 object-cover" src={TicketData.organizerImage || DefaultImage} alt="" />
              </div>
              <div className="mt-2 flex-1 pt-2 text-center text-white sm:mt-0 sm:text-left">
                <h3 className="text-xl">
                  Welcome Back, <span className="font-semibold">{TicketData.organizer || ''}</span>
                </h3>
                <p className="mt-2 pr-4 leading-relaxed">
                  Your This Month Card Sale is
                  <span className="font-semibold text-navy-700"> {TicketData.month_card_tickets || ''} </span> Ticket(s) and Cash Sale is <span className="font-semibold text-navy-700">{TicketData.month_cash_tickets || ''} </span> Ticket(s) and Complimentary Sale is <span className="font-semibold text-navy-700">{TicketData.month_complimentary_tickets}</span> Ticket(s).
                </p>
                <p><b>Progress is <span className="font-semibold">excellent!</span></b></p>

                <Link className="btn mt-6 bg-slate-50 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80" to="/organization/orders">
                  View Orders
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span12 lg:col-span-4">
      <div className="card pb-5">
        <div className="mt-3 flex items-center justify-between px-4">
          <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
            Ticket Sales
          </h2>
          <div className="inline-flex">
          <CustomDatePickerticket graphSelection={graphTicketSelection} onDateChange={handleDateTicketChange} />

          </div>
        </div>
        <div>
          <ReactApexChart
            options={radialOptions}
            series={[Tickets_percentage]}
            type="radialBar"
            height={175}
          />
        </div>
        <div className="px-4 text-center text-xs+ sm:px-5">
          <p>Percentage of total tickets sold</p>
        </div>
      </div>
    </div>
          <div className="col-span-12 lg:col-span-8">
            <div className="flex items-center justify-between space-x-2">
              <h2 className="text-base font-medium tracking-wide text-slate-800 line-clamp-1 dark:text-navy-100">
                Sales Overview
              </h2>

              <div
                id="sales-tab"
                className="is-scrollbar-hidden overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                <div className="tabs-list flex p-1">
                  <CustomDatePicker
                    graphSelection={graphDataSelection}
                    onDateChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-7">
              <div className="mt-4 flex shrink-0 flex-col items-center sm:items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-8 text-info"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                  />
                </svg>
                <div className="mt-4">
                  <div className="flex items-center space-x-1">
                    <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                      {formatCurrency(totalPaymentCount, 'STRIPE', 'USD')}
                    </p>
                  </div>

                </div>
                <div className="mt-3 flex items-center space-x-2">
                  <div className="ax-transparent-gridline w-28" id="charts">
                    <ReactApexChart
                      options={lineChartOptions}
                      series={lineChartOptions.series}
                      type="line"
                      height={60}
                    />
                  </div>

                </div>
                {/* <button onClick={exportChartToPDF} className="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4.5 text-slate-400 dark:text-navy-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                    />
                  </svg>
                  <span> Download Report</span>
                </button> */}
              </div>
              <div className="ax-transparent-gridline grid w-full grid-cols-1">
                <ReactApexChart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={300}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
              <div className="rounded-lg bg-custom-1 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {TicketData.total_order !== null ? TicketData.total_order : 'N/A'}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-7 text-primary"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    />
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Total Orders</p>
              </div>
              <div className="rounded-lg bg-custom-2 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {TicketData.month_total_order !== null ? TicketData.month_total_order : 'N/A'}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-7 text-blue"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    />
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Orders This Month</p>
              </div>
              <div className="rounded-lg bg-custom-3 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    USD$ {TicketData.card_sales !== null && TicketData.card_sales !== undefined ? TicketData.card_sales.toFixed(2) : 'N/A'}
                  </p>
                  <svg className="text-orange size-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Total Online Sales</p>
              </div>
              <div className="rounded-lg bg-custom-4 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  USD$ {TicketData.month_card_sales !== null && TicketData.month_card_sales !== undefined ? TicketData.month_card_sales.toFixed(2) : 'N/A'}
                  </p>
                  <svg className="text-success size-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Online Sales This Month</p>
              </div>
              <div className="rounded-lg bg-custom-3 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    JMD$ {TicketData.card_sales_jmd !== null && TicketData.card_sales_jmd !== undefined ? TicketData.card_sales_jmd.toFixed(2) : 'N/A'}
                  </p>
                  <svg className="text-orange size-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Total Online Sales</p>
              </div>
              <div className="rounded-lg bg-custom-4 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  JMD$ {TicketData.month_card_sales_jmd !== null && TicketData.month_card_sales_jmd !== undefined ? TicketData.month_card_sales_jmd.toFixed(2) : 'N/A'}
                  </p>
                  <svg className="text-success size-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Online Sales This Month</p>
              </div>
              <div className="rounded-lg bg-custom-5 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    JMD$ {TicketData.cash_sales !== null && TicketData.cash_sales !== undefined ? TicketData.cash_sales.toFixed(2) : 'N/A'}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-7 text-magenta dark:text-accent" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Total Cash Sales</p>
              </div>
              <div className="rounded-lg bg-custom-6 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    JMD$ {TicketData.month_cash_sales !== null && TicketData.month_cash_sales !== undefined ? TicketData.month_cash_sales.toFixed(2) : 'N/A'}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-7 text-warning dark:text-accent" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Cash Sales This Month</p>
              </div>
              <div className="rounded-lg bg-custom-7 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {TicketData.used_tickets !== null ? TicketData.used_tickets : 'N/A'}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-7 text-aqua" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Total Tickets Sold</p>
              </div>
              <div className="rounded-lg bg-custom-8 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {TicketData.month_used_tickets !== null ? TicketData.month_used_tickets : 'N/A'}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="size-7 text-red" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
                <p className="mt-1 text-xs+ card-font-color">Tickets Sold This Month</p>
              </div>
            </div>
          </div>

          <div className="card col-span-12 pb-2 lg:col-span-5 xl:col-span-6">
            <div className="my-3 flex items-center justify-between px-4 sm:px-5">
              <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">  Top Events  </h2>
              <div id="top-seller-menu" className="inline-flex">
                <button className="popper-ref btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="scrollbar-sm flex space-x-3 overflow-x-auto px-4 pb-3 sm:px-5">
              <div className="w-56 shrink-0 rounded-xl bg-gradient-to-l from-pink-300 to-indigo-400 dark:bg-navy-600">
                <Link className="h100 flex justify-center flex-col gap2" to="/organization/create-event">
                  <div className="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="size-15 text-indigo-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                  </div>
                  <div className="flex justify-center">
                    <p className="text-size-15 text-indigo-150">Create Event</p>
                  </div>

                </Link>
              </div>
              {EventData.map((event, index) => {

                
                const paymentValue = event.graph.total_payment;

                // Prepare the chart options for this specific event
                const eventSpecificLineChartOptions = {
                  ...lineChartOptions,
                  series: [{ 
                    ...lineChartOptions.series[0], 
                    data: paymentValue 
                  }]
                };

                return (
                              <div className="w-56 shrink-0 rounded-xl bg-slate-50 dark:bg-navy-600" key={index}>
                                <div className="flex flex-col items-center space-y-3 text-center">
                                  <div className="imageDiv">
                                    <img className="top-rounded-xl" src={event.imagePath + event.image} alt="image" />
                                  </div>
                                  <div>
                                    <p className="text-base font-medium text-slate-700 dark:text-navy-100">{event.name}</p>
                                    <p className="text-xs+ text-slate-400 dark:text-navy-300">{formatDate(event.start_time)}</p>
                                  </div>
                                </div>
                                <div className="mt-5 px-4">
                                  <div>
                                    <p>Sales</p>
                                    <div className="mt-0.5 flex space-x-2">
                                      <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                                        {new Intl.NumberFormat('en-US', {
                                          style: 'currency',
                                          currency: 'USD'
                                        }).format(event.graph.total_payment_count)}
                                      </p>
                                      <p className="flex items-center space-x-0.5 text-xs text-success">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                                        </svg>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="ax-transparent-gridline">
                                    <ReactApexChart
                                      options={eventSpecificLineChartOptions}
                                      series={eventSpecificLineChartOptions.series}
                                      type="line"
                                      height={60}
                                    />
                                  </div>
                                </div>
                                <div className="mt-5 px-4 pb-4 flex justify-center space-x-2">
                                  <Link to={`/organization/view-event/${event.id}`} title="View Event" className="btn h-8 w-8 rounded-full bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                                    <svg xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" className="size-5 text-primary dark:text-accent" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                    </svg>
                                  </Link>
                                  <Link to={`/organization/events/${event.id}`} title="Edit Event" className="btn h-8 w-8 rounded-full bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="size-5 text-primary dark:text-accent" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                                    </svg>
                                  </Link>
                                  <Link to={`/organization/events-tickets/${event.id}/${slugify(event.name)}/tickets`} title="Manage Tickets" className="btn h-8 w-8 rounded-full bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="size-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
              );

            })}
            </div>
          </div>
          <div className="col-span-12 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:col-span-7 lg:gap-6 xl:col-span-6">
            
            <div className="card px-4 pb-5 sm:px-5">
              <div className="my-3 flex h-8 items-center justify-between">
                <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                  Top 8 Countries By Ticket Sales
                </h2>
                {/* <a href="#" className="border-b border-dotted border-current pb-0.5 text-xs+ font-medium text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
                  View All
                </a> */}
              </div>
              <div>
                {/* <p><span className="text-2xl text-slate-700 dark:text-navy-100">64</span>
                </p>
                <p className="text-xs+">Countries</p> */}
              </div>
              <div className="mt-5 space-y-4 flex gap15 flex-col">
                {topCountrie.map((topcunt, index) => (
                  <div key={index} className="flex items-center justify-between mt0">
                    <div className="flex items-center space-x-2">
                    <img className="h-5 w-7" src={topcunt.flagImage} alt="flag"/>
                      <p>{topcunt.country_name}</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm text-slate-800 dark:text-navy-100">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(topcunt.total_payment)}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-success"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 11l5-5m0 0l5 5m-5-5v12"
                        />
                      </svg>
                    </div>
                  </div>
                ))}

              </div>
            </div>
            <div className="card px-4 pb-5 sm:px-5">
              <div className="my-3 flex h-8 items-center justify-between">
                <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                  Top 8 Countries By Users
                </h2>
                {/* <a href="#" className="border-b border-dotted border-current pb-0.5 text-xs+ font-medium text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
                  View All
                </a> */}
              </div>
              <div>
                {/* <p><span className="text-2xl text-slate-700 dark:text-navy-100">64</span>
                </p>
                <p className="text-xs+">Countries</p> */}
              </div>
              <div className="mt-5 space-y-4 flex gap15 flex-col">
                {UserPayments.map((userpaymnt, index) => (
                  <div key={index} className="flex items-center justify-between mt0">
                    <div className="flex items-center space-x-2">
                    <img className="h-5 w-7" src={userpaymnt.flagImage} alt="flag"/>
                      <p>{userpaymnt.country_name}</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm text-slate-800 dark:text-navy-100">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(userpaymnt.total_payment)}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-success"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 11l5-5m0 0l5 5m-5-5v12"
                        />
                      </svg>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex items-center justify-between">
              <h2
                className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100"
              >
                Latest Orders
              </h2>
              <div className="flex">
                <div className="table-search-wrapper flex items-center">
                  <Link to="/organization/orders" className="border-b border-dotted border-current pb-0.5 text-xs+ font-medium text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
                    View All
                  </Link>
                </div>

              </div>
            </div>
            <div className="card mt-3">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                <table className="is-hoverable w-full text-left">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Order ID
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Date
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Name
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Event
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Organizer
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Ticket Type
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        QTY
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                        Payment Method
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                        Payment
                      </th>
                      {/* <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Total
                      </th> */}
                      <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {TopOrders.map(order => (
                      <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500" key={order.id}>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.order_id}</td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          <p className="text-sm font-medium text-gray-900">{new Date(order.created_at).toLocaleDateString()}</p>
                          <p className="text-xs text-gray-500 mt-2">{new Date(order.created_at).toLocaleTimeString()}</p></td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 min-w-[150px] md:min-w-[200px] lg:min-w-[250px]">
                            <div className="flex items-center space-x-4 flex-grow">  
                                <img className="h-10 w-10 rounded-full object-cover" alt="avatar" src={order.imagePath} style={{ objectFit: 'cover' }} />
                                <div className="flex flex-col">
                                    <p className="text-sm font-medium text-gray-900 truncate">{order.customer_full_name}</p>
                                    <p className="text-xs text-gray-500 gridjs-td truncate">{order.customer_email}</p>
                                </div>
                            </div>
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.event_name}</td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.organizer_full_name}</td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.ticket_type}</td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.quantity}</td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <span className='badge bg-success/10 text-success dark:bg-success/15'>
                            {order.payment_type}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">{order.currency_symbol || ''} {` ${parseFloat(order.payment).toFixed(2)}`}</td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <Link to={`/organization/order-invoice/${order.id}`}>
                            <svg class="h-6 w-6 text-blue-500  ml-8" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="2" />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg>
                          </Link>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
                <div className="flex items-center space-x-2 text-xs+">
                  <span>Show</span>
                  <label className="block">
                    <select className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                      <option>10</option>
                      <option>30</option>
                      <option>50</option>
                    </select>
                  </label>
                  <span>entries</span>
                </div> */}

              {/* <ol className="pagination">
                  <li className="rounded-l-lg bg-slate-150 dark:bg-navy-500">
                    <a href="#" className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                      </svg>
                    </a>
                  </li>
                  <li className="bg-slate-150 dark:bg-navy-500">
                    <a href="#" className="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">1</a>
                  </li>
                  <li className="bg-slate-150 dark:bg-navy-500">
                    <a href="#" className="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">2</a>
                  </li>
                  <li className="bg-slate-150 dark:bg-navy-500">
                    <a
                      href="#"
                      className="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                      >3</a
                    >
                  </li>
                  <li className="bg-slate-150 dark:bg-navy-500">
                    <a
                      href="#"
                      className="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                      >4</a
                    >
                  </li>
                  <li className="bg-slate-150 dark:bg-navy-500">
                    <a
                      href="#"
                      className="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                      >5</a
                    >
                  </li>
                  <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                    <a
                      href="#"
                      className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                      </svg>
                    </a>
                  </li>
                </ol> */}
              {/* <div className="text-xs+">1 - 10 of 10 entries</div>
              </div> */}
            </div>
          </div>

        </div>

      </main>
    </>

  );
}

export default OrgDashboard
