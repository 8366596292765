import React from "react";
import { CategoryListFetch } from "../../../services/admin/categoryservice";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../hooks/PageHeader";
import { useState, useEffect } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useRef } from 'react';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { UpdateTcikets } from "../../../services/admin/ticketsService";
import { ticket_edit } from "../../../services/admin/ticketsService";
import { useParams } from 'react-router-dom';
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
registerPlugin(FilePondPluginImagePreview);




const TicketEdit = () => {


  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    quantity: yup.string().required('Quantity is required'),

    description: yup.string().required('Ticket Benefits is required').max(250, 'Ticket Benefits must be at most 250 characters'),


    start_time: yup.string().required('Start time is required'),
    end_time: yup.string().required('End time time is required'),
    ticket_per_order: yup.number().required('Tickets Quantity is required'),
    status: yup.string().required('Status is required'),


  });



  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const { id, slug } = useParams();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [type, setType] = useState('');
  const filePondRef = useRef(null);
  const [event, setEvent] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [availableTickets, setAvailableTickets] = useState('');
  const [quantity, setquantity] = useState('');
  const [count, setCount] = useState(0);
  // console.log(count,'count');
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  useEffect(() => {
    if (control && control.defaultValuesRef && control.defaultValuesRef.current && control.defaultValuesRef.current.description) {
      const initialText = control.defaultValuesRef.current.description || '';
      console.log(initialText, 'initialText');
      setCount(initialText.length);
    }
  }, [control]);


  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await ticket_edit(id);
        setEvent(response.event)


        setValue('name', response.ticket.name);
        setValue('price', response.ticket.price);
        setValue('quantity', response.ticket.quantity);
        setquantity(response.ticket.quantity);
        setValue('ticket_per_order', response.ticket.ticket_per_order);
        setValue('event_id', response.ticket.event_id);
        setAvailableTickets(response.available_limit);

        const startTime = new Date(response.ticket.start_time);
        setValue('start_time', startTime);


        // Assuming that 'response.ticket.end_time' contains the original end date string
        const endTime = new Date(response.ticket.end_time);
        setValue('end_time', endTime);

        setType(response.ticket.type);
        if (response.ticket.type === 'paid') {

          setValue('price', response.ticket.price);
          setValue('local_currency_price', response.ticket.local_currency_price);
        }

        setValue('description', response.ticket.description);
        setCount(response.ticket.description.length);
        setValue('status', response.ticket.status);

      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);

      }
      finally {
        setIsLoading(false);
      }
      setPageLoading(true);
    };

    fetchData();
  }, [id]);



  const handleFileChange = (event) => {

    const file = event.target.files[0];
    setValue('image', file);
  };


  const statusOptions = [
    { value: 0, label: 'Inactive' },
    { value: 1, label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };


  const handleButtonClick = (newType) => {
    setType(newType);
    if (newType === 'free' || newType === 'complimentary') {

      setValue('price', ''); // Clear the value of 'price'
      setValue('local_currency_price', ''); // Clear the value of 'local_currency_price'
    }


  };

  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {
      const startTime = new Date(formData.start_time);
      const endTime = new Date(formData.end_time);
      const eventEndTime = new Date(event.end_time);
      if (endTime <= startTime) {
        // Display error message
        Swal.fire({
          title: 'Error',
          text: 'End time must be later than start time',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return; // Exit the function early
      }
      if (endTime < startTime || endTime > eventEndTime) {
        Swal.fire({
          title: 'Error',
          text: 'Ticket creation time must be within the event time range',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return;
      }
      if (endTime < startTime) {
        // Display error message
        Swal.fire({
          title: 'Error',
          text: 'End time cannot be earlier than start time',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return; // Exit the function early
      }
      const response = await UpdateTcikets(formData, type, id);

      if (response.status === 200) {

        let link;
        link = response.data.url;

        const segments = link.split('/');

        // Select the last three segments
        const lastThreeSegments = segments.slice(-3);

        const commaSeparatedString = lastThreeSegments.join('/');


        Swal.fire({
          title: 'Good Job',
          text: response.data.msg,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/admin/' + commaSeparatedString);
          }
        });

      }
      e.target.reset();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.msg : error.msg;

      Swal.fire({
        icon: 'error',
        title: errorMessage || 'An error occurred',
      });
    } finally {
      setIsLoading(false);
    }
  };


  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate(-1);
  }

  return (
    <>
      <SEO
        title="Edit Ticket - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <ToastContainer />
        <PageHeader

          title={`Edit Ticket For ${event.name || ''}`}
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="font-semibold ml-2">Start Time: {new Date(event.start_time).toLocaleDateString()} {new Date(event.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
        <div className="font-semibold ml-2">End Time: {new Date(event.end_time).toLocaleDateString()} {new Date(event.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>

        <div className="mt-4 grid grid-cols-12   transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-layer-group" />
                  </div>
                  <h4 className=" font-medium text-slate-700 dark:text-navy-100">
                    Edit Ticket
                  </h4>
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4 sm:p-5" id="category">

                  <div>
                    <span
                      className={`mr-2 py-2 px-4 ${type === 'paid' ? 'bg-blue-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('paid')}
                    >
                      Paid
                    </span>
                    <span
                      className={`mr-2 py-2 px-4 ${type === 'free' ? 'bg-green-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('free')}
                    >
                      Free
                    </span>
                    <span
                      className={`py-2 px-4 ${type === 'complimentary' ? 'bg-yellow-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('complimentary')}
                    >
                      Complimentary
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Type of Ticket</span>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Enter Type of Ticket"
                                type="text"
                                {...field}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Quantity</span>
                        <Controller
                          name="quantity"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Enter Quantity"
                                type="number"
                                value={field.value}
                                onChange={(e) => {
                                  const newValue = parseInt(e.target.value, 10);
                                  console.log(e.target.value);
                                  if (e.target.value.trim() === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= availableTickets)) {
                                    field.onChange(e.target.value.trim() === '' ? '' : newValue);
                                  } else {

                                    Swal.fire({
                                      icon: 'error',
                                      title: 'You can add only ticket  less then available tickets',
                                    });
                                    field.onChange(quantity);
                                  }
                                }}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                              <p className="text-red-500">This event have  available tickets: {availableTickets}</p>
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>
                </div>


                {type === 'paid' &&

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <Controller
                            name="price"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="USD Price for online sales"
                                  type="number"
                                  value={field.value || ''}
                                  {...field}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    if (isNaN(value)) {

                                      field.onChange('');
                                    } else {

                                      if (e.target.value.includes("-")) {
                                        Swal.fire({
                                          title: 'Error',
                                          text: 'Please enter a positive value.',
                                          icon: 'error',
                                          confirmButtonColor: '#a23896',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: 'OK',
                                          showCloseButton: false
                                        });
                                        e.target.value = '';
                                      } else {
                                        field.onChange(value);
                                      }

                                    }

                                  }}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) for cash sales</span>
                          <Controller
                            name="local_currency_price"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Local Currency Price"
                                  type="number"
                                  {...field}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    if (isNaN(value)) {
                                      field.onChange(''); // Set field value to empty string if NaN
                                    } else {
                                      if (e.target.value.includes("-")) {
                                        Swal.fire({
                                          title: 'Error',
                                          text: 'Please enter a positive value.',
                                          icon: 'error',
                                          confirmButtonColor: '#a23896',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: 'OK',
                                          showCloseButton: false
                                        });
                                        e.target.value = '';
                                      } else {
                                        field.onChange(value);
                                      }
                                    }
                                  }}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>

                    </div>
                  </div>
                }



                {type === 'free' &&

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border   bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Free"
                            disabled
                            type="number"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) for cash sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Free"
                            type="text"

                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                }

                {type === 'complimentary' &&

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border   bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Complimentary"
                            disabled
                            type="number"
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) for cash sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Complimentary"
                            type="text"

                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                }






                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Maximum ticket per order</span>
                        <Controller
                          name="ticket_per_order"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Maximum ticket per order"
                                type="number"
                                {...field}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  console.log(value);
                                  if (isNaN(value)) {

                                    field.onChange('');
                                  } else {

                                    if (e.target.value > 10) {

                                      Swal.fire({
                                        title: 'Error',
                                        text: 'You add only maximum ticket per order 10',
                                        icon: 'error',
                                        confirmButtonColor: '#a23896',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'OK',
                                        showCloseButton: false // Ensure the close button is shown
                                      });
                                      e.target.value = '';
                                      field.onChange('');
                                    } else {

                                      if (e.target.value.includes("-")) {
                                        // Display an error alert if the input is not a number or negative
                                        Swal.fire({
                                          title: 'Error',
                                          text: 'Please enter a positive value.',
                                          icon: 'error',
                                          confirmButtonColor: '#a23896',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: 'OK',
                                          showCloseButton: false // Ensure the close button is shown
                                        });

                                        // Reset the input value
                                        e.target.value = '';
                                      } else {
                                        // If the value is valid, update the field value
                                        field.onChange(value);
                                      }

                                    }


                                  }

                                }}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>


                </div>



                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Ticket Sales Start Time</span>
                        <Controller
                          name="start_time"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              showTimeSelect
                              dateFormat="yyyy-MM-dd HH:mm"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholderText="Select Start Time"
                            />
                          )}
                        />
                      </label>
                    </div>
                  </div>


                  <div className="col-span-1">

                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Ticket Sales End Time</span>
                        <Controller
                          name="end_time"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              showTimeSelect
                              dateFormat="yyyy-MM-dd HH:mm"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholderText="Select End Time"
                            />
                          )}
                        />
                      </label>
                    </div>
                  </div>
                </div>



                <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block">
                    <span>Ticket Benefits</span>
                    <Controller
                      name="description"
                      control={control}

                      render={({ field, fieldState }) => (
                        <>
                          <div style={{ position: 'relative' }}>
                            <textarea
                              rows="3"
                              className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                              placeholder="Enter Ticket Benefits"
                              maxLength={250}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                setCount(e.target.value.length);
                              }}
                            />
                            <p className="absolute bottom-2 right-2 text-sm text-gray-500">{count}/250</p>
                          </div>
                          {errors.description && <p className="text-red-500">{errors.description.message}</p>}
                        </>
                      )}
                    />
                  </label>
                </div>




                <div className="col-span-1">

                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Status</span>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            isSearchable={true}
                            className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                            styles={customStyles}
                            value={statusOptions.find((option) => option.value === field.value)}
                            placeholder="Select Status"
                          />
                        )}
                      />
                      {errors.status && <p className="text-red-500">{errors.status.message}</p>}

                    </label>
                  </div>
                </div>




                <div className="space-y-4 p-4 sm:p-5" id="category">

                  <button
                    type="submit"
                    className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TicketEdit;
