import React, { useState, useEffect, useRef } from "react";
import { agentServices } from "../../services/agent/agentServices";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../hooks/Pagination";
import PageHeader from "../../hooks/PageHeader";
import TableTools from "../../hooks/TableTools";
import { formatCurrency, formatDate, formatName } from '../../helpers/Helpers';
import StatCard from "../../hooks/AdminStatCard";
import CustomLoader from './customloader';
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";
import { API_ENDPOINTS } from '../../services/api'

const CashTotal = () => {
  const [data, setData] = useState([]);
  const [CurrentSymbol, setCurrentSymbol] = useState([]);
  const [Currencycode , setCurrency ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [totalCash, setTotalCash] = useState(0);
  const [totalCashDeposit, setTotalCashDeposit] = useState(0);
  const [totalCashInHand, setTotalCashInHand] = useState(0);
  const [recordsPerPage, setRecordsPerPage ] = useState(0);
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  console.log(symbol,'symbol');
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await agentServices(currentPage, searchText);
        if (response ) {
          setTotalRecords(response.pagination.total);
          setRecordsPerPage(response.pagination.per_page);
          setCurrency(response.orders.currency_code )
          setCurrentSymbol(response.user.currency);
          setData(response.orders);
          setTotalCash(response.total_cash);
          setTotalCashDeposit(response.total_cash_deposit);
          setTotalCashInHand(response.cash_in_hand);
        } else {
            
            setTotalRecords(0);
            
        }
        
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
       setPageLoading(true);
    }; 

    fetchData();
  }, [currentPage, searchText]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.AGENT_CSV_CASHTOTAL_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.AGENT_PDF_CASHTOTAL_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
   if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
  const onButtonClickCreate = () => {
    alert('apply');
  }
 
  return (
    <>
    <SEO
    title="Cash Total - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Cash Total" 
        onButtonClick={onButtonClickCreate}
                showCreateButton={false}
      />
      <div class="grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-3 lg:gap-6">
        <StatCard
          title="Total Sale"
          value={`${symbol} ${totalCash.toFixed(2)}`}
          svg={
            <g>
              <path stroke="none" d="M0 0h24v24H0z"/>
              <polyline points="3 17 9 11 13 15 21 7" />
              <polyline points="14 7 21 7 21 14" />
            </g>
          }
          svgColor="red-500"
          textColor="gray"
        />

        <StatCard
          title="Total Cash Deposit"
          value={`${symbol} ${totalCash.toFixed(2)}`}
          svg={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          }
          svgColor="primary"
          textColor="gray"
        />

         <StatCard
          title="Cash in Hand"
          value={`${symbol} ${totalCash.toFixed(2)}`}
          svg={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          }
          svgColor="primary"
          textColor="gray"
        />
      </div> 
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        {/* Hoverable Rows */}
        <div className="card px-4 pb-4 sm:px-5">
          <TableTools
            title="Cash Rows"
            showSearch={true}
              showExportButtons={true}
            onSearchChange={handleSearchChange}
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
          />
          <div className="mt-5">
            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
            
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                     #
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Event Name	
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Paid Tickets
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Free Tickets	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Total Value	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Date
                    </th>
                    <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                    >
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap  px-4 py-3 sm:px-5">
                       
                        {item.name}
                       
                      
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.total_paid_tickets}
                      </td>
                     <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {item.total_free_tickets}
                    </td> 
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                    {`${symbol} ${item.total_payment.toFixed(2)}`}
                    </td> 
                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {formatDate(item.order_date)}
                    </td> 
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {/* Actions can be buttons or links */}
                        <Link to={`/agent/cash-total/${item.id}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 12s3-8 10-8 10 8 10 8-3 8-10 8-10-8-10-8z"></path>
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalRecords={totalRecords}
              perPage={recordsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default CashTotal;
