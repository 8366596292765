import React from "react";
import { CardRevenueReportsFetch } from "../../../services/admin/customer-report-service";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import TableHeader from "../../../hooks/TableHeader";
import SEO from "../../../hooks/seo";
import { useState, useEffect, useRef } from "react";
import {
  formatCurrency,
  formatDate,
  formatName,
} from "../../../helpers/Helpers";
import CustomLoader from "../Loaderfiles/customloader";
import { API_ENDPOINTS } from "../../../services/admin/api";
import useResizeObserver from "../../../hooks/useResizeObserver";
import Select from "react-select";
const CardRevenueReports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [yesOrnot, setyesOrnot] = useState(false);
  const [text, setText] = useState("");
  const [events, setEvents] = useState([]);
  const [orgs, setOrg] = useState([]);
  const [users, setUsers] = useState([]);
  const [advanced_Search, setAdvancedSearch] = useState(false);

  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  const truncateWords = (text, maxWords) => {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    return truncatedWords.join(" ");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CardRevenueReportsFetch(currentPage, searchText);
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setData(response.data);
          console.log(response.data, "ok");
          const optionsEvent = response.events.map((event) => ({
            value: event.id,
            label: event.name,
          }));

          setEvents(optionsEvent);

          const optionsOrg = response.orgs.map((event) => ({
            value: event.id,
            label: event.first_name + event.last_name,
          }));

          setOrg(optionsOrg);

          const optionsUser = response.users.map((event) => ({
            value: event.id,
            label: event.name + event.last_name,
          }));

          setUsers(optionsUser);
          console.log(response);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchText]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const handleSearchChange = (e) => {
    setText(e.target.value);
  };
  const handleExportCSV = async () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_CARD_REVENUE_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_CARD_REVENUE_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const onButtonClickCreate = () => {
    navigate("/admin/create-role");
  };

  const handleAdvancedSearch = () => {
    setAdvancedSearch(!advanced_Search);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Get the form data
    const formData = new FormData(event.target);

    // Initialize an empty string for the query parameters
    let queryString = "&";

    // Loop through form data entries and append them to the query string
    for (const [name, value] of formData.entries()) {
      queryString += `${encodeURIComponent(name)}=${encodeURIComponent(
        value
      )}&`;
    }

    // Remove the trailing '&' character
    queryString = queryString.slice(0, -1);
    console.log(queryString);
    setSearchText(queryString);
  };

  const handleReset = () => {
    setSearchText("");
  };

  const search_Text = () => {
    setSearchText(text);
    setyesOrnot(true);
  };
  const resetSearch = () => {
    setSearchText("");
    setText("");
    setyesOrnot(false);

    const inputElement = document.getElementById("inptserch");
    if (inputElement) {
      inputElement.value = ""; // Resetting the value of the input field
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "5px",
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : "none",
    }),

    // You can define more styles for other components like value, singleValue, menu, etc.
  };

  return (
    <>
      <SEO title="Revenue Report - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <PageHeader
          title="Revenue Report"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />

        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
          <div className="card px-4 pb-4 sm:px-5">
            <TableHeader
              //title="Customer Report Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
              onText={yesOrnot}
              onsearch_Text={search_Text}
              onResetSearch={resetSearch}
              advancedSearch={handleAdvancedSearch}
              showAdvanceSearch={false}
            />

            <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
              {advanced_Search && (
                <div className="col-span-12 lg:col-span-12 mt-4">
                  <form onSubmit={handleFormSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                      <div className="mb-4 lg:mb-0">
                        <label className="block">
                          <span className="mb-1">Events</span>
                          {/* <div className="flex items-center">  
                                                       
                                                    </div> */}

                          <Select
                            options={events}
                            isSearchable={true}
                            placeholder="Select Event"
                            name="event_id"
                            styles={customStyles}
                          />
                        </label>
                      </div>

                      <div className="mb-4 lg:mb-0">
                        <label className="block">
                          <span className="mb-1">Organization</span>
                          <Select
                            options={orgs}
                            isSearchable={true}
                            placeholder="Select Organization"
                            name="org"
                            styles={customStyles}
                          />
                        </label>
                      </div>

                      <div className="mb-4 lg:mb-0">
                        <label className="block">
                          <span className="mb-1">Customer</span>
                          <Select
                            options={users}
                            isSearchable={true}
                            placeholder="Select Customer"
                            name="user_id"
                            styles={customStyles}
                          />
                        </label>
                      </div>

                      <div className="mb-4 lg:mb-0">
                        <label className="block">
                          <span className="mb-1">Start Date</span>
                          <label class="relative flex">
                            <input
                              className=" mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Choose date..."
                              type="date"
                              name="start_date"
                            />
                          </label>
                        </label>
                      </div>

                      <div className="mb-4 lg:mb-0">
                        <label className="block">
                          <span className="mb-1">End Date</span>
                          <label class="relative flex">
                            <input
                              className=" mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Choose date..."
                              type="date"
                              name="end_date"
                            />
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white btn focus:bg-blue-700 ml-2"
                      >
                        Apply
                      </button>

                      <button
                        type="reset"
                        className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Revenue Report Rows"
              // showSearch={true}
              // showExportButtons={true}
              // onSearchChange={handleSearchChange}
              // onExportCSV={handleExportCSV}
              // onExportPDF={handleExportPDF}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr className="text-center">
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Order ID
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Customer
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Event Name
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Quantity
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Order Price
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Payout Amount
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Tickets Commission
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Org Vat
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Service Fee
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Processing Fee
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          User Vat
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total Paid
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Created at
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500 text-center"
                        >
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.id || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.customer_name
                              ? formatName(
                                  item.customer_name,
                                  item.customer_last_name
                                )
                              : "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  truncateWords(item.event_name, 5) || "NULL",
                              }}
                            />
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.quantity + " tickets" || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                            {"US$ " +
                              (item.sub_total
                                ? parseFloat(item.sub_total).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.sub_total
                                ? parseFloat(
                                    item.sub_total - item.admin_commission
                                  ).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.admin_commission
                                ? parseFloat(item.admin_commission).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.org_vat
                                ? parseFloat(item.org_vat).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.web_commission
                                ? parseFloat(item.web_commission).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.processing_fee
                                ? parseFloat(item.processing_fee).toFixed(2)
                                : "0.00")}
                          </td> 
                          
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.user_vat
                                ? parseFloat(item.user_vat).toFixed(2)
                                : "0.00")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {"US$ " +
                              (item.payment
                                ? parseFloat(item.payment).toFixed(2)
                                : "0.00")}
                          </td>

                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {formatDate(item.created_at)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CardRevenueReports;
