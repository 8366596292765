import React, { useState, useEffect, useRef } from 'react';
import PageHeader from '../../../hooks/PageHeader';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast, Toaster } from 'react-hot-toast';
import SEO from "../../../hooks/seo";
import {
    fetchUsersAndEvents,
    checkAvailableComplimentaryTicket,
    createComplimentaryTickets,
    searchUsers,
    searchEvent
} from '../../../services/complimentaryservice';
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";


const Complimentarytickets = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        setError,
    } = useForm();
    const [AvailableTicket, setAvailableTicket] = useState();
    const [CheckAvailTicket, setCheckAvailTicket] = useState();
    const [loading, setLoading] = useState(false);
    const [SelectedUser, setSelectedUser] = useState(null);
    const [validate, setvalidate] = useState(true);
    const [numberOfTicket, setnumberOfTicket] = useState();
    const [SelectedsEventID, setSelectedsEventID] = useState();
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [eventinputValue, setEventInputValue] = useState('');
    const [optionsevts, setoptionsevts] = useState([]);
    const navigate = useNavigate();
    const [EventID, setEventID] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
    const fetchData = async () => {
        setLoading(true);
        try {
            const { events } = await fetchUsersAndEvents();
            //setoptionsevts(events);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        setPageLoading(true);
    };
    const fetchDataCalledRef = useRef(false);
    const handleInputChange = (newValue) => {

        setInputValue(newValue);
        loadOptions(newValue);
    };
    const loadOptions = async (inputValue) => {
        try {
            // Split inputValue into words
            const words = inputValue.split('');

            console.log('words', words);
            if (words.length === 3) {
                // Fetch options from API based on the inputValue
                const response = await searchUsers(inputValue);

                const formattedOptions = response.appusers.map(option => ({
                    label: option.name + option.last_name + ' | ' + option.email + ' | ' + option.user_id,
                    value: option.id
                }));

                console.log('formattedOptions', formattedOptions);
                setOptions(formattedOptions);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };
    const handleEventInputChange = (newValue) => {

          
        setEventInputValue(newValue);
        loadEventOptions(newValue);
    };
    const loadEventOptions = async (inputValue) => {
        try {
            // Split inputValue into words
            const words = inputValue.split('');

            console.log('words', words);
            // if (words.length >= 1) {
                // Fetch options from API based on the inputValue
                const response = await searchEvent(inputValue);
                console.log(response.events);
                 

                const eventOptions = response.events.map(option => ({
                    label: option.name,
                    value: option.id
                }));

                console.log('formattedOptions', eventOptions);
                setoptionsevts(eventOptions);
            // }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };
    useEffect(() => {
        if (!fetchDataCalledRef.current) {
            fetchData();
            fetchDataCalledRef.current = true; // Set the ref to true after first call
        }
    }, []);
    useEffect(() => {
        if (SelectedsEventID) {
            checkEventTickets(SelectedsEventID);
        }
    }, [SelectedsEventID]);

    // const handleSelectChange = (selectedOptions) => {
    //     setSelectedUsers(selectedOptions);
    // };

    const handleChange = (e) => {
        e.preventDefault();

        if (e.target.value.includes("-")) {
   
            Swal.fire({
              title: 'Error',
              text: 'Please enter a positive value.',
              icon: 'error',
              confirmButtonColor: '#a23896',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK',
              showCloseButton: false // Ensure the close button is shown
            });
             
            setvalidate(true);
                e.target.value = ''; 
          }else{

            if (e.target.value > 4) {
                Swal.fire({
                    title: 'You can add only 4 complimentary',
                    icon: 'warning',
                    confirmButtonColor: '#a23896',
                    confirmButtonText: 'OK'
                });
                setvalidate(true);
                e.target.value = ''; // Clear the input field
            } else {
                if (e.target.value > CheckAvailTicket || e.target.value > 4) {
                    Swal.fire({
                        title: 'Number of Users and Available tickets should match',
                        icon: 'warning',
                        confirmButtonColor: '#a23896',
                        confirmButtonText: 'OK'
                    });
                    setvalidate(true);
                    e.target.value = ''; // Clear the input field
                } else {
                    setnumberOfTicket(e.target.value);
                    setvalidate(false);
                }
            }


          }


       
    };


    const handleSelectChanges = (selectedevt) => {
        console.log(selectedevt);
        setEventID(selectedevt);
        checkEventTickets(selectedevt.value);
        setSelectedsEventID(selectedevt.value);
    };

    const checkEventTickets = async (eventId) => {
        try {
            const availableTickets = await checkAvailableComplimentaryTicket(eventId);
            setAvailableTicket(<p className="available_tickt text-red-500" id="available_ticket_message">Available Complimentary Tickets: {availableTickets}</p>);
            setCheckAvailTicket(availableTickets);
        } catch (error) {
            console.error('Error checking available complimentary tickets:', error);
        }
    };

    const onSubmit = async (e) => {
        try {
            setLoading(true);
            const customerIdArray = SelectedUser.map(user => user.value);
            const formData = {
                customer_id: customerIdArray,
                event_id: SelectedsEventID,
                complimentary_tickets: numberOfTicket,
            };  
            // console.log(formData);
            // return false;
            const result = await createComplimentaryTickets(formData);


            Swal.fire({
                title: 'Good Job',
                text: 'Complimentary tickets created successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            }).then((result) => {
                if (result.isConfirmed) {
                    // User clicked 'OK'
                    navigate('/organization/orders', { state: { message: 'Scanner Create Successfully' } });
                }
            });

        } catch (error) {

            //  const errorData = await result.json();
            // const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';



            Swal.fire({
                title: 'Error',
                text: 'An error occurred',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            });

        } finally {
            setLoading(false);
        }
    };
    if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }

    return (
        <>
            <SEO
                title="Add Complimentary Ticket - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="container ">
                    <PageHeader
                        title="Add Complimentary Ticket"
                        showCreateButton={false}
                    />
                    <Toaster position="top-center" />
                    <div className="flex items-center justify-center mt-5 bg-white p-4 w-full">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-57rem mx-auto">
                            <div className="flex flex-wrap mb-4">
                                <div className="w-full md:w-1/2">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Select User</label>
                                        <Select
                                            name="customer_id"
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            options={options}
                                            isMulti={true}
                                            onChange={(selectedOption) => {
                                                console.log('Selected option:', selectedOption);
                                                setSelectedUser(selectedOption); // Set the selected user when an option is chosen
                                            }}
                                            placeholder="Select User..."
                                            className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            defaultOptions // Fetch options on initial render
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2">
                                    <div className="mb-4 ml-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Select Event</label>
                                        {/* <Select
                                            name="event_id"
                                            options={optionsevts}
                                            value={EventID}
                                            onChange={handleSelectChanges}
                                            placeholder="Choose Event"
                                            class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        /> */}

                                        <Select
                                            name="event_id"
                                            inputValue={eventinputValue}
                                            onInputChange={handleEventInputChange}
                                            options={optionsevts}
                                            isMulti={false}
                                            onChange={(selectedOption) => {
                                                console.log('Selected option:', selectedOption);
                                                    setEventID(selectedOption);
                                                    checkEventTickets(selectedOption.value);
                                                    setSelectedsEventID(selectedOption.value);
                                            }}
                                            placeholder="Choose Event..."
                                            className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            defaultOptions // Fetch options on initial render
                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-4 lg:pl-0 pl-2">
                                <div className="w-full md:w-1/2">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Select Ticket</label>
                                    <div className="mb-4">

                                        <input
                                            type="number"
                                            class="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="complimentary_tickets"
                                            placeholder="Number of Complimentaries"
                                            min='1'
                                            max='4'
                                            onChange={handleChange}

                                        />
                                        {AvailableTicket}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-4  lg:pl-0 pl-2">
                                <div className="w-full">
                                    <button
                                        type="submit"
                                        className={`text-white px-4 py-2 rounded focus:outline-none 
    ${loading || !SelectedUser || SelectedUser.length === 0 || !SelectedsEventID || !numberOfTicket || validate ? 'disabled bg-gray-500' : 'bg-blue-500'}`}
                                        disabled={loading || !SelectedUser || SelectedUser.length === 0 || !SelectedsEventID || !numberOfTicket || validate}
                                    >
                                        {loading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="spinner-border text-light mr-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span>Loading...</span>
                                            </div>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </main>
        </>
    )
}

export default Complimentarytickets