import React, { useState,useEffect,useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Edit_FeedbackData, submitFeedback,updateFeedback } from "../../../services/admin/feedbackservice";
import { useNavigate } from 'react-router-dom';
import PageHeader from "../../../hooks/PageHeader";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SEO from "../../../hooks/seo";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Link}  from 'react-router-dom';
import { useParams } from 'react-router-dom'; 
import Swal from 'sweetalert2';
import CustomLoader from '../Loaderfiles/customloader';
import useResizeObserver from "../../../hooks/useResizeObserver";
const schema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  message: yup.string().required('Message is required'),
});

const EditFeedback = () => {
  const { control, register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [rate, setRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  
  const { id } = useParams();
   
  const handleRateClick = (rating) => {
    setRate(rating);
  };
  const handleEditorChange = (content) => {
    setValue('message', content);
  };
 

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try { 

          const response = await Edit_FeedbackData(id);
            
          console.log('Edit_FeedbackData',response);
          
          setLoading(false);
           setValue('message',response.data.message);
           setValue('email',response.data.email);
           setRate(response.data.rate);
          
           
      } catch (error) {
           
          console.error("Error fetching data: ", error);
           //toast.success('This is a success message');
      } finally {
        setIsLoading(false);
      }
       
    };

    fetchData();
  }, [id]);


  const onSubmit = async (data) => {
    console.log(data);
   
    setLoading(true);
    try {
      const apiResponse = await updateFeedback({
        ...data,
        rate,
        _method: 'PUT', 
      },id);
      console.log('API response:', apiResponse);
      if (apiResponse.message) {
        reset(); 
       

        Swal.fire({
          title: 'Good Job',
          text: apiResponse.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              navigate('/admin/feedback');
            }
          });

      } else {
      }
    } catch (error) {
       
 
         Swal.fire({
          title: 'Error',
          text:   'An error occurred',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });
    } finally {
      setLoading(false);
    }
  };
   const onBackButtonClick = () => {
        navigate('/admin/feedback');
    }
  return (

    
    <>
    <SEO
    title="Edit Feedback - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
       <PageHeader
              title="Edit Feedback"
              showBackButton={true}
              onBackButtonClick={onBackButtonClick}
              />
      <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12 lg:col-span-12">
          <div className="card bg-white dark:bg-gray-800 p-4 sm:px-5 border rounded-lg">
            <div className="border-b border-slate-200 dark:border-navy-500 pb-4">
             <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Edit Feedback
                    </h4>
                  </div>
                </div>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4FA94D"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : (

                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mt-4">
                  <input type="hidden" name="rate" value={rate} />
                  <div className="rating flex items-center">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <i
                        key={index}
                        className={`fas fa-star text-2xl cursor-pointer ${rate >= index ? 'text-yellow-500' : 'text-gray-300'}`}
                        onClick={() => handleRateClick(index)}
                      ></i>
                    ))}
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    {...register('email')}
                    className={`form-input  w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                  />
                  {errors.email && (
                    <span className="text-red-500 block mt-2">{errors.email.message}</span>
                  )}
                </div>
                <div className="form-group mt-4">
                  <label>Message</label>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactQuill
                        theme="snow"
                        value={field.value}
                        onChange={(content) => {
                          field.onChange(content);
                          handleEditorChange(content);
                        }}
                        className={`quill-editor h-40 ${errors.message ? 'border-red-500' : ''}`}
                      />
                    )}
                  />
                  {errors.message && (
                    <span className="text-red-500 block mt-2">{errors.message.message}</span>
                  )}
                </div>
                <div className="form-group mt-4" style={{ marginTop: '60px' }}> 
                  <button
                    type="submit"
                    disabled={loading}
                    className={`btn bg-primary bg-blue-700 text-white font-medium hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 ${loading ? 'opacity-70 cursor-not-allowed' : ''}`}
                  >
                    {loading ? 'Updating...' : 'Update'}
                  </button>
                </div>
  
              </form>

              )}
            
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default EditFeedback;
