import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const GenateEvent = async (currentPage,searchText) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    let url = API_ENDPOINTS.GENERATE_EVENT_CODE + `?page=${currentPage}`;

    if (searchText && searchText.trim() !== '') {
      url += `&search=${encodeURIComponent(searchText)}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};

export const SubmitEvent = async (formData) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(API_ENDPOINTS.GENERATE_EVENT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorData = await response.json();
      const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
      throw new Error(`Failed to update data: ${errorMessage}`);
    }
  } catch (error) {
    throw new Error(`An error occurred: ${error.message}`);
  }
};



export const downloadFile = async (Id, eventCode) => {
  const authToken = localStorage.getItem('authToken');
  
  try {
    const response = await fetch(`${API_ENDPOINTS.DOWNLOAD_CSV}/${Id}/${eventCode}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.error('Error fetching file URL:', response.statusText);
      console.error('Response status:', response.status);
      throw new Error(`Error fetching file URL. Status: ${response.status}, Text: ${response.statusText}`);
    }

    const contentDisposition = response.headers.get('content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    const suggestedFileName = matches && matches[1] ? matches[1] : 'downloaded.csv';

    return { fileUrl: URL.createObjectURL(await response.blob()), suggestedFileName };
  } catch (error) {
    console.error('Error fetching file URL:', error.message);
    throw new Error('Error fetching file URL');
  }
};