import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import OrganizationDashboard from '../pages/organization/OrgDashboard';
import { useAuth } from '../hooks/useAuth'; 
import Sidebar from '../components/organization/Sidebar';
import Header from '../components/organization/Header';
import Scanner from '../pages/organization/scanner/scanner';
import Orders from '../pages/organization/orders/orders';
import Ordering from '../pages/organization/orders/ordering';
import ORGGetCode from '../pages/organization/orders/order-getcode';
import Event from '../pages/organization/Events/eventsList';
import  Orgeventgallery from '../pages/organization/Events/orgeventgallery';
import  OrgGallery from '../pages/organization/Gallery/orgGallery';
import AgentSales from '../pages/organization/AgentSales/agentsales';
import GenerateEventCode from '../pages/organization/GenerateEvent/generateeventcode';
import Eventcodelist from '../pages/organization/Eventcodelist/eventcodelist';
import ScannedTickets from '../pages/organization/ScannedTickets/scannedtickets';
import CreateScanner from '../pages/organization/scanner/createscanner';
import EditScanner from '../pages/organization/scanner/editscanner';
import OrderInvoice from '../pages/organization/orders/order-invoice';
import CreateEvent from '../pages/organization/Events/createevents';
import OrgProfile from '../pages/organization/profile/orgProfile';
import VeiwOrganizerEvent from '../pages/organization/Events/veiwOrganizerEvent';
import AgentOrderDetail from '../pages/organization/AgentSales/agentorderdetail';
import Complimentarytickets from '../pages/organization/Complimentary/complimentarytickets';
import EditEvent from '../pages/organization/Events/editEvent';
import VeiwEventTickets from '../pages/organization/Events/veiwEventTickets';
import TicketCreate from '../pages/organization/Events/ticketCreate';
import TicketEdit from '../pages/organization/Events/ticketEdit';
import NotFoundPage from '../pages/organization/Not-Found/NotFoundPage';
import ScanOrdersDetail from '../pages/organization/Events/ScanOrdersDetail';
import EventOrdersDetail from '../pages/organization/Events/EventOrdersDetail';
// import { UserProvider } from '../hooks/UserContext'; 



const OrganizationRoutes = () => {
  const { isAuthenticated, userRole, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated && userRole !== 'organizer') {
      
        navigate('/login');
      } 
    }
  }, [isAuthenticated, userRole, isLoading, navigate]);

 

  return (
    <Routes>
        <Route path="/*" element={

            <>

               <Sidebar />
               <Header />
               <NotFoundPage />

            </>

         } />
      <Route path="/dashboard" element={
        <>
         <Sidebar />
        <Header/> 
        <OrganizationDashboard /></>
      
      } />
        <Route path="/profile" element={
            <>
              {/* <UserProvider> */}
               <Sidebar />
               <Header />
               <OrgProfile />
               {/* </UserProvider> */}
            </>
         } />
      <Route path="/orders" element={
        <>
         <Sidebar />
        <Header/> 
        <Orders /></>
      
      } />

  <Route path="/ordering" element={
        <>
         <Sidebar />
        <Header/> 
        <Ordering /></>
      
      } />

      <Route path="/scanners" element={
        <>
         <Sidebar />
        <Header/> 
        <Scanner /></>
      
      } />
       <Route path="/events" element={
        <>
         <Sidebar />
        <Header/> 
        <Event /></>
      
      } />

    <Route path="/events/:id" element={
        <>
         <Sidebar />
        <Header/> 
        <EditEvent /></>
      
      } />
      <Route path="/create-event" element={
        <>
         <Sidebar />
        <Header/> 
        <CreateEvent /></>
      
      } />
      <Route path="/eventOrders/:id" element={
        <>
         <Sidebar />
          <Header /> 
          <EventOrdersDetail/>
        </>
      
      } />
      <Route path="/scanList/:id" element={
        <>
         <Sidebar />
          <Header /> 
          <ScanOrdersDetail/>
        </>
      
      } />
         <Route path="/view-event/:id" element={
            <>
               <Sidebar />
               <Header />
               <VeiwOrganizerEvent />
            </>
         } />

        <Route path="/orgeventgallery/:id" element={

        <>

          <Sidebar />
          <Header />
          <Orgeventgallery />

        </>

        } />
        <Route path="/gallery" element={
            <>

              <Sidebar />
              <Header />
              <OrgGallery />

            </>

            } />
          
         <Route path="/events-tickets/:id/:slug/tickets" element={
         <>
     
            <Sidebar />
            <Header />
            <VeiwEventTickets />
         </>
      } />

      <Route path="/agent-sale" element={
        <>
         <Sidebar />
        <Header/> 
        <AgentSales /></>
      
      } />
      <Route path="/event-codes" element={
        <>
         <Sidebar />
        <Header/> 
          <GenerateEventCode />
        </>
      
      } />
        <Route path="/event-codes-list" element={
        <>
         <Sidebar />
        <Header/> 
          <Eventcodelist />
        </>
      
      } />
      <Route path="/scanList" element={
        <>
         <Sidebar />
        <Header/> 
          <ScannedTickets />
        </>
      
      } />
      <Route path="/create-scanner" element={
        <>
         <Sidebar />
        <Header/> 
          <CreateScanner />
        </>
      
      } />

<Route path="/scanner/:id/edit" element={

<>
   <Sidebar />
   <Header />
   <EditScanner />

</>

} />
      <Route path="/order-invoice/:id" element={
        <>
         <Sidebar />
        <Header/> 
        <OrderInvoice />
        </>
      
      } />
       <Route path="/agent-orders-detail/:id" element={
        <>
         <Sidebar />
        <Header/> 
        <AgentOrderDetail />
        </>
      
      } />
        <Route path="/complimentary-tickets/create" element={
        <>
         <Sidebar />
        <Header/> 
        <Complimentarytickets />
        </>
      
      } />
      <Route path="/get-code/:id" element={
        <>
         <Sidebar />
        <Header/> 
        <ORGGetCode />
        </>
      
      } />
              <Route path="/:id/ticket/create" element={
               <>
                  <Sidebar />
                  <Header />
                  <TicketCreate />
               </>
            } />

     <Route path="/ticket/edit/:id" element={
                  <>
                     <Sidebar />
                     <Header />
                     <TicketEdit />
                  </>
               } />
    
    </Routes>
  );
};

export default OrganizationRoutes;
