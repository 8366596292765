import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

// export const ScannedTicketsFetch = async (currentPage,searchText,advanceSearchText) => {
  
//   try {
//       const authToken = localStorage.getItem('authToken');
//       const searchParam = searchText ? `&search=${searchText}` : '';
//       const advance_SearchText = advanceSearchText ? `?${advanceSearchText}` : '';
//         const response = await axios.get(API_ENDPOINTS.SCANNED_TICKER + `?page=${currentPage}${searchParam}${advance_SearchText}`, {
//       headers: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('scanner error:', error);
//     throw error;
//   }
// };



export const ScannedTicketsFetch = async (currentPage, searchText, advanceSearchText) => {
  try {

      console.log(advanceSearchText);
     

      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText !== '' ? searchText : '';  
      const advance_SearchText = advanceSearchText ? `?${advanceSearchText}` : '';

       
      let combinedParam = '';
      if (searchParam && advanceSearchText) {
          combinedParam = `${searchParam}${advanceSearchText.startsWith('?') ? '' : '&'}${advanceSearchText}`;
      } else {
          combinedParam = searchParam + advanceSearchText;
      } 
      
      if (combinedParam.includes('eventName=') && combinedParam.match(/eventName=/g).length > 1) {
          combinedParam = combinedParam.replace(/&eventName=\w*/, '&');
      } 
      
      console.log('combinedParam',combinedParam);
     
      
 
      const url = `${API_ENDPOINTS.SCANNED_TICKER}?page=${currentPage}&${combinedParam}`;
      
      
      const response = await axios.get(url, {
          headers: {
              Authorization: `Bearer ${authToken}`
          }
      });
      return response.data;
  } catch (error) {
      console.error('scanner error:', error);
      throw error;
  }
};

