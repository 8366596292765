import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

export const RoleListFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.ROLE_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const Role_Create = async () => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      
        const response = await axios.get(API_ENDPOINTS.ROLE_Create, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const Role_Edit = async (id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
   

     const response = await axios.get(`${API_ENDPOINTS.Role_Edit}${id}/edit`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

 


// export const submitRole = async (formDataToSend) => {
//   try {
//     console.log('API Request Data:', formDataToSend);
//     const authToken = localStorage.getItem('authToken');
//     const headers = {
//       Authorization: `Bearer ${authToken}`,
//       'Content-Type': 'multipart/form-data',
//     };
//     const formData = new FormData();
//     // Append all fields to the formData object
//     Object.keys(formDataToSend).forEach((key) => {
//       // If the field is a File type or Blob, append it to formData with its key
//       if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
//         formData.append(key, formDataToSend[key]);
//       } else if (Array.isArray(formDataToSend[key])) {
//         // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
//         formDataToSend[key].forEach((value, index) => {
//           formData.append(`${key}[${index}]`, value);
//         });
//       } else {
//         // For non-File, Blob, and non-array fields, simply append them to formData
//         formData.append(key, formDataToSend[key]);
//       }
//     });
//     try {
//       const response = await axios.post(API_ENDPOINTS.StoreRolet, formData, { headers });
//       // Process the response as needed
//       console.log('Response:', response.data);
//           //return response;
//       if (!response.ok) {
//         throw new Error('Failed to submit event data');
//       }
//       const responseData = await response.json();
//       return responseData;
//     } catch (error) {
//       console.error('Error submitting event data:', error);
//       throw error;
//     }
//   } catch (error) {
//     console.error('Error preparing FormData:', error);
//     throw error;
//   }
// };
export const submitRole = async (formDataToSend) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };

    const response = await axios.post(API_ENDPOINTS.StoreRolet, formDataToSend, { headers });

    console.log('Response:', response.data);

    if (response && response.data && response.data.error) {
      // Handle errors from the server
      console.error('Server error:', response.data.error);
      throw new Error(response.data.error.message || 'Failed to submit role data');
    } else {
      // Process the successful response as needed
      return response.data;
    }
  } catch (error) {
    console.error('Error submitting role data:', error.message || error);
    throw error;
  }
};

export const UpdateRole = async (formDataToSend,id) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });
    try {
      formData.append('_method', 'PUT');
     const response = await axios.post(`${API_ENDPOINTS.UpdateRolet}/${id}`, formData, { headers });

      // Process the response as needed
      console.log('Response:', response.data);
          return response;
      if (!response.ok) {
        throw new Error('Failed to submit event data');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error submitting event data:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error preparing FormData:', error);
    throw error;
  }
};