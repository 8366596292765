import React from "react";
import { OrderListFetch } from "../../../services/admin/orderservice";
import {useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
function ShowOrdersList() {
    const [data, setData] = useState([]);


    const [pageLoading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    return (
        <main className="main-content w-full px-[var(--margin-x)] pb-8">
            <div className="lg:flex gap-5 ">
                 <div>
                    <div className="mt-10 bg-white border rounded-md border-gray-300 lg:ml-4 ml-2  md:ml-8 lg:mr-0 mr-2  md:mr-4 p-6 lg:w-[62rem]  px-6 my-auto">
                        <div>
                            <img src="/650b47c031ec6.jpg" className="rounded w-full h-96" alt />
                        </div>
                        <div className="lg:flex md:flex justify-between mt-6">
                            <div>
                                <p className="text-black text-2xl font-medium mt-3">Test Yoga Event</p>
                            </div>
                            <div className="flex items-center">
                                <button className="text-white bg-green-600 lg:ml-0 md:ml-0 ml-3  lg:p-2 md:p-2 px-28 py-2 lg:mt-0 mt-3">Manage Tickets</button>
                            </div>
                        </div>
                        <div> <p className="lg:mt-1 md:mt-1 mt-3">This is a test yoga event</p></div>


                        <div className="lg:flex gap-8 mt-12 justify-center">
                            <div className="col-span-1 bg-gray-100 p-4" style={{ width: 179 }}>
                                <div className="text-center">
                                    <svg class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                </div>
                                <p className="font-lg text-gray text-center">People <br /> allowed <br /> 1000</p>
                            </div>
                            <div className="col-span-1 bg-gray-100 p-4">
                                <div className="text-center">
                                    <svg class="h-6 w-6 text-gray-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
                                </div>
                                <p className="font-lg text-gray text-center">Date <br />Friday,<br />22 <br />September <br />2023</p>
                            </div>
                            <div className="col-span-1 bg-gray-100 p-4">
                                <div className="text-center">
                                    <svg class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>

                                </div>
                                <p className="font-lg text-gray text-center">Location <br />New Providence,<br /> The Bahamas</p>
                            </div>
                        </div>
 
                    </div>
        
                    <div>
                        <div className="flex lg:ml-40 md:ml-24 ml-8 mt-3 ">
                            <p className="text-xl text-black">Recent Sales</p>
                            <button className="ml-auto  lg:mr-0 md:mr-4 mr-2 btn bg-info text-white">View All</button>
                        </div>
                        <div className="mt-3 bg-white border rounded-md border-gray-300 lg:ml-4 ml-2  md:ml-8 lg:mr-0 mr-2  md:mr-4 p-6 lg:w-[62rem]  px-6  my-auto">
                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto mt-5">
                                <table className=" w-full text-left">
                                    <thead>
                                        <tr>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                #
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Ticket Name
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Ticket Number
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Price
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Code
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Code
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Code
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* empty */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* Recent Sales */}
                    {/* Scanning List */}
                    <div className>
                        <div className="flex lg:ml-40 md:ml-24 ml-8 mt-3 ">
                            <p className="text-xl text-black">Scanning List</p>
                            <button className="ml-auto lg:mr-0 md:mr-4 mr-2 btn bg-info text-white">View All</button>
                        </div>
                        <div className="mt-3 bg-white border rounded-md border-gray-300 lg:ml-4 ml-2  md:ml-8 lg:mr-0 mr-2  md:mr-4 p-6 lg:w-[62rem] px-6   my-auto">
                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto mt-5">
                                <table className=" w-full text-left">
                                    <thead>
                                        <tr>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Order Id
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Customer Name
                                            </th>
                                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Scanned At
                                            </th>
                                        </tr></thead>
                                    <tbody>
                                        {/* empty */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* Scanning List */}
                </div>
                {/* left */}
                {/* Right */}
                <div className="lg:mt-10 bg-white border rounded-md border-gray-300 lg:ml-4 ml-2  md:ml-8 lg:mr-0 mr-2  md:mr-4 p-6 lg:w-1/2   my-auto">
                    <div className="col-span-1 bg-gray-100 p-6">
                        <p className="font-lg text-gray text-center">Total Card Sales</p>
                        <p className="font-lg text-lg text-gray text-center">$0.00</p>
                    </div>
                    <div className="col-span-1 bg-gray-100 p-6 mt-2">
                        <p className="font-lg text-gray text-center">Total Card Sales</p>
                        <p className="font-lg text-lg text-gray text-center">$0.00</p>
                    </div>
                    <div className="col-span-1 bg-blue-500 p-6 mt-5">
                        <p className="text-lg text-white text-center">Complimentary Ticket | $</p>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Paid Tickets</p>
                            <p className="text-lg text-lg text-black text-center">4/200</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Free Tickets</p>
                            <p className="text-lg text-lg text-black text-center">0/200</p>
                        </div>
                        <div className="mt-3">
                            <p className="text-white text-center">Sales end on <br /> 2023-09-20 | 02:00 pm</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Card Sales</p>
                            <p className="text-lg text-lg text-black text-center">$0.00</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Cash Sales</p>
                            <p className="text-lg text-lg text-black text-center">$0.00</p>
                        </div>
                    </div>
                    <div className="col-span-1 bg-blue-500 p-6 mt-10">
                        <p className="text-lg text-white text-center">General Admission | $10</p>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Paid Tickets</p>
                            <p className="text-lg text-lg text-black text-center">0/800</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Free Tickets</p>
                            <p className="text-lg text-lg text-black text-center">0/800</p>
                        </div>
                        <div className="mt-3">
                            <p className="text-white text-center">Sales end on <br /> 2023-09-20 | 02:00 pm</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Card Sales</p>
                            <p className="text-lg text-lg text-black text-center">$0.00</p>
                        </div>
                        <div className="col-span-1 bg-gray-100 p-6 mt-5">
                            <p className="text-lg text-gray text-center">Cash Sales</p>
                            <p className="text-lg text-lg text-black text-center">$135.00</p>
                        </div>
                    </div>
                </div>
                {/* Right */}
            </div>

        </main>
    )
}

export default ShowOrdersList