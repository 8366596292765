import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../hooks/PageHeader";
import { Toaster, toast } from 'react-hot-toast';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import { agentProfile } from "../../services/agent/agentServices";
import { agentProfileUpdate } from "../../services/agent/agentServices";
import { useNavigate, useLocation } from "react-router-dom";
import CustomLoader from './customloader';
import useResizeObserver from "./../../hooks/useResizeObserver";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import SEO from "../../hooks/seo";
import { useUser } from '../../hooks/UserContext';

 

registerPlugin(FilePondPluginImagePreview);


const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  language: yup.string().required("Language is required"),
  bio: yup.string().required("Bio is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  // profile_image: yup.mixed().required("Profile image is required"),
});

const AgentProfile = () => {
  const [formErrors, setFormErrors] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  const defaultValues = {
    password: '',
    confirm_password: '',
    profile_image: '',
  };

  const { user, updateUser } = useUser();

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const [options, setOptions] = useState([]);
  const [organizations_Options, setOrganizations_Options] = useState([]);
  const [currencies_Options, setCurrencies_Options] = useState([]);
  const [data, setData] = useState([]);
  const filePondRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const languageOptions = [
    { value: "English", label: "English" },
  ];
  const fetchData = async () => {
    try {
      const response = await agentProfile();
      //console.log(response);
      setLoading(false);

      setValue('name', response.user.name);
      setValue('email', response.user.email);
      setValue('language', response.user.language);
      setValue('bio', response.user.bio);

      setData(response.user);
      updateUser(response.user.imagePath);
    } catch (error) {
      // const errorMessage = error.response && error.response.data && error.response.data.error
      //   ? error.response.data.error.email[0] || 'An error occurred while submitting the role. Please try again.'
      //   : 'An unexpected error occurred.';
      // console.error(errorMessage, 'error');
      // toast.error(errorMessage);
    }
    setPageLoading(true);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      minHeight: "unset",
    }),
  };


  const handleImageChange = (fileItems) => {
    if (fileItems.length > 0) {
      const image = fileItems[0].file;
      setProfileImage(image);
    } else {
      setProfileImage(null);
    }
  };


  const onSubmit = async (formData, e) => {
    if (formData.password && formData.confirm_password && formData.password !== formData.confirm_password) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Password and Confirm Password must match',
      });
      return;
    }

    if (profileImage) {
      formData.profile_image = profileImage;
    }

    try {
      setLoading(true);
      const updatedFormData = { ...formData, image };
      const response = await agentProfileUpdate(updatedFormData);

      Swal.fire({
        icon: 'success',
        title: 'Good Job!',
        text: 'Update Profile Successfully',
        confirmButtonColor: '#A23896',
        confirmButtonText: 'OK',
      });

      fetchData();
      setValue('password', '');
      setValue('confirm_password', '');
    } catch (error) {
      //console.log(error, 'error');
      const errorMessage = error.response && error.response.data && error.response.data.error
      ? error.response.data.error || 'An error occurred while submitting the role. Please try again.'
      : 'An unexpected error occurred.';
    console.error(errorMessage, 'error');

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
        confirmButtonColor: '#A23896',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  const onBackButtonClick = () => {
    navigate(-1);
  }

  if (!pageLoading) {
    return (
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <div className="flex justify-center items-center">
                <div className="flex justify-center items-center h-96">
                    <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                    />
                </div>
            </div>
        </main>
    );
}


  return (
    <>
      <SEO
        title="Edit Profile - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full px-[var(--margin-x)] pb-8">
        <Toaster position="top-right" reverseOrder={false} />
        <PageHeader
          title="Edit Profile"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-5">
            <div className="card pb-3">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="grid grid-cols-4 gap-4 items-center">
                  <div className="avatar w-24 h-24">
                    <img
                      src={data.imagePath}
                      alt=""
                      className="rounded-lg w-full h-full"
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="grid grid-cols-2 gap-4 p-4">
                      <div>
                        <p className="text-15 text-center text-black   text-base">Events</p>
                        <p className="text-15 text-center text-black font-semibold mt-3 text-base">{data.toltal_event}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <p className="text-lg font-bold">{data.name}</p>
                <p className="mt-1 text-base">{data.email}</p>
                <p className="text-base font-semibold">{data.bio}</p>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-7">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Edit Profile
                    </h4>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4FA94D"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex">
                      <div className="flex-1">
                        <div className="space-y-4 p-4 sm:px-5" id="category">
                          <label className="block">
                            <span>Name</span>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                  placeholder="Enter Name"
                                  type="text"
                                  {...field}
                                />
                              )}
                            />
                            {errors.name && (
                              <p className="text-red-500">
                                {errors.name.message}
                              </p>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1">
                        <div className="space-y-4 p-4 sm:p-5" id="category">
                          <label className="block">
                            <span>Email</span>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                  placeholder="Enter email"
                                  type="email"
                                  {...field}
                                />
                              )}
                            />
                            {errors.email && (
                              <p className="text-red-500">{errors.email.message}</p>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Language</span>
                          <Controller
                            name="language"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={languageOptions}
                                isSearchable={true}
                                className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                onChange={(selected) =>
                                  setValue(
                                    "language",
                                    selected ? selected.value.toString() : null
                                  )
                                }
                                styles={customStyles}
                                value={languageOptions.find(
                                  (option) => option.value === field.value
                                )}
                                placeholder="Select language"
                              />
                            )}
                          />
                          {errors.language && (
                            <p className="text-red-500">
                              {errors.language.message}
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Bio</span>
                          <Controller
                            name="bio"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                rows="10"
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Enter Bio"
                                {...field}
                              />
                            )}
                          />
                          {errors.bio && (
                            <p className="text-red-500">{errors.bio.message}</p>
                          )}
                        </label>

                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <div className="space-y-4 p-4 sm:px-5" id="category">
                          <label className="block">
                            <span>New Password</span>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <div className="relative">
                                  <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter New Password"
                                    type={showPassword ? 'text' : 'password'}
                                    {...field}
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    {showPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M6 18L18 6M6 6l12 12"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              )}
                            />
                            {errors.password && (
                              <p className="text-red-500">{errors.password.message}</p>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <div className="space-y-4 p-4 sm:px-5" id="category">
                          <label className="block relative">
                            <span>Confirm Password</span>
                            <Controller
                              name="confirm_password"
                              control={control}
                              render={({ field }) => (
                                <div className="relative">
                                  <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    {...field}
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                  >
                                    {showConfirmPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M6 18L18 6M6 6l12 12"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              )}
                            />
                            {errors.confirm_password && (
                              <p className="text-red-500">
                                {errors.confirm_password.message}
                              </p>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <div className="space-y-4 p-4 sm:p-5" id="category">
                          <label className="block">
                            <span>Profile Image</span>
                            <FilePond
                              className="mt-2"
                              allowMultiple={false}
                              files={profileImage ? [profileImage] : []}
                              onupdatefiles={handleImageChange}
                              labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                              acceptedFileTypes={['image/*']}
                              name="profile_image"
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-4 p-4 sm:p-5">
                      <button
                        type="submit"
                        className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${loading ? 'cursor-not-allowed' : ''}`}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AgentProfile;
