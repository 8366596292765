import React from "react";
import { LanguageFetch } from "../../../services/admin/languageservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect,useRef } from "react";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";



const Language = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [pageLoading, setPageLoading] = useState(false);

    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await LanguageFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };

        fetchData();
    }, [currentPage, searchText]);
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchText(searchText);
          setCurrentPage(1);
        }, 300); // Adjust the delay as needed
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_LANGUAGE_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_LANGUAGE_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
    if (!pageLoading) {
        return (
            <main  ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
    return (
        <>
        <SEO
        title="Language - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Language"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="All Languages rows"
                        onSearchChange={handleSearchChange}
                         showSearch={true}
                        showExportButtons={true}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                             {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Image
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Name
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Status
                                        </th>
                                         
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                <div className="avatar flex size-30">
                                                    <img
                                                        className="mask is-squircle"
                                                        src={'https://api.tikkets.com/public/images/upload/' + item.image || '../assets/img/categories/concert.jpg'}
                                                        alt="Language"
                                                        style={{ borderRadius: '0.25rem' }}
                                                    />
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 {item.name}
                                            </td>   
                                             <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                              {item.status === 1 ? (
                                                <div className="badge space-x-2.5 px-0 text-success">
                                                <div className="size-2 rounded-full bg-current" />
                                                <span>Active</span>
                                                </div>
                                            ) : (
                                                <div className="badge space-x-2.5 px-0 text-error">
                                                        <div className="size-2 rounded-full bg-current" />
                                                        <span>Inactive</span>
                                                        </div>
                                            )}
                                            </td>
                                            

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                                         )}
                         <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                                       </div>
                                    
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default Language;
