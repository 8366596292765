import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

export const LanguageFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.LANGUAGE_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};