import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../../hooks/UserContext";
import { useSetting } from "../../../hooks/SettingContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Admin_profile } from "../../../services/admin/userservice";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  submitGeneralSubmit,
  SettingFetch,
  submitAgentSubmit,
  submitOrganizerSubmit,
  submitonSubmitVerification,
  submitPayment,
  submitMailNotification,
  submitSupportSetting,
} from "../../../services/admin/settingservice";
import Swal from "sweetalert2";
import useResizeObserver from "../../../hooks/useResizeObserver";
import CustomLoader from "../Loaderfiles/customloader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
const schemaGeneral = Yup.object().shape({});
const schemaOrganizer = Yup.object().shape({
  org_commission_type: Yup.string().required("Commission Type is required"),
  org_commission: Yup.number().required("Organizer Commission is required"),
  terms_use_organizer: Yup.string().required("terms use text is required"),
  privacy_policy_organizer: Yup.string().required(
    "privacy policy text is required"
  ),
});

const schemaAgent = Yup.object().shape({
  agent_commission_type: Yup.string().required("Commission Type is required"),
  agent_commission: Yup.number().required("General Commission is required"),
  agent_commission_about: Yup.number().required("Commission About is required"),
});
const schemaVerification = Yup.object().shape({
  user_verify: Yup.boolean().required(),
  verify_by: Yup.string().required(),
});
const schemaPayment = Yup.object().shape({
  cod: Yup.boolean(),
  stripeSecretKey: Yup.string().required("Stripe secret key is required"),
  stripePublicKey: Yup.string().required("Stripe public key is required"),
  paypalClientId: Yup.string().required("Paypal Client ID is required"),
  paypalSecret: Yup.string().required("Paypal Secret key is required"),
  razorPublishKey: Yup.string().required("Razorpay Publish key is required"),
  razorSecretKey: Yup.string().required("Razorpay Secret key is required"),
  ravePublicKey: Yup.string().required("Flutterwave public key is required"),
  raveSecretKey: Yup.string().required("Flutterwave secret key is required"),
});
const schemaMailNotification = Yup.object().shape({
  mail_notification: Yup.boolean(),
  sender_email: Yup.string().required("sender email is required"),
  mail_password: Yup.string().required("mail password is required"),
  mail_username: Yup.string().required("mail username is required"),
  mail_port: Yup.string().required("mail port is required"),
  mail_host: Yup.string().required("mail host is required"),
});
const schemaSupportsetting = Yup.object().shape({
  help_center: Yup.string().required("help center is required"),
  privacy_policy: Yup.string().required("privacy policy is required"),
  cookie_policy: Yup.string().required("cookie policy is required"),
  terms_services: Yup.string().required("terms services is required"),
  acknowledgement: Yup.string().required("acknowledgement is required"),
});

const SettingsPage = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaGeneral),
  });

  const {
    register: registerAgent,
    control: controlAgent,
    handleSubmit: handleSubmitAgentForm,
    formState: { errors: errorsAgent },
  } = useForm({
    resolver: yupResolver(schemaAgent),
  });
  const {
    register: registerschemaOrganizer,
    control: controlschemaOrganizer,
    handleSubmit: handleSubmitschemaOrganizerForm,
    formState: { errors: errorOrganizer },
  } = useForm({
    resolver: yupResolver(schemaOrganizer),
  });
  const {
    register: registerschemaVerification,
    setValue,
    watch,
    control: controlschemaVerification,
    handleSubmit: handleSubmitschemaVerificationForm,
    formState: { errors: errorVerification },
  } = useForm({
    resolver: yupResolver(schemaVerification),
  });
  const {
    register: registerschemaPayment,
    control: controlschemaPayment,
    handleSubmit: handleSubmitschemaPaymentForm,
    formState: { errors: errorPayment },
  } = useForm({
    resolver: yupResolver(schemaPayment),
  });
  const {
    register: registerschemaMailNotification,
    control: controlschemaMailNotification,
    handleSubmit: handleSubmitschemaMailNotificationForm,
    formState: { errors: errorMailNotification },
  } = useForm({
    resolver: yupResolver(schemaMailNotification),
  });
  const {
    register: registerschemaSupportsetting,
    control: controlschemaSupportsetting,
    handleSubmit: handleSubmitschemaSupportsettingForm,
    formState: { errors: errorSupportsetting },
  } = useForm({
    resolver: yupResolver(schemaSupportsetting),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const [Logo, setLogoImage] = useState(null);
  const [Favicon, setFaviconImage] = useState(null);
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [data, setData] = useState([]);
  const { updateSettings } = useSetting();
  const [SettingData, setSetting] = useState([]);
  const [Settingpayment, setSettingpayment] = useState([]);
  const [activeTab, setActiveTab] = useState("General");
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switch2Checked, setSwitch2Checked] = useState(false);
  const initialUserVerifyValue = SettingData.user_verify || "0";
  const initialVerifyByValue = SettingData.verify_by;
  const initialstripeValue = Settingpayment.stripe || "0";
  const initialmail_notification = SettingData.mail_notification;
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);

  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };

  useResizeObserver(ref, handleResize);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlelogoChange = (fileItems) => {
    if (fileItems.length > 0) {
      const image = fileItems[0].file;
      setLogoImage(image);
    } else {
      setLogoImage(null);
    }
  };
  const handleFaviconChange = (fileItems) => {
    if (fileItems.length > 0) {
      const image = fileItems[0].file;
      setFaviconImage(image);
    } else {
      setFaviconImage(null);
    }
  };

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (value === "email" && isChecked) {
      setSwitchChecked(true);
      setSwitch2Checked(false);
    } else if (value === "phone" && isChecked) {
      setSwitchChecked(false);
      setSwitch2Checked(true);
    }
    // else {
    //   setSwitchChecked(false);
    //   setSwitch2Checked(false);
    // }
  };

  const onSubmitGeneral = async (formData, e) => {
    setIsLoading(true);
    if (Logo) {
      formData.logo = Logo;
    }
    if (Favicon) {
      formData.favicon = Favicon;
    }

    try {
      const response = await submitGeneralSubmit(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
      setLogoImage(null);
      setFaviconImage(null);
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitAgent = async (formData, e) => {
    setIsLoading(true);
    if (formData.agent_commission === null) {
      formData.agent_commission = "null";
    }
    try {
      const response = await submitAgentSubmit(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitOrganizer = async (formData, e) => {
    setIsLoading(true);
    try {
      const response = await submitOrganizerSubmit(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitVerification = async (formData, e) => {
    setIsLoading(true);
    formData.user_verify = formData.user_verify ? 1 : 0;
    formData.verify_by = switchChecked
      ? "email"
      : switch2Checked
      ? "phone"
      : "";

    try {
      const response = await submitonSubmitVerification(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitPayment = async (formData, e) => {
    setIsLoading(true);
    formData.stripe = formData.stripe ? 1 : 0;

    try {
      const response = await submitPayment(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitMailNotification = async (formData, e) => {
    setIsLoading(true);
    formData.mail_notification = formData.mail_notification ? 1 : 0;

    try {
      const response = await submitMailNotification(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitSupportSetting = async (formData, e) => {
    setIsLoading(true);

    try {
      const response = await submitSupportSetting(formData);

      Swal.fire({
        title: "Good Job",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
      SettingfetchData();
    } catch (error) {
      console.log(error, "eee");
      const errorMessage =
        error.message ||
        "An error occurred while submitting the Setting. Please try again.";

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const SettingfetchData = async () => {
    setLoading(true);
    try {
      const response = await SettingFetch();

      if (response) {
        setSetting(response.setting);
        updateSettings({
          logo: response.setting.logo,
          favicon: response.setting.favicon,
        });
        setSettingpayment(response.payment);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };
  useEffect(() => {
    SettingfetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await Admin_profile();
      setLoading(false);

      setData(response.user);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setValue("user_verify", initialUserVerifyValue);
    setValue("verify_by", initialVerifyByValue);
    setSwitchChecked(
      initialVerifyByValue === "email" || SettingData.verify_by === "email"
    );
    setSwitch2Checked(
      initialVerifyByValue === "phone" || SettingData.verify_by === "phone"
    );
  }, [
    initialUserVerifyValue,
    initialVerifyByValue,
    SettingData.user_verify,
    SettingData.verify_by,
  ]);

  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  return (
    <>
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex items-center space-x-4 py-5 lg:py-6">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            Setting page
          </h2>
        </div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
          <div className="col-span-12 lg:col-span-4">
            <div className="card p-4 sm:p-5">
              <div className="flex items-center space-x-4">
                <div className="avatar size-14">
                  <img
                    className="rounded-full"
                    src={user ? user : (data && data.imagePath) || "aaa"}
                    alt="avatar"
                  />
                </div>
                <div>
                  <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                    {data.first_name} {data.last_name}
                  </h3>
                </div>
              </div>
              <ul className="mt-6 space-y-1.5 font-inter font-medium">
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "General"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("General")}
                  >
                    <i class="lni lni-cog"></i>
                    <span>General</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "agent-setting"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("agent-setting")}
                  >
                    <svg
                      className={`h-4 w-4 ${
                        activeTab === "agent-setting"
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="3" />
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                    </svg>

                    <span>Agent Setting</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "Organizer-setting"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("Organizer-setting")}
                  >
                    <svg
                      className={`h-4 w-4 ${
                        activeTab === "Organizer-setting"
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="3" />
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                    </svg>

                    <span>Organizer Setting</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "Verification"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("Verification")}
                  >
                    <i class="lni lni-checkmark-circle"></i>
                    <span>Verification</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "Payment-Setting"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("Payment-Setting")}
                  >
                    <svg
                      className={`h-4 w-4 ${
                        activeTab === "Payment-Setting"
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="3" />
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                    </svg>

                    <span>Payment Setting</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "Mail-Notification"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("Mail-Notification")}
                  >
                    <svg
                      className={`h-4 w-4 ${
                        activeTab === "Mail-Notification"
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                      <polyline points="22,6 12,13 2,6" />
                    </svg>

                    <span>Mail Notification</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all ${
                      activeTab === "Support-Setting"
                        ? "bg-primary text-white"
                        : "hover:bg-slate-100 focus:bg-slate-100 dark:bg-accent text-gray-500"
                    }`}
                    onClick={() => handleTabClick("Support-Setting")}
                  >
                    <i class="lni lni-lifering"></i>
                    <span>Support Setting</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <div className="card">
              <div className="my-div">
                {activeTab === "General" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            General Setting
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitGeneral)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="appName"
                                >
                                  App Name
                                </label>
                                <Controller
                                  name="app_name"
                                  control={control}
                                  defaultValue={SettingData.app_name}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="app_name"
                                      type="text"
                                      placeHolder="Enter App Name"
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="email"
                                >
                                  Email
                                </label>
                                <Controller
                                  name="email"
                                  control={control}
                                  defaultValue={SettingData.email}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="email"
                                      type="email"
                                      placeHolder="Enter Email"
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="email"
                                >
                                  Web Commission
                                </label>
                                <Controller
                                  name="web_commission"
                                  control={control}
                                  defaultValue={SettingData.web_commission}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="web_commission"
                                      type="number"
                                      placeHolder="Enter Web commission"
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                  Logo
                                </label>
                                <div className="avatar size-24">
                                  <img
                                    className="rounded-lg object-contain"
                                    src={
                                      SettingData.imagePath + SettingData.logo
                                    }
                                    alt="avatar"
                                  />
                                </div>
                                <FilePond
                                  className="mt-2"
                                  allowMultiple={false}
                                  files={Logo ? [Logo] : []}
                                  onupdatefiles={handlelogoChange}
                                  labelIdle='Drag & Drop your files or Browse <span className="filepond--label-action"></span>'
                                  acceptedFileTypes={["image/*"]}
                                  name="logo"
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                  Favicon
                                </label>
                                <div className="avatar size-24">
                                  <img
                                    className="rounded-lg object-contain"
                                    src={
                                      SettingData.imagePath +
                                      SettingData.favicon
                                    }
                                    alt="avatar"
                                  />
                                </div>
                                <FilePond
                                  className="mt-2"
                                  allowMultiple={false}
                                  files={Favicon ? [Favicon] : []}
                                  onupdatefiles={handleFaviconChange}
                                  labelIdle='Drag & Drop your files or Browse <span className="filepond--label-action"></span>'
                                  acceptedFileTypes={["image/*"]}
                                  name="favicon"
                                />
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "agent-setting" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Agent Setting
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitAgent)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission_type"
                                >
                                  Commission Type
                                </label>
                                <Controller
                                  name="agent_commission_type"
                                  control={control}
                                  defaultValue={
                                    SettingData.agent_commission_type
                                  }
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="form-select w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="agent_commission_type"
                                    >
                                      <option value="">
                                        Select Commission Type
                                      </option>
                                      <option value="amount">Amount</option>
                                      <option value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  )}
                                />
                                {errors.agent_commission_type && (
                                  <span className="text-red-500">
                                    Commission type is required.
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission"
                                >
                                  General Commission
                                </label>
                                <Controller
                                  name="agent_commission"
                                  control={control}
                                  defaultValue={SettingData.agent_commission}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="agent_commission"
                                      type="number"
                                      value={
                                        field.value === null ? "" : field.value
                                      }
                                      onChange={(e) =>
                                        field.onChange(
                                          e.target.value === ""
                                            ? null
                                            : e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {errors.agent_commission && (
                                  <span className="text-red-500">
                                    General commission is required.
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission_about"
                                >
                                  Commission About
                                </label>
                                <Controller
                                  name="agent_commission_about"
                                  control={control}
                                  defaultValue={
                                    SettingData.agent_commission_about
                                  }
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="form-select w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="agent_commission_about"
                                    >
                                      <option value="">
                                        Select Commission Type
                                      </option>
                                      <option value="per_ticket">
                                        Per Ticket
                                      </option>
                                      <option value="per_order">
                                        Per Order
                                      </option>
                                    </select>
                                  )}
                                />
                                {errors.agent_commission_about && (
                                  <span className="text-red-500">
                                    Commission About is required.
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Organizer-setting" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Organizer Setting
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitOrganizer)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission_type"
                                >
                                  Commission Type
                                </label>
                                <Controller
                                  name="org_commission_type"
                                  control={control}
                                  defaultValue={SettingData.org_commission_type}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="form-select w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="org_commission_type"
                                    >
                                      <option value="">
                                        Select Commission Type
                                      </option>
                                      <option value="amount">Amount</option>
                                      <option value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  )}
                                />
                                {errors.org_commission_type && (
                                  <span className="text-red-500">
                                    {errors.org_commission_type.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="org_commission"
                                >
                                  Organizer Commission
                                </label>
                                <Controller
                                  name="org_commission"
                                  control={control}
                                  defaultValue={SettingData.org_commission}
                                  render={({ field }) =>
                                    field && (
                                      <input
                                        {...field}
                                        className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        id="org_commission"
                                        type="number"
                                        value={
                                          field.value === null
                                            ? ""
                                            : field.value
                                        }
                                        onChange={(e) =>
                                          field.onChange(
                                            e.target.value === ""
                                              ? null
                                              : e.target.value
                                          )
                                        }
                                      />
                                    )
                                  }
                                />
                                {errors.org_commission && (
                                  <span className="text-red-500">
                                    {errors.org_commission.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission"
                                >
                                  Privacy Policy
                                </label>
                                <Controller
                                  name="privacy_policy_organizer"
                                  control={control}
                                  defaultValue={
                                    SettingData.privacy_policy_organizer
                                  }
                                  render={({ field }) => (
                                    <ReactQuill {...field} />
                                  )}
                                />
                                {errors.text && (
                                  <span className="text-red-500">
                                    {errors.text.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="agent_commission"
                                >
                                  Terms of use
                                </label>
                                <Controller
                                  name="terms_use_organizer"
                                  control={control}
                                  defaultValue={SettingData.terms_use_organizer}
                                  render={({ field }) => (
                                    <ReactQuill {...field} />
                                  )}
                                />
                                {errors.text && <p>{errors.text.message}</p>}
                              </div>

                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Verification" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Verification
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitVerification)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor="user_verify"
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                  Enable User Verification
                                </label>
                                <input
                                  type="checkbox"
                                  id="user_verify"
                                  name="user_verify"
                                  {...register("user_verify")}
                                  defaultChecked={initialUserVerifyValue === 1}
                                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                                />
                                {errors.user_verify && (
                                  <p className="text-red-500 text-xs italic">
                                    {errors.user_verify.message}
                                  </p>
                                )}
                              </div>

                              <div className="mb-4">
                                <label
                                  htmlFor="verify_by_email"
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                  Verify by Email
                                </label>
                                <input
                                  type="checkbox"
                                  id="verify_by_email"
                                  name="verify_by"
                                  onChange={handleSwitchChange}
                                  value="email"
                                  checked={switchChecked}
                                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  htmlFor="verify_by_phone"
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                  Verify by Phone
                                </label>
                                <input
                                  type="checkbox"
                                  id="verify_by_phone"
                                  name="verify_by"
                                  onChange={handleSwitchChange}
                                  value="phone"
                                  checked={switch2Checked}
                                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                                />
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Payment-Setting" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Payment Setting
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitPayment)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor="stripe"
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                  Stripe
                                </label>
                                <input
                                  type="checkbox"
                                  id="stripe"
                                  name="stripe"
                                  {...register("stripe")}
                                  defaultChecked={initialstripeValue === 1}
                                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                                />
                                {errors.stripe && (
                                  <p className="text-red-500 text-xs italic">
                                    {errors.stripe.message}
                                  </p>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="stripeSecretKey"
                                >
                                  Stripe secret key
                                </label>
                                <Controller
                                  name="stripeSecretKey"
                                  control={control}
                                  defaultValue={Settingpayment.stripeSecretKey}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="stripeSecretKey"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.stripeSecretKey && (
                                  <span className="text-red-500">
                                    {errors.stripeSecretKey.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="stripePublicKey"
                                >
                                  Stripe public key
                                </label>
                                <Controller
                                  name="stripePublicKey"
                                  control={control}
                                  defaultValue={Settingpayment.stripePublicKey}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="stripePublicKey"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.stripePublicKey && (
                                  <span className="text-red-500">
                                    {errors.stripePublicKey.message}
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Mail-Notification" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Mail Notification
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitMailNotification)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor="mail_notification"
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                  Enable Notification
                                </label>
                                <input
                                  type="checkbox"
                                  id="mail_notification"
                                  name="mail_notification"
                                  {...register("mail_notification")}
                                  defaultChecked={
                                    initialmail_notification === 1
                                  }
                                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                                />
                                {errors.cod && (
                                  <p className="text-red-500 text-xs italic">
                                    {errors.mail_notification.message}
                                  </p>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="mail_host"
                                >
                                  Mail Host
                                </label>
                                <Controller
                                  name="mail_host"
                                  control={control}
                                  defaultValue={SettingData.mail_host}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="mail_host"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.mail_host && (
                                  <span className="text-red-500">
                                    {errors.mail_host.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="mail_port"
                                >
                                  Mail Port
                                </label>
                                <Controller
                                  name="mail_port"
                                  control={control}
                                  defaultValue={SettingData.mail_port}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="mail_port"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.mail_port && (
                                  <span className="text-red-500">
                                    {errors.mail_port.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="mail_username"
                                >
                                  Mail Username
                                </label>
                                <Controller
                                  name="mail_username"
                                  control={control}
                                  defaultValue={SettingData.mail_username}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="mail_username"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.mail_username && (
                                  <span className="text-red-500">
                                    {errors.mail_username.message}
                                  </span>
                                )}
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="mail_password"
                                >
                                  Mail Password
                                </label>
                                <Controller
                                  name="mail_password"
                                  control={control}
                                  defaultValue={SettingData.mail_password}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="mail_password"
                                      type="password"
                                    />
                                  )}
                                />
                                {errors.mail_password && (
                                  <span className="text-red-500">
                                    {errors.mail_password.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="sender_email"
                                >
                                  Mail Sender Email
                                </label>
                                <Controller
                                  name="sender_email"
                                  control={control}
                                  defaultValue={SettingData.sender_email}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="sender_email"
                                      type="password"
                                    />
                                  )}
                                />
                                {errors.sender_email && (
                                  <span className="text-red-500">
                                    {errors.sender_email.message}
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Support-Setting" && (
                  <div>
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                            Support Setting
                          </h2>
                        </div>
                        <div className="p-4 sm:p-5">
                          <div className="grid grid-cols-1 mb-6">
                            <form
                              onSubmit={handleSubmit(onSubmitSupportSetting)}
                              className="bg-white rounded px-4 pt-6 pb-8 mb-4"
                            >
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="help_center"
                                >
                                  Help Center
                                </label>
                                <Controller
                                  name="help_center"
                                  control={control}
                                  defaultValue={SettingData.help_center}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="help_center"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.help_center && (
                                  <span className="text-red-500">
                                    {errors.help_center.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="privacy_policy"
                                >
                                  Privacy policy
                                </label>
                                <Controller
                                  name="privacy_policy"
                                  control={control}
                                  defaultValue={SettingData.privacy_policy}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="privacy_policy"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.privacy_policy && (
                                  <span className="text-red-500">
                                    {errors.privacy_policy.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="cookie_policy"
                                >
                                  Cookie policy
                                </label>
                                <Controller
                                  name="cookie_policy"
                                  control={control}
                                  defaultValue={SettingData.cookie_policy}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="cookie_policy"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.cookie_policy && (
                                  <span className="text-red-500">
                                    {errors.cookie_policy.message}
                                  </span>
                                )}
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="terms_services"
                                >
                                  Cookie policy
                                </label>
                                <Controller
                                  name="terms_services"
                                  control={control}
                                  defaultValue={SettingData.terms_services}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="terms_services"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.terms_services && (
                                  <span className="text-red-500">
                                    {errors.terms_services.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold mb-2"
                                  htmlFor="acknowledgement"
                                >
                                  Acknowledgement
                                </label>
                                <Controller
                                  name="acknowledgement"
                                  control={control}
                                  defaultValue={SettingData.acknowledgement}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      id="acknowledgement"
                                      type="text"
                                    />
                                  )}
                                />
                                {errors.acknowledgement && (
                                  <span className="text-red-500">
                                    {errors.acknowledgement.message}
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center justify-between">
                                <button
                                  type="submit"
                                  className={`btn mt-10 h-10 bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                                    isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                      <div
                                        className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                                        role="status"
                                      ></div>
                                      <span>Loading...</span>
                                    </div>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SettingsPage;
