import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AdminRoutes from './routes/AdminRoutes';
import OrganizationRoutes from './routes/OrganizationRoutes';
import AgentRoutes from './routes/AgentRoutes';
import Login from './auth/Login';
import Register from './auth/SignupForm';
import ProtectedRoute from './ProtectedRoute';
import UnAuthorized from './pages/UnAuthorizedPage';
import NotFoundPage from './pages/NotFoundPage';
import ForgetPage from './auth/ForgetPassword';
import { SettingProvider } from './hooks/SettingContext';
import { UserProvider } from './hooks/UserContext'; 
const App = () => {
  
  return (
    <Router>
      <SettingProvider>  
        <UserProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Home />} />
          <Route path="/401" element={<UnAuthorized />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/forgetPassword" element={<ForgetPage />} />

          <Route
            path="/admin/*"
            element={
              <ProtectedRoute role="admin">
                <AdminRoutes />
              </ProtectedRoute>
            }
          />

          <Route
            path="/organization/*"
            element={
              <ProtectedRoute role="organizer">
                <OrganizationRoutes />
              </ProtectedRoute>
            }
          />

          <Route
            path="/agent/*"
            element={
              <ProtectedRoute role="agent">
                <AgentRoutes />
              </ProtectedRoute>
            }
          />
        </Routes>
        </UserProvider>
      </SettingProvider>
    </Router>
  );
};

export default App;
