import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

export const CouponsListFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.COUPONS_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};




export const Coupon_Create = async () => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      
        const response = await axios.get(API_ENDPOINTS.CouponCreate, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const Coupon_Edit = async (id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      
        const response = await axios.get(API_ENDPOINTS.CouponEdit+id+'/edit', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};



export const submitCoupon = async (formDataToSend) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });
    try {
      const response = await axios.post(API_ENDPOINTS.Store_Coupon, formData, { headers });
      // Process the response as needed
      console.log('Response:', response.data);

          return response;
      if (!response.ok) {
        throw new Error('Failed to submit event data');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error submitting event data:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error preparing FormData:', error);
    throw error;
  }
};


export const UpdateCoupon = async (formDataToSend,id) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
      formData.append('_method','PUT');

    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });
    try {
      const response = await axios.post(API_ENDPOINTS.Update_Coupon+id, formData, { headers });
      // Process the response as needed
      console.log('Response:', response.data);

          return response;
      if (!response.ok) {
        throw new Error('Failed to submit event data');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error submitting event data:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error preparing FormData:', error);
    throw error;
  }
};

export const DeletecouponList = async (id) => {

  try {
    const authToken = localStorage.getItem('authToken');

    const response = await axios.delete(API_ENDPOINTS.DELETE_COUPONS_LIST + id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};