import React from 'react'
import { Link } from 'react-router-dom';
function UnAuthorizedPage() {
  return (
     <main className="grid w-full grow grid-cols-1 place-items-center">
        <div className="max-w-md p-6 text-center">
          <div className="w-full">
            <img
              className="w-full"
              src="images/illustrations/error-401.svg"
              alt="image"
            />
          </div>
          <p className="pt-4 text-7xl font-bold text-primary dark:text-accent">
            401
          </p>
          <p
            className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50"
          >
            You are not authorized
          </p>
          <p className="pt-2 text-slate-500 dark:text-navy-200">
            You are missing the required rights to be able to access this page
          </p>

          <Link
            className="btn mt-8 h-11 bg-primary text-base font-medium text-white hover:bg-primary-focus hover:shadow-lg hover:shadow-primary/50 focus:bg-primary-focus focus:shadow-lg focus:shadow-primary/50 active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:hover:shadow-accent/50 dark:focus:bg-accent-focus dark:focus:shadow-accent/50 dark:active:bg-accent/90" to='/login'  
          >
            Back To Login
          </Link>
        </div>
      </main>
  )
}

export default UnAuthorizedPage