// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adjust the swiper container if necessary */
.mySwiper {
    width: 100%;
    overflow: hidden;
  }
  
  /* Ensure slides have a specific width */
  .mySwiperCard {
    width: 135px; /* Adjust based on your needs */
    flex-shrink: 0; /* Prevents the slides from shrinking */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/agent/pos/CategorySwiper.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,WAAW;IACX,gBAAgB;EAClB;;EAEA,wCAAwC;EACxC;IACE,YAAY,EAAE,+BAA+B;IAC7C,cAAc,EAAE,uCAAuC;EACzD","sourcesContent":["/* Adjust the swiper container if necessary */\n.mySwiper {\n    width: 100%;\n    overflow: hidden;\n  }\n  \n  /* Ensure slides have a specific width */\n  .mySwiperCard {\n    width: 135px; /* Adjust based on your needs */\n    flex-shrink: 0; /* Prevents the slides from shrinking */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
