import React, { useState, useEffect } from 'react';
import PageHeader from "../../../hooks/PageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-encode/dist/filepond-plugin-file-encode.min';
import CustomLoader from '../Loaderfiles/customloader';
import { EventGalleryFetch, EventGallerySubmit , deleteEventGallery } from "../../../services/eventservice";
import Swal from 'sweetalert2';
import { API_ENDPOINTS } from "../../../services/admin/api";

function  Orgeventgallery() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const authToken = localStorage.getItem('authToken');

    // Fetch data for the event
    const fetchData = async () => {
        try {
            const response = await EventGalleryFetch(id);
            if (response && response.data) {
                setData(response.data);
            } else {
                console.error("Invalid data format received:", response);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleFileChange = (fileItems) => {
        setFiles(fileItems.map(fileItem => fileItem.file));
    };
    const showDeleteConfirmation = async (callback) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You Want To Delete This Image',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });
    
            if (result.isConfirmed) {
                await callback();
            }
        } catch (error) {
            console.error('Error in showDeleteConfirmation:', error);
            Swal.fire('Error!', 'Failed to delete the item.', 'error');
        }
    };
    const handleDeleteClick = async (eventId,imageName) => {
        showDeleteConfirmation(async () => {
            try {
                const response = await deleteEventGallery(eventId,imageName);
                
                if (response && response.status === 200) {
                    // Update the data after successful deletion
                    await fetchData(); // Assuming fetchData fetches the updated data
                    Swal.fire('Deleted!', 'Your event Gallery has been deleted.', 'success');
                } else {
                    console.log('Unexpected response:', response);
                    Swal.fire('Error!', 'Failed to delete the item.', 'error');
                }
            } catch (error) {
                console.error('Error deleting item:', error.response.data.error);
                Swal.fire('Error!', error.response.data.error, 'error');
            }
        });
    };
  
    const onBackButtonClick = () => {
        navigate('/organization/events');
    };

    if (loading) {
        return <CustomLoader />;
    }
 
    return (
        <main className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Event Gallery"
                showBackButton={true}
                onBackButtonClick={onBackButtonClick}
            />
            <div className="col-span-12 lg:col-span-12 bg-white">
            <div className="space-y-4 p-4 sm:p-5" id="images">
                    <FilePond
                        files={files}
                        onupdatefiles={handleFileChange}
                        allowMultiple={true}
                        server={{
                            process: {
                                url: 'https://api.tikkets.com/public/api/add-event-gallery',
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${authToken}`
                                },
                          
                                ondata: (formData) => {
                                    formData.append('eventId', id);
                                     formData.delete('filepond');  
                                     files.forEach((file, index) => {
                                                  formData.append(`file[${index}]`, file);
                                              });
                                    return formData;
                                    
                                },
                                onerror: (error) => {
                                    console.error('Upload error:', error);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Upload Error',
                                        text: 'There was an error uploading your files. Please try again.'
                                    });
                                }
                            },
                            revert: {
                                url: 'https://api.tikkets.com/public/api/delete-uploaded-file',
                                method: 'DELETE',
                                headers: {
                                    Authorization: `Bearer ${authToken}`
                                }
                            }
                        }}
                        labelIdle='Drag & Drop your files here or <span class="filepond--label-action">Browse</span>'
                        acceptedFileTypes={['image/*']}
                        maxFileSize='5MB'
                        onprocessfiles={(fileItems) => {
                            // Clear the files after processing
                            setFiles([]);
                            // Call fetchData or any other necessary actions
                            fetchData();
                        }}
                    />

                </div>
                <div className="image-gallery grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5">
                    {data?.gallery ? (
                        data.gallery.split(",").map((image, index) => (
                            <div key={index} className="flex flex-col items-center border rounded-lg p-4">
                                <img src={data.imagePath + image.trim()} className="w-full h-auto" alt="Gallery Image" />
                                <h4 className="text-center mt-2">{image.trim()}</h4>
                                <Link onClick={() => handleDeleteClick(data.id,image.trim())} className="text-red-500 mt-2"><i className="fas fa-trash-alt"></i> Remove</Link>
                            </div>
                        ))
                    ) : (
                        <div className="max-w-sm mx-auto p-6 bg-white rounded-lg shadow-md">
                        <div className="text-center text-gray-600">
                            <svg className="w-12 h-12 mx-auto mb-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                            <p className="text-lg font-semibold">No images found</p>
                            <p className="text-sm">Sorry, there are no images to display.</p>
                        </div>
                    </div>
                    )}
                </div>
                
            </div>
        </main>
    );
}

export default Orgeventgallery;
