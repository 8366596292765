import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../hooks/PageHeader";
import { useState, useEffect } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useRef } from 'react';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Toaster, toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { submitTcikets, ticketCreate } from "../../../services/ticketsService";
import { useParams } from 'react-router-dom';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import 'react-datepicker/dist/react-datepicker.css'; 
import DatePicker from 'react-datepicker';

registerPlugin(FilePondPluginImagePreview);

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  quantity: yup.string().required('Quantity is required'),
  price: yup.string().required('USD Price is required'),
  description: yup.string().required('Ticket Benefits is required').max(250, 'Ticket Benefits must be at most 250 characters'),

  local_currency_price: yup.string().required('Local Currency Price  is required'),
  start_time: yup.string().required('Start time is required'),
  end_time: yup.string().required('End time time is required'),
  ticket_per_order: yup.number().required('Tickets Quantity is required'),
  status: yup.string().required('Status is required'),


});


const TicketCreate = () => {

  const { handleSubmit, control, setValue,watch, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState('');
  const filePondRef = useRef(null);
  const [options, setOptions] = useState([]);
  const { id, slug } = useParams();
  const [availableTickets, setAvailableTickets] = useState('');
  const [charCount, setCharCount] = useState(0);

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setCharCount(inputText.length);
  };

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }


  const handleFileChange = (event) => {

    const file = event.target.files[0];
    setValue('image', file);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ticketCreate(id);
        setEvent(response.event);
        setAvailableTickets(response.available_limit);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);


  const statusOptions = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };

  const [type, setType] = useState('paid');

  const handleButtonClick = (newType) => {
    setType(newType);
    if (newType === 'free' || newType === 'complimentary') {

      setValue('price', 0);
      setValue('local_currency_price', 0);
    }


  };

  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {
      const startTime = new Date(formData.start_time);
      const endTime = new Date(formData.end_time);
      const eventEndTime = new Date(event.end_time);

      if (endTime <= startTime) {
        // Display error message
        Swal.fire({
          title: 'Error',
          text: 'End time must be later than start time',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return; // Exit the function early
      }
      if (endTime < startTime || endTime > eventEndTime) {
        Swal.fire({
          title: 'Error',
          text: 'Ticket creation time must be within the event time range',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return;
      }
      if (endTime < startTime) {

        Swal.fire({
          title: 'Error',
          text: 'End time cannot be earlier than start time',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        });
        setIsLoading(false);
        return;
      }
      const response = await submitTcikets(formData, type, id);

      if (response.status === 200) {
        Swal.fire({
          title: 'Good Job',
          text: response.data.msg,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        }).then(() => {
          const link = response.data.url;
          const segments = link.split('/');
          const lastThreeSegments = segments.slice(-3);
          const commaSeparatedString = lastThreeSegments.join('/');

          navigate('/organization/events-tickets/' + commaSeparatedString, { state: { successMessage: 'Submit Successfully' } });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.msg,
        });
      }
      e.target.reset();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.msg : error.msg;

      Swal.fire({
        icon: 'error',
        title: errorMessage || 'An error occurred',
      });

    } finally {
      setIsLoading(false);
    }
  };

  const onBackButtonClick = () => {
    navigate(-1);
  }

  return (
    <>
      <SEO
        title="Add Ticket - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full px-[var(--margin-x)] pb-8">
        <Toaster
          position="top-right"
        />
        <PageHeader
          title={`Add Ticket For ${event.name || ''}`}
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
          showCreateButton={false}
        />
        <div className="font-semibold ml-2">Start Time: {new Date(event.start_time).toLocaleDateString()} {new Date(event.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
        <div className="font-semibold ml-2">End Time: {new Date(event.end_time).toLocaleDateString()} {new Date(event.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>

        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-layer-group" />
                  </div>
                  <h4 className=" font-medium text-slate-700 dark:text-navy-100">
                    Add Ticket
                  </h4>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4 sm:p-5" id="category">

                  <div>
                    <span
                      className={`mr-2 py-2 px-4 ${type === 'paid' ? 'bg-blue-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('paid')}
                    >
                      Paid
                    </span>
                    <span
                      className={`mr-2 py-2 px-4 ${type === 'free' ? 'bg-green-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('free')}
                    >
                      Free
                    </span>
                    <span
                      className={`py-2 px-4 ${type === 'complimentary' ? 'bg-yellow-500 text-white' : 'bg-gray-300'} rounded-full `}
                      onClick={() => handleButtonClick('complimentary')}
                    >
                      Complimentary
                    </span>

                  </div>

                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Type of Ticket</span>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Enter Type of Ticket"
                                type="text"
                                {...field}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-span-1">

                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Quantity</span>
                        <Controller
                          name="quantity"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Enter Quantity"
                                type="number"
                                value={field.value}
                                onChange={(e) => {
                                  const newValue = parseInt(e.target.value, 10);
                                  console.log(e.target.value);
                                  if (e.target.value.trim() === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= availableTickets)) {
                                    // Check if input is empty or the entered value is within available tickets range
                                    field.onChange(e.target.value.trim() === '' ? '' : newValue);
                                  } else {

                                    Swal.fire({
                                      icon: 'error',
                                      title: 'You can add only ticket  less then available tickets',
                                    });

                                    field.onChange('');
                                  }
                                }}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                              <p className="text-red-500">This event have  available tickets: {availableTickets}</p>
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {type === 'paid' &&

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <Controller
                            name="price"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="USD Price for online sales"
                                  type="number"
                                  {...field}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);


                                    if (e.target.value.includes("-")) {

                                      Swal.fire({
                                        title: 'Error',
                                        text: 'Please enter a positive value.',
                                        icon: 'error',
                                        confirmButtonColor: '#a23896',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'OK',
                                        showCloseButton: false // Ensure the close button is shown
                                      });


                                      e.target.value = '';
                                    } else {
                                      field.onChange(value);

                                    }


                                  }}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) For Cash Sales</span>
                          <Controller
                            name="local_currency_price"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Local Currency Price"
                                  type="text"
                                  {...field}
                                  onChange={(e) => {
                                    let value = e.target.value.trim();
                                    if (value === "") {
                                      field.onChange("");
                                    } else {
                                      value = parseFloat(value);
                                      if (isNaN(value) || value < 0) {
                                        Swal.fire({
                                          title: 'Error',
                                          text: 'Please enter a positive numeric value.',
                                          icon: 'error',
                                          confirmButtonColor: '#a23896',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: 'OK',
                                          showCloseButton: false
                                        });
                                      } else {
                                        field.onChange(value);
                                      }
                                    }
                                  }}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                }

                {type === 'free' &&

                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border   bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Free"
                            disabled
                            type="number"
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) for cash sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Free"
                            type="text"

                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                }

                {type === 'complimentary' &&
                  <div className="grid grid-cols-2 gap-4">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>USD Price for online sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border   bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Complimentary"
                            disabled
                            type="number"
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Local Currency Price ($) for cash sales</span>
                          <input
                            className={`form-input mt-1.5 w-full rounded-lg border bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                            placeholder="Complimentary"
                            type="text"

                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                }
                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Maximum ticket per order</span>
                        <Controller
                          name="ticket_per_order"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                placeholder="Maximum ticket per order"
                                type="number"
                                max="10"
                                {...field}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);

                                  if (e.target.value > 10) {

                                    Swal.fire({
                                      title: 'Error',
                                      text: 'You add only maximum ticket per order 10',
                                      icon: 'error',
                                      confirmButtonColor: '#a23896',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: 'OK',
                                      showCloseButton: false // Ensure the close button is shown
                                    });
                                    e.target.value = '';
                                    field.onChange('');
                                  } else {

                                    if (e.target.value.includes("-")) {

                                      Swal.fire({
                                        title: 'Error',
                                        text: 'Please enter a positive value.',
                                        icon: 'error',
                                        confirmButtonColor: '#a23896',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'OK',
                                        showCloseButton: false // Ensure the close button is shown
                                      });


                                      e.target.value = '';
                                    } else {
                                      field.onChange(e.target.value);

                                    }

                                  }




                                }}
                              />
                              {fieldState.error && (
                                <p className="text-red-500">{fieldState.error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </label>
                    </div>
                  </div>


                </div>



                <div className="grid grid-cols-2 gap-4">
                  {/* First column */}
                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Ticket Sales Start Time</span>
                        <Controller
                          name="start_time"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              dateFormat="MM/dd/yyyy hh:mm aa"
                              showTimeSelect
                              name="start_time"
                              className="mt-1 p-2 max-w-full sm:max-w-xs md:max-w-sm lg:max-w-lg xl:max-w-xl border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={watch('start_time')} // Change from formData.start_time to watch('start_time')
                              onChange={(date) => {
                                setValue('start_time', date);
                                if (watch('end_time') < date) {
                                  setValue('end_time', date);
                                }
                              }}
                            />
                          )}
                        />
                      </label>
                    </div>
                  </div>


                  <div className="col-span-1">
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                      <label className="block">
                        <span>Ticket Sales End Time</span>
                        <Controller
                          name="end_time"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              dateFormat="MM/dd/yyyy hh:mm aa"
                              showTimeSelect
                              name="end_time"
                              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={watch('end_time')} // Change from formData.end_time to watch('end_time')
                              minDate={watch('start_time')}
                              minTime={
                                watch('start_time')
                                  ? new Date(watch('start_time').getTime() + 60000)
                                  : null
                              }
                              maxTime={
                                watch('start_time')
                                  ? new Date(watch('start_time').getTime() + 86399000)
                                  : null
                              }
                              onChange={(date) => {
                                if (date < watch('start_time')) {
                                  date = watch('start_time');
                                }
                                setValue('end_time', date);
                              }}
                            />
                          )}
                        />
                      </label>
                    </div>
                  </div>
                </div>



                <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block">
                    <span>Ticket Benefits</span>
                    <div style={{ position: 'relative' }}>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            {...field}
                            rows="3"
                            maxLength={250}
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter Ticket Benefits"
                            onChange={(e) => {
                              handleInputChange(e);
                              field.onChange(e);
                            }} // Trigger both custom function and react-hook-form's onChange
                          />
                        )}
                      />
                      <p className="absolute bottom-2 right-2 text-sm text-gray-500">{charCount}/250</p>
                      {errors.description && <p className="text-red-500">{errors.description.message}</p>}

                    </div>

                  </label>
                </div>



                <div className="col-span-1">

                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Status</span>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            isSearchable={true}
                            className="mt-1.5 w-full rounded-lg border bg-white px-3"
                            onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                            styles={customStyles}
                            value={statusOptions.find((option) => option.value === field.value)}
                            placeholder="Select Status"
                          />
                        )}
                      />
                      {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                    </label>
                  </div>
                </div>




                <div className="space-y-4 p-4 sm:p-5" id="category">

                  <button
                    type="submit"
                    className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TicketCreate;
