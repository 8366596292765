import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { ScannerList, ScannerStatus, DeletescannerList } from "../../../services/scannerServices";
import ConfirmationPopup from "../../../hooks/ConfirmationPopup";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import TableTools from "../../../hooks/TableTools";
import PageHeader from "../../../hooks/PageHeader";
import { toast, Toaster } from "react-hot-toast";
import { API_ENDPOINTS } from '../../../services/api';
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";


const Scanner = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
   {/* useResizeObserver Start  */ }
   const [size, setSize] = useState({ width: 0, height: 0 });
   const ref = useRef(null);
   // Implement the callback function
   const handleResize = (entries) => {
     const { width, height } = entries[0].contentRect;
     setSize({ width, height });
   };
   // Use the custom hook
   useResizeObserver(ref, handleResize);
   {/* useResizeObserver END  */ }
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };


  const handleExportCSV = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.CSV_SCANNER + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.PDF_SCANNER + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ScannerList(currentPage, searchText);
   
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
       } else {
        setData([]);
        setTotalRecords(0);
       }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };


  const showDeleteConfirmation = async (callback) => {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            await callback();
            Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
        }
    } catch (error) {
        console.error('Error in showDeleteConfirmation:', error);
        Swal.fire('Error!', 'Failed to delete the item.', 'error');
    }
};

const handleDeleteClick = async (id) => {
    showDeleteConfirmation(async () => {
        try {
            const response = await DeletescannerList(id);


            if (response.status === 200) {
                alert('OK');
                const responseData = await response.json();
                if (responseData.success) {
                    Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
                    fetchData();
                } else {

                    Swal.fire('Error!', 'Failed to delete the item.', 'error');
                    fetchData();
                }
            } else {

                Swal.fire('Error!', 'Failed to delete the item.', 'error');
                fetchData();
            }
        } catch (error) {
            fetchData();
            console.error('Error deleting item:', error);
            Swal.fire('Error!', error.message, 'error');
        }
    });
};



  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  useEffect(() => {
    if (currentPage !== prevPageRef.current || debouncedSearchText !== prevSearchTextRef.current) {
      fetchData();
    }
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
  }, [currentPage, debouncedSearchText]);

if (!pageLoading) {
        return (
            <main  ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }

  const handleStatusChange = async (userId) => {
    try {
      const response = await ScannerStatus(userId);
      if (response.success === true) {
        await fetchData();
        Swal.fire({
          title: 'Good Job',
          text: 'Status Change Successfully',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Status Change Failed',
          text: 'An error occurred while changing the status.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `An error occurred: ${error}`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
    }
  };  const openConfirmationPopup = (userId) => {
    setSelectedUserId(userId);
    setIsConfirmationOpen(true);
  };

  const closeConfirmationPopup = () => {
    setSelectedUserId(null);
    setIsConfirmationOpen(false);
  };

  const onButtonClickCreate = () => {
    navigate("/organization/create-scanner");
  };

  return (
    <>
    <SEO
    title="Scanner List - eticketing"
  />
    <main  ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Scanner List"
        onButtonClick={onButtonClickCreate}
        showCreateButton={true}
      />
      <Toaster position="top-right" />
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        <div className="card px-4 pb-4 sm:px-5">
          <TableTools
            title="Scanners Rows"
            onSearchChange={handleSearchChange}
            showSearch={true}
            showExportButtons={true}
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
          />
          <div>
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          #
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Image
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Email
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Phone Number
                        </th>
                        {/* <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total events
                        </th> */}
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Status
                        </th>
                        <th className="whitespace-nowrap   bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Actions
                        </th>
                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.id}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <img
                              className="max-h-10"
                              src={item.imagePath}
                              alt="Profile"
                            />
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.first_name && item.last_name
                              ? `${item.first_name} ${item.last_name}`
                              : 'N/A'}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.email}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.phone}
                          </td>
                          {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.events_count}
                          </td> */}

                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <div className="inline-block">
                              {item.status === 1 ? (
                                <div className="badge space-x-2.5 px-0 text-success">
                                  <div className="size-2 rounded-full bg-current" />
                                  <span>Active</span>
                                </div>
                              ) : (
                                <div className="badge space-x-2.5 px-0 text-error">
                                  <div className="size-2 rounded-full bg-current" />
                                  <span>Inactive</span>
                                </div>
                              )}

                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <Link
                              onClick={() => openConfirmationPopup(item.id)}
                              className="cursor-pointer focus:outline-none flex items-center">
                              {item.status === 0 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-2 h-5 w-5 text-green-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-2 h-5 w-5 text-red-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              )}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-4 pt-6 pb-4 sm:px-5 flex items-center space-x-4">
                                                        <Link className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25" to={`/organization/scanner/${item.id}/edit`}>
                                                            <i className="fa fa-edit" />
                                                        </Link>
                                                        <Link className="btn size-8 p-0 text-error hover:bg-error/20 focus:bg-error/20 active:bg-error/25" onClick={() => handleDeleteClick(item.id)} >
                                                            <i className="fa fa-trash-alt" />
                                                        </Link>
                                                    </td>
                        </tr>
                      ))}
                    </tbody>
                    <ConfirmationPopup
                      isOpen={isConfirmationOpen}
                      onCancel={closeConfirmationPopup}
                      onConfirm={() => {
                        handleStatusChange(selectedUserId);
                        closeConfirmationPopup();
                      }}
                      title="Status Change Confirmation"
                      message="Are you sure you want to change the status?"
                    />
                  </table>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default Scanner;
