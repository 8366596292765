import React, { useState, useEffect, useRef } from 'react';
import PageHeader from '../../hooks/PageHeader';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast, Toaster } from 'react-hot-toast';
import CustomLoader from './customloader';
import SEO from "../../hooks/seo";
import { API_ENDPOINTS } from '../../services/api'
import {
    fetchUsersAndEvents,
    checkAvailableTicket,
    checkTicketPrice,
    CreateSellTicketData,
    searchUsers,
    searchEvent,
    FetchservicFee
} from '../../services/agent/agent-SellTickets-service';
import useResizeObserver from "./../../hooks/useResizeObserver";
import Swal from 'sweetalert2';


const SellTickets = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        setError,
    } = useForm();

    const [AvailableTicket, setAvailableTicket] = useState('');
    const [CheckAvailTicket, setCheckAvailTicket] = useState(0);
    const [SelectedUser, setSelectedUser] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [numberOfTicket, setNumberOfTicket] = useState(0);
    const [SelectedEvent, setSelectedEvent] = useState(null);
    const [options, setOptions] = useState([]);
    const [optionsevts, setoptionsevts] = useState([]);
    const [SelectedEventData, setSelectedEventData] = useState([]);
    const [SelectedTicket, setSelectedTicket] = useState([]);
    const navigate = useNavigate();
    const [EventID, setEventID] = useState('');
    const [ticketPrice, setTicketPrice] = useState(0);
    const [Tper_order_limit, setTper_order_limit] = useState(0);
    // charge amount states
    const [totalPrice, setTotalPrice] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [vatTotal, setvatTotal] = useState(0);
    const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
    const [processingFeeTotal, setProcessingFeeTotal] = useState(0);
    const [agentCommisionTotal, setAgentCommisionTotal] = useState(0);
    const [selectedTickets, setSelectedTickets] = useState(0);
    //end charge amount states
    const [formIsValid, setFormIsValid] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState(0);
    const [additional_ticket, setAdditional_ticket] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [eventinputValue, setEventInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');


    const [processingFee, setProcessingFee] = useState(0);
    const [serviceFree, setServiceFee] = useState(0);
    const [vat_charge, setVat_charge] = useState(0);
    const [VatFee, setVatFee] = useState(0);
    const [agent_commission_type, set_agent_commission_type] = useState('');
    const [agent_commission_about, set_agent_commission_about] = useState('');
    const [agent_commissionCharge, set_agent_commissionCharge] = useState('');
     
     
    const [IdEvent, setIdEvent] = useState('');
    const [complimentaryTickets, setComplimentaryTickets] = useState(0);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);

    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };

    useResizeObserver(ref, handleResize);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const fetchFeeData = async () => {

     
        try {
            const data = await FetchservicFee();
            console.log(data.response);
            if(data.response.data.data.service_fee_enabled){
                setServiceFee(data.response.data.data.service_fee);
            }

            if(data.response.data.data.webfee_enabled){
                setProcessingFee(data.response.data.data.webfee_percentage);
            }

          setVat_charge(data.response.data.data.vat_exclusive);
          setVatFee(data.response.data.data.vatfee_percentage);
          set_agent_commission_type(data.response.data.data.agent_commission_type);
          set_agent_commission_about(data.response.data.data.agent_commission_about);
          set_agent_commissionCharge(data.response.data.data.agent_commission);

           
          
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setPageLoading(false);
        }
    };
    useEffect(() => {
            fetchFeeData();
    }, []);
    const fetchData = async () => {
        try {
            const { users, events } = await fetchUsersAndEvents();

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setPageLoading(false);
        }
    };

    const fetchDataCalledRef = useRef(false);

    useEffect(() => {
        if (!fetchDataCalledRef.current) {
            fetchData();
            fetchDataCalledRef.current = true;
        }
    }, []);


    const handleInputChange = (newValue) => {

        setInputValue(newValue);
        loadOptions(newValue);
    };

    const loadOptions = async (inputValue) => {
        try {

            const words = inputValue.split('');

            if (words.length >= 3) {

                const response = await searchUsers(inputValue);

                const formattedOptions = response.appusers.map(option => ({
                    label: option.name + option.last_name + ' | ' + option.email + ' | ' + option.user_id,
                    value: option.id
                }));


                setOptions(formattedOptions);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };


    const [symbol, setSymbol] = useState('');

    useEffect(() => {
      // Fetch and display currency from localStorage
      const storedCurrency = localStorage.getItem('currency');
      if (storedCurrency) {
        setSymbol(storedCurrency);
      }
    }, []);
    useEffect(() => {
        if (SelectedEvent) {

            checkEventTickets(SelectedEvent.value);
            checkSingleTicketsPrice(SelectedEvent.value, numberOfTicket);
        }
    }, [SelectedEvent, numberOfTicket]);

    useEffect(() => {
        if (SelectedEvent) {
            checkTicketPrice(SelectedEvent.value);
        }
    }, [SelectedEvent]);



    const handleChange_free = (e) => {

        if (e.target.value <= 4) {
            setDisable(false);
            setAdditional_ticket(e.target.value);
        } else {
            setDisable(true);
            alert('You can add up to 4 additional U18 free tickets.');
        }


    }

    const handleEventInputChange = (newValue, selectedEvent) => {
        setEventInputValue(newValue);
        loadEventOptions(newValue);
        const eventValue = selectedEvent ? selectedEvent.value : null;

        if (eventValue !== null) {
            const authToken = localStorage.getItem('authToken');
            // Making an API request using fetch
            fetch(`${API_ENDPOINTS.AGENT_EVENT_TICKETS}/${eventValue}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },

            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {

                    setSelectedEventData(data.tickets);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }

    };
    const handleTicketInputChange = async (selectedOption) => {
        if (selectedOption) {
            const ticketId = selectedOption.value;
            const eventId = SelectedEvent.value;
            const numberOfTicket = number;
            
            try {
                await checkEventTickets(eventId, ticketId);
                await checkSingleTicketsPrice(eventId, numberOfTicket, ticketId);
                setSelectedTicket(ticketId);
            } catch (error) {
                console.error('Error handling ticket input change:', error);
            }
        }
    };


    const loadEventOptions = async (inputValue) => {
        try {
            // Split inputValue into words
            const words = inputValue.split('');


            if (words.length !== 0) {
                // Fetch options from API based on the inputValue
                const response = await searchEvent(inputValue);



                const eventOptions = response.events.map(option => ({
                    label: option.name,
                    value: option.id
                }));


                setoptionsevts(eventOptions);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };



    const checkEventTickets = async (eventId, ticketId) => {
        try {
            const availableTickets = await checkAvailableTicket(eventId, ticketId);
            setAvailableTicket(`Available Tickets: ${availableTickets}`);
            setCheckAvailTicket(availableTickets);
        } catch (error) {
            console.error('Error checking available tickets:', error);
        }
    };

    const checkSingleTicketsPrice = async (eventId, numberOfTickets, ticketId) => {
        console.log(ticketId, 'ticketId');
        try {
            const price = await checkTicketPrice(eventId, ticketId);
            console.log(price, 'price');
            setTicketPrice(price.price);
            setTper_order_limit(price.per_order_limit);
            //calculateTotalPrice(numberOfTickets, price.price);
        } catch (error) {
            console.error('Error checking ticket price:', error);
        }
    };

    const calculateTotalPrice = (numberOfTickets, ticketPrice) => {
        const total = numberOfTickets * ticketPrice;
        setTotalPrice(total);
    };

    const onSubmit = (formData, ticketId) => {

        openModal(ticketId);
        //confirmSellTicket(ticketId);

    };






    const handleComplimentaryTicketsChange = (event) => {
        const { value } = event.target;
        console.log(value); // Log the value to the console
        setSelectedTickets(value); 
        if (parseInt(value) > Tper_order_limit) {
            // Display Swal alert for exceeding the limit
            Swal.fire({
                title: 'Error',
                text: `You cannot add more than ${Tper_order_limit} tickets per order.`,
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
        } else if (parseInt(value) > AvailableTicket) {
            // Display Swal alert for exceeding the available tickets
            Swal.fire({
                title: 'Error',
                text: `You cannot add more than ${AvailableTicket} tickets as per availability.`,
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
        } else {
            setComplimentaryTickets(value); // Update the state
        }
    };


// here is calculated value
    useEffect(() => {
        const totalComplimentaryPrice = complimentaryTickets * ticketPrice;
        const sub_total = (numberOfTicket + complimentaryTickets) * ticketPrice;
        const service_fee = (numberOfTicket + complimentaryTickets) * serviceFree;
        const processing_fee = (sub_total + service_fee) * processingFee / 100 ;

        var agentcommissionCharge = 0 ;
        if(agent_commission_type == "amount"){
            if(agent_commission_about == "per_ticket"){

                var agentcommissionCharge = (numberOfTicket + complimentaryTickets) * agent_commissionCharge;
            }else{
                  var agentcommissionCharge =  agent_commissionCharge;
            }
           
        }


        if(vat_charge == 1){
            var vat_fee = (service_fee + processing_fee) * VatFee / 100;
        } 
        const totalPrice = sub_total + service_fee + processing_fee + vat_fee;

       
        
        if(agent_commission_type == "percentage"){
            var agentcommissionCharge = totalPrice * agent_commissionCharge / 100;
           
        }  
        
        setAgentCommisionTotal(agentcommissionCharge);
        setProcessingFeeTotal(processing_fee);
        setServiceFeeTotal(service_fee);
        setvatTotal(vat_fee);
        setSubTotal(sub_total);
        setTotalPrice(totalPrice + agentcommissionCharge);

         

    }, [numberOfTicket, ticketPrice, complimentaryTickets]);



    useEffect(() => {

        const slectedEvent = localStorage.getItem('slectedEvent');
        if (slectedEvent) {
            const parsedArray = JSON.parse(slectedEvent);

            setEventInputValue(parsedArray.label);

            setSelectedEvent(parsedArray);
            checkEventTickets(parsedArray.value);
            setIdEvent(parsedArray.value);

        }


    }, []);


    const confirmSellTicket = async (ticketId) => {

        console.log(ticketId, 'ticketId');
        const data = {
            customer_id: SelectedUser.value,
            event_id: IdEvent,
            complimentary_tickets: complimentaryTickets,
            additional_ticket: additional_ticket,
            ticket_price: subTotal,
            ticket_id: ticketId,
        };


        localStorage.setItem('slectedEvent', JSON.stringify(SelectedEvent));


        try {
            setIsLoading(true);
            const result = await CreateSellTicketData(data, ticketId);
            setLoading(false);

            Swal.fire({
                title: 'Good Job',
                text: 'Ticket sold successfully',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/agent/orders', { state: { message: 'Scanner Create Successfully' } });
                }
            });

        } catch (error) {
            setIsLoading(false);
            // const errorData = await error.json();
            // const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
            let errorMessage = 'An unknown error occurred';
            if (error.response && error.response.json) {
                const errorData = await error.response.json();
                errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
            } else {
                errorMessage = error.message || 'An unknown error occurred';
            }
            Swal.fire({
                title: 'Error',
                text: errorMessage || 'An error occurred',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
        }
    };


    if (pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center h-screen">
                    <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                    />
                </div>
            </main>
        );
    }

    return (
        <>
            <SEO
                title="Sell Ticket - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="container">
                    <PageHeader title="Sell Ticket" showCreateButton={false} />
                    <Toaster position="top-center" />
                    <div className="flex items-center justify-center mt-5 bg-white p-4 w-full">
                        <form onSubmit={handleSubmit((formData) => onSubmit(formData, SelectedTicket))} className="w-full max-w-57rem mx-auto">
                            <div className="lg:flex lg:mb-4 mb-2">
                                <div className="w-full ">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">User</label>

                                        <Select
                                            name="customer_id"
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            options={options}
                                            onChange={(selectedOption) => {

                                                setSelectedUser(selectedOption); // Set the selected user when an option is chosen
                                            }}
                                            placeholder="Select user..."
                                            className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                            defaultOptions // Fetch options on initial render
                                        />

                                    </div>
                                </div>
                                <div className="w-full ">
                                    <div className="mb-4 lg:ml-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Event</label>
                                        {/* <Select
                                        name="event_id"
                                        options={optionsevts}
                                        value={SelectedEvent}
                                        onChange={handleSelectChanges}
                                        placeholder="Choose event..."
                                        className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    /> */}

                                        <Select
                                            name="event_id"
                                            //inputValue={eventinputValue}
                                            onInputChange={(newValue) => handleEventInputChange(newValue, SelectedEvent)}
                                            options={optionsevts}
                                            defaultOptions
                                            isMulti={false}
                                            onChange={(selectedOption) => {

                                                setSelectedEvent(selectedOption);
                                                checkEventTickets(selectedOption.value);
                                                checkSingleTicketsPrice(selectedOption.value, numberOfTicket);
                                                setIdEvent(selectedOption.value);
                                                localStorage.removeItem('slectedEvent');


                                            }}
                                            placeholder="Choose Event..."
                                            className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"

                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"> Event Ticket</label>

                                <Select
                                    name="ticket_id"
                                    onChange={(selectedOption) => handleTicketInputChange(selectedOption, numberOfTicket)}
                                    placeholder="Select ticket..."
                                    className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    options={[
                                        { value: '', label: 'Choose ticket...' },
                                        ...SelectedEventData.map(ticket => ({
                                            value: ticket.id,
                                            label: ticket.name
                                        }))
                                    ]}

                                />


                            </div>
                            <div className="lg:flex mb-4 ">
                                <div className="w-full">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Number of Tickets</label>
                                    <div className="mb-4">
                                        <input
                                            type="number"
                                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="complimentary_tickets"
                                            value={complimentaryTickets}
                                            onChange={handleComplimentaryTicketsChange} // Call the event handler on change
                                            placeholder="Number of complimentary tickets"
                                            min='1'
                                            max={CheckAvailTicket}
                                        />
                                        {AvailableTicket && (
                                            <div className='text-red-500'>{AvailableTicket}</div>
                                        )}

                                    </div>

                                </div>
                                <div className="w-full">
                                    <div className="mb-4 lg:ml-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Total Price (Price in {symbol})</label>
                                        <input
                                            type="ticket_price"
                                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="ticket_price"
                                            value={`${symbol} ${isNaN(subTotal) ? '0.00' : subTotal.toFixed(2)}`}
                                            placeholder=""
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-4 lg:pl-0 pl-2">
                                <div className="w-full">
                                    <button
                                        type="submit"
                                        className={`px-4 py-2 text-white rounded focus:outline-none ${!SelectedTicket || !SelectedUser || !complimentaryTickets || loading || disable ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                                        disabled={!SelectedTicket || !SelectedUser || !complimentaryTickets || loading || disable}
                                    >
                                        {loading ? (
                                            <div className="spinner-border text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {isModalOpen && (
                    <div>
                        <div className={`fixed inset-0 ${isModalOpen ? 'bg-black opacity-50' : ''}`} onClick={closeModal}></div>
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="relative bg-white p-8 lg:w-[50rem] md:[50rem] w-96 rounded-md">
                                <div className="flex mb-4">
                                    <h2 className="text-2xl font-bold">Sell Ticket Confirmation</h2>
                                    <button onClick={closeModal} className="ml-auto">
                                        <svg className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                                            <line x1="9" y1="9" x2="15" y2="15" />
                                            <line x1="15" y1="9" x2="9" y2="15" />
                                        </svg>
                                    </button>
                                </div>
                                <hr className="mb-4" />
                                <div className="flex items-center mt-3 hidden">
                                    <svg className="h-4 w-4 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                    <p className="font-medium text-black font-bold">You can add up to 4 additional U18 free tickets.</p>
                                </div>
                                <div className="mt-5 hidden">
                                    <input type="number" max="4" onChange={handleChange_free} placeholder="Add U18 free tickets" className="border border-gray-300 p-2 w-full" />
                                </div>

                                <div className="mt-6">
                                    <p className="text-gray-400 text-lg font-bold">Order Summary:</p>
                                </div>
                                <div className="mt-5">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                                        <tbody>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Event:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {SelectedEvent.label}
                                                </td>
                                            </tr>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    User:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {SelectedUser.label}
                                                </td>
                                            </tr>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Paid Tickets:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {selectedTickets}
                                                </td>
                                            </tr>
                                             <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Sub Total:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {subTotal.toFixed(2)}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Service Fee:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {serviceFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Processing Fee:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {processingFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>


                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Vat Tax:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {vatTotal.toFixed(2)}
                                                </td>
                                            </tr>


                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Agent Commission:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {agentCommisionTotal.toFixed(2)}
                                                </td>
                                            </tr>

  
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 hidden">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Free Tickets:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {additional_ticket}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                        Grand Total:
                                                </th>
                                                <td className="px-4 py-3">
                                                {symbol} {totalPrice.toFixed(2)}
                                                 
                                                </td>
                                            </tr>
                                            {/* Add more table rows as needed */}
                                        </tbody>


                                    </table>
                                </div>
                                <hr className="mt-6" />
                                <div className="flex justify-end mt-5">
                                    <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 py-3 px-4 mr-4 text-white font-semibold rounded-lg cursor-pointer">
                                        No
                                    </button>


                                    {disable ? (

                                        <button onClick={() => confirmSellTicket(SelectedTicket)} disabled className={`bg-blue-700 hover:bg-blue-500 py-3 px-4 text-white font-semibold rounded-lg   ${disable ? 'cursor-not-allowed' : ''}`}>

                                            {isLoading ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                                                    <span>Loading...</span>
                                                </div>
                                            ) : (
                                                "Yes"
                                            )}
                                        </button>

                                    ) : (

                                        <button onClick={() => confirmSellTicket(SelectedTicket)} disabled={isLoading} className={`bg-blue-700 hover:bg-blue-500 py-3 px-4 text-white font-semibold rounded-lg cursor-pointer ${isLoading ? 'cursor-not-allowed' : ''}`}>

                                            {isLoading ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                                                    <span>Loading...</span>
                                                </div>
                                            ) : (
                                                "Yes"
                                            )}

                                        </button>

                                    )}



                                </div>

                            </div>
                        </div>
                    </div>
                )}

            </main>
        </>
    );
};

export default SellTickets