const StatCard = ({ title, value }) => {
    return (
      <div className="bg-blue-500 text-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p className="text-4xl mt-2">{value}</p>
        
      </div>
    );
  };
  
  export default StatCard;