import QRCode from 'qrcode.react';
import React, { useState, useEffect , useRef} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import PageHeader from "../../hooks/PageHeader";
import CustomLoader from './customloader';
import {  AgentGetCode } from "../../services/agent/agentorderservice";
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";



function GetCode() {
    const { id } = useParams();
    const [Invoice, setInvoice] = useState([]);
    const [EvtData, setEvtData] = useState([]);
    const [organizer, setOrganizer] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const authToken = localStorage.getItem("authToken");
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(false);
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
    
    
    
    const fetchData = async (id) => {
        setLoading(true);
        try {
            const response = await AgentGetCode(id);
            console.log(response.ticket, 'response');
            if (response && response.ticket) {
                setInvoice(response.ticket.order);
                setEvtData(response.ticket.order.event);
                setOrganizer(response.ticket.order.organization);
                setCustomer(response.ticket.order.customer);
                setTicket(response.ticket);
                console.log(response, 'ddd');
            } else {
                setEvtData([]);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
        setPageLoading(true);
    };
    const formatDate = (dateString) => {
        const formattedDate = new Date(dateString).toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
        return formattedDate;
      };
    useEffect(() => {
        fetchData(id);
    }, [id]);
     if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
const onBackButtonClick = () => {
        navigate(-1);
    }
    return (
        <>
        <SEO
    title="Get Code - eticketing"
  />
            <main ref={ref} style={{ width: '100%', height: '100%'}}
             class="main-content w-full px-[var(--margin-x)] pb-8 pt-8">
                <PageHeader
             showBackButton={true}
              onBackButtonClick={onBackButtonClick}
              />
                <div class="grid grid-cols-1">
                    <div class="card px-5 py-12 sm:px-18">
                        <div>
                            <div class="text-center sm:text-left">
                                <h2
                                    class="text-3xl font-semibold uppercase text-gray dark:text-accent-light"
                                >
                                    e-Ticket
                                </h2>
                            </div>
      
      

                        </div>
                           <div className="my-4 mx-auto mr-[48px] max-w-md flex items-center justify-end pr-4">  
                        
                        </div>
                        <div class="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
                        <div class="flex flex-col justify-between sm:flex-row">
                         
                            <div class="text-center sm:text-left">
                                <h2
                                    class="text-2xl font-semibold uppercase text-slate-600 dark:text-navy-100"
                                >
                                    {EvtData.name}
                                </h2>
                                <div className="space-y-1 pt-2">
                                    <p className='text-black text-base'><span className='font-semibold'>Start Date:</span> {formatDate(EvtData.start_time)}</p>
                                    <p className='text-black text-base'><span className='font-semibold'>End Date:</span> {formatDate(EvtData.end_time)}</p>
                                    <p className='text-black text-base'><span className='font-semibold'>Address:</span> {EvtData.address || 'N/A'}</p>
                                    <p className='text-black text-base'><span className='font-semibold'>Organizer:</span> {organizer.first_name +' '+organizer.last_name}</p>
                                </div>
                            </div> 
                            <div className="mt-4 text-center sm:mt-0 sm:text-right">
                                <p
                                    class="text-xl font-medium text-black"
                                >
                                    Tickket: {ticket.ticket_number || 'N/A'}
                                </p>
                                <div class="space-y-1 pt-2">
                                    <p class="text-black text-xl font-semibold">
                                        {customer ? `${customer.name || 'N/A'} ${customer.last_name || 'N/A'}` : 'N/A'}
                                      </p>
                                    <p
                                        class="text-lg font-medium text-black"
                                    >
                                        Payment Method:  {Invoice.payment_type}
                                        
                                    </p>
                                </div>
                            </div>
                            <div className='lg:mt-0 mt-6 lg:pl-0 pl-24'>
                                <QRCode
                                    style={{ width: '150px', height: '150px' }}
                                />
                            </div>
                        </div>
                        <div class="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default GetCode;