import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api';

export const PageFetch = async (currentPage, searchText) => {

  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    const response = await axios.get(API_ENDPOINTS.PAGE_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const Delete_Page = async (id) => {

  try {
    const authToken = localStorage.getItem('authToken');

    const response = await axios.delete(API_ENDPOINTS.DELETE_PAGE + id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};


export const Edit_Page = async (id) => {
  
  try { 
      const authToken = localStorage.getItem('authToken');
 
        const response = await axios.get(API_ENDPOINTS.EDIT_PAGE+id+'/edit', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};


export const submitPage = async (formDataToSend) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

     try {
    const response = await axios.post(API_ENDPOINTS.STORE_PAGE, formData, { headers });
    return response;
        // Check if the response is okay (status code 2xx)
        if (response.ok) {
          const responseData = await response.json();
          // Process the successful response
          console.log('Response:', responseData);
          return responseData;
        } else {
          // Handle the case where the response status is not okay
          const errorData = response.data; // Access the error details from response.data
          console.error('Failed to submit event data. Status:', response.status);
          console.error('Error Details:', errorData);
          return 'Failed to submit event data';
        }
      } catch (error) {
        // Handle any network or other errors that may occur during the request
        console.error('Error:', error.message);
        return 'Failed to submit event data';
      }

  
  } catch (error) {
    console.error('Error preparing FormData:', error);
    return error;
  }
};

export const updatePage = async (formDataToSend,id) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.append("_method",'PUT');
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

     try {
    const response = await axios.post(API_ENDPOINTS.UPDATE_PAGE+id, formData, { headers });
    return response;
        // Check if the response is okay (status code 2xx)
        if (response.ok) {
          const responseData = await response.json();
          // Process the successful response
          console.log('Response:', responseData);
          return responseData;
        } else {
          // Handle the case where the response status is not okay
          const errorData = response.data; // Access the error details from response.data
          console.error('Failed to submit event data. Status:', response.status);
          console.error('Error Details:', errorData);
          return 'Failed to submit event data';
        }
      } catch (error) {
        // Handle any network or other errors that may occur during the request
        console.error('Error:', error.message);
        return 'Failed to submit event data';
      }

  
  } catch (error) {
    console.error('Error preparing FormData:', error);
    return error;
  }
};