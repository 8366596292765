import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 
import Swal from 'sweetalert2';
export const CategoryListFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.CATEGORY_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const Edit_category = async (id) => {
  
  try { 
      const authToken = localStorage.getItem('authToken');
 
        const response = await axios.get(API_ENDPOINTS.EditCategory+id+'/edit', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};


// export const Delete_category = async (id) => {
  
//   try { 
//     console.log(id,'ok');
//       const authToken = localStorage.getItem('authToken');
 
//         const response = await axios.get(API_ENDPOINTS.Deletecategory+id, {
//       headers: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('scanner error:', error);
//     throw error;
//   }
// };


export const submitCategory = async (formDataToSend) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

     try {
    const response = await axios.post(API_ENDPOINTS.StoreCategory, formData, { headers });
    return response;
        // Check if the response is okay (status code 2xx)
        if (response.ok) {
          const responseData = await response.json();
          // Process the successful response
          console.log('Response:', responseData);
          return responseData;
        } else {
          // Handle the case where the response status is not okay
          const errorData = response.data; // Access the error details from response.data
          console.error('Failed to submit event data. Status:', response.status);
          console.error('Error Details:', errorData);
          return 'Failed to submit event data';
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.response.data.error.image || 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });
        // Handle any network or other errors that may occur during the request
        console.error('Error:', error.response.data.error.image);
        return error.response.data.error.image;
      }

  
  } catch (error) {
    console.error('Error preparing FormData:', error);
    return error.response.data.error.image;
  }
};


export const updateCategory = async (formDataToSend,id) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.append('_method','PUT');
    // Append all fields to the formData object
    Object.keys(formDataToSend).forEach((key) => {
      // If the field is a File type or Blob, append it to formData with its key
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {
        // If the field is an array (e.g., scanner_id), loop through its values and append each to formData
        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

     try {
    const response = await axios.post(API_ENDPOINTS.UpdateCategory+id, formData, { headers });
    return response;
        // Check if the response is okay (status code 2xx)
        if (response.ok) {
          const responseData = await response.json();
          // Process the successful response
          console.log('Response:', responseData);
          return responseData;
        } else {
          // Handle the case where the response status is not okay
          const errorData = response.data; // Access the error details from response.data
          console.error('Failed to submit event data. Status:', response.status);
          console.error('Error Details:', errorData);
          return 'Failed to submit event data';
        }
      } catch (error) {
        // Handle any network or other errors that may occur during the request
        console.error('Error:', error.message);
        return 'Failed to submit event data';
      }

  
  } catch (error) {
    console.error('Error preparing FormData:', error);
    return error;
  }
};

export const CategorySorting = async (formData) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS. CATEGORY_SORTING, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data' // Assuming formData is multipart/form-data
      }
    });
    return response;
  } catch (error) {
    console.error('event error:', error);
    throw error;
  }
};