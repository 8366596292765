import React from 'react';

const TableTools = ({ title, onSearchChange, onExportCSV,showSearch, onExportPDF ,showExportButtons , showEventsButtons , activeButton , handleButtonClick }) => {
 
  return (
    <div className="my-3 lg:flex  h-8 items-center justify-between">
      {/* Title */}
      <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
        {title}
      </h2>

       {/* Events Buttons */}
       {showEventsButtons && (
       
       <>
             
  <div class="mt-5">
    <div class="flex space-x-px">
      <button
        className={`btn rounded-l-full rounded-r-none border border-blue-500 font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white ${
          activeButton === "all" ? "bg-blue-500 text-white" : ""
        }`}
        onClick={() => handleButtonClick("all")}
      >
        All Event
      </button>

      <button
       className={`btn border border-blue-500 font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white active:bg-blue-500/90 rounded-none ${
        activeButton === "active" ? "bg-blue-500 text-white" : ""
  
        }`}
        onClick={() => handleButtonClick("active")}
      >
        Active Event
      </button>

      <button
       className={`btn rounded-l-none rounded-r-full border border-blue-500 font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white active:bg-blue-500/90 ${
        activeButton === "past" ? "bg-blue-500 text-white" : ""
        }`}
        onClick={() => handleButtonClick("past")}
      >
        Past Event
      </button>
      
    </div>
  </div>

       </>


       )}

        {/* Events Buttons */}


      {/* Search and Export Buttons */}
      <div className="flex items-center space-x-4 mt-5">
        {/* Search Bar */}
         {showSearch && onSearchChange && (
        <label className="inline-flex items-center space-x-2">
          <input
            type="search"
            placeholder="Type a keyword..."
            aria-label="Type a keyword..."
            className="form-input rounded-md border border-gray-300 p-2"
            onChange={onSearchChange}
          />
        </label>
          )}
        {/* Export Buttons */}
        {showExportButtons && (
           <>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4   px-2 rounded"
          onClick={onExportCSV}
        >
          Export CSV
        </button>

        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4 px-2 rounded"
          onClick={onExportPDF}
        >
          Export PDF
            </button>
             </>
        )}
      </div>
    </div>
  );
};

export default TableTools;
