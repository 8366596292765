import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api';

export const CustomerReportFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? searchText : '';
    
    const postData = {
      page: currentPage,
     
    };

    const response = await axios.post(API_ENDPOINTS.CUSTOMER_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const OrganizationReportFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const postData = {
      page: currentPage,
      search: searchText,
    };

    const response = await axios.post(API_ENDPOINTS.ORGANIZATION_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const CardRevenueReportsFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const searchParam = searchText ? `&search=${searchText}` : '';
    
    const postData = {
      page: currentPage,
      search: searchText,
    };

    const response = await axios.post(API_ENDPOINTS.REVENUE_REPORT_LIST + `?page=${currentPage}${searchParam}`, postData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const SettlementReportFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.SETTLEMENT_REPORT_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const VeiwSettlementDetailFetch = async (currentPage,searchText , id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.VIEW_SETTLEMENT_DETAIL + `${id}` + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};