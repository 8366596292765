import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const EventList = async (currentPage,searchText,EventValue) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    let url = API_ENDPOINTS.EVENT_LIST + `?page=${currentPage}`+ `&event_value=${EventValue}`;

    if (searchText) {
      url += `&search=${encodeURIComponent(searchText)}`;
    }
    
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const VeiwEventListFetch = async (currentPage,searchText,id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.ORG_VEIW_EVENT_LIST + `${id}` + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const EventOrderList = async (currentPage,searchText,id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.ORG_VEIW_Event_LIST + `${id}` + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const ScanOrderList = async (currentPage,searchText,id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.ORG_VEIW_SCAN_LIST + `${id}` + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const EventTickets = async (currentPage,searchText,id,slug) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.EVENT_Tickets+id+`/`+slug+'/tickets'+`?page=${currentPage}` + `&search=${searchText}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};


export const deleteTicket = async (id) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.DELETE_ORG_EVENTS + id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    if (response.status === 200) {
      return response;
      }
    } catch (error) {
      console.error('Error deleting ticket:', error);
      throw error;
    }
  };

export const submitEventData = async (formData) => {
  try {
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image);
    formDataToSend.append('desktop_image', formData.desktop_image);
    formDataToSend.append('currency_id', formData.currency_id);
    for (const key in formData) {
      if (key !== 'image' && key !== 'desktop_image') {
        formDataToSend.append(key, formData[key]);
      }
    }
    //formDataToSend.append('tags', JSON.stringify(tags));
      // formDataToSend.append('type', activeTab);

      // if (activeTab === 'offline') {
      //   formDataToSend.append('address', data.address);
      //   formDataToSend.append('lat', data.lat);
      //   formDataToSend.append('lang', data.lang);
      // }

      formData.scanner_id.forEach((scannerId) => {
        formDataToSend.append('scanner_id[]', scannerId);
      });
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(API_ENDPOINTS.CREATE_EVENTS, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
      body: formDataToSend,
    });

    if (!response.ok) {
      throw new Error('Failed to submit event data');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error submitting event data:', error);
    throw error;
  }
};

export const createEvent = async () => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.createEventOrg, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};



export const searchScanner = async (scanner_name) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.SCANNER_SEARCH + '?scanner_name=' + scanner_name, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const getScanner = async () => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.OrgSCANNERS,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};



export const storeEvent = async (formData) => {
  try {
    
    const authToken = localStorage.getItem('authToken');
    const response = await axios({
      method: 'post',
      url: API_ENDPOINTS.STORE_EVENT,
      headers: {
        'Authorization': `Bearer ${authToken}`  // Ensure authToken is defined and available
      },
      data: formData
    });

    return response.data;

  } catch (error) {
    console.error('Error submitting event data:', error);
    throw error;
  }
};



export const fetchEvent = async (event_id) => {
  try {

    const updatedUrl = API_ENDPOINTS.FETCH_SINGLE_EVENT.replace(':id', event_id);
   // console.log(updatedUrl, 'request');
    const authToken = localStorage.getItem('authToken');
    const response = await axios({
      method: 'get',
      url: updatedUrl,
      headers: {
        'Authorization': `Bearer ${authToken}`  // Ensure authToken is defined and available
      }
    });

    return response.data;

  } catch (error) {
    console.error('Error submitting event data:', error);
    throw error;
  }
};


export const deleteEvent = async (id) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.ORG_EVENTS_LIST_DELETE + id, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    if (response.status === 200) {
    return response;
    }
  } catch (error) {
    console.error('Error deleting ticket:', error);
    throw error;
  }
};

 

export const updateEvent = async (formData, event_id) => {
  try {
    
    const updatedUrl = API_ENDPOINTS.UPDATE_SINGLE_EVENT.replace(':id', event_id);

    const authToken = localStorage.getItem('authToken');
    const response = await axios({
      method: 'post',
      url: updatedUrl,
      headers: {
        'Authorization': `Bearer ${authToken}`  // Ensure authToken is defined and available
      },
      data: formData
    });

    return response.data;

  } catch (error) {
    console.error('Error submitting event data:', error);
    throw error;
  }
};

export const EventGalleryFetch = async (id) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(`${API_ENDPOINTS.ORG_EVENTS_FETCH_GALLERY}${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
 
export const EventGallerySubmit = async (formData) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS. ORG_EVENTS_Submit_GALLERY, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data' // Assuming formData is multipart/form-data
      }
    });
    return response;
  } catch (error) {
    console.error('event error:', error);
    throw error;
  }
};

export const deleteEventGallery = async (eventId,imageName) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.ORG_DELETE_ADMIN_GALLERY +imageName+ '/' + eventId, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error deleting gallery:', error);
    throw error;
  }
};
