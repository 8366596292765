import React from "react";
import { AppUserListFetch,Delete_AppUser } from "../../../services/admin/appUserservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import SEO from "../../../hooks/seo";
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";

import { API_ENDPOINTS } from '../../../services/admin/api';

const UsersList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);

    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
    


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const toggleDropdown = (id) => {
      setOpenDropdownId((prevId) => (prevId === id ? null : id));
    };


      const fetchData = async () => {
            setLoading(true);
            try {
                const response = await AppUserListFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
          }
          setPageLoading(true);
        };


    useEffect(() => {
      

        fetchData();
    }, [currentPage, searchText]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    

    const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_APP_USER_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
 
    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_APP_USER_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };


    const onButtonClickCreate = () => {
        navigate('/admin/app-user/create');
    }


        const showDeleteConfirmation = async (callback) => {
    try {
        const result = await Swal.fire({
            title: 'Are you sure to update this user?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, update it!',
        });

        if (result.isConfirmed) {
            await callback();
            Swal.fire('Update!', 'User Status Update.', 'success');
        }
    } catch (error) {
        console.error('Error in showDeleteConfirmation:', error);
        Swal.fire('Error!', 'Failed User Status Update.', 'error');
    }
};


    const handleDeleteClick = async (id) => {
    showDeleteConfirmation(async () => {
        try {
             
            const response = await Delete_AppUser(id);
            if (response.status === 200) {
              Swal.fire('Update!', 'User Status Update.', 'success');
             setOpenDropdownId(null);
                fetchData();  
            } else {
                Swal.fire('Error!', 'Failed to  User Status Update.', 'error');
                fetchData(); 
                setOpenDropdownId(null);
            }
        } catch (error) {
            fetchData(); 
            setOpenDropdownId(null);
            console.error('Error deleting item:', error);
            Swal.fire('Error!', error.message, 'error');
        }
    });
};

 if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    return (
        <>
        <SEO
        title="App Users List - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="App Users List"
                onButtonClick={onButtonClickCreate}
                showCreateButton={true}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="App Users"
                        onSearchChange={handleSearchChange}
                         showSearch={true}
                        showExportButtons={true}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                    
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                   <h1 className="xl text-3xl">No records found.</h1>

                                </div>
                            ) : (
                                <table className="is-hoverable w-full text-left">
                                    <thead>
                                        <tr>
                                            <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Image
                                            </th>
                                            <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                User Id
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Name
                                            </th>
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Email
                                            </th> 
                                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Status
                                            </th>
                                            <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                            >
                                                
                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 

                                                {item.image !== null ? (
                                                      <img src={`https://api.tikkets.com/public/images/upload/${item.image}`} alt="Item Image" className="mr-3 w-12 h-12 object-cover rounded-full" />

                                                    ) : (
                                                      

                                                      <img src="`https://api.tikkets.com/public/images/upload/defaultuser.png" alt="Item Image" className="mr-3 w-12 h-12 object-cover rounded-full" />
                                                    )}

 
                                                 
                                                </td>

                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    #{item.user_id}
                                                </td>

                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.name || item.last_name ? `${item.name || ''} ${item.last_name || ''}`.trim() || 'N/A' : 'N/A'}
                                                </td>
                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    {item.email}
                                                </td>

                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    
                                                     {item.status === 1 ? (
                                                    <div className="badge space-x-2.5 px-0 text-success">
                                                    <div className="size-2 rounded-full bg-current" />
                                                    <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div className="badge space-x-2.5 px-0 text-error">
                                                            <div className="size-2 rounded-full bg-current" />
                                                            <span>Inactive</span>
                                                            </div>
                                                )}

                                                </td>

                                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                    
                                                      <td className="whitespace-nowrap px-4 py-3 sm:px-5 relative">
                                          <div className="relative inline-block text-left">
                                            <button
                                              onClick={() => toggleDropdown(item.id)}
                                              className="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" className="size-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
                                              </svg>
                                            </button>
                                            {openDropdownId === item.id && (
                                                <div className="dropdown-content absolute bg-white shadow-lg rounded mt-2 z-10" style={{ left: '-120%', width: '150px' }}>
                                                  <ul className="list-none p-0">
                                                    <li className="mb-1">
                                                      <Link to={`/admin/view-user/${item.id}`} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">View User</Link>
                                                    </li>
                                                     
                                                    <li className="mb-1">
                                                      

                                                         <Link 
                                                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200" 
                                                             onClick={() => handleDeleteClick(item.id)}>
                                                             Update Status
                                                        </Link>
                                                    </li>
                                                  </ul>
                                                </div>

                                            )}
                                          </div>
                                          
                                </td>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                               
                        )}
                                <Pagination
                                    totalRecords={totalRecords}
                                    perPage={recordsPerPage}
                                    onPageChange={handlePageChange}
                                />
                          </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default UsersList;
