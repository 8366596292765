import React from "react";
import { CashOrganizationReportFetch } from  "../../../services/admin/cash-report-service";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef} from "react";
import { formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import useResizeObserver from "../../../hooks/useResizeObserver";

const OrganizationReportCash = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    
        {/* useResizeObserver Start  */ }
        const [size, setSize] = useState({ width: 0, height: 0 });
        const ref = useRef(null);
        // Implement the callback function
        const handleResize = (entries) => {
            const { width, height } = entries[0].contentRect;
            setSize({ width, height });
        };
        // Use the custom hook
        useResizeObserver(ref, handleResize);
        {/* useResizeObserver END  */ }



    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await CashOrganizationReportFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, searchText]);
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchText(searchText);
          setCurrentPage(1);
        }, 300); // Adjust the delay as needed
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
        const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_CASH_ORGANIZATION_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
 
    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_CASH_ORGANIZATION_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
    return (
        <>
        <SEO
        title="Organization Report - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Organization Report"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Organization Report Rows"
                        onSearchChange={handleSearchChange}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                        showSearch={true}
                        showExportButtons={true}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                             {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Organization Name
                                        </th>
                                        <th className="whitespace-nowrap text-center bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Phone
                                        </th>
                                        <th className="whitespace-nowrap text-center  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Total Events
                                        </th>
                                        <th className="whitespace-nowrap text-center  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Tickets / Sold
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 text-center px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Registered at
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-b border-gray-200 dark:border-b-navy-500 hover:bg-gray-100 dark:hover:bg-gray-800 transition"
                                        >
                                            <td className="px-6 py-4">
                                                <div className="flex items-center">
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-900">
                                                            {item.first_name + ' ' + item.last_name || 'N/A'}
                                                        </p>
                                                        <p className="text-xs text-gray-500">{item.email}</p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 text-center">
                                                {item.phone || 'NULL'}
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                {item.total_events}
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                {item.sold_tickets}
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                {formatDate(item.created_at)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                                         )}
                         <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                                        </div>
                                    
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default OrganizationReportCash;
