import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { AgentAllCategory } from "../../../services/agent/agentPosService";
import './CategorySwiper.css';
import CustomLoader from '.././customloader';
import { Helmet } from 'react-helmet';
const UserPageComponent = ({ userDataInfo, pageNumber, setpageNumber }) => {

  
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [categories, setcategories] = useState([]);
  const [events, setEvents] = useState([]);
 
  

  const handleConfirm = () => {
    setpageNumber(3);
    localStorage.setItem("AgentuserId", userDataInfo.id);
    localStorage.setItem("AgentSellTicket_userInfo", JSON.stringify(userDataInfo));
  };


  const handleCancel = () => {
    setpageNumber(1);
  }



  useEffect(() => {
    // Set a timer to remove loader after 2 seconds
    const timer = setTimeout(() => {
       setLoading(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);


  return (
    <>
  <Helmet>

  <link rel="stylesheet" href="/css/userInfo.css" />
  </Helmet>
 

  {loading ? (
 
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      
    ) : (
  // <section className="vh-100">
  // <div className="container py-5 h-100">
  // <div className="row d-flex">
  // <div className="col col-lg-6 mb-4 mb-lg-0 mx-auto">
  // <div className="card mb-3" style={{ borderRadius: '.5rem' }}>
  //   <div className="row g-0">
  //     <div
  //       className="col-md-4 text-center text-white "
  //       style={{
  //         borderTopLeftRadius: '.5rem',
  //         borderBottomLeftRadius: '.5rem',
  //         background: 'linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))',
       
  //       }}
  //     >
  //       <img
  //         src={userDataInfo.imagePath + userDataInfo.image}
  //         alt="Avatar"
  //         className="img-fluid  mx-auto"
  //         style={{ width: '125px',
  //           height: '125px',
  //           borderRadius: '100%', 
  //           marginTop:"40px",
  //           objectFit:'fill'
  //          }}
  //       />
  //       <h5 className="mt-2">{userDataInfo.name} {userDataInfo.last_name}</h5>
  //       <p>User ID: {userDataInfo.user_id}</p>
         
  //     </div>
  //     <div className="col-md-8">
  //       <div className="card-body p-4">
  //         <h6>User information</h6>
  //         <hr className="mt-0 mb-4" />
  //         <div className="row pt-1">
  //           <div className="col-6 mb-3">
  //             <h6>Email</h6>
  //             <p className="text-muted">{userDataInfo.email}</p>
  //           </div>
  //           <div className="col-6 mb-3">
  //             <h6>Phone</h6>
  //             <p className="text-muted">{userDataInfo.phone}</p>
  //           </div>
  //         </div>
        
          
          
  //         <div className="mt-6 grid-cols-12 flex justify-center">
  //           <button
  //             className="btn w-full sm:w-1/2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent"
  //             onClick={handleConfirm}
  //           >
  //             Confirm
  //           </button>
  //           <button
  //             className="ms-0 ms-3 btn w-full sm:w-1/2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450"
  //             onClick={handleCancel}
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  // </div>
  // </div>
  // </div>
  // </section>
  <section className="profile-section">
  <div className="container">
    <div className="profile-card">
      <div className="profile-image-container">
        <img
          src={`${userDataInfo.imagePath}${userDataInfo.image}`}
          alt="Avatar"
          className="profile-image"
        />
        <h5 className="profile-name">{userDataInfo.name} {userDataInfo.last_name}</h5>
        <p className="profile-id">User ID: {userDataInfo.user_id}</p>
      </div>
      <div className="profile-info">
        <h6 className="info-title">User Information</h6>
        <hr className="divider" />
        <div className="info-details">
          <div className="info-item">
            <h6 className="info-label">Email</h6>
            <p className="info-text">{userDataInfo.email}</p>
          </div>
          <div className="info-item">
            <h6 className="info-label">Phone</h6>
            <p className="info-text">{userDataInfo.phone}</p>
          </div>
        </div>
        <div className="button-container">
          <button
            className="btn btn-confirm"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className="btn btn-cancel"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

   )}
   </>
 );


{/* <main class="main-content w-full px-[var(--margin-x)] pb-8">
  <div class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800">
  <img class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-auto md:mt-10 md:rounded-none md:rounded-s-lg sm:w-auto sm:h-44 sm:mt-10" id="image-userporfilecard" src={userDataInfo.imagePath + userDataInfo.image} alt />
    <div class="flex flex-col justify-between p-4 leading-normal">
      <h1 class="text-center text-3xl font-bold text-slate-700 dark:text-navy-100 py-5">A user was found !</h1>
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">{userDataInfo.name} {userDataInfo.last_name}</h5>
      <h3 class="pb-3 text-lg text-center font-medium text-slate-700 dark:text-navy-100">User ID: {userDataInfo.user_id}</h3>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-center">{userDataInfo.role}<br/>Is this the user you were looking for?</p>
      <div class="mt-6 grid-cols-12 flex justify-center">
        <button class="btn w-full sm:w-1/2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent" onClick={handleConfirm}>
          Confirm
        </button>
        <button class="ms-0 sm:ms-3 btn w-full sm:w-1/2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  </div>
</main> */}


    
};

export default UserPageComponent;
