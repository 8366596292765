import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ graphSelection, onDateChange }) => {
  const [selectedOption, setSelectedOption] = useState('months');

  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(new Date(currentYear, 11, 31));

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setStartDate(null);
    setEndDate(null);
    onDateChange(null, null, e.target.value); // Reset date values and notify parent
  };

  const handleRangeChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);

    // Check if both startDate and endDate are selected
    if (dates[0] && dates[1]) {
      onDateChange(dates[0], dates[1], selectedOption); // Notify parent of the selected values
    }
  };


  return (
    <div className='flex p-2'>
    <div>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
        Select Option:
        </label>
        <select
           className=" mt-1.5  rounded-lg border bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
          value={selectedOption} onChange={handleOptionChange}>
          <option value="days">Days</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>

     
    </div>
    <div className='ml-2'>
      {selectedOption === 'days' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Select Date Range:</label>
          <DatePicker
            selected={startDate}
            onChange={handleRangeChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className="mt-1 px-3 py-1   border  rounded-lg focus:outline-none focus:border-blue-500"

          />
        </div>
      )}
      {selectedOption === 'months' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Select Month Range:</label>
          <DatePicker
            selected={startDate}
            onChange={handleRangeChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selectsRange
            className="mt-1 px-3 py-1  border  rounded-lg focus:outline-none focus:border-blue-500"

          />
        </div>
      )}
      {selectedOption === 'years' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Select Year Range:</label>
          <DatePicker
            selected={startDate}
            onChange={handleRangeChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy"
            showYearPicker
            selectsRange
            className="mt-1 px-3 py-1   border  rounded-lg focus:outline-none focus:border-blue-500"

          />
        </div>
      )}
    </div>
  </div>
  );
};

export default CustomDatePicker;
