import React, { useState, useEffect,useRef } from "react";
import { AgentDetailList } from "../../../services/agentdetailservice";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { API_ENDPOINTS } from '../../../services/api';

const AgentOrderDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await AgentDetailList(currentPage, searchText ,id);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };

        fetchData();
    }, [currentPage, searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = `${API_ENDPOINTS.CSV_AGENT_SALE_DETAIL}/${id}?token=${encodeURIComponent(token)}&search=${encodeURIComponent(searchText)}`;
        window.location.href = urlWithToken;
    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = `${API_ENDPOINTS.PDF_AGENT_SALE_DETAIL}/${id}?token=${encodeURIComponent(token)}&search=${encodeURIComponent(searchText)}`;
        window.location.href = urlWithToken;

    };
    if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    const onButtonClickCreate = () => {
        alert('apply');
    }
    return (
        <>
        <SEO
        title="Agent Sale Detail - eticketing"
    />
        <main ref={ref} style={{ width: '100%', height: '100%'}}
             className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Agent Sale Detail"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
                onBackButtonClick={() => navigate(-1)}
                showBackButton
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Agent Sale Detail"
                        showSearch={true}
                    showExportButtons={true}
                        onSearchChange={handleSearchChange}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="mt-5">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            #ID
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Event Name
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Ticket Name
                                        </th>
                                         <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Ticket Number
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Ticket Price
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Customer Name
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 {item.id || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.event_name  || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                  {item.name  || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 {item.ticket_number || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            {item.currency_code} {item.local_currency_price.toFixed(2)}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.user_name || 'N/A'} {item.last_name || 'N/A'}
                                            </td>
                                             <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {formatDate(item.updated_at)}
                                            </td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default AgentOrderDetail;
