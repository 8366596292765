import React from "react";
import { SettlementReportFetch } from "../../../services/admin/customer-report-service";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import TableHeader from "../../../hooks/TableHeader";
import SEO from "../../../hooks/seo";
import { useState, useEffect, useRef } from "react";
import { formatCurrency } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import useResizeObserver from "../../../hooks/useResizeObserver";
import Select from 'react-select';

const SettlementReports = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [yesOrnot, setyesOrnot] = useState(false);
    const [text, setText] = useState('');
    const [events, setEvents] = useState([]);
    const [orgs, setOrg] = useState([]);
    const [users, setUsers] = useState([]);
    const [advanced_Search, setAdvancedSearch] = useState(false);

    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await SettlementReportFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);

                    setData(response.data);
                    const optionsEvent = response.events.map(event => ({
                        value: event.id,
                        label: event.name
                    }));

                    setEvents(optionsEvent);

                    const optionsOrg = response.orgs.map(event => ({
                        value: event.id,
                        label: event.first_name + event.last_name
                    }));

                    setOrg(optionsOrg);


                    const optionsUser = response.users.map(event => ({
                        value: event.id,
                        label: event.name + event.last_name
                    }));

                    setUsers(optionsUser);
                    console.log(response)
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, searchText]);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
            setCurrentPage(1);
        }, 300); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);
    const handleSearchChange = (e) => {

        setText(e.target.value);
    };
    const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_CARD_SETTLEMENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };

    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_CARD_SETTLEMENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }

    const handleAdvancedSearch = () => {

        setAdvancedSearch(!advanced_Search)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Get the form data
        const formData = new FormData(event.target);

        // Initialize an empty string for the query parameters
        let queryString = '&';

        // Loop through form data entries and append them to the query string
        for (const [name, value] of formData.entries()) {
            queryString += `${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
        }

        // Remove the trailing '&' character
        queryString = queryString.slice(0, -1);
        console.log(queryString);
        setSearchText(queryString); 

    };


    const handleReset = () => {
        setSearchText('');
    };

    const search_Text = () => {
        setSearchText(text);
        setyesOrnot(true);
    };
    const resetSearch = () => {
        setSearchText('');
        setText('');
        setyesOrnot(false);

        const inputElement = document.getElementById('inptserch');
        if (inputElement) {
            inputElement.value = ''; // Resetting the value of the input field
        }

    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            marginTop: '5px',
            boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0, 123, 255, 0.25)' : 'none',
        }),

        // You can define more styles for other components like value, singleValue, menu, etc.
    };

    return (
        <>
            <SEO
                title="Settlement Report - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
                <PageHeader
                    title="Settlement Report"
                    onButtonClick={onButtonClickCreate}
                    showCreateButton={false}
                />

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableHeader
                            //title="Customer Report Rows"
                            onSearchChange={handleSearchChange}
                            showSearch={true}
                            showExportButtons={true}
                            onExportCSV={handleExportCSV}
                            onExportPDF={handleExportPDF}
                            onText={yesOrnot}
                            onsearch_Text={search_Text}
                            onResetSearch={resetSearch}
                            advancedSearch={handleAdvancedSearch}
                            showAdvanceSearch={false}
                        />

                        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
                            {advanced_Search && (
                                <div className="col-span-12 lg:col-span-12 mt-4">
                                    <form onSubmit={handleFormSubmit} className="space-y-4">
                                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                                            <div className="mb-4 lg:mb-0">
                                                <label className="block">
                                                    <span className="mb-1">Events</span>
                                                    {/* <div className="flex items-center">  
                                                       
                                                    </div> */}

                                                    <Select
                                                        options={events}

                                                        isSearchable={true}
                                                        placeholder="Select Event"
                                                        name="event_id"
                                                        styles={customStyles}
                                                    />
                                                </label>
                                            </div>

                                            <div className="mb-4 lg:mb-0">
                                                <label className="block">
                                                    <span className="mb-1">Organization</span>
                                                    <Select
                                                        options={orgs}
                                                        isSearchable={true}
                                                        placeholder="Select Organization"
                                                        name="org"
                                                        styles={customStyles}
                                                    />
                                                </label>
                                            </div>

                                            <div className="mb-4 lg:mb-0">
                                                <label className="block">
                                                    <span className="mb-1">Customer</span>
                                                    <Select
                                                        options={users}
                                                        isSearchable={true}
                                                        placeholder="Select Customer"
                                                        name="user_id"
                                                        styles={customStyles}
                                                    />
                                                </label>
                                            </div>


                                            <div className="mb-4 lg:mb-0">
                                                <label className="block">
                                                    <span className="mb-1">Start Date</span>
                                                    <label class="relative flex">
                                                        <input

                                                            className=" mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            placeholder="Choose date..."
                                                            type="date"
                                                            name="start_date"
                                                        />

                                                    </label>
                                                </label>
                                            </div>


                                            <div className="mb-4 lg:mb-0">
                                                <label className="block">
                                                    <span className="mb-1">End Date</span>
                                                    <label class="relative flex">
                                                        <input

                                                            className=" mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                                            placeholder="Choose date..."
                                                            type="date"
                                                            name="end_date"
                                                        />

                                                    </label>
                                                </label>
                                            </div>




                                        </div>

                                        <div className="flex justify-end">
                                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white btn focus:bg-blue-700 ml-2">Apply</button>

                                            <button
                                                type="reset"
                                                className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </form>
                                </div>



                            )}
                        </div>

                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableTools
                            title="Settlement Report Rows"
                        // onSearchChange={handleSearchChange}
                        // onExportCSV={handleExportCSV}
                        // onExportPDF={handleExportPDF}
                        // showSearch={true}
                        // showExportButtons={true}
                        />
                        <div className="lg:mt-5 md:mt-5 mt-16">
                            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                                {loading ? (
                                    <div className="flex justify-center items-center">
                                        <div className="flex justify-center items-center h-96">
                                            <CustomLoader
                                                visible={true}
                                                height={80}
                                                width={80}
                                                color="#4fa94d"
                                                ariaLabel="tail-spin-loading"
                                                radius={1}
                                            />
                                        </div>
                                    </div>
                                ) : data.length === 0 ? (
                                    <div className="text-center text-gray-500 mt-8">
                                        <h1 className="xl text-3xl">No records found.</h1>
                                    </div>
                                ) : (
                                    <table className="is-hoverable w-full text-left">
                                        <thead>
                                            <tr>
                                                <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Organization Name
                                                </th>
                                                <th className="whitespace-nowrap text-center  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Total Orders
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Total Sales
                                                </th>
                                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Paid-to-Date
                                                </th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Balance Due
                                                </th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                    Action
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                                >
                                                    <td className="px-6 py-4">
                                                        <div className="flex items-center">
                                                            <div>
                                                                <p className="text-sm font-medium text-gray-900">
                                                                    {item.first_name + ' ' + item.last_name || 'N/A'}
                                                                </p>
                                                                <p className="text-xs text-gray-500">{item.email}</p>
                                                            </div>
                                                        </div>
                                                    </td>


                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                                        {item.total_orders}

                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">

                                                        {formatCurrency(
                                                            item?.total_commission,
                                                            item?.payment_type,
                                                            item?.currency?.symbol ?? 'US$'
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {formatCurrency(
                                                            item?.pay_commission,
                                                            item?.payment_type,
                                                            item?.currency?.symbol ?? 'US$'
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        {formatCurrency(
                                                            item?.organization_commission,
                                                            item?.payment_type,
                                                            item?.currency?.symbol ?? 'US$'
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                        <Link to={`/admin/view-settlement/card/${item.id}`}>
                                                            <svg class="h-6 w-6 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="2" />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                <Pagination
                                    totalRecords={totalRecords}
                                    perPage={recordsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SettlementReports;
