import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Scanner_Edit, UpdateScanner } from '../../../services/scannerServices';
import { toast, Toaster } from 'react-hot-toast';
import PageHeader from '../../../hooks/PageHeader';
import SEO from "../../../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CustomLoader from '../Loaderfiles/customloader';


const EditScanner = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch
  } = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }


  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const defaultCountry = 'US';
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };


  useEffect(() => {
    // Fetch the current phone number from the form values
    const currentPhoneNumber = watch('phone');
    // Update the phoneNumber state
    setPhoneNumber(currentPhoneNumber || '');
  }, [watch('phone')]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Scanner_Edit(id);
        if (response) {
          setValue('first_name', response.scanner.first_name);
          setValue('last_name', response.scanner.last_name);
          setValue('email', response.scanner.email);
          setValue('phone', response.scanner.phone);
          setValue('status', response.scanner.status);
        }
      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);

      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };

    fetchData();
  }, [id]);


  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const result = await UpdateScanner(formData, id);
      console.log(result,'result')
      if (result.status === 200) {
                //   toast.success('Submit Successfully', { position: 'top-right' });
        //    setTimeout(() => {
        //   navigate('/organization/scanners', { state: { successMessage: 'Submit Successfully' } });
        // }, 1000);

        Swal.fire({
          title: 'Good Job',
          text: result.data.msg,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/organization/scanners', { state: { message: 'Submitted Successfully' } });
          }
        });


      }  
    } catch (error) {
      console.error('Registration failed:', error);

      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage.email || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false 
      });
    } finally {
      setLoading(false);
    }
  };

  if (!pageLoading) {
    return (
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate(-1);
  }
  return (
    <>
      <SEO
        title="Create Scanner - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <PageHeader
          title="Edit Scanner"
          onBackButtonClick={onBackButtonClick}
          showBackButton={true}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 ">
          <Toaster position="top-right" />
          <div className="card px-4 pb-4 sm:px-5">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-5"
            >

              <div className="lg:flex mt-5 lg:pl-0 pl-5 ">
                <div className="lg:pr-2  w-1/2">
                  <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    {...register('first_name', { required: 'Please enter first name.' })}
                  />
                  {errors.first_name && <p className="text-red-500">{errors.first_name.message}</p>}
                </div>
                <div className="lg:pl-2  lg:mt-0 mt-8  w-1/2">
                  <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    {...register('last_name', { required: 'Please enter last name.' })}
                  />
                  {errors.last_name && <p className="text-red-500">{errors.last_name.message}</p>}
                </div>
              </div>
              <div className="lg:flex mt-8 lg:pl-0 pl-5">
                <div className="lg:pl-0 lg:pr-2 w-1/2">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    {...register('email', { required: 'Please enter email.' })}
                  />
                  {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                </div>
                <div className="lg:pl-2 w-1/2 lg:mt-0 mt-8">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  {/* <input */}
                  {/*   type="text" */}
                  {/*   id="phone" */}
                  {/*   name="phone" */}
                  {/*   placeholder="Phone" */}
                  {/*   className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" */}
                  {/*   {...register('phone', { required: 'Please enter phone number.' })} */}
                  {/* /> */}

                  <PhoneInput
                    className="form-input mt-1 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-1.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    placeholder="Enter Phone"
                    name="phone"
                    value={phoneNumber}
                    country={defaultCountry}
                    defaultCountry={defaultCountry}
                    onChange={(value) => {
                      if (value) {
                        setPhoneNumber(value);
                        setValue("phone", value);
                      }
                    }}
                    onCountryChange={handleCountryChange}
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
                </div>
              </div>

              <div className="mt-8 lg:pl-0 pl-5 relative">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="mt-1 p-2 lg:w-full w-80 border rounded-lg focus:outline-none focus:border-blue-500"
                    {...register('password')}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400 hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {errors.password && <p className="text-red-500">{errors.password.message}</p>}
              </div>
              <div className="mt-8 lg:pl-0 pl-5">
                <label className="block">
                  <span className="block text-sm font-medium text-gray-700">Status</span>
                  <select
                    className="mt-1 p-2 lg:w-full w-80 border  rounded-lg focus:outline-none focus:border-blue-500"
                    {...register('status')}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </label>
              </div>
              <div className="mt-8 lg:pl-0 pl-5 ">
                <button
                  type="submit"
                  className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${loading ? 'cursor-not-allowed' : ''}`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditScanner;
