import React, { useEffect, useState, useRef } from 'react';
import DefaultImage from '../../assets/images/200x200.png';
import logoImage from '../../assets/images/app-logo.svg';
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { Admin_profile } from "../../services/userservice";
import { useUser } from '../../hooks/UserContext';
import { Helmet } from 'react-helmet';
import { useSetting } from '../../hooks/SettingContext';


const Sidebar = () => {
  const { user } = useUser();
  const [profileWindow, setProfileWindow] = React.useState(false);
  const navigate = useNavigate();
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const dropdown1Ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
   const location = useLocation();
   const { settings, updateSettings } = useSetting();
   const [profileDropdown, setProfileDropdown] = useState(false);
  const toggleProfileBox = () => {
    setProfileDropdown(!profileDropdown);
  };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen_1, setIsOpen_1] = useState(false);
    const fetchData = async () => {
      try {
        const response = await Admin_profile();
        setLoading(false);
   
        setData(response.user)
  
  
      } catch (error) {
         
        //console.error("Error fetching data: ", error);
         
      } finally {
      }
    };
    useEffect(() => {
      fetchData();
    }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


   const closeDropdown = () => {
    setIsOpen(false);
  };

  const closeDropdown_1 = () => {
    setIsOpen_1(false);
  };

    const isActiveLink = (pathname) => location.pathname === pathname;

 const toggleDropdown_1 = () => {
    setIsOpen_1(!isOpen);
  };
  

//   function toggleProfileBox() {
//   var profileBox = document.getElementById('profile-box');
//   profileBox.classList.toggle('hidden'); // Assuming you have a 'hidden' class for hiding the element
// }


  const toggleProfileWindow = () => {
    setProfileWindow(prevState => !prevState);
  };
  const toggleSidebar = () => {
    const body = document.body;
    const sidebar = document.querySelector('.sidebar');
    body.classList.toggle('is-sidebar-open');
    sidebar.classList.toggle('is-sidebar-open');
  };
  const handleLogout = () => {
    logout(navigate);
  };
  const logout = (navigate) => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('permissions');
    navigate('/login');
  };
  
   

  useEffect(() => {
    if (isDropdownOpen1 && dropdown1Ref.current) {
      dropdown1Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isDropdownOpen1]);
  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
      existingFavicon.href = `https://api.tikkets.com/public/images/upload/${settings.favicon}`;
    }
  }, [settings.favicon]);
 
 

  return (
    <>
    <Helmet>
   <script>
     {`
       var existingFavicon = document.querySelector("link[rel*='icon']");
       if (existingFavicon) {
         existingFavicon.href = "${'https://api.tikkets.com/public/images/upload/'+ settings.favicon}";
       }
          `}
        </script>
      </Helmet>
      <div class="sidebar sidebar-panel print:hidden">
        <div
          class="flex h-full grow flex-col border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-750"
        >
            
              <div className="flex items-center px-4 pt-8 justify-between"> {/* Changed justify-center to justify-between */}
  <Link to="/organization/dashboard">
    <img
      className="w-24 md:w-40 object-cover"  
      src={`https://api.tikkets.com/public/images/upload/${settings.logo}`}
      alt="logo"
    />
  </Link>

              <button onClick={toggleSidebar} className="sidebar-close btn size-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
            </div>

          <div
            class="nav-wrapper mt-5 h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
            data-simplebar
          >
             
            <div class="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
            <ul className="flex flex-1 flex-col px-4 font-inter">
               
               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                  className={`nav-link gap05 flex items-center p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                      isActiveLink('/organization/dashboard') ? 'text-blue-600' : ''
                    }`} 
                    to="/organization/dashboard">
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_113_1435)">
                            <path d="M9.75 15.75H5.25C4.69772 15.75 4.25 16.1977 4.25 16.75V19.25C4.25 19.8023 4.69772 20.25 5.25 20.25H9.75C10.3023 20.25 10.75 19.8023 10.75 19.25V16.75C10.75 16.1977 10.3023 15.75 9.75 15.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.75154 3.75H5.24846C4.69703 3.75 4.25 4.19824 4.25 4.75118V11.2488C4.25 11.8018 4.69703 12.25 5.24846 12.25H9.75154C10.303 12.25 10.75 11.8018 10.75 11.2488V4.75118C10.75 4.19824 10.303 3.75 9.75154 3.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.25 8.25H19.75C20.3023 8.25 20.75 7.80228 20.75 7.25V4.75C20.75 4.19772 20.3023 3.75 19.75 3.75L15.25 3.75C14.6977 3.75 14.25 4.19772 14.25 4.75V7.25C14.25 7.80228 14.6977 8.25 15.25 8.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.25 20.25H19.75C20.3023 20.25 20.75 19.8023 20.75 19.25V12.75C20.75 12.1977 20.3023 11.75 19.75 11.75H15.25C14.6977 11.75 14.25 12.1977 14.25 12.75V19.25C14.25 19.8023 14.6977 20.25 15.25 20.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_113_1435">
                                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                        </defs>
                      </svg>
                   Dashboard
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                 className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/orders') ? 'text-blue-600' : ''
                }`} 
                 to="/organization/orders">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_304_23693)">
                        <path d="M5.61528 8.59201C5.6929 8.10688 6.11142 7.75 6.60272 7.75H17.3973C17.8886 7.75 18.3071 8.10688 18.3847 8.59201L20.0647 19.092C20.1619 19.6996 19.6926 20.25 19.0773 20.25H4.92272C4.30741 20.25 3.83807 19.6996 3.93528 19.092L5.61528 8.59201Z" stroke="#738196" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M8.75 12.25V6C8.75 4.21 10.21 2.75 12 2.75C13.79 2.75 15.25 4.21 15.25 6V12.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_304_23693">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                  </svg>

                 Orders
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light"
                  className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                    isActiveLink('/organization/scanners') ? 'text-blue-600' : ''
                  }`} 
                   to="/organization/scanners">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_304_23703)">
                      <path d="M20.25 15.75V19.25C20.25 19.8 19.8 20.25 19.25 20.25H15.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.75 8.25V4.75C3.75 4.2 4.2 3.75 4.75 3.75H8.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8.25 20.25H4.75C4.2 20.25 3.75 19.8 3.75 19.25V15.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.75 3.75H19.25C19.8 3.75 20.25 4.2 20.25 4.75V8.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.75 12H20.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_304_23703">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                 Scanners
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                 className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/events') ? 'text-blue-600' : ''
                }`} 
                 to="/organization/events">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_113_1427)">
                      <path d="M4.5 8.75H20.5" stroke="#738196" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                      <path d="M8.5 2.75V4.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 2.75V4.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <rect x="4.25" y="4.75" width="16.5" height="15.5" rx="1" stroke="#738196" stroke-width="1.5"/>
                      <circle cx="8.5" cy="16.5" r="1" fill="#738196"/>
                      <circle cx="12.5" cy="16.5" r="1" fill="#738196"/>
                      <circle cx="16.5" cy="16.5" r="1" fill="#738196"/>
                      <circle cx="16.5" cy="13" r="1" fill="#738196"/>
                      <circle cx="12.5" cy="13" r="1" fill="#738196"/>
                      <circle cx="8.5" cy="13" r="1" fill="#738196"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_113_1427">
                      <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                 Events
                 </Link> 
               </li>
               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                 className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/gallery') ? 'text-blue-600' : ''
                }`} 
                 to="/organization/gallery">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_304_23709)">
                      <path d="M20.25 4.75H3.75C3.19772 4.75 2.75 5.19772 2.75 5.75V18.25C2.75 18.8023 3.19772 19.25 3.75 19.25H20.25C20.8023 19.25 21.25 18.8023 21.25 18.25V5.75C21.25 5.19772 20.8023 4.75 20.25 4.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M21 18.91L16.54 11.75L13.79 16.14" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.7002 19.2502L9.23023 8.75L2.99023 18.9002" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_304_23709">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                 Gallery
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light"
                 className={`nav-link gap05 flex items-center p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/complimentary-tickets/create') ? 'text-blue-600' : ''
                }`}
                  to="/organization/complimentary-tickets/create">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_304_23705)">
                        <path d="M18.41 10.41C18 10.82 17.75 11.38 17.75 12C17.75 13.24 18.76 14.25 20 14.25H21.25V18.25C21.25 18.8 20.8 19.25 20.25 19.25H3.75C3.2 19.25 2.75 18.8 2.75 18.25V14.25H4C4.62 14.25 5.18 14 5.59 13.59C6 13.18 6.25 12.62 6.25 12C6.25 10.76 5.24 9.75 4 9.75H2.75V5.75C2.75 5.2 3.2 4.75 3.75 4.75H20.25C20.8 4.75 21.25 5.2 21.25 5.75V9.75H20C19.38 9.75 18.82 10 18.41 10.41Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <line x1="10.75" y1="4.75" x2="10.75" y2="7.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
                        <line x1="10.75" y1="10.75" x2="10.75" y2="13.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
                        <line x1="10.75" y1="16.75" x2="10.75" y2="19.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_304_23705">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                 Complimentary Tickets
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                className={`nav-link gap05 flex items-center p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/agent-sale') ? 'text-blue-600' : ''
                }`}
                 to="/organization/agent-sale">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_113_1391)">
                      <rect x="3.25" y="4.75" width="18.5" height="14.5" rx="1" stroke="#738196" stroke-width="1.5"/>
                      <path d="M13.25 12C13.25 10.2051 14.7051 8.75 16.5 8.75H21.75V15.25H16.5C14.7051 15.25 13.25 13.7949 13.25 12V12Z" stroke="#738196" stroke-width="1.5"/>
                      <circle cx="16.5" cy="12" r="1" fill="#738196"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_113_1391">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                 Agent Sale
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                 className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/event-codes') ? 'text-blue-600' : ''
                }`}
                 to="/organization/event-codes">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_305_27941)">
                      <path d="M2.75 5.75C2.75 5.19771 3.19772 4.75 3.75 4.75H20.25C20.8023 4.75 21.25 5.19772 21.25 5.75V18.25C21.25 18.8023 20.8023 19.25 20.25 19.25H3.75C3.19772 19.25 2.75 18.8023 2.75 18.25V5.75Z" stroke="#738196" stroke-width="1.5" stroke-linejoin="round"/>
                      <path d="M15.75 13.25H18.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8.25 13.25L5.75 10.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5.75 13.25L8.25 10.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.25 13.25L10.75 10.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.75 13.25L13.25 10.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_305_27941">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  Private Access Codes
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" 
                 className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/event-codes-list') ? 'text-blue-600' : ''
                }`}
                 to="/organization/event-codes-list">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_304_23721)">
                      <path d="M10.75 4.75L5.25 10.25L2.75 7.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.75 13.75L5.25 19.25L2.75 16.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.75 8H21.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.75 17H21.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_304_23721">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  Private Access Codes  List
                 </Link>
               </li>

               <li>
                 <Link  data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" data-active-class="font-medium text-primary dark:text-accent-light" className={`nav-link gap05 flex items-center  p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                  isActiveLink('/organization/scanList') ? 'text-blue-600' : ''
                }`} to="/organization/scanList">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_305_27935)">
                      <path d="M10.02 13.5H7.23C6.9649 13.5 6.75 13.7149 6.75 13.98V16.77C6.75 17.0351 6.9649 17.25 7.23 17.25H10.02C10.2851 17.25 10.5 17.0351 10.5 16.77V13.98C10.5 13.7149 10.2851 13.5 10.02 13.5Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.02 6.75H7.23C6.9649 6.75 6.75 6.9649 6.75 7.23V10.02C6.75 10.2851 6.9649 10.5 7.23 10.5H10.02C10.2851 10.5 10.5 10.2851 10.5 10.02V7.23C10.5 6.9649 10.2851 6.75 10.02 6.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.77 6.75H13.98C13.7149 6.75 13.5 6.9649 13.5 7.23V10.02C13.5 10.2851 13.7149 10.5 13.98 10.5H16.77C17.0351 10.5 17.25 10.2851 17.25 10.02V7.23C17.25 6.9649 17.0351 6.75 16.77 6.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.41 12.75H14.5V14.5H12.75V13.41C12.75 13.05 13.04 12.75 13.41 12.75Z" fill="#738196"/>
                      <path d="M16.25 12.75H17.34C17.7 12.75 18 13.04 18 13.41V14.5H16.25V12.75Z" fill="#738196"/>
                      <path d="M16.25 14.5H12.75V16.25H16.25V14.5Z" fill="#738196"/>
                      <path d="M12.75 16.25H14.5V18H13.41C13.05 18 12.75 17.71 12.75 17.34V16.25Z" fill="#738196"/>
                      <path d="M16.25 16.25H18V17.34C18 17.7 17.71 18 17.34 18H16.25V16.25Z" fill="#738196"/>
                      <path d="M20.25 15.75V19.25C20.25 19.8 19.8 20.25 19.25 20.25H15.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.75 8.25V4.75C3.75 4.2 4.2 3.75 4.75 3.75H8.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8.25 20.25H4.75C4.2 20.25 3.75 19.8 3.75 19.25V15.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.75 3.75H19.25C19.8 3.75 20.25 4.2 20.25 4.75V8.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_305_27935">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                 Scanned Tickets
                 </Link>
               </li>
             </ul> 

          </div>
        </div>
      </div>

      {/* App Header Wrapper*/}
      <nav className="header print:hidden">
        {/* App Header  */}
        <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
          {/* Header Items */}
          <div className="flex w-full items-center justify-between">
            {/* Left: Sidebar Toggle Button */}
            <div className="size-7">
              <button onClick={toggleSidebar}
                className="sidebar-toggle ml-0.5 flex size-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80">
                <span />
                <span />
                <span />
              </button>
            </div>
            {/* Right: Header buttons */}
            <div className="-mr-1.5 flex items-center space-x-2">
              {/* Mobile Search Toggle */}
              <button className="mobile-searchbar-show btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="size-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
              
              <button
            id="profile-ref"
            className="avatar size-10"
            onClick={toggleProfileBox}
          >
            <img className="rounded-full"
                  src={user ? user : (data.imagePath ? data.imagePath : DefaultImage)}
                  alt="avatar" />
            <span className="absolute right-0 size-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
          </button>
      {profileDropdown && (
        <div className="absolute top-full right-0 w-48 bg-white border border-slate-150 shadow-md dark:bg-navy-700 dark:border-navy-600">
          <ul className="py-2">
            <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
            <Link to="/organization/profile" className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600">
                        <div className="flex size-8 items-center justify-center rounded-lg bg-warning text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" className="size-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg>
                        </div>
                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            Profile
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Your profile setting
                          </div>
                        </div>
                      </Link>
            </li>
             <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
            <button
            onClick={handleLogout}
            className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
            <svg xmlns="http://www.w3.org/2000/svg" className="size-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span>Logout</span>
            </button>
            </li>
           
          </ul>
        </div>
      )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;