import React from 'react';
import {Link, useNavigate } from 'react-router-dom';
const NotFoundPage = () => {
        const navigate = useNavigate();
     const handleGoBack = () => { 
 navigate(-1);
  };
    return (
    
         <main className="grid w-full grow grid-cols-1 place-items-center">
        <div className="max-w-2xl p-6 text-center">
          <div className="w-full max-w-md">
            <img
              className="w-full"
              id="hero-image-light"
              src="images/illustrations/error-404.svg"
              alt="image"
            />
             
          </div>
          <p
            className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50"
          >
            Oops. This Page Not Found.
          </p>
          <p className="pt-2 text-slate-500 dark:text-navy-200">
            This page you are looking not available. Please back to home
          </p>
          <Link
            onClick={handleGoBack}
            className="btn mt-8 h-11 bg-primary text-base font-medium text-white hover:bg-primary-focus hover:shadow-lg hover:shadow-primary/50 focus:bg-primary-focus focus:shadow-lg focus:shadow-primary/50 active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:hover:shadow-accent/50 dark:focus:bg-accent-focus dark:focus:shadow-accent/50 dark:active:bg-accent/90"
            >
            Back To Previous Page
            </Link>
        </div>
      </main>
    );
};

export default NotFoundPage;
