import React from "react";
import { VeiwEventListFetch } from "../../../services/eventservice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from "../Loaderfiles/customloader";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";

function VeiwOrganizerEvent() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [recentSales, setRecentSales] = useState([]);
  const [scanningList, setScanninglist] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listCurrentPage, setListCurrentPage] = useState(1);
  const [slug, setSlug] = useState("");
  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const prevListCurrentPageRef = useRef(listCurrentPage);
  const [pageLoading, setPageLoading] = useState(false);
  const [CardData, setcard] = useState([]);
  const [TicketData, setTicket] = useState([]);
  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await VeiwEventListFetch(
        currentPage,
        searchText,
        id,
        listCurrentPage
      );
      const slg = nameToSlug(response.event.name);
      setSlug(slg);

      if (response) {
        //setTotalRecords(response.event.sales.total);
        //settotalRecordsScanList(response.orderChild.total);
        const first10Sales = response.event.sales.slice(0, 10);
        const first10Orders = response.orderChild.slice(0, 10);
        setData(response.event);
        setRecentSales(first10Sales);
        setScanninglist(first10Orders);
        setcard(response);
        setTicket(response.results);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  useEffect(() => {
    if (
      currentPage !== prevPageRef.current ||
      debouncedSearchText !== prevSearchTextRef.current ||
      listCurrentPage !== prevListCurrentPageRef.current
    ) {
      fetchData();
    }
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
    prevListCurrentPageRef.current = listCurrentPage;
  }, [currentPage, debouncedSearchText, listCurrentPage]);

  // const handlePageChange = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  // };

  // const ScanListhandlePageChange = (pageNumber) => {
  //     setListCurrentPage(pageNumber);
  // };

  // const handleSearchChange = (e) => {
  //     setSearchText(e.target.value);
  // };
  // const handleExportCSV = () => {
  //     alert('CSV Export');
  // };

  function nameToSlug(name) {
    const slug = name
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with dashes
      .replace(/[^\w-]/g, ""); // Remove non-word characters

    return slug;
  }

  const handleExportPDF = () => {
    alert("PDF Export");
  };

  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate("/organization/events");
  };
  const onEditButtonClick = () => {
    navigate(`/organization/events/${id}`);
  };
  return (
    <>
      <SEO title="View Organizer Event - eticketing" />

      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="">
          <PageHeader
            showBackButton={true}
            showEditButton={true}
            onEditButtonClick={onEditButtonClick}
            onBackButtonClick={onBackButtonClick}
          />
          <div className="lg:grid lg:grid-cols-12 gap-4">
            <div className="lg:col-span-8 ">
              <div className="rounded-md pt-6 my-auto">
                {loading ? (
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={50}
                      color="#4FA94D"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                ) : (
                  <>
                    <div class="bg-white border rounded-md border-white lg:ml-0 ml-2  md:ml-8 lg:mr-0 mr-2  md:mr-4 p-6   px-6  my-auto">
                      <div class="grid grid-cols-12 gap-2">
                        <div class="col-span-8">
                          <h1 className="text-xl font-medium text-slate-900 dark:text-navy-50 lg:text-2xl flex-grow">
                            {data.name}
                          </h1>
                        </div>
                        <div class="col-span-4 flex justify-end">
                          <Link
                            to={`/organization/events-tickets/${id}/${slug}/tickets`}
                            className="btn bg-success font-medium mb-6 text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                          >
                            Manage Tickets
                          </Link>
                        </div>
                      </div>
                      <img
                        class="mt-5 h-90 w-full rounded-lg object-cover object-center"
                        src={data.imagePath + data.image}
                        alt="image"
                      />
                      <div>
                        <p className="lg:mt-6 md:mt-6 mt-6">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.description,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div class="mt-6 grid grid-cols-3 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-3">
                      <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                        <div class="flex justify-between space-x-1">
                          <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            People allowed
                          </p>
                          <svg
                            className="h-6 w-6 text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                        <p class="mt-1 text-xs+">{data.people || "NULL"}</p>
                      </div>
                      <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                        <div class="flex justify-between space-x-1">
                          <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            Date
                          </p>
                          <svg
                            class="h-6 w-6 text-gray-500"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <rect x="4" y="5" width="16" height="16" rx="2" />{" "}
                            <line x1="16" y1="3" x2="16" y2="7" />{" "}
                            <line x1="8" y1="3" x2="8" y2="7" />{" "}
                            <line x1="4" y1="11" x2="20" y2="11" />{" "}
                            <rect x="8" y="15" width="2" height="2" />
                          </svg>
                        </div>
                        <p class="mt-1 text-xs+">
                          {formatDate(data.updated_at) || "NULL"}
                        </p>
                      </div>
                      <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                        <div class="flex justify-between space-x-1">
                          <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                            Location
                          </p>
                          <svg
                            class="h-6 w-6 text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>
                        <p class="mt-1 text-xs+"> {data.address || "NULL"}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex  rounded-md mt-6 my-auto">
                <p className="text-xl text-black">Recent Sales</p>
                <Link
                  to={`/organization/eventOrders/${id}`}
                  className="ml-auto btn bg-info text-white"
                >
                  View All
                </Link>
              </div>

              <div className="rounded-md pb-6 my-auto">
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto mt-5">
                  {loading ? (
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={50}
                        color="#4FA94D"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  ) : (
                    <>
                      <table className=" w-full text-left">
                        <thead>
                          <tr>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Order Id
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Customer Name
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Ticket Name
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Date
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Sold Ticket
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Order Amount
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Payment
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Service Charges
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              Org Vat
                            </th>
                            <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              PAYMENT METHOD
                            </th>
                            {/* <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                                        Payment
                                                    </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {recentSales.length === 0 ? (
                            <tr>
                              <td
                                colSpan="7"
                                className="text-center text-gray-500 py-8"
                              >
                                No records found.
                              </td>
                            </tr>
                          ) : (
                            <>
                              {recentSales.map((sale, index) => (
                                <tr key={index}>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {sale.order_id}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {sale.customer.name}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {sale.ticket.name}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {formatDate(sale.updated_at)}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {sale.quantity + " Tickets"}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                    {sale.curruncry === "JMD"
                                      ? "JMD$"
                                      : sale.curruncry}{" "}
                                    {(sale.sub_total ?? 0).toFixed(2)}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                    {sale.curruncry === "JMD"
                                      ? "JMD$"
                                      : sale.curruncry}{" "}
                                    {(sale.order_price ?? 0).toFixed(2)}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                    {sale.curruncry === "JMD"
                                      ? "JMD$"
                                      : sale.curruncry}{" "}
                                    {(sale.admin_commission ?? 0).toFixed(2)}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                    {sale.curruncry === "JMD"
                                      ? "JMD$"
                                      : sale.curruncry}{" "}
                                    {(sale.org_vat ?? 0).toFixed(2)}
                                  </td>

                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                    <span className="badge bg-success/10 text-success dark:bg-success/15">
                                      {sale?.payment_type}
                                    </span>
                                  </td>

                                  {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                                {sale.curruncry === 'JMD' ? 'J$' : sale.curruncry} {(sale.payment ?? 0).toFixed(2)}
                                                                </td> */}
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  {/* <Pagination
                                 totalRecords={totalRecords}
                                 perPage={recordsPerPage}
                                 onPageChange={handlePageChange}
                             /> */}
                </div>
              </div>

              <div className="aaaaa">
                <div className="flex  rounded-md  my-auto">
                  <p className="text-xl text-black">Scanning List</p>
                  <Link
                    to={`/organization/scanList/${id}`}
                    className="ml-auto btn bg-info text-white"
                  >
                    View All
                  </Link>
                </div>
                <div className="rounded-md my-auto">
                  <div className="is-scrollbar-hidden min-w-full overflow-x-auto mt-5">
                    {loading ? (
                      <div className="flex justify-center items-center h-96">
                        <CustomLoader
                          visible={true}
                          height={80}
                          width={50}
                          color="#4FA94D"
                          ariaLabel="tail-spin-loading"
                          radius={1}
                        />
                      </div>
                    ) : (
                      <>
                        <table className="w-full text-left">
                          <thead>
                            <tr>
                              <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Order Id
                              </th>
                              <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Customer Name
                              </th>
                              <th className="whitespace-nowrap bg-gray-100 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {scanningList.length === 0 ? (
                              <tr>
                                <td
                                  colSpan="7"
                                  className="text-center text-gray-500 py-8"
                                >
                                  No records found.
                                </td>
                              </tr>
                            ) : (
                              <>
                                {scanningList.map((scan, index) => (
                                  <tr key={index}>
                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                      #{scan.order_id}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                      {scan.name}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                      {formatDate(scan.updated_at)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                    {/* <Pagination
                                 totalRecords={totalRecordsScanList}
                                 perPage={recordsPerPage}
                                 onPageChange={ScanListhandlePageChange}
                                 currentPage={listCurrentPage}
                             /> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-4">
              <div class="rounded-md lg:ml-4 ml-2 py-6 my-auto">
                <div class="col-span-12 lg:col-span-4">
                  <div class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
                    <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                      <div class="flex justify-between space-x-1">
                        <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                          {"USD$ " + (CardData.total_card_sum ?? 0).toFixed(2)}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-5 text-primary dark:text-accent"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <p class="mt-1 text-xs">Total Card Sales</p>
                    </div>
                    <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                      <div class="flex justify-between">
                        <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                          {data?.currency?.symbol}{" "}
                          {(CardData.total_local_sum ?? 0).toFixed(2)}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-5 text-success"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </div>
                      <p class="mt-1 text-xs">
                        Total {data?.currency?.symbol} Sales
                      </p>
                    </div>
                  </div>
                  {TicketData.length === 0 ? (
                    <div className="col-span-1 sm:col-span-2 text-center mt-6">
                      <div className="bg-slate-150 p-8 rounded-lg dark:bg-navy-700 mb-6 flex flex-col items-center">
                        <p className="text-lg font-semibold text-slate-700 dark:text-navy-100">
                          No tickets available
                        </p>
                        <Link to={`/organization/${id}/ticket/create`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-circle mt-4"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    TicketData.map((ticket, index) => (
                      <div class="mt-6 mb-6">
                        <h3 className="text-xl font-semibold text-slate-700 dark:text-navy-100 text-center mb-2">
                          {ticket.name} | ${ticket.price}
                        </h3>

                        <div class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
                          <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                            <div class="flex justify-between space-x-1">
                              <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                                {ticket.paid_tickets}/{ticket.quantity}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="size-5 text-primary dark:text-accent"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                            </div>
                            <p class="mt-1 text-xs+">Paid USD$ Tickets</p>
                          </div>
                          <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                            <div class="flex justify-between">
                              <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                                {ticket.cash_tickets}/{ticket.quantity}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="size-5 text-success"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                ></path>
                              </svg>
                            </div>
                            <p class="mt-1 text-xs+">
                              Paid {data?.currency?.code}$ Tickets
                            </p>
                          </div>
                          <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                            <div class="flex justify-between">
                              <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                                {(ticket.card_sales ?? 0).toFixed(2)}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="size-5 text-warning"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                            </div>
                            <p class="mt-1 text-xs+">Card USD$ Sales</p>
                          </div>
                          <div class="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                            <div class="flex justify-between">
                              <p class="text-xl font-semibold text-slate-700 dark:text-navy-100">
                                {data?.currency?.symbol}{" "}
                                {(ticket.cash_sales ?? 0).toFixed(2)}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="size-5 text-info"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                ></path>
                              </svg>
                            </div>
                            <p class="mt-1 text-xs+">
                              Cash {data?.currency?.symbol} Sales
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default VeiwOrganizerEvent;
