import React, { useState,useRef} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { submitFeedback } from "../../../services/admin/feedbackservice";
import { useNavigate } from 'react-router-dom';
import PageHeader from "../../../hooks/PageHeader";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SEO from "../../../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";



const schema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  message: yup.string().required('Message is required'),
});

const CreateFeedback = () => {
  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [rate, setRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  const handleRateClick = (rating) => {
    setRate(rating);
  };

  const handleEditorChange = (content) => {
    setValue('message', content);
  };

  const onSubmit = async (data) => {
 
   setIsLoading(true);
    try {
      const apiResponse = await submitFeedback({
        ...data,
        rate,
      })


        Swal.fire({
          title: 'Good Job',
          text: apiResponse.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              navigate('/admin/feedback', { state: { message: apiResponse.msg } });
            }
          });


      if (apiResponse.status === 200) {
       console.log('API response:', apiResponse);
        
       
      }
    } catch (error) {
       const errorMessage = error && error.response && error.response.error
          ? error.response.error
          : 'An error occurred while submitting the role. Please try again.';
        
          
         Swal.fire({
          title: 'Error',
          text: errorMessage || 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });
    } finally {
      setIsLoading(false);
    }
  };
    const onBackButtonClick = () => {
        navigate('/admin/feedback');
    }
  return (
    <>
    <SEO
    title="Add Feedback - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
       <PageHeader
              title="Add Feedback"
              showBackButton={true}
              onBackButtonClick={onBackButtonClick}
              />
      <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12 lg:col-span-12">
          <div className="card bg-white dark:bg-gray-800 p-4 sm:px-5 border rounded-lg">
            <div className="border-b border-slate-200 dark:border-navy-500 pb-4">
            
            <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add Feedback
                    </h4>
                  </div>
                  

                </div>

            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mt-4">
                <input type="hidden" name="rate" value={rate} />
                <div className="rating flex items-center">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <i
                      key={index}
                      className={`fas fa-star text-2xl cursor-pointer ${rate >= index ? 'text-yellow-500' : 'text-gray-300'}`}
                      onClick={() => handleRateClick(index)}
                    ></i>
                  ))}
                </div>
              </div>

              <div className="form-group mt-4">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register('email')}
                  className={`form-input ${errors.email ? 'border-red-500' : ''}w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </div>

              <div className="form-group mt-4">
                <label>Message</label>
                <Controller
                  name="message"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <ReactQuill
                      theme="snow"
                      value={field.value}
                      onChange={(content) => {
                        field.onChange(content);
                        handleEditorChange(content);
                      }}
                      className={`quill-editor h-40 ${errors.message ? 'border-red-500' : ''}`}
                    />
                  )}
                />
                {errors.message && (
                  <span className="text-red-500">{errors.message.message}</span>
                )}
              </div>

              <div className="space-y-4 p-4 sm:p-5 mt-16">
                
              <button
                type="submit"
                className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default CreateFeedback;
