const StatCard = ({ title, value, svg, svgColor, textColor }) => {
  return (
    <div className={`bg-slate-150 text-${textColor} p-6 rounded-lg shadow-md mb-6`}>
      <div className="flex justify-between space-x-1">
        <p className={`text-lg font-semibold text-${textColor}-700 dark:text-navy-100`}>
          {title}
        </p>
        {svg && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`size-5 text-${svgColor} dark:text-accent`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            {svg}
          </svg>
        )}
      </div>
      <p className={`mt-3 text-base font-medium text-${textColor}-500`}>{value}</p>
    </div>
  );
};

export default StatCard;
