import React from 'react';

const PageHeader = ({ title, onButtonClick, showCreateButton, showBackButton = false,showEditButton = false,onEditButtonClick = false,onCreateButtonClick = false, onBackButtonClick = false, showSubmitButton = false, onSubmitButtonClick = false }) => {
  return (
    <div className="flex items-center justify-between space-x-4 py-5 lg:py-6">
      {/* Left Section: Title and Breadcrumbs */}
      <div className="flex items-center space-x-4">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          {title}
        </h2>
        <div className="hidden h-full py-1 sm:flex">
          <div className="h-full w-px bg-slate-300 dark:bg-navy-600" />
        </div>
        <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
          {/* Additional elements (like breadcrumbs) can be added here */}
        </ul>
      </div>
      {showCreateButton && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={onButtonClick}
        >
          Create
        </button>
      )}

      {showEditButton && (
        <button
          className="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded" style={{ marginLeft: 'auto' }}
          onClick={onEditButtonClick}
        >
          Edit
        </button>
      )}
      {onCreateButtonClick && (
        <button
          className="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded" style={{ marginLeft: 'auto' }}
          onClick={onCreateButtonClick}
        >
          Create
        </button>
      )}

      {showBackButton && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={onBackButtonClick}
        >
          Back
        </button>
      )}
      
      {showSubmitButton && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Save
        </button>
      )}
    </div>
  );
};

export default PageHeader;
