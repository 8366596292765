import React from 'react';

const TableHeader = ({
  title,
  onSearchChange,
  onExportCSV,
  showSearch,
  onExportPDF,
  showExportButtons,
  advancedSearch,
  showAdvanceSearch,
  onsearch_Text,
  onText,
  onResetSearch
}) => {
  return (
    <div className="my-3 lg:flex md:flex h-8 items-center justify-between">
      {/* Title */}
      <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
        {title}
      </h2>

      {/* Search and Export Buttons */}
      <div className="flex items-center space-x-4 mt-5 ">

        {showAdvanceSearch && (
          <>
            {/* <button
          className="blue-text"
           
          onClick={advancedSearch}
        >
          <img src="/images/filter.svg" alt="Custom Icon" className="w-10 h-15" />
        </button> */}
            <button
              className="advnce bg-blue-500 hover:bg-blue-700 text-white py-2 px-2 rounded inline-flex items-center"
              onClick={advancedSearch}
            >
             <svg xmlns="http://www.w3.org/2000/svg" class="size-6 color-white" fill="none" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3 5.109C3 4.496 3.47 4 4.05 4h16.79c.58 0 1.049.496 1.049 1.109 0 .612-.47 1.108-1.05 1.108H4.05C3.47 6.217 3 5.721 3 5.11zM5.798 12.5c0-.612.47-1.109 1.05-1.109H18.04c.58 0 1.05.497 1.05 1.109s-.47 1.109-1.05 1.109H6.848c-.58 0-1.05-.497-1.05-1.109zM9.646 18.783c-.58 0-1.05.496-1.05 1.108 0 .613.47 1.109 1.05 1.109h5.597c.58 0 1.05-.496 1.05-1.109 0-.612-.47-1.108-1.05-1.108H9.646z"></path>
              </svg>
              

            </button>


            



          </>
        )}

        {/* Search Bar */}
        {showSearch && onSearchChange && (
          <label className="inline-flex items-center space-x-2">
            <input
              type="search"
              placeholder="Type a keyword..."
              aria-label="Type a keyword..."
              id="inptserch"
              className="form-input rounded-md border border-gray-300 p-2"
              onChange={onSearchChange}
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4 px-2 rounded"
              onClick={onsearch_Text}
            >
              Search
            </button>

            {onText && (

              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4 px-2 rounded"
                onClick={onResetSearch}
              >
                Reset
              </button>

            )}

          </label>

        )}





        {showExportButtons && (
          <>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4   px-2 rounded"
              onClick={onExportCSV}
            >
              Export CSV
            </button>

            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold lg:py-2 lg:px-4 md:py-2 md:px-4 px-2 rounded"
              onClick={onExportPDF}
            >
              Export PDF
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
