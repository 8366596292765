import React from "react";
import { AppUserListFetch, ExportCsv,Delete_AppUser,ViewAppUser } from "../../../services/admin/appUserservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import SEO from "../../../hooks/seo";
import { useParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../services/admin/api';
import CustomLoader from '../Loaderfiles/customloader';
import moment from 'moment';
import useResizeObserver from "../../../hooks/useResizeObserver";

const UserView = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id, slug } = useParams();
    const [showDiv1, setShowDiv1] = useState(true);

        {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
    
  

   const [isUpcomingVisible, setUpcomingVisible] = useState(true);
  const [isPastVisible, setPastVisible] = useState(false);

  const toggleVisibility = (type) => {
    if (type === 'upcoming') {
      setUpcomingVisible(!isUpcomingVisible);
      setPastVisible(false);
    } else if (type === 'past') {
      setPastVisible(!isPastVisible);
      setUpcomingVisible(false);
    }
  };

 
    
      const fetchData = async () => {
            setLoading(true);
            try {
                const response = await ViewAppUser(id);
                console.log(response, "response");
                  

                if (response && response.user) {
                   
                    setData(response.user);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };


    useEffect(() => {
      

        fetchData();
    }, [id]);

 
 

 console.log(data,'ok');
    return (
      <>
      <SEO
      title="Customer Detail - eticketing"
    />
       <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">

        {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-center h-96">
                            <CustomLoader
                                visible={true}
                                height={80}
                                width={80}
                                color="#4fa94d"
                                ariaLabel="tail-spin-loading"
                                radius={1}
                            />
                        </div>
                    </div>
                ) : data.length === 0 ? (
                    <div className="text-center text-gray-500 mt-8">
                        <h1 className="xl text-3xl">No records found.</h1>
                    </div>
                ) : (

                <>
               <div className="flex items-center space-x-4 py-5 lg:py-6">
                <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                  Customer Detail 
                </h2>
                <div className="hidden h-full py-1 sm:flex">
                  <div className="h-full w-px bg-slate-300 dark:bg-navy-600" />
                </div>
              </div>
              {/* new */}
           <div className="grid lg:grid-cols-5 gap-4 mt-5">
                <div className="col-span-2">
                  <div className="card pb-3">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                      <div className="grid grid-cols-4 gap-4">
                        <div>
                        <img
                          src={data.image ? data.imagePath + '/' + data.image : './kanooLogo.png'}
                          alt=""
                          className="w-18 h-18 rounded-full"
                        />                     
                        </div>
                        <div className="border-r">
                          <p className="text-15 text-center text-black font-medium">Pending Order</p>
                          <p className="text-15 text-center text-black font-bold mt-3">{data.pending}</p>
                        </div>
                        <div className="border-r">
                          <p className="text-15 text-center text-black font-medium">Complete Order</p>
                          <p className="text-15 text-center text-black font-semibold mt-3"> {data.complete}</p>
                        </div>
                        <div>
                          <p className="text-15 text-center text-black font-medium">Cancel Order</p>
                          <p className="text-15 text-black text-center font-semibold mt-3">{data.cancel}</p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <p>{data.name}</p>
                      <p>{data.email}</p>
                      <p>#{data.user_id}</p>
                    </div>
                  </div>
                  <div className="mt-5"> 
                  <div className="card ">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                      <div className="flex items-center space-x-2">
                        <p>Following (0)</p>
                      </div>
                    </div>
                    <div className="p-4"> <h1 className="xl text-18 text-center flex items-center justify-center h-full">
                            No records found.
                          </h1> </div>
                  </div>
                  </div>
                </div>
                <div className="lg:col-span-3 col-span-2">
                <div className="card">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                      <div className="flex items-center space-x-2">
                        <h4 className="">All Orders ({data.orders}) </h4>
                      </div>

                   
                    
                      <ul
                        className="mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
                        role="tablist"
                        data-te-nav-ref=""
                      >
                        <li role="presentation">
                           


                          <span
                          onClick={() => toggleVisibility('upcoming')}
                          className={`my-2 block px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 focus:isolate 
                            ${isUpcomingVisible && 'text-primary bg-gray-100 border   hover:border-primary hover:text-primary hover:bg-neutral-100 focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400'}`
                        }
                        >
                          Upcoming
                        </span>

       
                        </li>
                        <li role="presentation" className="pl-4">
                         

                         <span onClick={() => toggleVisibility('past')}
                          
                          className={`my-2 pl-4 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:border-primary hover:text-primary hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400
                            ${!isUpcomingVisible && ' text-primary bg-gray-100 border   hover:border-primary hover:text-primary hover:bg-neutral-100 focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400'}`
                        } >
                          Past</span>  

                        </li>
                      </ul>
                      
                      <div className="mb-6">

                      {isUpcomingVisible &&  <div
                          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                          id="tabs-home"
                          role="tabpanel"
                          aria-labelledby="tabs-home-tab"
                          data-te-tab-active=""
                        >
                       {data.upcoming.length > 0 ? (
                            data.upcoming.map((item) => (
                              <div key={item.id} className="flex items-center justify-between mb-4 mt-4">
                                <div className="flex-1 pr-4">
                                <Link to={`/admin/order-invoice/${item.id}`} className="text-blue-500 hover:underline">
                                    <span>#{item.id}</span>
                                  </Link>
                                  <h6 className="mb-0">
                                    {data.event_name} ({item.quantity} Tickets)
                                  </h6>
                                  <p className="text-gray-600">{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                </div>
                                <div className="text-right">
                                  <button className="btn px-0">
                                    <strong>${item.payment}</strong>
                                  </button>
                                  <h6>
                                  <span className={`inline-block px-2 py-1 font-semibold text-sm rounded ${item.order_status === 'success' || item.order_status === 'Pending'  ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                  {item.order_status === 'Pending' ? 'Paid' : item.order_status}
                                  </span>



                                  </h6>
                                </div>
                              </div>
                            ))
                          ) : (
                          <h1 className="xl text-18 text-center flex items-center justify-center h-full">
                            No records found.
                          </h1> 

                          )}


                         

                        </div>}



                         {isPastVisible && 


                           <div
                          className="   transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                          id="tabs-profile"
                          role="tabpanel"
                          aria-labelledby="tabs-profile-tab"
                        >
                             

                         {data.past.length > 0 ? (
                            data.past.map((item) => (
                              <div key={item.id} className="flex items-center justify-between mb-4 mt-4">
                                <div className="flex-1 pr-4">
                                 
                                  <Link to={`/admin/order-invoice/${item.id}`} className="text-blue-500 hover:underline">

                                    <span>#{item.id}</span>
                                    </Link>
                                  <h6 className="mb-0">
                                    {data.event_name} ({item.quantity} Tickets)
                                  </h6>
                                  <p className="text-gray-600">{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                </div>
                                <div className="text-right">
                                  <button className="btn px-0">
                                    <strong>${item.payment}</strong>
                                  </button>
                                  <h6>
                                  <span className={`inline-block px-2 py-1 font-semibold text-sm rounded ${item.order_status === 'success' || item.order_status === 'Pending' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                    {item.order_status === 'Pending' ? 'Paid' : item.order_status}
                                  </span>


                                  </h6>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h1 className="xl text-18 text-center flex items-center justify-center h-full">
                            No records found.
                          </h1>

                          )}

                        </div>

                          }

                       
                       
                      </div>
                      {/* tab content */}
                    </div>
                  </div>
                </div>
              </div>

              {/* new */}
                </> 
               )}

            </main>
            </>

    );
};

export default UserView;
