import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../hooks/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Loaderfiles/customloader";
import Swal from "sweetalert2";
import SEO from "../../../hooks/seo";
import {
  editEventCountry,
  updateEventCountry,
} from "../../../services/admin/event-countries-service";

const schema = yup.object().shape({
  country_name: yup.string().required("Country name is required"),
  fee: yup.number().typeError("Fee is required").required("Fee is required"),
  vat_fee: yup
    .number()
    .typeError("VAT fee is required")
    .required("VAT fee is required"),
  service_fee: yup
    .number()

    .typeError("Service fee is required")
    .required("Service fee is required"),
  // agent_commission_type: Yup.string().required("Commission Type is required"),
  // agent_commission: Yup.number().required("General Commission is required"),
  // agent_commission_about: Yup.number().required("Commission About is required"),
});

function EditEventCountries() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country_name: "",
      fee: "",
      fee_enabled: "",
      vat_fee: "",
      vat_exclusive: "",
      service_fee: "",
      service_fee_enabled: "",
      agent_commission_type: "",
      agent_commission: "",
      agent_commission_about: "",
    },
  });

  const feeEnabled = watch("fee_enabled");
  const serviceFeeEnabled = watch("service_fee_enabled");
  const vatExclusive = watch("vat_exclusive");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await editEventCountry(id);
        setValue("country_name", response.data.country);
        setValue("fee", response.data.fee ?? 0);
        setValue("fee_enabled", response.data.fee_enabled);
        setValue("vat_fee", response.data.vat_fee ?? 0);
        setValue("vat_exclusive", response.data.vat_exclusive);
        setValue("service_fee", response.data.service_fee);
        setValue("service_fee_enabled", response.data.service_fee_enabled);
        setValue("agent_commission_type", response.data.agent_commission_type);
        setValue("agent_commission", response.data.agent_commission);
        setValue(
          "agent_commission_about",
          response.data.agent_commission_about
        );
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
        setPageLoading(true);
      }
    };

    fetchData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await updateEventCountry(id, data);
      Swal.fire({
        title: "Success!",
        text: "Event country updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/admin/event-country");
      });
    } catch (error) {
      toast.error("Error updating event country: " + error.message);
      console.error("Error updating event country:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#bd387a"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate("/admin/event-country");
  };

  return (
    <>
      <SEO title="Edit Event Country - eTicketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <ToastContainer />
        <PageHeader
          title="Edit Event Country"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Edit Event Country
                    </h4>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label className="block">
                        <span>Country Name</span>
                        <Controller
                          name="country_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Country Name"
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        {errors.country_name && (
                          <p className="text-red-500">
                            {errors.country_name.message}
                          </p>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label className="flex items-center space-x-2">
                        <Controller
                          name="fee_enabled"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={(e) =>
                                field.onChange(e.target.checked ? 1 : 0)
                              }
                            />
                          )}
                        />
                        <span>Enable Processing Fee </span>
                      </label>
                      {errors.fee_enabled && (
                        <p className="text-red-500">
                          {errors.fee_enabled.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {feeEnabled === 1 && (
                  <div className="flex space-x-4">
                    <div className="flex-1">
                      <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                        <label className="block">
                          <span>Processing Fee</span>
                          <Controller
                            name="fee"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                type="number"
                                min={0}
                                step="0.1"
                                {...field}
                              />
                            )}
                          />
                          {errors.fee && (
                            <p className="text-red-500">{errors.fee.message}</p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label className="flex items-center space-x-2">
                        <Controller
                          name="service_fee_enabled"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={(e) =>
                                field.onChange(e.target.checked ? 1 : 0)
                              }
                            />
                          )}
                        />
                        <span>Enable Service Fee </span>
                      </label>
                      {errors.service_fee_enabled && (
                        <p className="text-red-500">
                          {errors.service_fee_enabled.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {serviceFeeEnabled === 1 && (
                  <div className="flex space-x-4">
                    <div className="flex-1">
                      <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                        <label className="block">
                          <span>Service Fee</span>
                          <Controller
                            name="service_fee"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                type="number"
                                min={0}
                                step="0.1"
                                {...field}
                              />
                            )}
                          />
                          {errors.service_fee && (
                            <p className="text-red-500">
                              {errors.service_fee.message}
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label className="flex items-center space-x-2">
                        <Controller
                          name="vat_exclusive"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              // checked={field.value === 1}
                              checked={true}
                              value={1}
                              // onChange={(e) =>
                              //   field.onChange(e.target.checked ? 1 : 0)
                              // }
                            />
                          )}
                        />
                        <span>Vat Exclusive</span>
                      </label>
                      {errors.vat_exclusive && (
                        <p className="text-red-500">
                          {errors.vat_exclusive.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {vatExclusive === 1 && (
                  <div className="flex space-x-4">
                    <div className="flex-1">
                      <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                        <label className="block">
                          <span>Vat Fee In Percentage</span>
                          <Controller
                            name="vat_fee"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                type="number"
                                min={0}
                                step="0.1"
                                {...field}
                              />
                            )}
                          />
                          {errors.vat_fee && (
                            <p className="text-red-500">
                              {errors.vat_fee.message}
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label
                        className="block mb-1.5"
                        htmlFor="agent_commission_type"
                      >
                        Commission Type
                      </label>
                      <Controller
                        name="agent_commission_type"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className="form-select w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            id="agent_commission_type"
                          >
                            <option value="">Select Commission Type</option>
                            <option value="amount">Amount</option>
                            <option value="percentage">Percentage</option>
                          </select>
                        )}
                      />
                      {errors.agent_commission_type && (
                        <span className="text-red-500">
                          Commission type is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label
                        className="block mb-1.5"
                        htmlFor="agent_commission"
                      >
                        Agent Commission
                      </label>
                      <Controller
                        name="agent_commission"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            id="agent_commission"
                            type="number"
                            value={field.value === null ? "" : field.value}
                            onChange={(e) =>
                              field.onChange(
                                e.target.value === "" ? null : e.target.value
                              )
                            }
                          />
                        )}
                      />
                      {errors.agent_commission && (
                        <span className="text-red-500">
                          General commission is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <div className="space-y-4 px-4 py-2 sm:px-5 sm:py-3">
                      <label
                        className="block mb-1.5"
                        htmlFor="agent_commission_about"
                      >
                        Commission About
                      </label>
                      <Controller
                        name="agent_commission_about"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className="form-select w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            id="agent_commission_about"
                          >
                            <option value="">Select Commission Type</option>
                            <option value="per_ticket">Per Ticket</option>
                            <option value="per_order">Per Order</option>
                          </select>
                        )}
                      />
                      {errors.agent_commission_about && (
                        <span className="text-red-500">
                          Commission About is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="space-y-4 p-4 sm:p-5">
                  <button
                    type="submit"
                    className={`btn bg-blue-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${
                      isLoading ? "cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div
                          className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                          role="status"
                        ></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditEventCountries;
