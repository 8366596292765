import axios from "axios";
import { API_ENDPOINTS } from "../admin/api";

export const EventCountryListFetch = async (currentPage, searchText) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const searchParam = searchText ? `&search=${searchText}` : "";
    const response = await axios.get(
      API_ENDPOINTS.EVENT_COUNTRIES_LIST + `?page=${currentPage}${searchParam}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const editEventCountry = async (id) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const url = `${API_ENDPOINTS.EDIT_EVENT_COUNTRY}${id}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    throw error;
  }
};

export const updateEventCountry = async (id, updatedData) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const url = `${API_ENDPOINTS.UPDATE_EVENT_COUNTRY}/${id}`; // Ensure proper URL format

    const response = await axios.post(url, updatedData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating event country:", error);
    throw error;
  }
};
