import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../hooks/PageHeader";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { submitCashDepositForm } from "../../services/agent/agentServices";
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";



const CashHandlingCreate = () => {

  const onButtonClickCreate = () => { };
  const [files, setFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const refFilePond = useRef(null);
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
  // Form validation schema
  const validationSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required'),
    images: Yup.array().min(1, 'At least one image is required')
  });

  // Initial form values
  const initialValues = {
    amount: '',
    images: []
  };
  const handleFilePondReset = () => {
    if (refFilePond.current) {
      refFilePond.current.removeFiles();  
    }
  };
  // Form submit handler
const handleSubmit = async (values , { resetForm, setFieldValue }) => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append('amount', values.amount);
    formData.append('image', values.images[0]);

    const response = await submitCashDepositForm(formData);
    resetForm();
    handleFilePondReset(); // Clear FilePond input field
    setFieldValue('images', null); // Clear the images field
    setFiles([]);

    // Check if the response indicates success
    if (response.success) {
      setSuccessMessage(response.message || 'Cash deposit submitted successfully.');
      setErrorMessage('');
    } else {
      setErrorMessage(response.error?.image.join(' ') || 'An error occurred.');
      setSuccessMessage('');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    setErrorMessage('Failed to submit the form. Please try again.');
    setSuccessMessage('');  
  } finally {
    setIsLoading(false);
  }
};

    
  return (
    <>
     <SEO
        title="Cash Deposit - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
        <PageHeader
          title="Cash Deposit"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          
           <div className="col-span-12 lg:col-span-8">

           {successMessage && (
                <div className="  bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-base" role="alert">
                    <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0 mr-3 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 4.293a1 1 0 00-1.414 0L7.5 11.086 4.793 8.379a1 1 0 10-1.414 1.414l3.5 3.5a1 1 0 001.414 0l8.5-8.5a1 1 0 000-1.414z" clipRule="evenodd" />
                        </svg>
                        <strong className="font-bold text-green-700">Success!</strong>
                    </div>
                    <p className="mt-2 text-green-700 text-sm">{successMessage}</p>
                </div>
            )}
            {errorMessage && (
                <div className="mb-4 text-base mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error! </strong>
                <span className="block sm:inline">{errorMessage}</span>
                </div>
            )}
         

            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
            {({ setFieldValue, errors, touched, resetForm }) => (
                <Form>
                    <div className="card">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                        <div className="flex items-center space-x-2">
                        <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                            <i className="fa-solid fa-layer-group" />
                        </div>
                        <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                            Cash Deposit Form
                        </h4>
                        </div>
                    </div>
                    <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                        <span>Amount</span>
                        <Field
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter Amount"
                            name="amount"
                            type="number"
                            min="1"
                        />
                        {touched.amount && errors.amount && <div className="text-red-600" >{errors.amount}</div>}
                        </label>
                        <div>
                        <span>Images</span>
                        
                        <FilePond
                            ref={refFilePond}
                            onupdatefiles={(fileItems) => {
                            setFieldValue('images', fileItems.map(fileItem => fileItem.file));
                            }} 
                            onprocessfiles={(fileItems) => {
                              setFieldValue([]);
                            }}
                            allowMultiple={false}
                            name="images"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        {touched.images && errors.images && <div className="text-red-600" >{errors.images}</div>}
                        </div>

                        <div className="flex   space-x-2 pt-4">
                        {/* <button type="submit" className="bg-blue-600 hover:bg-blue-700 btn space-x-2 bg-primary font-medium text-white focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:focus:bg-accent-focus dark:active:bg-accent/90">
                            <span>Submit</span>
                        </button> */}
                        <button
                        type="submit"
                        className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>

                        </div>
                    </div>
                    </div>
                
                </Form>
            )}
            </Formik>
          </div>            
          
        </div>
      </main>
    </>
  );
};

export default CashHandlingCreate;
