import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { CategoryListFetch , CategorySorting } from "../../../services/admin/categoryservice";
import { Delete_category } from "../../../services/admin/categoryservice";
import { Link } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from "../Loaderfiles/customloader";
import { useNavigate, useLocation } from "react-router-dom";
import { API_ENDPOINTS } from "../../../services/admin/api";
import { Toaster, toast } from "react-hot-toast";
import Swal from "sweetalert2";
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";

const CategoryList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const message = location.state && location.state.message;
  const [messageShown, setMessageShown] = useState(false);

  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items);

    const itemIds = items.map((item) => item.id);
    console.log(itemIds);
    const formdata = {
        itemIds: itemIds,
    };
    
  

  try {
    const response = await CategorySorting(formdata);
    console.log(response);

    toast.success('Sorting updated successfully');
  } catch (error) {
    console.error("Error sorting categories: ", error);

    toast.error('Error sorting categories');
  }
};


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CategoryListFetch(currentPage, searchText);
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };
    if (message && !messageShown) {
      setMessageShown(true);
      Swal.fire("Update!", message, "success");
    }
    fetchData();
  }, [currentPage, searchText, message]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_CATEGORY_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_CATEGORY_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const onButtonClickCreate = () => {
    navigate("/admin/category/create");
  };
  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  return (
    <>
      <SEO title="Categories List - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <Toaster position="top-right" reverseOrder={false} />
        <PageHeader
          title="Categories List"
          onButtonClick={onButtonClickCreate}
          showCreateButton={true}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Category Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className=" lg:mt-5 md:mt-5 mt-16 ">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="categories">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <table className="min-w-full divide-y divide-gray-200" >
                            <thead className="bg-gray-50 text-left">
                              <tr>
                                <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  
                                </th>
                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  Image
                                </th>
                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  Name
                                </th>
                                <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  Status
                                </th>
                                <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  Action
                                </th>
                              </tr>
                            </thead> 
                            <tbody className="bg-white divide-y divide-gray-200">
                              {data.map((item, index) => (
                                <Draggable
                                  key={String(item.id)}
                                  draggableId={String(item.id)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <tr
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                     <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                      <svg className="h-6 w-6 text-neutral-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <polyline points="8 7 12 3 16 7" />
                                        <polyline points="8 17 12 21 16 17" />
                                        <line x1="12" y1="3" x2="12" y2="21" />
                                      </svg>
                                      </td>
                                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                        <div className="avatar flex size-30">
                                          <img
                                            className="mask is-squircle"
                                            src={
                                              item.imagePath
                                                ? item.imagePath
                                                : "../assets/img/categories/concert.jpg"
                                            }
                                            alt="Category"
                                            style={{ borderRadius: "0.25rem" }}
                                          />
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                        {item.name || "NULL"}
                                      </td>
                                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                        {item.status === 0 ? (
                                          <div className="badge space-x-2.5 px-0 text-error">
                                            <div className="size-2 rounded-full bg-current" />
                                            <span>Inactive</span>
                                          </div>
                                        ) : (
                                          <div className="badge space-x-2.5 px-0 text-success">
                                            <div className="size-2 rounded-full bg-current" />
                                            <span>Active</span>
                                          </div>
                                        )}
                                      </td>
                                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                        <Link
                                          className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25"
                                          to={`/admin/category/${item.id}/edit`}
                                        >
                                          <i className="fa fa-edit" />
                                        </Link>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          </table>
                          </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CategoryList;
