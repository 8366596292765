import React, { useState, useEffect, useRef } from "react";
import { GenateEvent, SubmitEvent, downloadFile } from "../../../services/generateeventservice";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { Link } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import Select from 'react-select';
import Swal from 'sweetalert2';
import { API_ENDPOINTS } from '../../../services/api';

const schema = yup.object().shape({
  //event_id: yup.string().required('Please select an event.'),
  quantity: yup.number().typeError('Please enter a valid number.').min(0, 'Quantity must be greater than or equal to 0.'),
});
const GenerateEventCode = () => {
  const { handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
    resolver: yupResolver(schema) // Use Yup resolver for validation
  });

  const [EventData, SetEventData] = useState([]);
  const [data, setData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const recordsPerPage = 10;
  const eventRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [validationErrors, setValidationErrors] = useState({});
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [pageLoading, setPageLoading] = useState(false);
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const truncateWords = (text, maxWords) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    return truncatedWords.join(' ');
  };






  const fetchData = async () => {
    setSearchLoading(true); // Set loading state when searching
    try {
      const response = await GenateEvent(currentPage, searchText);

      if (response && response.data) {
        SetEventData(response.events);
        setTotalRecords(response.recordsTotal.total);
        setData(response.data);
      }
    } catch (error) {

    } finally {
      setSearchLoading(false); // Reset loading state after search completes
      setPageLoading(true);
    }
    setPageLoading(true);
  };

  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();

  useEffect(() => {

    if (currentPage !== prevPageRef.current || debouncedSearchText !== prevSearchTextRef.current) {

      fetchData();
    }
    // Update the previous values
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;

  }, [currentPage, debouncedSearchText]);


  const onSubmit = async (data) => {
    try {
      setSubmitLoading(true); // Set loading state when submitting

      // Fetch EventData before showing the SweetAlert
      await fetchData(); // Assuming this fetches EventData

      const eventId = data.event_id.value;
      const eventName = data.event_id.label;
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: `Do you want to create ${data.quantity || ''} codes for event: ${eventName || ''}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!'
      });

      if (result.isConfirmed) {
        const submitData = {
          event_id: eventId,
          quantity: parseInt(data.quantity)
        };
        const result = await SubmitEvent(submitData);
        await fetchData();

        Swal.fire({
          title: 'Good Job',
          text: 'Submit Successfully',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });
        reset({ event_id: '', quantity: '' });


      }

    } catch (error) {

    } finally {
      setSubmitLoading(false); // Reset loading state after submit completes
    }
  };
  const clearValidationErrors = () => {
    setValidationErrors({});
  };
  const onButtonClickCreate = () => {
    alert('apply');
  };

  const handleDownloadFile = async (id, event_id) => {
    try {
      const { fileUrl, suggestedFileName } = await downloadFile(id, event_id);

      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = suggestedFileName;
      anchor.click();

      window.URL.revokeObjectURL(anchor.href);
    } catch (error) {

    }
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.CSV_GERNATE_EVENT_CODE + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const handleExportPDF = () => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.PDF_GERNATE_EVENT_CODE + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
    window.location.href = urlWithToken;

  };
  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  return (
    <>
      <SEO
        title="Generate Event - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }}
        className="main-content w-full px-[var(--margin-x)] pb-8">

        <PageHeader
          title="Generate Private Access Codes"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        <Toaster position="top-center" />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
              <div className="flex items-center justify-center mt-5">
                <div className="bg-white border rounded-md border-gray-300 lg:ml-0 md:ml-0 ml-2 p-6 w-full mx- my-auto">
                  <div className="lg:flex mt-3">
                    <div className="lg:pl-0 w-1/2 ">
                      <label className="block">
                        <span className="block text-sm text font-medium text-gray-700">Events</span>
                        <Controller
                          control={control}
                          name="event_id"
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="mt-1 lg:w-full md:w-[32rem] w-80"
                              options={[
                                { value: '', label: 'Select Event' },
                                ...EventData.map(item => ({ value: item.id, label: item.name }))
                              ]}
                              placeholder="Select Event"
                            />
                          )}
                        />
                        {errors.event_id && <p className="text-red-500">{errors.event_id.message}</p>}
                      </label>
                    </div>
                    <div className="lg:pl-2 w-1/2 lg:mt-0 mt-8">
                      <label htmlFor="quantity" className="block text-sm font-medium text-gray-700"> Quantity</label>
                      <Controller
                        control={control}
                        name="quantity"
                        render={({ field }) => (
                          <input
                            {...field}
                            type="number"
                            className="form-input mt-1 p-2 lg:w-full w-80 border border-slate-300 rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            min="0"
                          />
                        )}
                      />
                      {errors.quantity && <p className="text-red-500">{errors.quantity.message}</p>}
                    </div>
                  </div>
                  <div className="mt-8 lg:pl-0">
                    <button
                      type="submit"
                      className={`btn h-10 bg-green-500 font-medium text-white hover:bg-sky-600 focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative`}
                      disabled={!isValid}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Download Private Access Codes"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="mt-5">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                <table className="is-hoverable w-full text-left">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        #
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Event
                      </th>
                      <th className="text-center whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Quantity
                      </th>
                      <th className="text-center whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Download
                      </th>
                      <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Created at
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap  px-4 py-3 sm:px-5">
                          {item.id || 'N/A'}
                        </td>
                        <td className="whitespace-nowrap  px-4 py-3 sm:px-5">
                          <p dangerouslySetInnerHTML={{ __html: truncateWords(item.name, 5) || 'NULL' }} />
                        </td>
                        <td className="text-center whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.quantity || 'N/A'}
                        </td>
                        <td className="text-center whitespace-nowrap px-4 py-3 sm:px-5">
                          <div className="flex justify-center items-center"> {/* Centering container */}
                            <Link onClick={() => handleDownloadFile(item.id, item.event_id)}>
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white cursor-pointer"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 18"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                                />
                              </svg>
                            </Link>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {formatDate(item.created_at)}
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default GenerateEventCode;
