import React, { useRef, useState, useEffect } from "react";
import PinInput from "react-pin-input";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const PinKeyboardComponent = ({ onSubmit }) => {
  const [input, setInput] = useState("");
  const pinRef = useRef(null);
  const keyboardRef = useRef(null);

  useEffect(() => {
    if (input.length === 5) {
      setTimeout(() => {
        onSubmit(input);
      }, 10);
    }
  }, [input, onSubmit]);

  const onChange = (newInput) => {
    if (newInput.length <= 5) {
      setInput(newInput);
      updatePinInput(newInput);
    }
  };

  const onKeyPress = (button) => {
    if (button === '{clear}') {
      handleClear();
    } else if (button === '{bksp}') {
      const updatedInput = input.slice(0, -1);
      setInput(updatedInput);
      updatePinInput(updatedInput);
    } else if (input.length < 5 && /^\d$/.test(button)) {
      const updatedInput = input + button;
      setInput(updatedInput);
      updatePinInput(updatedInput);
    }
  };

  const updatePinInput = (value) => {
    if (pinRef.current) {
      // Clear all the current values
      pinRef.current.elements.forEach((element, index) => {
        element.state.value = value[index] || "";
      });
    }
  };

  const handleClear = () => {
    setInput("");
    keyboardRef.current.clearInput();
    if (pinRef.current) {
      pinRef.current.clear();
    }
  };

  const pinInputOnChange = (e) => {

    console.log('=============================>');
    setInput(e);
    console.log(e);
    console.log(input, 'input');
    console.log('=============================<');
  }

  return (
    <div className="">
      <div className="w-full pin_code mt-10 mb-5">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Enter User Id of the person you are selling tickets to
        </h1>
      </div>
      <div className="w-full flex items-center justify-center mb-10">
        <div className="col-span-12 sm:col-span-6 lg:col-span-8 flex flex-col items-center w-1/2">
          <div className="w-full">
            <PinInput
              length={5}
              focus
              ref={pinRef}
              type="numeric"
              inputMode="numeric"
              pattern="\d*"
              onChange={pinInputOnChange} // Disable onChange to prevent unexpected behavior
              onComplete={() => onSubmit(input)}
            />
          </div>
          <div className="w-full flex justify-center mt-4">
            <Keyboard
              keyboardRef={(r) => (keyboardRef.current = r)}
              layoutName="default"
              theme="hg-theme-default hg-theme-numeric hg-layout-numeric numeric-theme"
              layout={{
                default: ["1 2 3", "4 5 6", "7 8 9", "{clear} 0 {bksp}"],
              }}
              display={{
                "{clear}": "Clear",
                "{bksp}": "&#8592",
              }}
              onKeyPress={onKeyPress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinKeyboardComponent;
