import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const AgentDetailList = async (currentPage,searchText,id) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    let url = API_ENDPOINTS.AGENT_SALES_DETAILS + `/${id}` + `?page=${currentPage}`;
    
    if (searchText && searchText.trim() !== '') {
      url += `&search=${encodeURIComponent(searchText)}`;
    }
    
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
