import React, { useEffect, useState, useRef } from "react";
import { VeiwSettlementDetailFetch } from "../../../services/admin/customer-report-service";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";


const VeiwSettlementDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(1);
  const navigate = useNavigate();
  const recordsPerPage = 10;
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {

      setLoading(true);
      try {
        const response = await VeiwSettlementDetailFetch(currentPage, searchText, id);
        console.log(response,'customer-report-cash');
        if (response && response.data) {
          setTotalRecords(response.data.total);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchText, id]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleExportCSV = () => {
    alert('CSV Export');
  };

  const handleExportPDF = () => {
    alert('PDF Export');
  };
  const onBackButtonClick = () => {
    navigate(-1);
  };
  const onButtonClickCreate = () => {
    navigate('/admin/create-role');
  };

  return (
    <>
    <SEO
    title="Organizer Settlement Detail - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Organizer Settlement Detail"
        onButtonClick={onButtonClickCreate}
        showCreateButton={false}
        showBackButton={true}
        onBackButtonClick={onBackButtonClick}

      />
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        <div className="card px-4 pb-4 sm:px-5">
          <TableTools
            title="Settlement Detail Rows"
            onSearchChange={handleSearchChange}
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
          />
          <div className="mt-5">
             
            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
              {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Organization Name
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Payment
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Payment Type
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Payment Token
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Payment Status
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Payment on

                    </th>


                  </tr>
                </thead>
                <tbody>
                  {/* {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="px-6 py-4">
                                                <div className="flex items-center">
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-900">
                                                            {item.name + ' ' + item.last_name || 'N/A'}
                                                        </p>
                                                        <p className="text-xs text-gray-500">{item.email}</p>
                                                    </div>
                                                </div>
                                            </td>


                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.total_orders}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.total_commission}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            null
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 {item.agent_commission || 'NULL'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                <Link to={`/admin/view-settlement/card/${item.id}`}>
                                                    <svg class="h-6 w-6 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="2" />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))} */}
                </tbody>
              </table>
                      )}
             <Pagination
              totalRecords={totalRecords}
              perPage={recordsPerPage}
              onPageChange={handlePageChange}
            />
                  </div>
                                   
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default VeiwSettlementDetail;
