import React, { useState, useEffect, useRef } from 'react';
import PageHeader from '../../hooks/PageHeader';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast, Toaster } from 'react-hot-toast';
import CustomLoader from './customloader';
import SEO from "../../hooks/seo";
import { API_ENDPOINTS } from '../../services/api';
import {
    fetchUsersAndEvents,
    checkAvailableTicket,
    checkTicketPrice,
    CreateWindowTicketData,
    searchUsers,
    searchEvent,
    FetchservicFee
} from '../../services/agent/agent-SellTickets-service';
import useResizeObserver from "./../../hooks/useResizeObserver";
import Swal from 'sweetalert2';

const WindowTickets = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        setError,
    } = useForm();

    const [AvailableTicket, setAvailableTicket] = useState('');
    const [CheckAvailTicket, setCheckAvailTicket] = useState(0);
    const [SelectedUser, setSelectedUser] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [numberOfTicket, setNumberOfTicket] = useState(0);
    const [complimentaryTickets, setComplimentaryTickets] = useState(0);
    const [SelectedEvent, setSelectedEvent] = useState(null);
    const [options, setOptions] = useState([]);
    const [optionsevts, setoptionsevts] = useState([]);
    const [eventinputValue, setEventInputValue] = useState('');
    const navigate = useNavigate();
    const [EventID, setEventID] = useState('');
    const [SelectedTicket, setSelectedTicket] = useState([]);
    const [ticketPrice, setTicketPrice] = useState(0);
     
    const [formIsValid, setFormIsValid] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Tper_order_limit, setTper_order_limit] = useState(0);
    const [number, setNumber] = useState(0);
    const [additional_ticket, setAdditional_ticket] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [IdEvent, setIdEvent] = useState('');
    const [SelectedEventData, setSelectedEventData] = useState([]);
    // charge amount states
    const [totalPrice, setTotalPrice] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [vatTotal, setvatTotal] = useState(0);
    const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
    const [processingFeeTotal, setProcessingFeeTotal] = useState(0);
    const [agentCommisionTotal, setAgentCommisionTotal] = useState(0);
    const [selectedTickets, setSelectedTickets] = useState(0);
    //end charge amount states
    const [processingFee, setProcessingFee] = useState(0);
    const [serviceFree, setServiceFee] = useState(0);
    const [vat_charge, setVat_charge] = useState(0);
    const [VatFee, setVatFee] = useState(0);
    const [agent_commission_type, set_agent_commission_type] = useState('');
    const [agent_commission_about, set_agent_commission_about] = useState('');
    const [agent_commissionCharge, set_agent_commissionCharge] = useState('');
     
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    // Implement the callback function
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }
    const [symbol, setSymbol] = useState('');

    useEffect(() => {
      // Fetch and display currency from localStorage
      const storedCurrency = localStorage.getItem('currency');
      if (storedCurrency) {
        setSymbol(storedCurrency);
      }
    }, []);
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const fetchFeeData = async () => {
        try {
            const data = await FetchservicFee();
          
            console.log(data,'feee.data')

            if(data.response.data.data.service_fee_enabled){
                setServiceFee(data.response.data.data.service_fee);
            }

            if(data.response.data.data.webfee_enabled){
                setProcessingFee(data.response.data.data.webfee_percentage);
            }

          setVat_charge(data.response.data.data.vat_exclusive);
          setVatFee(data.response.data.data.vatfee_percentage);
          set_agent_commission_type(data.response.data.data.agent_commission_type);
          set_agent_commission_about(data.response.data.data.agent_commission_about);
          set_agent_commissionCharge(data.response.data.data.agent_commission);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setPageLoading(false);
        }
    };
    useEffect(() => {
            fetchFeeData();
    }, []);

    const fetchData = async () => {
        try {
            const { users, events } = await fetchUsersAndEvents();
            // setOptions(users);
            //setOptionsevts(events);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setPageLoading(false);
        }
    };

    const fetchDataCalledRef = useRef(false);

    useEffect(() => {
        if (!fetchDataCalledRef.current) {
            fetchData();
            fetchDataCalledRef.current = true;
        }
    }, []);


    const handleInputChange = (newValue) => {
        console.log(newValue);
        setInputValue(newValue); // Update the state with the new input value
        loadOptions(newValue); // Return the new value
    };


    const loadOptions = async (inputValue) => {
        try {
            // Fetch options from API based on the inputValue
            const response = await searchUsers(inputValue);


            const formattedOptions = response.appusers.map(option => ({
                label: option.name,
                value: option.id
            }));
            console.log('formattedOptions', formattedOptions);
            setOptions(formattedOptions);



        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };


    useEffect(() => {
        if (SelectedEvent) {
            checkEventTickets(SelectedEvent.value);
            checkSingleTicketsPrice(SelectedEvent.value, numberOfTicket);
        }
    }, [SelectedEvent, numberOfTicket]);

    useEffect(() => {
        if (SelectedEvent) {
            checkTicketPrice(SelectedEvent.value);
        }
    }, [SelectedEvent]);

    const handleSelectChange = (selectedOption) => {
        setSelectedUser(selectedOption); // Updated to set single user
    };



    const handleChange_free = (e) => {

        if (e.target.value <= 4) {
            setDisable(false);
            setAdditional_ticket(e.target.value);
        } else {
            setDisable(true);
            alert('You can add up to 4 additional U18 free tickets.');
        }


    }


    const handleChange = (e) => {
        e.preventDefault();
        const inputNumber = parseInt(e.target.value, 10);

        if (e.target.value.includes("-")) {

            Swal.fire({
                title: 'Error',
                text: 'Please enter a positive value.',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            });


            e.target.value = '';
        }


        if (CheckAvailTicket < inputNumber) {
            Swal.fire({
                title: 'Alert',
                text: 'Number of User And Available ticket Should be Match',
                icon: 'warning',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            }).then((result) => {
                if (result.isConfirmed) {
                    e.target.value = ''; // Clear input field
                    setFormIsValid(false); // Set form validity state to false
                }
            });
            return;
        }

        const isFormValid = inputNumber > 0 && SelectedEvent !== null && inputNumber <= CheckAvailTicket;
        setNumber(inputNumber);
        setFormIsValid(isFormValid);
        setNumberOfTicket(inputNumber);
        //calculateTotalPrice(inputNumber, ticketPrice);
    };

    // const handleSelectChanges = (selectedOption) => {
    //     setSelectedEvent(selectedOption); // Updated to set single event
    //     checkEventTickets(selectedOption.value);
    //     checkSingleTicketsPrice(selectedOption.value, numberOfTicket);
    // };


    const handleEventInputChange = (newValue) => {

        setEventInputValue(newValue);
        loadEventOptions(newValue);
        const eventValue = SelectedEvent ? SelectedEvent.value : null;

        if (eventValue !== null) {
            const authToken = localStorage.getItem('authToken');
            // Making an API request using fetch
            fetch(`${API_ENDPOINTS.AGENT_EVENT_TICKETS}/${eventValue}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },

            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {

                    setSelectedEventData(data.tickets);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }

    };
    const loadEventOptions = async (inputValue) => {
        try {
            // Split inputValue into words
            const words = inputValue.split('');

            console.log('words', words);
            if (words.length !== 0) {
                // Fetch options from API based on the inputValue
                const response = await searchEvent(inputValue);
                console.log(response.events);


                const eventOptions = response.events.map(option => ({
                    label: option.name,
                    value: option.id
                }));

                console.log('formattedOptions', eventOptions);
                setoptionsevts(eventOptions);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };



    const checkEventTickets = async (eventId, ticketId) => {
        try {
            const availableTickets = await checkAvailableTicket(eventId, ticketId);
            setAvailableTicket(`Available Tickets: ${availableTickets}`);
            setCheckAvailTicket(availableTickets);
        } catch (error) {
            console.error('Error checking available tickets:', error);
        }
    };

    const checkSingleTicketsPrice = async (eventId, numberOfTickets, ticketId) => {
        console.log(ticketId, 'ticketId');
        try {
            const price = await checkTicketPrice(eventId, ticketId);
            console.log(price, 'price');
            setTicketPrice(price.price);
            setTper_order_limit(price.per_order_limit);
            //calculateTotalPrice(numberOfTickets, price.price);
        } catch (error) {
            console.error('Error checking ticket price:', error);
        }
    };

    // const calculateTotalPrice = (numberOfTickets, ticketPrice) => {
    //     const total = numberOfTickets * ticketPrice;
    //     setTotalPrice(total);
    // };

    const handleComplimentaryTicketsChange = (event) => {
        const { value } = event.target;
        console.log(value); // Log the value to the console
        setSelectedTickets(value); 
        if (parseInt(value) > Tper_order_limit) {
            // Display Swal alert for exceeding the limit
            Swal.fire({
                title: 'Error',
                text: `You cannot add more than ${Tper_order_limit} tickets per order.`,
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
        } else if (parseInt(value) > AvailableTicket) {
            // Display Swal alert for exceeding the available tickets
            Swal.fire({
                title: 'Error',
                text: `You cannot add more than ${AvailableTicket} tickets as per availability.`,
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false
            });
        } else {
            setComplimentaryTickets(value); // Update the state
        }
    };
    useEffect(() => {
        const totalComplimentaryPrice = complimentaryTickets * ticketPrice;
        const sub_total = (numberOfTicket + complimentaryTickets) * ticketPrice;
        const service_fee = (numberOfTicket + complimentaryTickets) * serviceFree;
        const processing_fee = (sub_total + service_fee) * processingFee / 100 ;

        var agentcommissionCharge = 0 ;
        if(agent_commission_type == "amount"){
            if(agent_commission_about == "per_ticket"){

                var agentcommissionCharge = (numberOfTicket + complimentaryTickets) * agent_commissionCharge;
            }else{
                  var agentcommissionCharge =  agent_commissionCharge;
            }
           
        }


        if(vat_charge == 1){
            var vat_fee = (service_fee + processing_fee) * VatFee / 100;
        } 
        const totalPrice = sub_total + service_fee + processing_fee + vat_fee;

       
        
        if(agent_commission_type == "percentage"){
            var agentcommissionCharge = totalPrice * agent_commissionCharge / 100;
           
        }  
        
        setAgentCommisionTotal(agentcommissionCharge);
        setProcessingFeeTotal(processing_fee);
        setServiceFeeTotal(service_fee);
        setvatTotal(vat_fee);
        setSubTotal(sub_total);
        setTotalPrice(totalPrice + agentcommissionCharge);

    }, [numberOfTicket, ticketPrice, complimentaryTickets]);

    const onSubmit = () => {
        if (!SelectedEvent || !numberOfTicket || !formIsValid) {
            console.error('Required fields are empty.');
            return;
        }
        if (SelectedUser) {
            openModal();
        } else {
            console.error('No user selected.');
        }
    };

    useEffect(() => {

        const slectedEvent = localStorage.getItem('window_eventId');
        if (slectedEvent) {
            const parsedArray = JSON.parse(slectedEvent);
            setEventInputValue(parsedArray.label);

            setSelectedEvent(parsedArray);
            checkEventTickets(parsedArray.value);
            setIdEvent(parsedArray.value);

        }


    }, []);
    const handleTicketInputChange = async (selectedOption) => {
        if (selectedOption) {
            const ticketId = selectedOption.value;
            const eventId = SelectedEvent.value;
            const numberOfTicket = number;

            try {
                await checkEventTickets(eventId, ticketId);
                await checkSingleTicketsPrice(eventId, numberOfTicket, ticketId);
                setSelectedTicket(ticketId);
            } catch (error) {
                console.error('Error handling ticket input change:', error);
            }
        }
    };

    const confirmSellTicket = async (ticketId) => {

        const data = {
            // customer_id: SelectedUser.value,
            event_id: IdEvent,
            complimentary_tickets: complimentaryTickets,
            additional_ticket: additional_ticket,
            ticket_price: subTotal,
            ticket_id: ticketId,
        };
        console.log('data', data);
        localStorage.setItem('window_eventId', JSON.stringify(SelectedEvent));



        try {
            setIsLoading(true);
            const result = await CreateWindowTicketData(data);
            setLoading(false);

            //             closeModal();
            //             toast.success('Ticket sold successfully');
            // 
            //             setTimeout(() => {
            //                 navigate('/agent/orders');
            //             }, 1000);


            Swal.fire({
                title: 'Good Job',
                text: 'Ticket sold successfully',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            }).then((result) => {
                if (result.isConfirmed) {
                    // User clicked 'OK'
                    navigate('/agent/orders', { state: { message: 'Scanner Create Successfully' } });
                }
            });


        } catch (error) {
            setIsLoading(false);


            Swal.fire({
                title: 'Error',
                text: 'An error occurred',
                icon: 'error',
                confirmButtonColor: '#a23896',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                showCloseButton: false // Ensure the close button is shown
            });
        }
    };

    if (pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center h-screen">
                    <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                    />
                </div>
            </main>
        );
    }

    return (
        <>
            <SEO
                title="Window Ticket - eticketing"
            />
            <main ref={ref} style={{ width: '100%', height: '100%' }}
                className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="container">
                    <PageHeader title="Window Ticket" showCreateButton={false} />
                    <Toaster position="top-center" />
                    <div className="flex items-center justify-center mt-5 bg-white p-4 w-full">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-57rem mx-auto">
                            <div className="lg:flex lg:mb-4 mb-2">


                                <div className="w-full mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Event</label>
                                    {/* <Select
                                        name="event_id"
                                        options={optionsevts}
                                        value={SelectedEvent}
                                        onChange={handleSelectChanges}
                                        placeholder="Choose event..."
                                        className="mt-1.5 w-full rounded-lg bg-white   hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    /> */}

                                    <Select
                                        name="event_id"
                                        //inputValue={eventinputValue}
                                        onInputChange={handleEventInputChange}
                                        options={optionsevts}
                                        isMulti={false}
                                        onChange={(selectedOption) => {

                                            setSelectedEvent(selectedOption);
                                            checkEventTickets(selectedOption.value);
                                            setIdEvent(selectedOption.value);
                                            checkSingleTicketsPrice(selectedOption.value, numberOfTicket);
                                            localStorage.removeItem('window_eventId');
                                        }}
                                        placeholder="Choose Event..."
                                        className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                        defaultOptions // Fetch options on initial render
                                    />

                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"> Event Ticket</label>

                                <Select
                                    name="ticket_id"
                                    onChange={(selectedOption) => handleTicketInputChange(selectedOption, numberOfTicket)}
                                    placeholder="Select ticket..."
                                    className="mt-1.5 w-full rounded-lg border-slate-300 bg-white hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    options={[
                                        { value: '', label: 'Choose ticket...' },
                                        ...SelectedEventData.map(ticket => ({
                                            value: ticket.id,
                                            label: ticket.name
                                        }))
                                    ]}

                                />


                            </div>
                            <div className="lg:flex mb-4 ">
                                <div className="w-full">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Number of Tickets</label>
                                    <div className="mb-4">
                                        <input
                                            type="number"
                                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="complimentary_tickets"
                                            value={complimentaryTickets}
                                            onChange={handleComplimentaryTicketsChange}
                                            placeholder="Number of complimentary tickets"
                                            min='1'
                                            max={CheckAvailTicket}
                                        />
                                        <div className='text-red-500'>{AvailableTicket}</div>
                                    </div>

                                </div>
                                <div className="w-full">
                                    <div className="mb-4 lg:ml-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Total Price (Price in {symbol})</label>
                                        <input
                                            type="ticket_price"
                                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="ticket_price"
                                            value={`${symbol} ${isNaN(subTotal) ? '0.00' : subTotal.toFixed(2)}`}
                                            placeholder=""
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-4 lg:pl-0 pl-2">
                                <div className="w-full">
                                    <button
                                        type="submit"
                                        disabled={!SelectedTicket || !complimentaryTickets || loading || disable}
                                        onClick={openModal}
                                        className={`px-4 py-2 text-white rounded focus:outline-none ${!SelectedTicket || !complimentaryTickets || loading || disable ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                                    >
                                        {loading ? (
                                            <div className="spinner-border text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {isModalOpen && (
                    <div>
                        <div className={`fixed inset-0 ${isModalOpen ? 'bg-black opacity-50' : ''}`} onClick={closeModal}></div>
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="relative bg-white p-8 lg:w-[50rem] md:[50rem] w-96 rounded-md">
                                <div className="flex mb-4">
                                    <h2 className="text-2xl font-bold">Window Ticket Confirmation</h2>
                                    <button onClick={closeModal} className="ml-auto">
                                        <svg className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                                            <line x1="9" y1="9" x2="15" y2="15" />
                                            <line x1="15" y1="9" x2="9" y2="15" />
                                        </svg>
                                    </button>
                                </div>
                                <hr className="mb-4" />
                                <div className="flex items-center mt-3 hidden">
                                    <svg className="h-4 w-4 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                    <p className="font-medium text-black font-bold">You can add up to 4 additional U18 free tickets.</p>
                                </div>
                                <div className="mt-5 hidden ">
                                    <input type="number" max="4" onChange={handleChange_free} placeholder="Add U18 free tickets" className="border border-gray-300 p-2 w-full" />
                                </div>

                                <div className="mt-6">
                                    <p className="text-gray-400 text-lg font-bold">Order Summary:</p>
                                </div>
                                <div className="mt-5">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                                        <tbody>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Event:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {SelectedEvent.label}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Paid Tickets:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {selectedTickets}
                                                </td>
                                            </tr>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Sub Total:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {subTotal.toFixed(2)}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Service Fee:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {serviceFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Processing Fee:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {processingFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>


                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Vat Tax:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {vatTotal.toFixed(2)}
                                                </td>
                                            </tr>


                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                Agent Commission:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {symbol} {agentCommisionTotal.toFixed(2)}
                                                </td>
                                            </tr>

 
                                             {/* <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Processing fee ({webfee_percentage}%):
                                                </th>
                                                <td className="px-4 py-3">
                                                {symbol} {((totalPrice * webfee_percentage) / 100).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                    <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                        Vat Fee ({vatfee_percentage}%):
                                                    </th>
                                                    <td className="px-4 py-3">
                                                        {symbol} {((totalPrice * webfee_percentage / 100) * vatfee_percentage / 100).toFixed(2)}
                                                    </td>
                                                </tr> */}
                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 hidden">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Free Tickets:
                                                </th>
                                                <td className="px-4 py-3">
                                                    {additional_ticket}
                                                </td>
                                            </tr>

                                            <tr className="no-shadow-border bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                                                <th scope="row" className="py-3 pl-4 pr-2 font-medium text-gray-900 dark:text-white">
                                                    Grand Total:
                                                </th>
                                                <td className="px-4 py-3">
                                                {symbol} {totalPrice.toFixed(2)}
                                                {/* {symbol} {((totalPrice + (totalPrice * webfee_percentage) / 100)).toFixed(2)} */}
                                                {/* {symbol} {((totalPrice + (totalPrice * webfee_percentage / 100)) + ((totalPrice * webfee_percentage / 100) * vatfee_percentage / 100)).toFixed(2)} */}
                                                </td>
                                            </tr>
                                            {/* Add more table rows as needed */}
                                        </tbody>


                                    </table>
                                </div>
                                <hr className="mt-6" />
                                <div className="flex justify-end mt-5">
                                    <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 py-3 px-4 mr-4 text-white font-semibold rounded-lg cursor-pointer">
                                        No
                                    </button>
                                    <button onClick={() => confirmSellTicket(SelectedTicket)} className={`bg-blue-700 hover:bg-blue-500 py-3 px-4 text-white font-semibold rounded-lg`}>

                                        {isLoading ? (
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                                                <span>Loading...</span>
                                            </div>
                                        ) : (
                                            "Yes"
                                        )}

                                    </button>

                                    {/* {disable ? (

                                        <button onClick={() => confirmSellTicket(SelectedTicket)} disabled className={`bg-blue-700 hover:bg-blue-500 py-3 px-4 text-white font-semibold rounded-lg   ${disable ? 'cursor-not-allowed' : ''}`}>          

                                            {isLoading ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                                                    <span>Loading...</span>
                                                </div>
                                            ) : (
                                                "Yes"
                                            )}
                                        </button>

                                    ) : (

                                        <button onClick={() => confirmSellTicket(SelectedTicket)} disabled className={`bg-blue-700 hover:bg-blue-500 py-3 px-4 text-white font-semibold rounded-lg   ${disable ? 'cursor-not-allowed' : ''}`}>

                                            {isLoading ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                                                    <span>Loading...</span>
                                                </div>
                                            ) : (
                                                "Yes"
                                            )}

                                        </button>

                                    )} */}



                                </div>

                            </div>
                        </div>
                    </div>
                )}

            </main>
        </>
    )
}

export default WindowTickets;
