import React from "react";
import { CustomerReportCashFetch } from "../../../services/admin/cash-report-service";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import SEO from "../../../hooks/seo";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import TableHeader from "../../../hooks/TableHeader";
import { useState, useEffect, useRef } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import useResizeObserver from "../../../hooks/useResizeObserver";
import 'react-datepicker/dist/react-datepicker.css';
import { API_ENDPOINTS } from '../../../services/admin/api';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subMonths, subWeeks, format } from 'date-fns'; // Import format function
import Datepicker from "react-tailwindcss-datepicker";
const CustomerReportCash = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLoading, setPageLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(1);
    const [yesOrnot, setyesOrnot] = useState(false);
    const [text, setText] = useState('');
    const [advanced_Search, setAdvancedSearch] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [customRange, setCustomRange] = useState(false);
    const [showCustomRangePicker, setShowCustomRangePicker] = useState(false);
    const [selectedRangeText, setSelectedRangeText] = useState('');
    {/* useResizeObserver Start  */ }
    const [size, setSize] = useState({ width: 0, height: 0 });
    const ref = useRef(null);
    const [timeValue, setTimeValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    // Implement the callback function
    const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
    };
    // Use the custom hook
    useResizeObserver(ref, handleResize);
    {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await CustomerReportCashFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, searchText]);

    const handleSearchChange = (e) => {
        setText(e.target.value);
    };
    const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_CASH_customer_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
 
    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_CASH_customer_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }

    // const handleReset = () => {
    //     setSearchText('');
    // };

    const search_Text = () => {
        setSearchText(text);
        setyesOrnot(true);
    };
    
    const resetSearch = async () => {
        setSearchText('');
        setText('');
        setyesOrnot(false);
    
        const inputElement = document.getElementById('inptserch');
        if (inputElement) {
            inputElement.value = '';
        }
    
        await fetchDataWithFilters(''); // Fetch data without any filters
    };

     const handleAdvancedSearch = () => {

        setAdvancedSearch(!advanced_Search)
    }
    
    const fetchDataWithFilters = async (name, startDate, endDate) => {
        setLoading(true);
        try {
            const response = await CustomerReportCashFetch(currentPage, name,timeValue);
            if (response && response.data) {
                setTotalRecords(response.recordsTotal);
                setData(response.data);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
        setPageLoading(true);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const name = formData.get('name');

        fetchDataWithFilters(name);
        // fetchDataWithFilters(timeValue);
    };

   

    const handleCustomRange = (range) => {
        const today = new Date();
        switch (range) {
            case 'this_week':
                setStartDate(startOfWeek(today));
                setEndDate(endOfWeek(today));
                setCustomRange(true);
                break;
            case 'last_week':
                const lastWeek = subWeeks(today, 1);
                setStartDate(startOfWeek(lastWeek));
                setEndDate(endOfWeek(lastWeek));
                setCustomRange(true);
                break;
            case 'this_month':
                setStartDate(startOfMonth(today));
                setEndDate(endOfMonth(today));
                setCustomRange(true);
                break;
            case 'last_month':
                const lastMonth = subMonths(today, 1);
                setStartDate(startOfMonth(lastMonth));
                setEndDate(endOfMonth(lastMonth));
                setCustomRange(true);
                break;
            default:
                setStartDate(null); // Reset startDate
                setEndDate(null);   // Reset endDate
                setCustomRange(true);
                break;
        }
    };

    const handleCustomRangeChange = (event) => {
        const range = event.target.value;
        if (range === 'custom') {
            setShowCustomRangePicker(true);
            setCustomRange(false);
        } else {
            handleCustomRange(range);
            setShowCustomRangePicker(false);
        }
    };

    const [value, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    
    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setTimeValue(newValue);
    }

    return (
        <>
        <SEO
        title="Customer Report - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Customer Report"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
            />

<div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4 mb-4">
                    <div className="card px-4 pb-4 sm:px-5">
                        <TableHeader
                            //title="Customer Report Rows"
                            onSearchChange={handleSearchChange}
                            showSearch={true}
                            showExportButtons={true}
                            onExportCSV={handleExportCSV}
                            onExportPDF={handleExportPDF}
                            onText={yesOrnot}
                            onsearch_Text={search_Text}
                            onResetSearch={resetSearch}
                            advancedSearch={handleAdvancedSearch}
                            showAdvanceSearch={false}
                        />

                        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
                            {advanced_Search && (
                                <div className="col-span-12 lg:col-span-12 mt-4">
                                    <form onSubmit={handleFormSubmit} className="space-y-4">
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                            <div className="mb-3 lg:mb-0">
                                                <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="customer-name"
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Enter Customer name"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>



                                            {/* <div className="mb-3 lg:mb-0">

                                                <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                        </svg>
                                                    </span>
                                                    <select value={customRange} onChange={handleCustomRangeChange}
                                                        className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select Range</option>
                                                        <option value="this_week">This Week</option>
                                                        <option value="last_week">Last Week</option>
                                                        <option value="this_month">This Month</option>
                                                        <option value="last_month">Last Month</option>
                                                        <option value="custom">Custom Range</option>
                                                    </select>

                                                

                                                </div>

                                            </div> */}
                                            {/* {selectedRangeText && !showCustomRangePicker && (
                                                <div className="mb-3 lg:mb-0">
                                                    <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                    <div className="flex">
                                                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                            </svg>
                                                        </span>

                                                        <input type="text"
                                                            value={selectedRangeText}
                                                            readOnly
                                                            className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        />

                                                    </div>
                                                </div>
                                            )} */}
                                            
                                            <div className="mb-3 lg:mb-0">
                                                    <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Date</label>
                                                    <div className="flex">
                                                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                            </svg>
                                                        </span>
                                                       

                                                        <Datepicker
                                                        primaryColor={"blue"} 
                                                            value={value}
                                                            onChange={handleValueChange}
                                                            showShortcuts={true} 
                                                            className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        />

                                                    </div>
                                                </div>
                                            
                                            {showCustomRangePicker ? (
                                                <div className="mb-3 lg:mb-0">
                                                    <label htmlFor="customer-name" className="block mb-2 text-sm font-medium dark:text-white">Customer Name</label>
                                                    <div className="flex">
                                                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-l-md border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                            </svg>
                                                        </span>
                                                       

                                                        <Datepicker
                                                            value={value}
                                                            onChange={handleValueChange}
                                                            showShortcuts={true} 
                                                            className="rounded-none rounded-r-md bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        />

                                                    </div>
                                                </div>
                                            ) : null}

  



                                        </div>



                                        <div className="flex justify-end">
                                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white btn focus:bg-blue-700 ml-2">Apply</button>

                                            <button
                                                type="reset"
                                                className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 focus:bg-gray-500 ml-2"
                                                onClick={resetSearch}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </form>
                                </div>



                            )}
                        </div>

                    </div>
                </div>

            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Customer Report Rows"
                        // onSearchChange={handleSearchChange}
                        // onExportCSV={handleExportCSV}
                        // onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                         
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                         <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Customer Name
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                         Email
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Phone
                                        </th>
                                        <th className="whitespace-nowrap text-center bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Tickets Sold
                                        </th>
                                        <th className="whitespace-nowrap text-center bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Registered At
                                        </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            <div className="flex items-center">
                                            <div class="avatar flex">
                                                            <img className="rounded-full" src={item.imagePath + item.image} alt="avatar" />
                                                            </div>
                                                <div>
                                                <p className="text-sm font-medium ml-2 text-gray-900">
                                                {(item.name !== null ? item.name : '') + (item.name && item.last_name ? ' ' : '') + (item.last_name !== null ? item.last_name : '')}
                                                </p>
                                                </div>
                                            </div>
                                            </td>

                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.email || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.phone ? item.phone : 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                                {item.buy_tickets}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                                                        {formatDate(item.created_at)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                                        )}
                         <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                                        </div>
                                     
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default CustomerReportCash;
