import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation , Link } from 'react-router-dom';
import logoImage from '../assets/images/app-logo.svg';
import logo from '../assets/images/logo.png';
import { login } from '../services/authService';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getProfileDetails } from '../services/profileService';
import { useSetting } from '../hooks/SettingContext';
import { toast, Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import CustomLoader from './customloader';
import SEO from "../hooks/seo";

import { Helmet } from 'react-helmet';
const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const message = location.state?.message;
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false); // State to track password visibility
    const { settings, updateSettings } = useSetting();
    useEffect(() => {
        const timeout = setTimeout(() => {
            setPageLoading(false);
        }, 2000); // 2 seconds

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!pageLoading) {
            setLoading(true); // Set loading to true when starting profile details request
            getProfileDetails()
                .then(data => {
                    setLoading(false);
                    if (!data || Object.keys(data).length === 0) {
                        navigate('/login');
                    } else {
                        switch (data.role) {
                            case 'admin':
                                navigate('/admin/dashboard');
                                break;
                            case 'organizer':
                                navigate('/organization/dashboard');
                                break;
                            case 'agent':
                                navigate('/agent/dashboard');
                                break;
                            default:
                                Swal.fire({
                                    title: 'Invalid Role',
                                    text: 'Invalid Role: ' + data.role,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                });
                                break;
                        }
                    }
                })
                .catch(error => {
                    console.error(error.message);
                    navigate('/login');
                });
        }
    }, [navigate, pageLoading]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const data = await login(values.email, values.password);
            console.log(data);
             
            switch (data.role) {
                case 'admin':
                    navigate('/admin/dashboard');
                    break;
                case 'organizer':
                    navigate('/organization/dashboard');
                    break;
                case 'agent':
                    navigate('/agent/dashboard');
                    break;
                default:
                    Swal.fire({
                        title: 'Invalid Role',
                        text: 'Invalid Role: ' + data.role,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                    break;
            }
        } catch (error) {
            console.error('Login failed:', error);
            if (error.response && error.response.status === 401) {
                Swal.fire('Unauthorized', 'Invalid Credentials', 'error');
            } else {
                Swal.fire('Error', 'Login failed. Please try again later.', 'error');
            }
        } finally {
            setSubmitting(false);
        }
    };
    useEffect(() => {
        const existingFavicon = document.querySelector("link[rel*='icon']");
        if (existingFavicon) {
          existingFavicon.href = `https://api.tikkets.com/public/images/upload/${settings.favicon}`;
        }
      }, [settings.favicon]);
    if (pageLoading) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
                <div className="p-6 lg:p-12">
                    <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
        <Helmet>
        <script>
          {`
            var existingFavicon = document.querySelector("link[rel*='icon']");
            if (existingFavicon) {
              existingFavicon.href = "${'https://api.tikkets.com/public/images/upload/'+ settings.favicon}";
            }
          `} 
        </script>
      </Helmet>
          <SEO
      title="Login - eticketing"
    />
            <Toaster position="top-center" />
            <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
                <a href="/" className="flex items-center space-x-2">
                    <img className="w-40 h-12 mx-auto" src={`https://api.tikkets.com/public/images/upload/${settings.logo}`} alt="logo" />
                </a>
            </div>
            <div className="hidden w-full place-items-center lg:grid">
                <div className="w-full max-w-lg p-6">
                    <img
                        className="w-full"
                        id="hero-image-light"
                        src="images/illustrations/dashboard-check.svg"
                        alt="image"
                    />
                    <img
                        className="w-full hidden"
                        id="hero-image-dark"
                        src="images/illustrations/dashboard-check-dark.svg"
                        alt="image"
                    />
                </div>
            </div>
            <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
                <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
                    <div className="text-center">
                        <img className="mx-auto w-44 h-16 lg:hidden" src={`https://api.tikkets.com/public/images/upload/${settings.logo}`} alt="logo" />
                        <div className="mt-4">
                            <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                                Welcome Back
                            </h2>
                            <p className="text-slate-400 dark:text-navy-300">Please sign in to continue</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={LoginSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSubmit(values, { setSubmitting });
                        }}
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <Form className="mt-16">
                                <div className="grid grid-cols-1  sm:grid-cols-12 ">
                                   

                                    <label class="block col-span-12">
                                        <span>Email Address</span>
                                        <div class="relative mt-1.5 flex">
                                          
                                          <Field
                                        name="email"
                                        className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        placeholder="Email"
                                        type="text"
                                             />


                                          <span
                                            class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                                          >
                                            <i class="fa-regular fa-envelope text-base"></i>
                                          </span>
                                        </div>
                                    </label>

                                </div>
                                {errors.email && touched.email && (
                                    <p className="mt-2 peer-invalid:visible text-pink-600 text-sm">{errors.email}</p>
                                )}
                                <div className="grid grid-cols-1  sm:grid-cols-12 ">

                        <label class="block col-span-12 mt-3">
                    <span>Password</span>
                    <span class="relative mt-1.5 flex">
                       

                      <Field
                        name="password"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                    />
                    <button
                        type="button"
                         onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        {showPassword ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                />
                            </svg>
                        )}
                    </button>


                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa fa-lock text-base"></i>
                      </span>



                    </span>

                    {touched.password && errors.password && (
                    <div className="text-red-500">{errors.password}</div>
                  )}
                  </label>

{/*                                     <Field */}
{/*                                         name="password" */}
{/*                                         className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" */}
{/*                                         placeholder="Password" */}
{/*                                         type={showPassword ? 'text' : 'password'} // Use state to toggle type */}
{/*                                     /> */}
{/*                                     <button */}
{/*                                         type="button" */}
{/*                                         className="absolute inset-y-0 right-0 px-3 flex items-center" */}
{/*                                         onClick={() => setShowPassword(!showPassword)} // Toggle state */}
{/*                                     > */}
{/*                                         {showPassword ? ( */}
{/*                                             <svg */}
{/*                                                 xmlns="http://www.w3.org/2000/svg" */}
{/*                                                 className="h-6 w-6 text-gray-400 hover:text-gray-600" */}
{/*                                                 fill="none" */}
{/*                                                 viewBox="0 0 24 24" */}
{/*                                                 stroke="currentColor" */}
{/*                                             > */}
{/*                                                 <path */}
{/*                                                     strokeLinecap="round" */}
{/*                                                     strokeLinejoin="round" */}
{/*                                                     strokeWidth={2} */}
{/*                                                     d="M6 18L18 6M6 6l12 12" */}
{/*                                                 /> */}
{/*                                             </svg> */}
{/*                                         ) : ( */}
{/*  */}
{/*                                             <svg */}
{/*                                                 xmlns="http://www.w3.org/2000/svg" */}
{/*                                                 class="h-6 w-6 text-gray-400 hover:text-gray-600" */}
{/*                                                 fill="none" */}
{/*                                                 viewBox="0 0 24 24" */}
{/*                                                 stroke="currentColor" */}
{/*                                             > */}
{/*                                                 <path */}
{/*                                                     stroke-linecap="round" */}
{/*                                                     stroke-linejoin="round" */}
{/*                                                     stroke-width="2" */}
{/*                                                     d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" */}
{/*                                                 /> */}
{/*                                                 <path */}
{/*                                                     stroke-linecap="round" */}
{/*                                                     stroke-linejoin="round" */}
{/*                                                     stroke-width="2" */}
{/*                                                     d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z" */}
{/*                                                 /> */}
{/*                                             </svg> */}
{/*  */}
{/*  */}
{/*                                         )} */}
{/*                                     </button> */}
                                </div>
                                 
                                <div>
                                   <Link to="/forgetPassword" className="text-blue-500 text-xs">
                                          Forget Password
                                        </Link>
                                </div>
                                <button
                                    type="submit"
                                    className={`btn mt-10 h-10 w-full bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isSubmitting ? 'cursor-not-allowed' : ''
                                        }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <div className="flex items-center justify-center space-x-2">
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full" role="status"></div>
                                            <span>Loading...</span>
                                        </div>
                                    ) : (
                                        'Sign In'
                                    )}
                                </button>
                                <p class="text-sm mt-2 font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <Link to="/register" class="font-medium text-primary-600 hover:underline dark:text-primary-500"> Sign up</Link></p>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
                    <a href="#">Privacy Notice</a>
                    <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                    <a href="#">Term of service</a>
                </div>
            </main>
        </>
    );
};

export default Login;
