import { useState, useEffect } from 'react';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const storedUserRole = localStorage.getItem('userRole');

    if (authToken) {
      setIsAuthenticated(true);
      setUserRole(storedUserRole);
    }

    setIsLoading(false); // Set loading to false after checking auth status
  }, []);

  return { isAuthenticated, userRole, isLoading };
};
