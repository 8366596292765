import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { submitEventData, createEvent, searchScanner, storeEvent } from "../../../services/admin/eventservice";
import { EventListFetch } from "../../../services/admin/eventservice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster, toast } from "react-hot-toast";
import PageHeader from "../../../hooks/PageHeader";
import Select from "react-select";
import ReactQuill from "react-quill";
import SEO from "../../../hooks/seo";
import "react-quill/dist/quill.snow.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import debounce from 'lodash.debounce';
import { CreateScannerData } from '../../../services/scannerServices';
import CustomLoader from '../Loaderfiles/customloader';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";


const SignupSchema = Yup.object({
  name: Yup.string().required("Event Name is required").max(250, "Event Name must not exceed 250 characters"),
  category_id: Yup.string().required("Category is required"),
  start_time: Yup.date()
    .required("Event Start Time is required"),
    end_time: Yup.date()
    .min(Yup.ref('start_time'), "End Date must be after Start Date")
    .required("Event End Time is required"),
  scanner_id: Yup.array().min(1, 'Scanner required'),

  currency_id: Yup.string().required("Local Currency required"),
  maximumPeople: Yup.number().required("Maximum people allowed is required"),
  appImage: Yup.array()
  .min(1, 'App Image is required')
  .test('image-size', 'App Image must be at least 1600x900', async (value) => {
    if (!value) return false;  
    const dimensions = await Promise.all(value.map(async (image) => {
      try {
        return await getImageDimensions(image);
      } catch (error) {
        console.error('Error loading image:', error);
        return { width: 0, height: 0 }; // Return dummy dimensions
      }
    }));
    return dimensions.every(({ width, height }) => width >= 1600 && height >= 900);
  }),

webImage: Yup.array()
  .min(1, 'Website Image is required')
  .test('image-size', 'Website Image must be at least 1600x900', async (value) => {
    if (!value) return false; 
    const dimensions = await Promise.all(value.map(async (image) => {
      try {
        return await getImageDimensions(image);
      } catch (error) {
        console.error('Error loading image:', error);
        return { width: 0, height: 0 }; // Return dummy dimensions
      }
    }));
    return dimensions.every(({ width, height }) => width >= 1600 && height >= 900);
  }),

 
  isPublic: Yup.boolean(),
  isPrivate: Yup.boolean(),
  complimentary_tickets: Yup.number().required(
    "Number of complimentary Tickets is required"
  ),
  eventType: Yup.string().required('Event type is required'),
  address: Yup.string().when('eventType', (eventType, schema) => {
    return eventType[0] === 'offline'
      ? schema.required('Address is required for venue events')
      : schema.notRequired();
  }),

  latitude: Yup.string().when('eventType', (eventType, schema) => {
    return eventType[0] === 'offline'
      ? schema.required('Latitude is required for venue events')
      : schema.notRequired();
  }),

  longitude: Yup.string().when('eventType', (eventType, schema) => {
    return eventType[0] === 'offline'
      ? schema.required('Longitude is required for venue events')
      : schema.notRequired();
  }),
  status: Yup.string().required("Status is required"),
  tags: Yup.array()
    .of(Yup.string())
    .required('Tags is required')
    .min(1, 'At least one tag is required'),

  description: Yup.string().required("Description is required"),


}).test(
  function (values) {

    return true;
  }
);
const getImageDimensions = (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      let width = 1600;
      let height = 900;
      if (aspectRatio > 16 / 9) {
        height = Math.floor(width / aspectRatio);
      } else {
        width = Math.floor(height * aspectRatio);
      }

      resolve({ width, height });
    };
    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
    img.src = URL.createObjectURL(image);
  });
};
const EventsCreate = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [scanners, setScanners] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [defaultScanners, setDefaultScanners] = useState([]);

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [eventType, setEventType] = useState('offline');
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  });

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }
  const initialValues = {
    name: "",
    category_id: "",
    start_time: null,
    end_time: null,
    currency_id: "",
    scanner_id: [],
    complimentary_tickets: "",
    status: "",
    tags: "",
    description: "",
    appImage: [],
    webImage: [],
    maximumPeople: "",
    exclusive: 0,
    security: 0,
    eventType: 'offline',
    address: "",
    latitude: "",
    longitude: "",
    type: "",
    is_vat_accept: 1,
  };



  const [isInclusive, setInclusive] = useState(true);
  const handleEventTypeChange = (type) => {
    setEventType(type);
  };

  const statusOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const slugify = (text) => {
        if (!text) return '';
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    };
    const formData = new FormData();
    Object.keys(values).forEach(key => {
        if (key === "webImage" && values[key].length > 0) {
            const file = values[key][0];
            if (file.type.startsWith('image/') && file.type !== 'image/gif') {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = async () => {
                    const aspectRatio = img.width / img.height;
                    if (aspectRatio === 16 / 9) {
                        formData.append(key, file);
                        try {
                            const startTime = new Date(values.start_time);
                            const endTime = new Date(values.end_time);
                            if (endTime <= startTime) {
                                Swal.fire({
                                    title: 'Error',
                                    text: 'End time must be later than start time',
                                    icon: 'error',
                                    confirmButtonColor: '#a23896',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'OK',
                                    showCloseButton: false
                                });
                                setIsLoading(false);
                                return;
                            }
                            const response = await storeEvent(formData);
                            if (response && response.success === true) {
                                Swal.fire({
                                    title: 'Good Job',
                                    text: "Event Successfully Created!",
                                    icon: 'success',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3b82f6',
                                    cancelButtonColor: '#22c55e',
                                    confirmButtonText: 'Event List',
                                    cancelButtonText: 'Manage Tickets',
                                    showCloseButton: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/admin/events', { state: { message: response.msg } });
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                        const eventId = response.event.id;
                                        const title = values.name || 'untitled';
                                        navigate(`/admin/${eventId}/${slugify(title)}/tickets`);
                                    }
                                });
                            }
                        } catch (error) {
                            setIsLoading(false);
                            // Handle other errors if any
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Please upload a 1600 x 900 aspect ratio Website image',
                            icon: 'error',
                            confirmButtonColor: '#a23896',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            showCloseButton: false
                        });
                        setIsLoading(false);
                        return;
                    }
                };
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Please upload a valid image file',
                    icon: 'error',
                    confirmButtonColor: '#a23896',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    showCloseButton: false
                });
                setIsLoading(false);
                return;
            }
        } else if (key === "appImage" && values[key].length > 0) {
            const file = values[key][0];
            if (file.type.startsWith('image/') && file.type !== 'image/gif') {
                formData.append(key, file);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Please upload a valid image file for App Image',
                    icon: 'error',
                    confirmButtonColor: '#a23896',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    showCloseButton: false
                });
                setIsLoading(false);
                return;
            }
        } else {
            formData.append(key, values[key]);
        }
    });
};





  const onBackButtonClick = () => {
    navigate('/admin/events');
  }





  const fetchScannerData = async (value) => {

    try {
      const scanner = await searchScanner(value);
      const transformedOptions = scanner.scanners.map(item => ({
        value: item.id,
        label: item.first_name + ' ' + item.last_name
      }));
      setScanners(transformedOptions);
    } catch (error) {
      console.error('Error fetching scanner data:', error);
    }

  };


  const debouncedFetch = debounce((value) => {
    fetchScannerData(value);
  }, 200);

  useEffect(() => {
    debouncedFetch(inputValue);
    return () => {
      debouncedFetch.cancel();
    };
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };


  const fetchEventData = async () => {
    try {
      const response = await createEvent();


      const transformedOptions = response.scanner.map(item => ({
        value: item.id,
        label: item.first_name + ' ' + item.last_name
      }));

      setScanners(transformedOptions);


      setCategoryOptions(response.category);
      setCurrencyOptions(response.currencies);
    } catch (error) {
      console.error('Error fetching scanner data:', error);
    }

  };

  useEffect(() => {
    fetchEventData();

  }, []);


  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const defaultCountry = 'US';
  const handleCountryChange = (country) => {

    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };


  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setError,
    setValue,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [isScannerCreateLoading, setIsScannerCreateLoading] = useState(false);
 
    // const HandleErrorMessage = (fieldName) => {
    //   setErrors(prevErrors => {
    //     const updatedErrors = { ...prevErrors };
    //     delete updatedErrors[fieldName];
    //     return updatedErrors;
    //   });
    // };
  
  const ScannerUpdater = ({ defaultScanners }) => {
    const { setFieldValue } = useFormikContext();
  
    useEffect(() => {
      const scannerIds = defaultScanners.map(scanner => scanner.value);
      setFieldValue('scanner_id', scannerIds);
    }, [defaultScanners, setFieldValue]);

    return null;  
  };
  const onSubmitScanner = async (formDataScanner) => {



    try {
      setIsScannerCreateLoading(true);
      const scannerCreateResult = await CreateScannerData(formDataScanner);
      setShowModal(false);
      reset();

      if (scannerCreateResult.success) {
        fetchEventData();
        const transformedOptions = {
          value: scannerCreateResult.scanners.id,
          label: scannerCreateResult.scanners.first_name + " " + scannerCreateResult.scanners.last_name,
        };
        setDefaultScanners([transformedOptions, ...defaultScanners]);
        setScanners([transformedOptions, ...scanners]);
        toast.success('Submit Successfully', { position: 'top-right' });

      } else {
        const errorData = await scannerCreateResult.json();
        const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
        toast.error(errorMessage, { position: 'top-center' });
      }
    } catch (error) {
      toast.error(`${error.message}`, { position: 'top-right' });
    } finally {
      setIsScannerCreateLoading(false);
    }

  };



  return (
    <>
      <SEO
        title="Create Event - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">



        <Toaster />
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center h-96">
              <CustomLoader
                visible={true}
                height={80}
                width={80}
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius={1}
              />
            </div>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ errors, touched, setFieldValue, values, resetForm }) => {

              // Corrected handleSelect function
              const handleSelect = async value => {

                try {
                  const results = await geocodeByAddress(value);
                  const latLng = await getLatLng(results[0]);
                  setCoordinates(latLng);
                  // Now using the setFieldValue correctly
                  setAddress(value);
                  setFieldValue('address', value);
                  setFieldValue('latitude', latLng.lat.toString());
                  setFieldValue('longitude', latLng.lng.toString());
                } catch (error) {
                  console.error('Error', error);
                }
              };

              return (
                <Form className="">
                  <PageHeader
                    title="Create Event"
                    showCreateButton={false}
                    showSubmitButton={false}
                    onSubmitButtonClick={handleSubmit}
                    showBackButton={true}
                    onBackButtonClick={onBackButtonClick}
                  />
                   {Object.keys(errors).map(fieldName => (
                          (errors[fieldName] && touched[fieldName]) && (
                            <div className="error-messages mt-2 mb-2" key={fieldName}>
                              <div className="alert flex items-center justify-between overflow-hidden rounded-lg border border-error text-error">
                                <div className="flex">
                                  <div className="bg-error p-3 text-white">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="size-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="px-4 py-3 sm:px-5">
                                    {errors[fieldName]}
                                    <br />
                                  </div>
                                </div>
                                {/* <div className="px-2">
                                  <button
                                    onClick={() => HandleErrorMessage(fieldName)}
                                    className="btn size-7 rounded-full p-0 font-medium text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="size-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          )
                        ))}

                  <ScannerUpdater defaultScanners={defaultScanners} />
                  {/* <div className="validation-errors"> */}
                  {/*         {Object.keys(errors).length > 0 && ( */}
                  {/*           <ul> */}
                  {/*             {Object.keys(errors).map((fieldName) => { */}
                  {/*               // Check if the field was touched before displaying the error */}
                  {/*               if (touched[fieldName]) { */}
                  {/*                 return <li key={fieldName}>{errors[fieldName]}</li>; */}
                  {/*               } */}
                  {/*               return null; */}
                  {/*             })} */}
                  {/*           </ul> */}
                  {/*         )} */}
                  {/*       </div> */}

                  <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
                    <div className="col-span-12 lg:col-span-8">
                      <div className="card">
                        <div className="tabs flex flex-col">
                          <div className="p-4 sm:p-5">
                            <div className="space-y-5">
                              <label className="block">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Title
                                </span>
                                <Field
                                  name="name" // specify the field name
                                  className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                  placeholder="Enter post title"
                                  type="text"
                                  autoComplete="off" 
                                />
                                {/* If you want to display errors for this field */}
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </label>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Description
                                </span>
                                <div className="mt-1.5 w-full">
                                  <ReactQuill
                                    id="description"
                                    name="description"
                                    value={values.description || ''}
                                    style={{ height: '150px', marginBottom: '40px' }}
                                    onChange={(content) => {
                                      const isEmpty = !content || content === '<p><br></p>';
                                      setFieldValue("description", isEmpty ? '' : content);
                                    }}

                                    modules={{
                                      toolbar: [
                                        [{ header: [1, 2, false] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                        ['link', 'image'],
                                        ['clean'],
                                      ],
                                    }}
                                    formats={[
                                      'header',
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote',
                                      'list',
                                      'bullet',
                                      'link',
                                      'image',
                                    ]}
                                    placeholder="Write something..."
                                  />
                                            {/* <Field
                                      as="textarea"
                                      id="description"
                                      className="block p-2.5 w-full text-sm text-gray-900 bg-White rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      name="description"
                                      rows="4"
                                      placeholder="Enter event description"
                                    /> */}



                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-12">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  App Image
                                </span>
                                <div className="filepond fp-bordered fp-grid [--fp-grid:2]">
                                  <FilePond
                                    onupdatefiles={(fileItems) => {
                                      setFieldValue('appImage', fileItems.map(fileItem => fileItem.file));
                                    }}
                                    allowMultiple={false}
                                    name="appImage"
                                    labelIdle='Drag & Drop your files or Browse <span class="filepond--label-action"></span>'
                                  />
                                  <ErrorMessage
                                    name="appImage"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-5">
                                <span className="font-medium text-slate-600 dark:text-navy-100">
                                  Website Image
                                </span>
                                <div className="filepond fp-bordered fp-grid mt-1.5 [--fp-grid:2]">
                                  <FilePond
                                    onupdatefiles={(fileItems) => {
                                      setFieldValue('webImage', fileItems.map(fileItem => fileItem.file));
                                    }}
                                    allowMultiple={false}
                                    name="webImage"
                                    labelIdle='Drag & Drop your files or Browse<span class="filepond--label-action"></span>'
                                  />
                                  <ErrorMessage
                                    name="webImage"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                {/* Maximum People */}
                                <label className="block">
                                  <span>Maximum People</span>
                                  <span className="relative mt-1.5 flex">
                                    <Field
                                      name="maximumPeople"
                                      type="number"
                                      min="0"
                                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      placeholder="Maximum People"
                                    />
                                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                      <i className="far fa-user text-base" />
                                    </span>
                                  </span>
                                  <ErrorMessage
                                    name="maximumPeople"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </label>

                                {/* Complimentary Tickets */}
                                <label className="block">
                                  <span>Complimentary Tickets</span>
                                  <span className="relative mt-1.5 flex">
                                    <Field
                                      name="complimentary_tickets"
                                      type="text"
                                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      placeholder="Complimentary Tickets"
                                    />
                                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                      <i className="far fa-user text-base" />
                                    </span>
                                  </span>
                                  <ErrorMessage
                                    name="complimentary_tickets"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </label>
                              </div>

                              <div className="flex justify-start space-x-2">
                                <button
                                  type="button"
                                  className={`btn space-x-2 ${eventType === 'offline' ? 'bg-slate-700 text-white' : 'bg-slate-500 text-white'} font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90`}
                                  onClick={() => {
                                    setEventType('offline');
                                    setFieldValue("eventType", 'offline');
                                  }}
                                >
                                  <span>Venue</span>
                                </button>
                                <button
                                  type="button"
                                  className={`btn space-x-2 ${eventType === 'online' ? 'bg-slate-700 text-white' : 'bg-slate-500 text-white'} font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90`}
                                  onClick={() => {
                                    setEventType('online');
                                    setFieldValue("eventType", 'online');
                                  }}
                                >
                                  <span>Online Event</span>
                                </button>
                                <ErrorMessage
                                  name="eventType"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </div>

                              {eventType === 'offline' && (
                                <>
                                  <label className="block">
                                    <span className="font-medium text-slate-600 dark:text-navy-100">
                                      Enter Address
                                    </span>

                                    <PlacesAutocomplete
                                      value={address}
                                      onChange={setAddress}
                                      onSelect={handleSelect}
                                    >
                                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                          <input

                                            {...getInputProps({
                                              placeholder: 'Enter address...',
                                              className: 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent'
                                            })}
                                          />
                                          <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                              const style = {
                                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff'
                                              };
                                              return (
                                                <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                    style
                                                  })}
                                                >
                                                  {suggestion.description}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </label>

                                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                    <label className="block">
                                      <span>Latitude</span>
                                      <span className="relative mt-1.5 flex">
                                        <Field
                                          name="latitude"
                                          type="text"
                                          readOnly
                                          className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                          placeholder="Latitude"

                                          value={coordinates.lat}
                                        />
                                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                          <i className="far fa-user text-base" />
                                        </span>
                                      </span>
                                      <ErrorMessage
                                        name="latitude"
                                        component="div"
                                        className="text-red-500 text-sm"
                                      />
                                    </label>
                                    {/* latitude */}
                                    <label className="block">
                                      <span>Longitude</span>
                                      <span className="relative mt-1.5 flex">
                                        <Field
                                          name="longitude"
                                          type="text"
                                          readOnly
                                          className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                          placeholder="Longitude"
                                          value={coordinates.lat && coordinates.lng && (coordinates.lng)}
                                        />
                                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                                          <i className="far fa-user text-base" />
                                        </span>
                                      </span>
                                      <ErrorMessage
                                        name="longitude"
                                        component="div"
                                        className="text-red-500 text-sm"
                                      />
                                    </label>

                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4">
                      <div className="card space-y-5 p-4 sm:p-5">
                        <label className="block">
                          <div>
                            <div>
                              <span className="inline-block font-medium text-slate-600 dark:text-navy-100">
                                Select Scanners
                              </span>

                              <span
                                onClick={() => setShowModal(true)}
                                className="inline-block ml-2 float-right font-medium text-slate-600 dark:text-navy-100 cursor-pointer text-blue-500"
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4.2a1 1 0 1 0-2 0V11H7.8a1 1 0 1 0 0 2H11v3.2a1 1 0 1 0 2 0V13h3.2a1 1 0 1 0 0-2H13V7.8Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>

                          </div>

                          <Select
                            name="scanner_id"
                            options={scanners}
                            isMulti
                            onChange={(selectedOptions) => {
                              setDefaultScanners(selectedOptions);
                              const selectedScannerIds = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFieldValue("scanner_id", selectedScannerIds);
                            }}
                            value={defaultScanners}
                            isLoading={inputValue && scanners.length === 0}
                            placeholder="Select Scanner"
                            className="mt-1.5 lg:w-full rounded-lg bg-white  py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />


                          <ErrorMessage
                            name="scanner_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>

                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Category
                          </span>
                          <Select
                            name="category_id"
                            options={categoryOptions}
                            onChange={(selectedOption) =>
                              setFieldValue('category_id', selectedOption ? selectedOption.value : '')
                            }
                            placeholder="Select Category"
                            className=" mt-1.5 lg:w-full   rounded-lg  bg-white  py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />
                          <ErrorMessage
                            name="category_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label className="block relative">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Tags
                          </span>
                          <TagsInput
                            value={values.tags || []}
                            onChange={(newTags) => setFieldValue("tags", newTags)}
                            addKeys={["Enter", ","]}
                            inputProps={{ placeholder: "Add tags...", name: "tags" }}
                            className="mt-1 p-2 lg:w-full w-80 border rounded-lg focus:outline-none focus:border-blue-500"
                          />
                          <span className="absolute top-full left-0 text-red-500 text-sm mt-1">
                            <ErrorMessage
                              name="tags"
                              component="div"
                            />
                          </span>

                        </label>

                       {/* Start Date */}
                        <label className="block mt-4">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Start Date
                          </span>
                          <span className="relative mt-1.5 flex">
                            <DatePicker
                              dateFormat="MM/dd/yyyy HH:mm:ss"
                              showTimeSelect
                              timeFormat="HH:mm:ss"
                              name="start_time"
                              className="mt-1 p-2 max-w-full sm:max-w-xs md:max-w-sm lg:max-w-lg xl:max-w-xl border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={values.start_time}
                              onChange={(date) => {
                                // Update start_time value
                                setFieldValue('start_time', date);
                                
                                // If end_time is before start_time, reset it
                                if (values.end_time < date) {
                                  setFieldValue('end_time', date);
                                }
                              }}
                            />
                          </span>
                          <ErrorMessage
                            name="start_time"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        {/* End Date */}
                        <label className="block mt-4">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            End Date
                          </span>
                          <span className="relative mt-1.5 flex">
                            <DatePicker
                              dateFormat="MM/dd/yyyy HH:mm:ss"
                              showTimeSelect
                              timeFormat="HH:mm:ss"
                              name="end_time"
                              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:border-blue-500"
                              placeholderText="MM/DD/YYYY"
                              selected={values.end_time}
                              minDate={values.start_time} // Set minDate to start_time
                              minTime={
                                values.start_time
                                  ? new Date(values.start_time.getTime() + 60000) // If start_time is defined, set minTime to start_time + 1 minute
                                  : null // Otherwise, set minTime to null
                              }
                              maxTime={
                                values.start_time
                                  ? new Date(values.start_time.getTime() + 86399000) // If start_time is defined, set maxTime to start_time + 23 hours, 59 minutes, and 59 seconds
                                  : null // Otherwise, set maxTime to null
                              }
                              onChange={(date) => {
                                // Check if end date is before start date
                                if (date < values.start_time) {
                                  // If end date is before start date, reset end date to start date
                                  date = values.start_time;
                                }
                                setFieldValue('end_time', date);
                              }}
                            />
                          </span>
                          <ErrorMessage
                            name="end_time"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>

                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Local Currency
                          </span>
                          <Select
                            name="currency_id"
                            options={currencyOptions}
                            onChange={(selectedOption) =>
                              setFieldValue('currency_id', selectedOption ? selectedOption.value : '')
                            }
                            placeholder="Select Local Currency"
                            className=" mt-1.5 lg:w-full   rounded-lg  bg-white py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />
                          <ErrorMessage
                            name="currency_id"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <label className="block">
                          <span className="font-medium text-slate-600 dark:text-navy-100">
                            Status
                          </span>
                          <Select
                            name="status"
                            options={statusOptions}
                            onChange={(selectedOption) =>
                              setFieldValue('status', selectedOption ? selectedOption.value : '')
                            }
                            placeholder="Select Status"
                            className=" mt-1.5 lg:w-full   rounded-lg  bg-white py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </label>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                          <label className="inline-flex items-center space-x-2">
                            <span className="text-xs text-slate-400 dark:text-navy-300">
                              {values.security === 0 ? 'Public access' : 'Private access'}
                            </span>

                            <input
                              onChange={() => setFieldValue('security', values.security === 1 ? 0 : 1)}
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="security"
                              checked={values.security === 1} // assuming 1 is the default value
                            />

                            <ErrorMessage
                              name="security"
                              component="div"
                              className="text-red-500 text-sm"
                            />

                          </label>

                          {/* <label className="inline-flex items-center space-x-2">

                            <span className="text-xs text-slate-400 dark:text-navy-300">
                              {values.exclusive === 0 ? 'Exclusive Event' : 'Inclusive Event'}
                            </span>

                            <input
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="exclusive"
                              checked={values.exclusive === 1} // assuming 1 is the default value
                              onChange={() => setFieldValue('exclusive', values.exclusive === 1 ? 0 : 1)}
                            />

                            <ErrorMessage
                              name="exclusive"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </label> */}

                          <label className="inline-flex items-center space-x-2">
                            <span className="text-xs text-slate-400 dark:text-navy-300">
                              {values.is_vat_accept === 1 ? 'Vat Exempt' : 'Vat not Exempt'}
                            </span>

                            <input
                              className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
                              type="checkbox"
                              name="exclusive"
                              checked={values.is_vat_accept === 1} // checked if value is 1
                              onChange={() => setFieldValue('is_vat_accept', values.is_vat_accept === 1 ? 0 : 1)} // toggle between 1 and 0
                            />

                            <ErrorMessage
                              name="is_vat_accept"
                              component="div"
                              className="text-red-500 text-sm" 
                            />
                          </label>


                        </div>

                      </div>
                    </div>

                    <div className="col-span-12">

                      {/* <button className="btn bg-slate-500 text-white" type="submit" onClick={handleSubmit}>Save</button> */}

                      <button
                        type="submit"
                        className={`btn bg-slate-500 text-white ${isLoading ? 'cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>


                  </div>
                </Form>
              );
            }}
          </Formik>

        )}

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <form onSubmit={handleFormSubmit(onSubmitScanner)} className="mt-5">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <h3 className="text-lg font-semibold">
                        Add Scanner
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                      <div className="lg:flex mt-5 lg:pl-0 pl-5 ">
                        <div className="lg:pr-2  w-1/2">
                          <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register('first_name', { required: 'Please enter first name.' })}
                          />
                          {errors.first_name && <p className="text-red-500">{errors.first_name.message}</p>}
                        </div>
                        <div className="lg:pl-2  lg:mt-0 mt-8  w-1/2">
                          <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register('last_name', { required: 'Please enter last name.' })}
                          />
                          {errors.last_name && <p className="text-red-500">{errors.last_name.message}</p>}
                        </div>
                      </div>
                      <div className="lg:flex mt-8 lg:pl-0 pl-5">
                        <div className="lg:pl-0 lg:pr-2 w-1/2">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            {...register('email', { required: 'Please enter email.' })}
                          />
                          {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                        </div>
                        <div className="lg:pl-2 w-1/2 lg:mt-0 mt-8">
                          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Phone
                          </label>
                          {/* <input */}
                          {/*   type="text" */}
                          {/*   id="phone" */}
                          {/*   name="phone" */}
                          {/*   placeholder="Phone" */}
                          {/*   className="form-input mt-1 p-2 lg:w-full w-80 border  rounded-md bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" */}
                          {/*   {...register('phone', { required: 'Please enter phone number.' })} */}
                          {/* /> */}

                          <PhoneInput
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter Phone"
                            value={countryCode + phoneNumber}
                            country={defaultCountry}
                            defaultCountry={defaultCountry}
                            onChange={(value) => {


                              if (value) {
                                // setPhoneNumber(value.slice(countryCode.length)); // Remove country code from input
                                // field.onChange(value.slice(countryCode.length));
                                setValue("phone", value)
                              }
                            }}
                            onCountryChange={handleCountryChange}
                          />

                          {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
                        </div>
                      </div>

                      <div className="mt-8 lg:pl-0 pl-5">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          className="mt-1 p-2 lg:w-full w-80 border  rounded-lg focus:outline-none focus:border-blue-500"
                          {...register('password', { required: 'Please enter password.' })}
                        />
                        {errors.password && <p className="text-red-500">{errors.password.message}</p>}
                      </div>
                      <div className="mt-8 lg:pl-0 pl-5">
                        <label className="block">
                          <span className="block text-sm font-medium text-gray-700">Status</span>
                          <select
                            className="mt-1 p-2 lg:w-full w-80 border  rounded-lg focus:outline-none focus:border-blue-500"
                            {...register('status')}
                          >
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                          </select>
                        </label>
                      </div>


                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>

                      <button
                        type="submit"
                        className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

      </main>
    </>
  );
};

export default EventsCreate;
