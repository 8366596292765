import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api';

export const GetCodeFetch = async (id) => {
   
  try {
    const authToken = localStorage.getItem('authToken');
 
    const response = await axios.get(API_ENDPOINTS.GET_CODE + `${id}`, 
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
