import axios from 'axios';
import { API_ENDPOINTS } from '../api';

 

export const AgentAllCategory = async () => {
  
    
  try {
    let url = `${API_ENDPOINTS.allcategoryAgent}`;

    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
 

export const AgentAllEvents = async () => {
  
    
  try {
    let url = `${API_ENDPOINTS.allEventAgent}`;

    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};


export const AgentSearchEvents = async (event) => {
  
    
  try {
    let url = `${API_ENDPOINTS.agent_SearchEvents}`+'?search='+event;

    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
 
export const fetchEventWithCate = async (id) => {
  
    
  try {
    let url = `${API_ENDPOINTS.fetchEventCate}`+'?category_id='+id;

    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
 
export const fetchEvent_Tickets = async (id) => {
  
    
  try {
    let url = `${API_ENDPOINTS.fetchEventTickets}`+id;

    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}` // Include the auth token in the header
      }
    });
    return response.data;
  } catch (error) { 

    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    console.error('agent error:', error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
 

 


 
export const sellTicketsAgent = async (formData) => {
  try { 
    
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS. TicketsSellAgent, formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};