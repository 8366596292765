import React, { useState, useEffect, useRef } from "react";
import { ScanOrderList } from "../../../services/eventservice";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import defaultAvator from '../../../assets/images/200x200.png';
import { API_ENDPOINTS } from '../../../services/api';
import CustomLoader from '../Loaderfiles/customloader';
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";

const ScanOrderDetail = () => {
  const [data, setData] = useState([]);
  const [Eventdata, SetEventName] = useState([]);
  const [loading, setLoading] = useState(false);
  const countPerPage = 10;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const { id } = useParams();
   {/* useResizeObserver Start  */ }
   const [size, setSize] = useState({ width: 0, height: 0 });
   const ref = useRef(null);
   // Implement the callback function
   const handleResize = (entries) => {
       const { width, height } = entries[0].contentRect;
       setSize({ width, height });
   };
   // Use the custom hook
   useResizeObserver(ref, handleResize);
   {/* useResizeObserver END  */ }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ScanOrderList(currentPage, searchText,id);

      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        SetEventName(response.allEvents[0].name);
        setData(response.orderChildAll);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
     setPageLoading(true);
  };

  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const location = useLocation();
  const message = location.state?.message;
  const prevMessageRef = useRef();
  const prevLocationKeyRef = useRef(location.key);
 

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (
      currentPage !== prevPageRef.current ||
      debouncedSearchText !== prevSearchTextRef.current
    ) {
      fetchData();
    }
    // Update the previous values
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
  }, [currentPage, debouncedSearchText]);
  

   useEffect(() => {
    // Check if the location is different from the previous one and if the message is different
    if (
      message &&
      (message !== prevMessageRef.current ||
        location.key !== prevLocationKeyRef.current)
    ) {
      //toast.success(message, { duration: 3000 });
      prevMessageRef.current = message;
      prevLocationKeyRef.current = location.key;

      // Clear the message in the state after showing the toast
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, message: null },
      });
    }
  }, [message, location, navigate]);


  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
    const handleImageError = (event) => {
     event.target.src = defaultAvator
   
    };
  const handleExportCSV = () => {
   const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_EVENT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_EVENT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
  };
  if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}}
              className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
  const onBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
    <SEO
    title="Scan Orders List - eticketing"
  />
    <main ref={ref} style={{ width: '100%', height: '100%'}}
     className="main-content w-full px-[var(--margin-x)] pb-8">
      <PageHeader
        title="Scan Orders List"
        onBackButtonClick={onBackButtonClick}
        showBackButton={true}
      />
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        <div className="card px-4 pb-4 sm:px-5">
          <TableTools
            title="Scan Orders Rows"
            onSearchChange={handleSearchChange}
            showSearch={false}
            showExportButtons={false}
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
          />
          <div className="mt-5">
             {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Order Id
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Event Name	
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Customer Name	
                    </th>
                    <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Scanned At
                    </th>
                  </tr>
                </thead>
                {data.map((item, index) => (
                                <tr
                                    key={index}
                                    className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {item.order_id || 'N/A'}
                                    </td>

                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {item.name || 'N/A'}
                                    </td>
                                    
                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {Eventdata || 'N/A'}
                                    </td>

                                    <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {formatDate(item.scanned_at) || 'N/A'}
                                    </td>
                                    
                                     

                                </tr>
                                ))}
              </table>
              <Pagination
              totalRecords={totalRecords}
              perPage={recordsPerPage}
              onPageChange={handlePageChange}
            />

           
            </div>
                            )} 
          </div>
        </div>
      </div>
    </main>
    </>
  );
};

export default ScanOrderDetail;
