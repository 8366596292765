import React from "react";
import { CashRevenueReportsFetch } from "../../../services/admin/cash-report-service";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import {
  formatDate,
  formatName,
  formatCurrency,
} from "../../../helpers/Helpers";
import CustomLoader from "../Loaderfiles/customloader";
import { API_ENDPOINTS } from "../../../services/admin/api";
import useResizeObserver from "../../../hooks/useResizeObserver";

const RevenueReportCash = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const truncateWords = (text, maxWords) => {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    return truncatedWords.join(" ");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CashRevenueReportsFetch(currentPage, searchText);
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchText]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = async () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_CASH_REVENUE_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_CASH_REVENUE_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const onButtonClickCreate = () => {
    navigate("/admin/create-role");
  };
  console.log(data, "data");
  return (
    <>
      <SEO title="Revenue Report - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <PageHeader
          title="Revenue Report"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Revenue Report Rows"
              onSearchChange={handleSearchChange}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
              showSearch={true}
              showExportButtons={true}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr className="text-center">
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Order ID
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Customer
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Event Name
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Quantity
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Order Price
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Payout Amount
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Tickets Commission
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Org Vat
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Service Fee
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          User Vat
                        </th>

                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total Paid
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Created at
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500 text-center"
                        >
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.order_id || "NULL"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.customer_name
                              ? formatName(
                                  item.customer_name,
                                  item.customer_last_name
                                )
                              : "NULL"}
                          </td>

                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  truncateWords(item.event_name, 5) || "NULL",
                              }}
                            />
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.quantity + " tickets" || "NULL"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.sub_total ?? 0).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.sub_total - item.admin_commission).toFixed(
                              2
                            )}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {/* {formatCurrency(
                                                            item?.payment,
                                                            item?.payment_type,
                                                            item?.currency_symbol ?? 'N/A'
                                                        )} */}
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.admin_commission ?? 0).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.org_vat ?? 0).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {/* {`${item?.currency_symbol} ${(item.tax + item.org_commission).toFixed(2)}`} */}

                            {`${item?.curruncry}$ ${item.web_commission.toFixed(
                              2
                            )}`}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.user_vat ?? 0).toFixed(2)}
                          </td>

                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.curruncry === "JMD" ? "JMD" : item.curruncry}${" "}
                            {(item.payment ?? 0).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {formatDate(item.created_at)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RevenueReportCash;
