import React, { useEffect, useState,useRef } from "react";
import ReactApexChart from "react-apexcharts";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
import { DashboardData, graphData } from "../../../services/admin/adminDashboardservice";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import CustomDatePicker from './CustomDatePicker';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";


function Dashboard() {
  const [data, setData] = useState([]);
  const [EventData, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  
  const [graphDate, setGraphDate] = useState([]);
  const [paymentValue, setPaymentValue] = useState([]);
  const currentYear = new Date().getFullYear();


  const [totalPaymentCount, setTotalPaymentCount] = useState('');
  const [startDataFromApi, setStartDataFromApi] = useState('');
  const [endDataFromApi, setEndDataFromApi] = useState('');


   {/* useResizeObserver Start  */ }
   const [size, setSize] = useState({ width: 0, height: 0 });
   const ref = useRef(null);
   // Implement the callback function
   const handleResize = (entries) => {
     const { width, height } = entries[0].contentRect;
     setSize({ width, height });
   };
   // Use the custom hook
   useResizeObserver(ref, handleResize);
   {/* useResizeObserver END  */ }
 
  


  const [graphDataSelection, setGraphDataSelection] = useState({
    'start_date': new Date(currentYear, 0, 1),
    'end_date': new Date(currentYear, 11, 31),
    'selected_option': 'months'
  });

  // Define a callback function to update graphDataSelection
  const handleDateChange = (startDate, endDate, selectedOption) => {

    setGraphDataSelection({
      'start_date': startDate,
      'end_date': endDate,
      'selected_option': selectedOption
    });
  };

  useEffect(() => {

    
    setChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }],
      xaxis: { ...prevOptions.xaxis, categories: graphDate }
    }));

    setLineChartOptions(prevOptions => ({
      ...prevOptions,
      series: [{ ...prevOptions.series[0], data: paymentValue }]
    }));

    

  }, [paymentValue, graphDate]);

  useEffect(() => {
   
    const fetchGraphData = async () => {

      try {
        const response = await graphData(graphDataSelection);
        if (response) { 

          if (response && response.date) {
            setGraphDate(response.date);
          } 
  
          if (response && response.total_payment) {
            setPaymentValue(response.total_payment);
          }

          if (response) {
            setTotalPaymentCount(response.total_payment_count);
          }
          if (response && response.startDate) {
            setStartDataFromApi(response.startDate);
          }
          if (response && response.endDate) {
            setEndDataFromApi(response.endDate);
          }

        }
      }
      catch (error) { 
         console.error("Error fetching data: ", error);
       }
     
    }; 
    
    fetchGraphData();
  
  }, [graphDataSelection]);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await DashboardData();
      if (response && response) {
        setData(response);
        setEvent(response.monthEvent);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [lineChartOptions, setLineChartOptions] = useState({
    colors: ["#4467EF"],
    chart: {
      height: 60,
      type: "line",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: "Sales",
        data: [],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: -20,
        bottom: -10,
      },
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  });


  const [chartOptions, setChartOptions] = useState({
    colors: ["#4C4EE7", "#0EA5E9"],
    series: [
      {
        name: "Sales",
        data: [],
      }
      
    ],
    chart: {
      height: 255,
      type: "bar",
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: "90%",
        columnWidth: "35%",
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
       
      ],
      labels: {
        hideOverlappingLabels: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: -10,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 850,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "55%",
            },
          },
        },
      },
    ],
  });

  const exportChartToPDF = () => {
    html2canvas(document.querySelector("#chart")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
  
      // Calculate the PDF page width and desired image width (50% of the PDF page width)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth * 0.5; // 50% of the PDF page width
      
      // Calculate the scaled image height to maintain the aspect ratio
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      
      // Center the image horizontally: (PDF Page Width - Image Width) / 2
      const x = (pdfWidth - imgWidth) / 2;
      const y = 0; // Adjust this value if you want to add a top margin
  
      // Add image to PDF
      pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
  
      // Save the PDF
      pdf.save("graph.pdf");
    });
  };
  
  if (!pageLoading) {
    return (
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <div className="flex justify-center items-center">
                <div className="flex justify-center items-center h-96">
                    <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                    />
                </div>
            </div>
        </main>
    );
}

  return (
    <>
    
    <SEO
    title="Dashboard - eticketing"
  />
      <main className="main-content w-full pb-8">
        <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-8">
            <div className="flex items-center justify-between space-x-2">
              <h2 className="text-base font-medium tracking-wide text-slate-800 line-clamp-1 dark:text-navy-100">
                Sales Overview
              </h2>
              <div
                id="sales-tab"
                className="is-scrollbar-hidden overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                <div className="tabs-list flex p-1">
                <CustomDatePicker
                  graphSelection={graphDataSelection}
                  onDateChange={handleDateChange}
                  />
                  
                  
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-7">
              <div className="mt-4 flex shrink-0 flex-col items-center sm:items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-8 text-info"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                  />
                </svg>
                <div className="mt-4">
                  <div className="flex items-center space-x-1">
                    <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                      { formatCurrency(totalPaymentCount, 'STRIPE', 'USD') }
                    </p>
                   
                  </div>
                
                </div>
                <div className="mt-3 flex items-center space-x-2">
                  <div className="ax-transparent-gridline w-28"  id="chart">
                    <ReactApexChart
                      options={lineChartOptions}
                      series={lineChartOptions.series}
                      type="line"
                    
                  />
                  </div>
                  
                </div>
                {/* <button onClick={exportChartToPDF} className="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4.5 text-slate-400 dark:text-navy-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                    />
                  </svg>
                  <span> Download Report</span>
                </button> */}
              </div>
              <div className="ax-transparent-gridline grid w-full grid-cols-1">

                <ReactApexChart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={255}
                />

              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between space-x-1">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                    {data.total_order}

                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-primary dark:text-accent"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>

                <p className="mt-1 text-xs+">Total Orders</p>

              </div>
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">

                    {data.users}

                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-success"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    />
                  </svg>
                </div>

                <p className="mt-1 text-xs+">Customers</p>

              </div>
              <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
                <div className="flex justify-between">
                  <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">

                    {data.organizations}

                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-warning"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>

                <p className="mt-1 text-xs+">Organizations</p>
              </div>
            </div>
          </div>
          <div className="card col-span-12 lg:col-span-12">
            <div className="grid grid-cols-1 gap-y-4 pb-3 sm:grid-cols-1">
              <div className="card pb-4">
                <div className="my-3 flex h-8 items-center justify-between px-4 sm:px-5">
                  <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
                    Recent Events
                  </h2>

                </div>
                <div>
                 
                  <div className="mt-5">
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <div className="flex justify-center items-center h-96">
                          <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                          />
                        </div>
                      </div>
                    ) : data.length === 0 ? (
                      <div className="text-center text-gray-500 mt-8">
                        No records found.
                      </div>
                    ) : (
                      <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                        <table className="is-zebra w-full text-left">
                          <thead>
                            <tr>
                              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Event Image
                              </th>
                              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Event Title
                              </th>
                              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Max People Allowed
                              </th>
                              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Remaining Tickets
                              </th>
                              <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                Event Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {EventData.map(event => (
                              <tr key={event.id}>
                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  <div class="avatar flex size-10">
                                    <img class="mask is-squircle" src={event.imagePath + event.image} alt="avatar" />
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  <p>{event.name}</p>
                                  <p>{event.address}</p>
                                </td>
                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  <svg className="h-6 w-6 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                    <circle cx="9" cy="7" r="4" />
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                  </svg>
                                  {event.people}
                                </td>

                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  <svg className="h-6 w-6 text-blue-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1="15" y1="5" x2="15" y2="7" />
                                    <line x1="15" y1="11" x2="15" y2="13" />
                                    <line x1="15" y1="17" x2="15" y2="19" />
                                    <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" />
                                  </svg>
                                  {event.people - event.soldTickets + ' tickets'}
                                </td>

                                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  <svg className="h-6 w-6 text-blue-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <rect x="4" y="5" width="16" height="16" rx="2" />
                                    <line x1="16" y1="3" x2="16" y2="7" />
                                    <line x1="8" y1="3" x2="8" y2="7" />
                                    <line x1="4" y1="11" x2="20" y2="11" />
                                    <rect x="8" y="15" width="2" height="2" />
                                  </svg>
                                  {formatDate(event.updated_at)}
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </main>
    </>
  );
}

export default Dashboard;
