import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../hooks/PageHeader";
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Role_Edit } from "../../../services/admin/roleservice";
import { submitRole } from "../../../services/admin/roleservice";
import { UpdateRole } from "../../../services/admin/roleservice";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link,useParams  } from "react-router-dom";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";


 const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  permissions: yup.array().required('Permissions are required'),
});


const RoleEdit = () => {
     
      const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  
  

  const { id } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [name, setName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected_role, setselected_role] = useState([]);
  const [initialValues, setInitialValues] = useState({ name: '', permissions: [] });
  
  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  useEffect(() => {
    let isMounted = true; // Initialize isMounted variable
  
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Role_Edit(id);
        setIsLoading(false);
  
        const roleData = response;
  
        setName(roleData.role.name);
        setselected_role(roleData.rolePermissions);
  
        const permissionsData = roleData.permissions.map((permission) => ({
          value: permission.id,
          label: permission.name,
        }));
  
        setOptions(permissionsData);
  
        const filteredOptions = permissionsData.filter((option) =>
          roleData.rolePermissions.includes(option.value)
        );
  
        setValue("permissions", filteredOptions);
        setValue("name", roleData.role.name);
      } catch (error) {
        const errorMessage = error.response.data.error;
  
        // Check if component is still mounted before showing the SweetAlert modal
        if (isMounted) {
          Swal.fire({
            title: "Error fetching data",
            text: errorMessage,
            icon: "error",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn-ok",
                closeModal: true,
              },
            },
          }).then((value) => {
            if (value) {
              navigate("/admin/roles");
            }
          });
        }
      } finally {
        setLoading(false);
        setPageLoading(true);
      }
    };
  
    fetchData();
  
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array indicates this effect should only run once, similar to componentDidMount
  
 
       const customStyles = {
        control: (styles) => ({
          ...styles,
          border: 'none',
          minHeight: 'unset',  
        }),
      };

    const onSubmit = async (data,e) => {
      setIsLoading(true);
        try {

            const selectedPermissionValues = data.permissions.map(option => option.value);
            const formData = {
            ...data,
            permissions: selectedPermissionValues,
            }

            const response = await UpdateRole(formData,id);

         //  toast.success(response.data.message)
         // navigate('/admin/roles', { state: { message: response.data.message } });

            Swal.fire({
            title: 'Good Job',
            text: response.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#a23896',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              navigate('/admin/roles');
            }
          });

        } catch (error) {
          const errorMessage = error && error.response.data && error.response.data.error ? error.response.data.error : 'An error occurred while submitting the role. Please try again.';
          

          Swal.fire({
            title: 'Error',
            text: errorMessage || 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#a23896',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            showCloseButton: false // Ensure the close button is shown
          });

      }
      finally {
        setIsLoading(false);
    }
    };

    if (!pageLoading) {
      return (
          <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
              <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                      <CustomLoader
                          visible={true}
                          height={80}
                          width={80}
                          color="#4FA94D"
                          ariaLabel="tail-spin-loading"
                          radius={1}
                      />
                  </div>
              </div>
          </main>
      );
    }


const onBackButtonClick = () => {
        navigate('/admin/roles');
    }
 
    return (
      <>
      <SEO
      title="Edit Roles - eticketing"
    />
         <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
           <PageHeader
              title="Edit Roles"
             showBackButton={true}
              onBackButtonClick={onBackButtonClick}
        />
        <Toaster position="top-right" reverseOrder={false} />
           <div className="mt-4 grid grid-cols-12   transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
              <div className="col-span-12 lg:col-span-12">
                 <div className="card">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                       <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Edit Roles
                    </h4>
                  </div>
                   

                </div>
                    </div>

                    {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                         <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : options.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    {/* <h1 className="xl text-3xl">No records found.</h1> */}
                                </div>
                            ) : (


                      <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="space-y-4 p-4 sm:p-5" id="category">
                            <label className="block">
                              <span>Name</span>
                              <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Username"
                                    type="text"
                                    disabled
                                    {...field}
                                  />
                                )}
                              />
                              {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                            </label>
                          </div>

                          <div className="space-y-4 p-4 sm:p-5" id="category">
                            <label className="block">
                              <span>Permissions</span>
                            

                               <Controller
                                  name="permissions"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isMulti
                                      options={options}
                                      className="select2form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                      onChange={(selected) => setValue('permissions', selected)}
                                      styles={customStyles}
                                      isSearchable={true} // Enable search functionality
                                    />
                                  )}
                                />
                              {errors.permissions && <p className="text-red-500">{errors.permissions.message}</p>}
                            </label>
                          </div>

                          <div className="space-y-4 p-4 sm:p-5" id="category">
                           
                   <button
                   
                  className={`btn mt-10 h-10 bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
                          </div>
                        </form>
                            )}
                 </div>
              </div>
           </div>
        </main>
        </>
    );
};

export default RoleEdit;
