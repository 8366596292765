import React from "react";
import { AgentSettlementDetailFetch } from "../../../services/admin/cash-report-service";
import { useNavigate,useParams } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import CustomLoader from '../Loaderfiles/customloader';
import { API_ENDPOINTS } from '../../../services/admin/api';
import useResizeObserver from "../../../hooks/useResizeObserver";


const VeiwSettlementDetailCash = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);

    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await AgentSettlementDetailFetch(currentPage, searchText ,id);
                if (response && response.data) {   
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
      const handleExportCSV = async () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_CASH_SETTLEMENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
 
    const handleExportPDF = () => {
        const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_CASH_SETTLEMENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
    const onBackButtonClick = () => {
        navigate(-1);
      };
    return (
        <>
        <SEO
        title="Organizer Settlement Detail - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="Organizer Settlement Detail"
                onButtonClick={onButtonClickCreate}
                showCreateButton={false}
                showBackButton={true}
                onBackButtonClick={onBackButtonClick}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Organizer Settlement Detail"
                        onSearchChange={handleSearchChange}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                         showSearch={false}
                        showExportButtons={false}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                             {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                    <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Organization Name
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                         Payment
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Payment Type	
                                        </th>
                                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Payment Token	
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Payment Status
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                          Payment on
                                        </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="px-6 py-4">
                                                <div className="flex items-center">
                                                    <div>
                                                        <p className="text-sm font-medium text-gray-900">
                                                            {item.name + ' ' + item.last_name || 'N/A'}
                                                        </p>
                                                        <p className="text-xs text-gray-500">{item.email}</p>
                                                    </div>
                                                </div>
                                            </td>


                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.total_orders}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.total_commission}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                            null
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                 {item.agent_commission || 'NULL'}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                               {item.created_at }
                                            </td>
                                        </tr>
                                    ))} */}
                                </tbody>
                            </table>
                                          )}
                         <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                        />
                                        </div>
                                   
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default VeiwSettlementDetailCash;
