import React from "react";
import { CategoryListFetch } from "../../../services/admin/categoryservice";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../hooks/PageHeader";
import { useState, useEffect } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import SEO from "../../../hooks/seo";
import { useRef } from 'react';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { Coupon_Create } from "../../../services/admin/couponsservice";
import { submitCoupon } from "../../../services/admin/couponsservice";
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";

registerPlugin(FilePondPluginImagePreview);

const schema = yup.object().shape({
  name: yup.string().required('name is required'),
  start_date: yup.string().required('Start Date is required'),
  end_date: yup.string().required('End Date Date is required'),
  status: yup.number().required('Status is required'),
  // event_id: yup.string().required('Event is required'),
  discount: yup.string().required('Discount is required'),
  max_use: yup.string().required('Maximum Use is required'),
  description: yup.string().required('Description is required'),

  // Add more validation rules as needed
});

// };


const CouponCreate = () => {

  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });




  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const filePondRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Coupon_Create();
        // console.log('Coupon_Create',response);
        if (response && response.event) {
          setIsLoading(false);

          const eventData = response.event;

          const eventOption = eventData.map(event => ({
            value: event.id,
            label: event.name,
          }));

          setOptions(eventOption);

        }
      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);

      } finally {
        setLoading(false);

      }
      setPageLoading(true);
    };

    fetchData();
  }, []);



  const handleFileChange = (event) => {

    const file = event.target.files[0];
    setValue('image', file);
  };


  const statusOptions = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };




  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {

      console.log(formData);


      const response = await submitCoupon(formData);


      // if (response.status === 201) {
      //     toast.success(response.data.message);
      //     navigate('/admin/coupons', { state: { message: response.data.message } });
      // }

      Swal.fire({
        title: 'Good Job',
        text: response.data.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked 'OK'
          navigate('/admin/coupons', { state: { message: response.msg } });
        }
      });

      e.target.reset();
      //navigate('/login', { state: { message: 'Registration successful. Now you can log in with your credentials.' } });
    } catch (error) {


      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });


      console.error('Registration failed:', error);
      if (error.response && error.response.status === 422) {
        // 422 Unprocessable Entity
        console.log('Error details:', error.response.data);

      } else {
        // Handle other errors
        if (error.response && error.response.data && error.response.data.error) {
          const errorsArray = Object.entries(error.response.data.error).flatMap(([key, value]) => value.map(v => `${key}: ${v}`));
          console.log(errorsArray);
        } else {
          // Fallback error message
          console.log('An unexpected error occurred.');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4FA94D"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  const onBackButtonClick = () => {
    navigate('/admin/coupons');
  }



  return (
    <>
      <SEO
        title="Add Coupon - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <ToastContainer />
        <PageHeader
          title="Add Coupon"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Add Coupon
                    </h4>
                  </div>

                </div>
              </div>



              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : options.length === 0 ? (
                <div className="text-center text-gray-500 mt-8">
                  {/* <h1 className="xl text-3xl">No records found.</h1> */}
                </div>
              ) : (

                <form onSubmit={handleSubmit(onSubmit)}>

                  <div id="category">
                    {/* First column */}
                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Coupon</span>
                          <Controller
                            name="name"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Enter Coupon"
                                  type="text"
                                  {...field}
                                />
                                {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>

                  </div>



                  <div className="grid grid-cols-2 gap-4">

                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Discount (In percentage)</span>
                          <Controller
                            name="discount"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Enter Discount"
                                  type="text"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Maximum Use</span>
                          <Controller
                            name="max_use"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Enter title"
                                  type="number"
                                  min="0"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4">

                    <div className="col-span-1">
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Start Date</span>
                          <Controller
                            name="start_date"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Enter title"
                                  type="date"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>


                    <div className="col-span-1">

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>End Date</span>
                          <Controller
                            name="end_date"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                    } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Enter title"
                                  type="date"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>
                    </div>
                  </div>



                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Description</span>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <input
                              className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                              placeholder="Enter title"
                              type="text"
                              {...field}
                            />
                            {errors.description && <p className="text-red-500">{errors.description.message}</p>}
                          </>
                        )}
                      />
                    </label>
                  </div>


                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Status</span>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            isSearchable={true}
                            className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                            styles={customStyles}
                            value={statusOptions.find((option) => option.value === field.value)}
                            placeholder="Select Status"
                          />
                        )}
                      />
                      {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                    </label>
                  </div>

                  <div className="space-y-4 p-4 sm:p-5" id="category">

                    <button
                      type="submit"
                      className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center space-x-2">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CouponCreate;
