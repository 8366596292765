import React, { useEffect, useState, useRef } from "react";
import DefaultImage from "../../assets/images/200x200.png";
import logoImage from "../../assets/images/app-logo.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { agentProfile } from "../../services/agent/agentServices";
import { useUser } from "../../hooks/UserContext";
import { Helmet } from "react-helmet";
import { useSetting } from "../../hooks/SettingContext";

const Dropdown = ({ title, links, isOpen, toggleDropdown }) => {
  return (
    <div className="relative inline-block mt-4 mb-4">
      <button
        onClick={toggleDropdown}
        className="focus:outline-none text-blue-500 hover:text-blue-700 dark:text-gray-300 dark:hover:text-gray-100"
      >
        {title}
      </button>
      {isOpen && (
        <ul className="absolute bg-white dark:bg-gray-800 shadow-md py-2 mt-1 rounded-md transition-all duration-300 ease-in-out">
          {links.map((link, index) => (
            <li key={index}>
              <Link
                className="block px-4 py-2 text-sm tracking-wide outline-none transition-colors duration-300 ease-in-out text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100"
                to={link.to}
                onClick={toggleDropdown}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Sidebar = () => {
  const { user } = useUser();
  const [profileWindow, setProfileWindow] = React.useState(false);
  const navigate = useNavigate();
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const dropdown1Ref = useRef(null);
  const [data, setData] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_1, setIsOpen_1] = useState(false);
  const { settings, updateSettings } = useSetting();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const closeDropdown_1 = () => {
    setIsOpen_1(false);
  };

  const isActiveLink = (pathname) => location.pathname === pathname;
  
   const toggleDropdown_1 = () => {
    setIsOpen_1(!isOpen);
  };

  //   function toggleProfileBox() {
  //   var profileBox = document.getElementById('profile-box');
  //   profileBox.classList.toggle('hidden'); // Assuming you have a 'hidden' class for hiding the element
  // }
  const fetchData = async () => {
    try {
      const response = await agentProfile();

      setLoading(false);
      setData(response.user);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  const toggleProfileWindow = () => {
    setProfileWindow((prevState) => !prevState);
  };
  const toggleSidebar = () => {
    const body = document.body;
    const sidebar = document.querySelector('.sidebar');
    body.classList.toggle('is-sidebar-open');
    sidebar.classList.toggle('is-sidebar-open');
  };
  const handleLogout = () => {
    logout(navigate);
  };
  const logout = (navigate) => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("permissions");
    navigate("/login");
  };

  const [profileDropdown, setProfileDropdown] = useState(false);

  const toggleProfileBox = () => {
    setProfileDropdown(!profileDropdown);
  };

  useEffect(() => {
    if (isDropdownOpen1 && dropdown1Ref.current) {
      dropdown1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isDropdownOpen1]);

  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
      existingFavicon.href = `https://api.tikkets.com/public/images/upload/${settings.favicon}`;
    }
  }, [settings.favicon]);

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const permissionNames = permissions.map((permission) => permission.name);

  const sidebarLinks = [
    {
      label: "Dashboard",
      path: "/agent/dashboard",
      name: "agent_dashboard",
      icon: <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_113_1435)">
          <path d="M9.75 15.75H5.25C4.69772 15.75 4.25 16.1977 4.25 16.75V19.25C4.25 19.8023 4.69772 20.25 5.25 20.25H9.75C10.3023 20.25 10.75 19.8023 10.75 19.25V16.75C10.75 16.1977 10.3023 15.75 9.75 15.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.75154 3.75H5.24846C4.69703 3.75 4.25 4.19824 4.25 4.75118V11.2488C4.25 11.8018 4.69703 12.25 5.24846 12.25H9.75154C10.303 12.25 10.75 11.8018 10.75 11.2488V4.75118C10.75 4.19824 10.303 3.75 9.75154 3.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.25 8.25H19.75C20.3023 8.25 20.75 7.80228 20.75 7.25V4.75C20.75 4.19772 20.3023 3.75 19.75 3.75L15.25 3.75C14.6977 3.75 14.25 4.19772 14.25 4.75V7.25C14.25 7.80228 14.6977 8.25 15.25 8.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.25 20.25H19.75C20.3023 20.25 20.75 19.8023 20.75 19.25V12.75C20.75 12.1977 20.3023 11.75 19.75 11.75H15.25C14.6977 11.75 14.25 12.1977 14.25 12.75V19.25C14.25 19.8023 14.6977 20.25 15.25 20.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
          <clipPath id="clip0_113_1435">
              <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
          </clipPath>
      </defs>
  </svg>,
    },
    {
      label: "Orders",
      path: "/agent/orders",
      name: "agent_orders",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_304_23693)">
          <path d="M5.61528 8.59201C5.6929 8.10688 6.11142 7.75 6.60272 7.75H17.3973C17.8886 7.75 18.3071 8.10688 18.3847 8.59201L20.0647 19.092C20.1619 19.6996 19.6926 20.25 19.0773 20.25H4.92272C4.30741 20.25 3.83807 19.6996 3.93528 19.092L5.61528 8.59201Z" stroke="#738196" stroke-width="1.5" stroke-linejoin="round"/>
          <path d="M8.75 12.25V6C8.75 4.21 10.21 2.75 12 2.75C13.79 2.75 15.25 4.21 15.25 6V12.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
          <clipPath id="clip0_304_23693">
              <rect width="24" height="24" fill="white"/>
          </clipPath>
      </defs>
  </svg>
  ,
    },
    {
      label: "Sell Tickets",
      path: "/agent/sell-tickets",
      name: "agent_sell_tikkets",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_304_23705)">
      <path d="M18.41 10.41C18 10.82 17.75 11.38 17.75 12C17.75 13.24 18.76 14.25 20 14.25H21.25V18.25C21.25 18.8 20.8 19.25 20.25 19.25H3.75C3.2 19.25 2.75 18.8 2.75 18.25V14.25H4C4.62 14.25 5.18 14 5.59 13.59C6 13.18 6.25 12.62 6.25 12C6.25 10.76 5.24 9.75 4 9.75H2.75V5.75C2.75 5.2 3.2 4.75 3.75 4.75H20.25C20.8 4.75 21.25 5.2 21.25 5.75V9.75H20C19.38 9.75 18.82 10 18.41 10.41Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <line x1="10.75" y1="4.75" x2="10.75" y2="7.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      <line x1="10.75" y1="10.75" x2="10.75" y2="13.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      <line x1="10.75" y1="16.75" x2="10.75" y2="19.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      </g>
      <defs>
      <clipPath id="clip0_304_23705">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>,
    },
    {
      label: "Window Tickets",
      path: "/agent/window-tickets",
      name: "agent_window_tikkets",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_304_23705)">
      <path d="M18.41 10.41C18 10.82 17.75 11.38 17.75 12C17.75 13.24 18.76 14.25 20 14.25H21.25V18.25C21.25 18.8 20.8 19.25 20.25 19.25H3.75C3.2 19.25 2.75 18.8 2.75 18.25V14.25H4C4.62 14.25 5.18 14 5.59 13.59C6 13.18 6.25 12.62 6.25 12C6.25 10.76 5.24 9.75 4 9.75H2.75V5.75C2.75 5.2 3.2 4.75 3.75 4.75H20.25C20.8 4.75 21.25 5.2 21.25 5.75V9.75H20C19.38 9.75 18.82 10 18.41 10.41Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <line x1="10.75" y1="4.75" x2="10.75" y2="7.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      <line x1="10.75" y1="10.75" x2="10.75" y2="13.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      <line x1="10.75" y1="16.75" x2="10.75" y2="19.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round"/>
      </g>
      <defs>
      <clipPath id="clip0_304_23705">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>,
    },
    {
      label: "Cash Total",
      path: "/agent/cash-total",
      name: "cash_total",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_305_27868)">
      <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#738196" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M9.88012 14.36C9.88012 15.53 10.8301 16.25 12.0001 16.25C13.1701 16.25 14.1201 15.53 14.1201 14.36C14.1201 13.19 13.3501 12.75 11.5301 11.66C10.6701 11.15 9.87012 10.82 9.87012 9.64C9.87012 8.46 10.8201 7.75 11.9901 7.75C13.1601 7.75 14.1101 8.7 14.1101 9.87" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 16.25V18.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 5.75V7.75" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_305_27868">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>,
    },
    {
      label: "Cash Handling",
      path: "/agent/cash-handling",
      name: "agent_cash_handling",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_305_27933)">
      <path d="M20.25 3.75H3.75V14.25H20.25V3.75Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 20.25H20.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 17.25H20.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.25 3.75C5.25 4.58 4.58 5.25 3.75 5.25V3.75H5.25Z" fill="#738196" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 12.75H3.95C4.67 12.75 5.25 13.33 5.25 14.05V14.25H3.75V12.75Z" fill="#738196" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.25 5.25H20.05C19.33 5.25 18.75 4.67 18.75 3.95V3.75H20.25V5.25Z" fill="#738196" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.75 14.25V14.05C18.75 13.33 19.33 12.75 20.05 12.75H20.25V14.25H18.75Z" fill="#738196" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_305_27933">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>,
    },
    {
      label: "Faq",
      path: "/faq",
      name: "faq_access",
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_305_31721)">
      <path d="M4.75 19.25V11.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.25 11.25V19.25H10.75V11.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.25 11.25H4.74996C3.43996 11.25 2.47996 10 2.81996 8.73L3.88996 4.75H20.11L21.18 8.73C21.52 10 20.57 11.25 19.25 11.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_305_31721">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      ,
    },
  ];

  const filteredSidebarLinks = sidebarLinks.filter((link) =>
    permissionNames.includes(link.name)
  );

  return (
    <>
      <Helmet>
        <script>
          {`
            var existingFavicon = document.querySelector("link[rel*='icon']");
            if (existingFavicon) {
              existingFavicon.href = "${
                "https://api.tikkets.com/public/images/upload/" +
                settings.favicon
              }";
            }
          `}
        </script>
      </Helmet>
      <div class="sidebar sidebar-panel print:hidden">
        <div class="flex h-full grow flex-col border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-750">
          
            <div className="flex items-center px-4 pt-8 justify-between">  
              <Link to="/agent/dashboard">
                <img
                  className="w-24 md:w-40 object-cover"  
                  src={`https://api.tikkets.com/public/images/upload/${settings.logo}`}
                  alt="logo"
                />
              </Link>

            <button onClick={toggleSidebar} className="sidebar-close btn size-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>

          <div
            class="nav-wrapper mt-5 h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
            data-simplebar
          >
            <div class="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
            <ul class="flex flex-1 flex-col px-4 font-inter">
              {filteredSidebarLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className={`nav-link flex items-center   p-2 text-md tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4 ${
                      isActiveLink(link.path) ? "text-blue-600" : ""
                    }`}
                  >
                    <div className="flex items-center space-x-2">
                      {link.icon}
                      <span>{link.label}</span>
                    </div>
                  </Link>
                </li>
              ))}

              <li>
                <Link
                  to="/agent/tickets-pos"
                  className={`nav-link flex items-center   p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4`}
                >
                  <div className="flex items-center space-x-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_305_31721)">
                    <path d="M4.75 19.25V11.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.25 11.25V19.25H10.75V11.25" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.25 11.25H4.74996C3.43996 11.25 2.47996 10 2.81996 8.73L3.88996 4.75H20.11L21.18 8.73C21.52 10 20.57 11.25 19.25 11.25Z" stroke="#738196" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_305_31721">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    <span>Tickets POS</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* App Header Wrapper*/}
      <nav className="header print:hidden">
        {/* App Header  */}
        <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
          {/* Header Items */}
          <div className="flex w-full items-center justify-between">
            {/* Left: Sidebar Toggle Button */}
            <div className="size-7">
              <button
                onClick={toggleSidebar}
                className="sidebar-toggle ml-0.5 flex size-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80"
              >
                <span />
                <span />
                <span />
              </button>
            </div>
            {/* Right: Header buttons */}
            <div className="-mr-1.5 flex items-center space-x-2">
              {/* Mobile Search Toggle */}
              <button className="mobile-searchbar-show btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5.5 text-slate-500 dark:text-navy-100"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>

              <button
                id="profile-ref"
                className="avatar size-10"
                onClick={toggleProfileBox}
              >
                <img
                  className="rounded-full"
                  src={
                    user ? user : data.imagePath ? data.imagePath : DefaultImage
                  }
                  alt="avatar"
                />
                <span className="absolute right-0 size-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
              </button>

              {profileDropdown && (
                <div className="absolute top-full right-0 w-48 bg-white border border-slate-150 shadow-md dark:bg-navy-700 dark:border-navy-600">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
                      <Link
                        to="/agent/profile"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                      >
                        <div className="flex size-8 items-center justify-center rounded-lg bg-warning text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            Profile
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Your profile setting
                          </div>
                        </div>
                      </Link>
                    </li>

                    <li className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-navy-600">
                      <button
                        onClick={handleLogout}
                        className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                        <span>Logout</span>
                      </button>
                    </li>
                    {/* Add more links as needed */}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
