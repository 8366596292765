import React from "react";
import { CategoryListFetch } from "../../../services/admin/categoryservice";
import { Link,useParams } from "react-router-dom";
import PageHeader from "../../../hooks/PageHeader";
import { useState, useEffect } from "react";
import SEO from "../../../hooks/seo";
import { useNavigate, useLocation } from 'react-router-dom';
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import  { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditBanner } from "../../../services/admin/bannerservice";
import { Edit_banner } from "../../../services/admin/bannerservice";
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";

 
const schema = yup.object().shape({
  title: yup.string().required('title is required'),
  description: yup.string().required('Description is required'),
  status: yup.number().required('Status is required'),
//image: yup.mixed().required('File is required'),

  // Add more validation rules as needed
});



const BannerEdit = () => {

       const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

    const { id } = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const filePondRef = useRef(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [image, setImage] = useState(null);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
      {/* useResizeObserver Start  */ }
      const [size, setSize] = useState({ width: 0, height: 0 });
      const ref = useRef(null);
      // Implement the callback function
      const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      };
      // Use the custom hook
      useResizeObserver(ref, handleResize); 
      {/* useResizeObserver END  */ }

         useEffect(() => {
            const fetchData = async () => {
              setLoading(true); ;
                try {
                    const response = await Edit_banner(id);
                    console.log('edit banner',response);
                    setIsLoading(false);
                    setValue('title',response.banner.title);
                    setValue('description',response.banner.description);
                    setValue('status',response.banner.status);
                    setImage(response.banner.image);
                    
                } catch (error) {
                    toast.error(error);
                    console.error("Error fetching data: ", error);
                      
                  } finally {
                    setLoading(false);
                      
                  }
                  setPageLoading(true);
              };

            fetchData();
        }, [id]);
        console.log('options:',options);

   


        const handleFileChange = (fileItems) => {
          if (fileItems.length > 0) {
            const image = fileItems[0].file;
            setProfileImage(image);
          } else {
            setProfileImage(null);
          }
        };
  
    const statusOptions = [
      // { value:0,label: 'Inactive' },
      { value:1,label: 'Active' },
    ]; 
   
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset', 
    }),
  };
 

const onSubmit = async (formData, e) => {
  setIsLoading(true);
  try {
    
  
    if (profileImage) {
      formData.image = profileImage;
    }
     
    const response = await EditBanner(formData,id);
     
    // toast.success(response.data.message);
    // navigate('/admin/dasktop-banner', { state: { message: response.data.message } });

      Swal.fire({
          title: 'Good Job',
          text: response.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'OK'
              
              navigate('/admin/dasktop-banner', { state: { message: response.msg } });
            }
          });

 
      e.target.reset(); 
    //navigate('/login', { state: { message: 'Registration successful. Now you can log in with your credentials.' } });
  } catch (error) {
    const errorMessage = error && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An error occurred while submitting the role. Please try again.';
        
          
         Swal.fire({
          title: 'Error',
          text: errorMessage || 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#a23896',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          showCloseButton: false // Ensure the close button is shown
        });
  } finally {
    setIsLoading(false);
  }
};

      if (!pageLoading) {
        return (
            <main 
            ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4FA94D"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
      }

    const onBackButtonClick = () => {
        navigate('/admin/dasktop-banner');
    }  
   
    return (
      <>
      <SEO
      title="Edit Banner - eticketing"
    />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
              <ToastContainer/>
           <PageHeader
              title="Edit Banner"
             showBackButton={true}
              onBackButtonClick={onBackButtonClick}
              />
           <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
              <div className="col-span-12 lg:col-span-12">
                 <div className="card">
                    <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                        <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                        <i className="fa-solid fa-layer-group" />
                      </div>
                      <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                        Edit Desktop Banner
                      </h4>
                    </div>
                </div>
                    </div>
                      
 
                       {loading ? (
                        <div className="flex justify-center items-center">
                          <div className="flex justify-center items-center h-96">
                            <CustomLoader
                              visible={true}
                              height={80}
                              width={80}
                              color="#4FA94D"
                              ariaLabel="tail-spin-loading"
                              radius={1}
                            />
                          </div>
                        </div>
                      ) : (
                          
                             <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Name</span>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${
                                    fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Name"
                                  type="text"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>


                        <div className="space-y-4 p-4 sm:p-5" id="category">
                        <label className="block">
                          <span>Description</span>
                          <Controller
                            name="description"
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className={`form-input mt-1.5 w-full rounded-lg border ${
                                    fieldState.error ? 'border-red-500' : 'border-slate-300'
                                  } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                                  placeholder="Name"
                                  type="text"
                                  {...field}
                                />
                                {fieldState.error && (
                                  <p className="text-red-500">{fieldState.error.message}</p>
                                )}
                              </>
                            )}
                          />
                        </label>
                      </div>


                     <div className="space-y-4 p-4 sm:p-5" id="category">
                          <label className="block">
                            <span>Status</span>
                             
                               <Controller
                                  name="status"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={statusOptions}
                                      isSearchable={true}
                                      className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                      onChange={(selected) => setValue('status', selected ? selected.value.toString() : null)}
                                      styles={customStyles}
                                      value={statusOptions.find(option => option.value === field.value)}
                                      placeholder="Select Status"
                                    />
                                  )}
                                />

                       


                            {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                          </label>
                        </div>

                        <div className="space-y-4 p-4 sm:p-5" id="category">


                          {imagePreview ? (
                              <div>
                                <img
                                  src={imagePreview}
                                  alt="Preview"
                                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                                />
                              </div>
                            ) : (
                                <img
                                   
                                    src={'https://api.tikkets.com/public/images/upload/' + image}
                                    alt={image}
                                    width="400" // Set your desired width
                                    height="400" // Set your desired height
                                  />
                                   )}


                        </div>

                      <div className="space-y-4 p-4 sm:p-5" id="category">
                      <FilePond
                   className="mt-2"
                              allowMultiple={false}
                              files={profileImage ? [profileImage] : []}
                              onupdatefiles={handleFileChange}
                              labelIdle='Drag & Drop your files or Browse <span class="filepond--label-action"></span>'
                              acceptedFileTypes={['image/*']}
                              name="image"
                            />

                        
<span>Please upload an image with desktop size of 1920 x 1080 pixels</span>                
                      
                      </div>

                      <div className="space-y-4 p-4 sm:p-5" id="category">

                      <button
                        type="submit"
                        className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>

                        
                        {/* <button
                          type="submit"
                          className="btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                          Update
                        </button> */}
                      </div>
                    </form>    
                            

                        )}
                      
                           
                           
                           
                  
                      
                 </div>
              </div>
           </div>
        </main>
        </>
    );
};

export default BannerEdit;
