import axios from 'axios';
import { API_ENDPOINTS } from './api';


export const GalleryFetch = async () => {
  
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await axios.get(`${API_ENDPOINTS.ORG_FETCH_GALLERY}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('scanner error:', error);
      throw error;
    }
  };
  export const deleteGallery = async (imageName) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await axios.get(API_ENDPOINTS.ORG_DELETE_GALLERY +imageName, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      return response;
    } catch (error) {
      console.error('Error deleting gallery:', error);
      throw error;
    }
  };