import React, { useState } from "react";
import { Draggable } from "react-drag-reorder";

const Ordering = () => {
  const [data, setData] = useState([]);
  const [words] = useState(["Hello", "Hi", "How are you", "Cool"]);
  const [languages] = useState(["C", "C++", "Java", "JS"]);
  const [shows] = useState(["GOT", "Friends", "Big Bang"]);

  const getChangedPos = (currentPos, newPos) => {
    console.log(currentPos, newPos);
  };

  const handleDataUpdate = (newData) => {
    setData(newData);
  };

  return (
    <main className="main-content w-full px-[var(--margin-x)] pb-8">
      <div className="grid grid-cols-3 gap-4 sm:gap-5 lg:gap-6">
        <div className="card px-4 pb-4 sm:px-5">
          <div className="lg:mt-5 md:mt-5 mt-16">
            <div>
              <p className="font-bold mt-2 mb-3">Words</p>
              <Draggable onDataChange={handleDataUpdate}>
                {words.map((word, idx) => (
                  <div key={idx}>{word}</div>
                ))}
              </Draggable>
            </div>
          </div>
        </div>
        <div className="card px-4 pb-4 sm:px-5">
          <div className="lg:mt-5 md:mt-5 mt-16">
            <div>
              <p className="font-bold mt-2 mb-3">Languages</p>
              <Draggable onDataChange={handleDataUpdate}>
                {languages.map((lng, idx) => (
                  <div key={idx}>{lng}</div>
                ))}
              </Draggable>
            </div>
          </div>
        </div>
        <div className="card px-4 pb-4 sm:px-5">
          <div className="lg:mt-5 md:mt-5 mt-16">
            <div>
              <p className="font-bold mt-2 mb-3">Shows</p>
              <Draggable onDataChange={handleDataUpdate}>
                {shows.map((show, idx) => (
                  <div key={idx}>{show}</div>
                ))}
              </Draggable>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Ordering;
