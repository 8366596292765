import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AgentDashboard from '../pages/agent/AgentDashboard';
import Sidebar from '../components/agent/Sidebar';
import Order from '../pages/agent/Orders';
import CashTotal from '../pages/agent/CashTotal';
import CashTotalDetail from '../pages/agent/CashTotalDetail';
import CashHandling from '../pages/agent/CashHandling';
import CashHandlingCreate from '../pages/agent/CashHandlingCreate';
import NotFoundPage from '../pages/agent/NotFoundPage'; 
import OrdersInvoice from '../pages/agent/OrdersInvoice';
import GetCode from '../pages/agent/GetCode';
import SellTickets from '../pages/agent/SellTickets';
import SellTickets_two from '../pages/agent/SellTickets_two';
import WindowTickets from '../pages/agent/WindowTickets';
import AgentProfile from '../pages/agent/AgentProfile';

import { UserProvider } from '../hooks/UserContext'; 


const AgentRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={
        
        <>
          <Sidebar />
          <AgentDashboard />
        </>

      } />

      <Route path="orders" element={
        
        <>
          <Sidebar />
          <Order />
        </>

      } />

<Route path="/profile" element={
            <>
               <UserProvider>
              <Sidebar />
            <AgentProfile />
            </UserProvider>
            </>
         } />

       <Route path="/order-invoice/:id" element={

          <>

            <Sidebar />      
            <OrdersInvoice/>

          </>

      } />

        <Route path="/get-code/:id" element={
                    <>
                      <Sidebar />
                      <GetCode />
                    </>
         } />

      <Route path="sell-tickets" element={
              
              <>
                <Sidebar />
                <SellTickets />
              </>

      } />

      <Route path="tickets-pos" element={
              
              <>
                <Sidebar />
                <SellTickets_two  initialPageNumber={0} />
              </>

      } />

       <Route path="/window-tickets" element={
              
              <>
                <Sidebar />
                <WindowTickets />
              </>

      } />

    <Route path="cash-total" element={
        
        <>
          <Sidebar />
          <CashTotal />
        </>

      } />

      <Route path="cash-total/:id" element={
        
        <>
          <Sidebar />
          <CashTotalDetail />
        </>

      } />  

    <Route path="cash-handling" element={
        
        <>
          <Sidebar />
          <CashHandling />
        </>

      } />
     <Route path="cash-handling/create" element={
        
        <>
          <Sidebar />
          <CashHandlingCreate />
        </>

      } />

      
      <Route path="/*" element={
        <>
          <Sidebar />
          <NotFoundPage />
        </>
      } />

      
    </Routes>
  );
};

export default AgentRoutes;