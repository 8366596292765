import axios from 'axios';
import { API_ENDPOINTS } from './api'; 


export const Admin_profile = async () => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      
        const response = await axios.get(API_ENDPOINTS.orgProfile, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const updateProfile = async (formDataToSend) => {
  try {
    console.log('API Request Data:', formDataToSend);
    const authToken = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    Object.keys(formDataToSend).forEach((key) => {
      if (formDataToSend[key] instanceof File || formDataToSend[key] instanceof Blob) {
        formData.append(key, formDataToSend[key]);
      } else if (Array.isArray(formDataToSend[key])) {

        formDataToSend[key].forEach((value, index) => {
          formData.append(`${key}[${index}]`, value);
        });
      } else {
        // For non-File, Blob, and non-array fields, simply append them to formData
        formData.append(key, formDataToSend[key]);
      }
    });

     try {
    const response = await axios.post(API_ENDPOINTS.StoreProfile, formData, { headers });
    //return response;
        // Check if the response is okay (status code 2xx)
        if (response.ok) {
          const responseData = await response.json();
          // Process the successful response
          console.log('Response:', responseData);
          return responseData;
        } else {
          // Handle the case where the response status is not okay
          const errorData = response.data; // Access the error details from response.data
          console.error('Failed to submit event data. Status:', response.status);
          console.error('Error Details:', errorData);
          return 'Failed to submit event data';
        }
      } catch (error) {
        // Handle any network or other errors that may occur during the request
        console.error('Error:', error.message);
        return 'Failed to submit event data';
      }

 
  } catch (error) {
    console.error('Error preparing FormData:', error);
    return error;
  }
};