import axios from 'axios';
import { API_ENDPOINTS } from '../api';

export const fetchUsersAndEvents = async (authToken) => {
  try {
     authToken = authToken || localStorage.getItem('authToken');

    const response = await axios.get(API_ENDPOINTS.AGENT_FETCH_USER, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
     if (Array.isArray(response.data.users) && Array.isArray(response.data.events)) {
      const users = response.data.users.map((item) => ({ value: item.id, label: item.name }));
        
       const events = response.data.events.map((item) => ({ value: item.id, label: item.name }));

      return { users, events };
    } else {
      console.error('API response does not contain an array of users or events.');
      return { users: [], events: [] };
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return { users: [], events: [] };
  }
};
export const FetchservicFee = async (authToken) => {
  try {
     authToken = authToken || localStorage.getItem('authToken');

    const response = await axios.get(API_ENDPOINTS.AGENT_FETCH_Fee, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
     if (response) {
      return { response };
    } else {
      console.error('API error.');
      return { response: [] };
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return { response: [] };
  }
};

export const checkAvailableTicket = async (eventId, ticketId) => {
    try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(`${API_ENDPOINTS.AGENT_CHECK_AVAILABLE_TICKET}?event_id=${eventId}&ticket_id=${ticketId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error checking available complimentary tickets:', error);
    return 0;
  }
};

export const fetchEventTickets = async (eventValue) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(`${API_ENDPOINTS.AGENT_EVENT_TICKETS}/${eventValue}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching event tickets:', error);
    return [];
  }
};
export const checkTicketPrice = async (eventId , ticketId) => {
  try {
  const authToken = localStorage.getItem('authToken');
  const response = await axios.get(`${API_ENDPOINTS.AGENT_CHECK_ticketPrice}?event_id=${eventId}&ticket_id=${ticketId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  });

  return response.data;
} catch (error) {
  console.error('Error checking available complimentary tickets:', error);
  return 0;
}
};

export const CreateSellTicketData = async (data,ticketId) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(`${API_ENDPOINTS.CREATE_SELLTICKETDATA}?ticket_id=${ticketId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorData = await response.json();
      const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
      throw new Error(`Failed to update data: ${errorMessage}`);
    }
  } catch (error) {
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    throw new Error(`An error occurred: ${error.message}`);
  }
};


export const CreateWindowTicketData = async (data) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(API_ENDPOINTS.CREATE_WindowTICKETDATA, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorData = await response.json();
      const errorMessage = errorData.error ? Object.values(errorData.error).flat().join(' ') : 'Unknown error';
      throw new Error(`Failed to update data: ${errorMessage}`);
    }
  } catch (error) {
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Navigate to the login page
      window.location.href = '/login';

    }
    throw new Error(`An error occurred: ${error.message}`);
  }
};


export const searchUsers = async (inputValue) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.AgentsearchUsers+"?username="+inputValue,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const searchUsersPos = async (inputValue) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.AgentsearchUsersPos+"?id="+inputValue,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const searchEvent = async (inputValue) => {
     
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.AgentsearchEvent+"?event="+inputValue,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};


