import React from "react";
import { EventTickets, deleteTicket } from "../../../services/eventservice";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import CustomLoader from "../Loaderfiles/customloader";
import { formatDate } from "../../../helpers/Helpers";
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { Toaster, toast } from "react-hot-toast";
import {ticketCreate , OrgTicketsSorting } from "../../../services/ticketsService";



const VeiwEventTickets = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [event, setEvent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const { id, slug } = useParams();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
  
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items);
  
    const itemIds = items.map((item) => item.id);
   
    const formdata = {
      itemIds: itemIds,
    };
  
    try {
      const response = await OrgTicketsSorting(formdata);
  
      toast.success('Sorting updated successfully');
    } catch (error) {
      console.error("Error sorting categories: ", error);
  
      toast.error('Error sorting categories');
    }
  };
  

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await EventTickets(currentPage, searchText, id, slug);
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  const showDeleteConfirmation = async (callback) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await callback();
      }
    } catch (error) {
      console.error("Error in showDeleteConfirmation:", error);
      Swal.fire("Error!", "Failed to delete the item.", "error");
    }
  };
  useEffect(() => {
    const fetchDataEvent = async () => {
      try {
        const response = await ticketCreate(id);
        setEvent(response.event);
       } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataEvent();
  }, [id]);
  const getChangedPos = (currentPos, newPos) => {
    console.log(currentPos, newPos);
  };

  const handleDataUpdate = (newData) => {
    console.log(newData, " new data from sorting");
    setData(newData);
  };

  const handleDeleteClick = async (id) => {
    showDeleteConfirmation(async () => {
      try {
        const response = await deleteTicket(id);

        if (response && response.status === 200) {
          // Delete the ticket from the state
          setData(data.filter((ticket) => ticket.id !== id));
          Swal.fire("Deleted!", "Your ticket has been deleted.", "success");
        } else {
          console.log("Unexpected response:", response);
          Swal.fire("Error!", "Failed to delete the item.", "error");
        }
      } catch (error) {
        console.error("Error deleting item:", error.response.data.error);
        Swal.fire("Error!", error.response.data.error, "error");
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    alert("CSV Export");
  };
  const handleExportPDF = () => {
    alert("PDF Export");
  };
  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate(-1);
  };
  const onCreateButtonClick = () => {
    navigate(`/organization/${id}/ticket/create`);
  }
  return (
    <>
      <SEO title="Event Tickets - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
         <Toaster position="top-right" reverseOrder={false} />
        <PageHeader
          title={`Event Tickets ${event.name || ''}`}
          showBackButton={true}
     
          onCreateButtonClick={onCreateButtonClick}
          onBackButtonClick={onBackButtonClick}
        />
      <div className="font-semibold ml-2">Start Time: {new Date(event.start_time).toLocaleDateString()} {new Date(event.start_time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</div>
      <div className="font-semibold ml-2">End Time: {new Date(event.end_time).toLocaleDateString()} {new Date(event.end_time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</div>


        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 mt-4">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Tickets"
              onSearchChange={handleSearchChange}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="mt-5">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    No records found.
                  </div>
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="categories">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                      <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                  
                                  </th>
                        <th className="whitespace-nowrap bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Ticket Number
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Quantity
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Price
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Sales End
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Status
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full">
                      {/* <Draggable className="w-full" onDataChange={handleDataUpdate}> */}
                      {data.map((item, index) => (
                                <Draggable
                                  key={String(item.id)}
                                  draggableId={String(item.id)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <tr
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      key={index}
                                                className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                              > 
                                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                      <svg className="h-6 w-6 text-neutral-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <polyline points="8 7 12 3 16 7" />
                                        <polyline points="8 17 12 21 16 17" />
                                        <line x1="12" y1="3" x2="12" y2="21" />
                                      </svg>
                                      </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.ticket_number}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.name}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.quantity}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.type === "paid"
                            ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.price)
                            : item.type === "free"
                            ? "Free"
                            : item.type === "complimentary"
                            ? "Complimentary"
                            : null}

                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {formatDate(item.end_time)}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              <div className="inline-block">
                                {item.status === 1 ? (
                                  <span className="bg-green-500 text-white py-1 px-2 rounded-full text-sm">
                                    Active
                                  </span>
                                ) : (
                                  <span className="bg-red-500 text-white py-1 px-2 rounded-full text-sm">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </td>

                            <td className="whitespace-nowrap px-4 pt-6 pb-4 sm:px-5 flex items-center space-x-4">
                              <Link
                                className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25"
                                to={`/organization/ticket/edit/${item.id}`}
                              >
                                <i className="fa fa-edit" />
                              </Link>
                              <Link
                                className="btn size-8 p-0 text-error hover:bg-error/20 focus:bg-error/20 active:bg-error/25"
                                onClick={() => handleDeleteClick(item.id)}
                              >
                                <i className="fa fa-trash-alt" />
                              </Link>
                            </td>
                          </tr>
                         )}
                         </Draggable>
                       ))}
                       {provided.placeholder}
                     </tbody>
                   </table>
                 </div>
               )}
             </Droppable>
           </DragDropContext>
         )}
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        </div>
      </main>
    </>
  );
};
export default VeiwEventTickets;
