import { useRef, useState, useEffect } from "react";


const useResizeObserver = (ref, callback) => {
  useEffect(() => {
    // Ensure ref is current and callback is provided
    if (ref.current && callback) {
      const observer = new ResizeObserver((entries) => {
        // Callback for handling resize events
        callback(entries);
      });

      // Start observing the element
      observer.observe(ref.current);

      // Cleanup observer on component unmount
      return () => observer.disconnect();
    }
  }, [ref, callback]);
};

export default useResizeObserver;