import React from "react";
import { TailSpin, FallingLines } from "react-loader-spinner";

 const CustomLoader = ({ visible, height, width, color, ariaLabel, radius }) => {
    return (
        visible && (
            <div className="flex justify-center items-center">
                <FallingLines
                    height={height}
                    width={width}
                    color="#bd387a"
                    ariaLabel="falling-circles-loading"
                    radius={radius}
                />
            </div>
        )
    );
};
export default CustomLoader;