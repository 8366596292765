import axios from 'axios';
import { API_ENDPOINTS } from '../api';

export const Agentorderlist = async (currentPage,searchText) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    let url = API_ENDPOINTS.AGENT_ORDERS_LIST + `?page=${currentPage}`;
    
    if (searchText && searchText.trim() !== '') {
      url += `&search=${encodeURIComponent(searchText)}`;
    }
    
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
     throw error;
  }
};
export const AgentOrderInvoiceService = async (id) => {
  
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.AGENT_ORDER_INVOICE + `${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const AgentGetCode = async (id) => {
   
  try {
    const authToken = localStorage.getItem('authToken');
 
    const response = await axios.get(API_ENDPOINTS.AGENT_GET_CODE + `${id}`, 
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

