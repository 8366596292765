import React, { useState, useEffect, useRef } from "react";
import { EventOrderList } from "../../../services/eventservice";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import defaultAvator from "../../../assets/images/200x200.png";
import { API_ENDPOINTS } from "../../../services/api";
import CustomLoader from "../Loaderfiles/customloader";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import SEO from "../../../hooks/seo";

const EventOrdersDetail = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const countPerPage = 10;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const { id } = useParams();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await EventOrderList(currentPage, searchText, id);
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };

  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const location = useLocation();
  const message = location.state?.message;
  const prevMessageRef = useRef();
  const prevLocationKeyRef = useRef(location.key);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (
      currentPage !== prevPageRef.current ||
      debouncedSearchText !== prevSearchTextRef.current
    ) {
      fetchData();
    }
    // Update the previous values
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
  }, [currentPage, debouncedSearchText]);

  useEffect(() => {
    // Check if the location is different from the previous one and if the message is different
    if (
      message &&
      (message !== prevMessageRef.current ||
        location.key !== prevLocationKeyRef.current)
    ) {
      //toast.success(message, { duration: 3000 });
      prevMessageRef.current = message;
      prevLocationKeyRef.current = location.key;

      // Clear the message in the state after showing the toast
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, message: null },
      });
    }
  }, [message, location, navigate]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleImageError = (event) => {
    event.target.src = defaultAvator;
  };
  const handleExportCSV = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_EVENT_eventOrders +
      id +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_EVENT_eventOrders +
      id +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  if (!pageLoading) {
    return (
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const onBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      <SEO title="Event Orders List - eticketing" />
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        <PageHeader
          title="Event Orders List"
          onBackButtonClick={onBackButtonClick}
          showBackButton={true}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Event Orders Rows"
              onSearchChange={handleSearchChange}
              showSearch={false}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="mt-5">
              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : data.length === 0 ? (
                <div className="text-center text-gray-500 mt-8">
                  <h1 className="xl text-3xl">No records found.</h1>
                </div>
              ) : (
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Order Id
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Customer Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Event Name
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Date
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Tickets Sold
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Order Amount
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Payment
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Service Charges
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Org Vat
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          PAYMENT METHOD
                        </th>

                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                      >
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.order_id || "N/A"}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <div>
                            {item.customer &&
                            item.customer.name &&
                            item.customer.last_name
                              ? `${item.customer.name} ${item.customer.last_name}`
                              : "N/A"}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.event.name ? item.event.name : "N/A"}
                          <p>{formatDate(item.event.created_at) || "N/A"}</p>
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {formatDate(item.updated_at) || "N/A"}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {item.quantity || "N/A"}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                          {item.curruncry === "JMD" ? "JMD$" : item.curruncry}{" "}
                          {(item.sub_total ?? 0).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                          {item.curruncry === "JMD" ? "JMD$" : item.curruncry}{" "}
                          {(item.order_price ?? 0).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                          {item.curruncry === "JMD" ? "JMD$" : item.curruncry}{" "}
                          {(item.admin_commission ?? 0).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                          {item.curruncry === "JMD" ? "JMD$" : item.curruncry}{" "}
                          {(item.org_vat ?? 0).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                          <span className="badge bg-success/10 text-success dark:bg-success/15">
                            {item?.payment_type}
                          </span>
                        </td>

                        {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {item.curruncry === 'JMD' ? 'JMD$' : item.curruncry}
                                    {(item.payment ?? 0).toFixed(2)}
                                    </td> */}

                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <Link to={`/organization/order-invoice/${item.id}`}>
                            <svg
                              class="h-6 w-6 text-blue-500"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              {" "}
                              <path stroke="none" d="M0 0h24v24H0z" />{" "}
                              <circle cx="12" cy="12" r="2" />{" "}
                              <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />{" "}
                              <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EventOrdersDetail;
