import React from "react";

const FormErrorPopup = ({ isOpen, errors, onClose }) => {
  return (
    <div> 
      {isOpen && errors && errors.length > 0 && (
        <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
          <div
            className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
            onClick={onClose}
          ></div>
          <div className="relative max-w-md rounded-lg bg-white pt-10 pb-4 text-center transition-all duration-300 dark:bg-navy-700">
            <div className="mt-4 px-4 sm:px-12">
              <h3 className="text-lg text-slate-800 dark:text-navy-50">
                Form Errors
              </h3>
              <ul className="mt-1 text-slate-500 dark:text-navy-200 list-disc list-inside">
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
            <div className="my-4 mt-16 h-px bg-slate-200 dark:bg-navy-500"></div>
            <button
              onClick={onClose}
              className="btn w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormErrorPopup;
