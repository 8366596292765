import React, { useState, useEffect, useRef } from "react";
import { Agentorderlist } from "../../services/agent/agentorderservice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../hooks/Pagination";
import PageHeader from "../../hooks/PageHeader";
import TableTools from "../../hooks/TableTools";
import { formatCurrency, formatDate, formatName } from "../../helpers/Helpers";
import CustomLoader from "./customloader";
import useResizeObserver from "./../../hooks/useResizeObserver";
import SEO from "../../hooks/seo";
import { API_ENDPOINTS } from "../../services/api";
// payment_type
// Event > Currency > Symbol
// Cash > Symbal Default USD
// STRIPE, CASH, Complimentary
const Orders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [symbol, setSymbol] = useState("");

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem("currency");
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Agentorderlist(currentPage, searchText);
        //console.log(response, "response");
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setData(response.data);
        }
      } catch (error) {
        //console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      setPageLoading(true);
    };

    fetchData();
  }, [currentPage, searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.AGENT_CSV_ORDER_LIST_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.AGENT_ORDER_LIST_EXPORT_PDF +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const onButtonClickCreate = () => {
    alert("apply");
  };
  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  return (
    <>
      <SEO title="Order List - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <PageHeader
          title="Order List"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          {/* Hoverable Rows */}
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Order Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <div className="overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="is-hoverable w-full text-left">
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Order Id
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Customer Name
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Event Name
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Date
                          </th>
                          <th className="whitespace-nowrap text-center  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Tickets Sold
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Order Amount
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Payout Amount
                          </th>
                          
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Tickets Commission
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Org Vat
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Agent Commission
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Service Fees
                          </th> 
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            Processing Fees
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                            User Vat
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Payment
                          </th>
                          <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Payment Type
                          </th>
                          <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((item, index) => (
                          <tr
                            key={index}
                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                          >
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.order_id}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.customer
                                ? formatName(
                                    item.customer.name,
                                    item.customer.last_name
                                  ) || "N/A"
                                : "N/A"}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.event.name
                                ? item.event.name.length > 50
                                  ? item.event.name.slice(0, 50) + "..."
                                  : item.event.name
                                : "NULL"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {formatDate(item.updated_at)}
                            </td>

                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.quantity}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.sub_total ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.order_price ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.admin_commission ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.org_vat ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.agent_commission ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.web_commission ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.processing_fee ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                              {item.curruncry === "JMD"
                                ? "JMD$"
                                : item.curruncry}{" "}
                              {(item.user_vat ?? 0).toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item?.payment_type === "Complimentary" ||
                              item?.payment_type === "Free"
                                ? "0"
                                : `${
                                    item.curruncry === "JMD"
                                      ? "JMD$"
                                      : item.curruncry
                                  } ${(item.payment ?? 0).toFixed(2)}`}
                              {/* {item?.payment === 'Free' ? (
                                                '0'
                                            ) : (
                                                item?.payment_type === 'Complimentary' ? (
                                                    '0'
                                                ) : (
                                                    formatCurrency(
                                                        item?.payment,
                                                        item?.payment_type,
                                                        item?.agent_id ? item.currency_code : (item?.currency?.symbol ?? '$ ')
                                                    )
                                                )
                                            )} */}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {item.payment_type}
                            </td>
                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                              {/* Actions can be buttons or links */}
                              <Link to={`/agent/order-invoice/${item.id}`}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                  ></path>
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M2 12s3-8 10-8 10 8 10 8-3 8-10 8-10-8-10-8z"
                                  ></path>
                                </svg>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Orders;
