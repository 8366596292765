import React, { useEffect, useRef, useState } from "react";
import SEO from "../../../hooks/seo";
import { EventCountryListFetch } from "../../../services/admin/event-countries-service";
import CustomLoader from "../Loaderfiles/customloader";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import Pagination from "../../../hooks/Pagination";
import { Link, useNavigate } from "react-router-dom";

function EventCountriesList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [totalRecords, setTotalRecords] = useState(0);

  const recordsPerPage = 10;
  const ref = useRef(null);

  useEffect(() => {
    const fetchCountries = async () => {
      setLoading(true);
      try {
        const response = await EventCountryListFetch(currentPage, searchText);
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } catch (err) {
        setError(err.message || "An error occurred");
      } finally {
        setLoading(false);
        setPageLoading(true);
      }
    };

    fetchCountries();
  }, [currentPage, searchText]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const handleExportCSV = async () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_USER_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_USER_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  if (!pageLoading) {
    return (
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#bd387a"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }

  return (
    <>
      <SEO title="Event Countries List - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <PageHeader title="Event Countries List" />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Users Rows"
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={false}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              {data.length === 0 ? (
                <div className="text-center text-gray-500 mt-8">
                  <h1 className="text-3xl">No records found.</h1>
                </div>
              ) : (
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Id
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Image
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Processing Fee
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Service Fee
                        </th>

                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Vat Fee
                        </th>
                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((country) => (
                        <tr
                          key={country.id}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                        >
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {country.id || "NA"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <img
                              src={country.country_flag || "N/A"}
                              alt={country.country_flag || "N/A"}
                              className="w-16 h-16 object-cover"
                            />
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {country.country || "NA"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {country.fee ? `${country.fee}%` : "N/A"} (
                            {country.fee_enabled === 1
                              ? "Enabled"
                              : country.fee_enabled === 0
                              ? "Disabled"
                              : "N/A"}
                            )
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {country.service_fee
                              ? `$${country.service_fee.toFixed(2)}`
                              : "N/A"}{" "}
                            (
                            {country.service_fee_enabled === 1
                              ? "Enabled"
                              : country.service_fee_enabled === 0
                              ? "Disabled"
                              : "N/A"}
                            )
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {country.vat_fee ? `${country.vat_fee}%` : "N/A"} (
                            {country.vat_exclusive === 1
                              ? "Exclusive"
                              : country.vat_exclusive === 0
                              ? "Inclusive"
                              : "N/A"}
                            )
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <Link
                              className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25"
                              to={`/admin/event-country/edit/${country.id}`}
                            >
                              <i className="fa fa-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <Pagination
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                onPageChange={handlePageChange}
                // currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default EventCountriesList;
