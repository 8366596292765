import React, { useState, useEffect, useRef, useReducer } from "react";
import PageHeader from "../../hooks/PageHeader";
import CustomLoader from "./customloader";
import SEO from "../../hooks/seo";
import useResizeObserver from "./../../hooks/useResizeObserver";
import {
  AgentAllEvents,
  fetchEventWithCate,
  fetchEvent_Tickets,
  sellTicketsAgent,
  AgentSearchEvents
} from "../../services/agent/agentPosService";
import {
  FetchservicFee
} from '../../services/agent/agent-SellTickets-service';
import CategorySwiper from "./pos/CategorySwiper";
import Swal from "sweetalert2";
import { agentProfile } from "../../services/agent/agentServices";


const PosTickets = ({ pageNumber, setpageNumber, user_Info }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [events, setEvents] = useState([]);
  const [eventsTickets, setEventsTickets] = useState([]);
  const [eventWithticket, seteventWithticket] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [agentcurrency, setAgentcurrency] = useState('');
  const [processingFee, setProcessingFee] = useState(0);
  const [serviceFree, setServiceFee] = useState(0);
  const [vat_charge, setVat_charge] = useState(0);
  const [VatFee, setVatFee] = useState(0);
  const [agent_commission_type, set_agent_commission_type] = useState('');
  const [agent_commission_about, set_agent_commission_about] = useState('');
  const [agent_commissionCharge, set_agent_commissionCharge] = useState('');

   // charge amount states
   const [totalPrice, setTotalPrice] = useState(0);
   const [subTotal, setSubTotal] = useState(0);
   const [vatTotal, setvatTotal] = useState(0);
   const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
   const [processingFeeTotal, setProcessingFeeTotal] = useState(0);
   const [agentCommisionTotal, setAgentCommisionTotal] = useState(0);
   const [selectedTickets, setSelectedTickets] = useState(0);
   //end charge amount states


  const handleClick = async (categoryId) => {
    console.log(categoryId);
    setSelectedCategoryId(categoryId);
    setLoading(true); // Set loading to true initially

    if (categoryId !== "0") {
      try {
        const response = await fetchEventWithCate(categoryId);
        console.log(response.events, "response");

        setEvents(response.events);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // Always set loading to false after the operation completes
      }
    } else {
      fetchAllEvent();
    }
  };

  const fetchFeeData = async () => {
    try {
        const data = await FetchservicFee();
        console.log(data,'feee.data')
        if(data.response.data.data.service_fee_enabled){
            setServiceFee(data.response.data.data.service_fee);
        }

        if(data.response.data.data.webfee_enabled){
            setProcessingFee(data.response.data.data.webfee_percentage);
        }

      setVat_charge(data.response.data.data.vat_exclusive);
      setVatFee(data.response.data.data.vatfee_percentage);
      set_agent_commission_type(data.response.data.data.agent_commission_type);
      set_agent_commission_about(data.response.data.data.agent_commission_about);
      set_agent_commissionCharge(data.response.data.data.agent_commission);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        setPageLoading(false);
    }
};
useEffect(() => {
        fetchFeeData();
}, []);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = async (event) => {
    const inputValue = event.target.value.trim();
    setSearchQuery(inputValue);

    // Check if the length of inputValue is greater than or equal to 4
    if (inputValue.length >= 3) {
      setLoading(true);
      try {
        const response = await AgentSearchEvents(inputValue);
        console.log('search', response);
        setEvents(response.events);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    }
    else if (inputValue.length === 0) {
      setLoading(false);

      fetchAllEvent();
    }
  };


  const handleClearSearch = () => {
    setLoading(true);
    setSearchQuery(''); // Clear the search query
    fetchAllEvent(); // Fetch all events
  };








  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };

  useResizeObserver(ref, handleResize);

  const fetchAllEvent = async () => {
    try {
      const response = await AgentAllEvents();
      console.log(response.events, "response");
      setEvents(response.events);
      setLoading(true);
      setPageLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEvent();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');


  const handleClickModel = (event, index) => {
    setSelectedEvent(event);
    setShowModal(true);
    fetchEventTickets(event.id);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const [symbol, setSymbol] = useState('');

  useEffect(() => {
    // Fetch and display currency from localStorage
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      setSymbol(storedCurrency);
    }
  }, []);
  const fetchAgent = async () => {
    try {
      const response = await agentProfile();
      console.log('fetchAgent', response);
      setAgentcurrency(response.user.currency.symbol);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching agent profile:', error);

    }
  };

  useEffect(() => {
    fetchAgent();
  }, []);


  const fetchEventTickets = async (id) => {
    setEventsTickets([]);
    setTicketLoading(true);
    try {
      const response = await fetchEvent_Tickets(id);
      console.log(response, "response");
      const ticketsWithSelectedQuantity = response.tickets.map((ticket) => ({
        ...ticket,
        selectedQuantity: 0,
      }));
      setEventsTickets(ticketsWithSelectedQuantity);
      seteventWithticket(response.event);
      setTicketLoading(false);
      // setPageLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setTicketLoading(false);
    }
  };

  function formatDateTime(date) {
    // Ensure date is a Date object
    const eventDate = new Date(date);

    // Format the date and time as 'Month Date, Year Hour:Minute AM/PM'
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return eventDate.toLocaleString("en-US", options);
  }

  const [firstArray, setfirstArray] = useState([]);
  const [cart, setCart] = useState([]);
  const [grandTotal, setgrandTotal] = useState("0");

  const handleIncrement = (ticket) => {
    console.log(ticket);
    const updatedTickets = eventsTickets.map((t) => {
      if (t.id === ticket.id) {
        const newSelectedQuantity = t.selectedQuantity + 1;
        if (newSelectedQuantity <= t.available_quantity && newSelectedQuantity <= t.ticket_per_order) { // Check against available quantity and ticket per order
          return {
            ...t,
            selectedQuantity: newSelectedQuantity,
          };
        } else {
          let errorMessage = "";
          if (newSelectedQuantity > t.available_quantity) {
            errorMessage = `You can only add up to ${t.available_quantity} tickets per order.`;
          }
          if (newSelectedQuantity > t.ticket_per_order) {
            errorMessage += ` You can only add ${t.ticket_per_order} tickets per order.`;
          }
          // Show SweetAlert notification if maximum limit reached
          Swal.fire({
            icon: "warning",
            title: "Maximum Limit Reached",
            text: errorMessage,
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
        }
      }
      return t;
    });
    setEventsTickets(updatedTickets);

    // Construct the selected ticket array after incrementing the quantity
    const selectedTicketArray = updatedTickets
      .filter((t) => t.selectedQuantity > 0)
      .map((t) => ({
        name: t.name,
        perTicketPrice: t.local_currency_price,
        quantity: t.selectedQuantity,
        totalPrice: t.selectedQuantity * t.local_currency_price,
        eventId: t.eventId,
        available_quantity: t.available_quantity,
      }));
    // Now, you can do whatever you want with the selected ticket array, like saving it to state or local storage
    setfirstArray(selectedTicketArray);
    console.log("Selected Ticket Array:", selectedTicketArray);
  };


  const handleDecrement = (ticket) => {
    const updatedTickets = eventsTickets.map((t) => {
      if (t.id === ticket.id && t.selectedQuantity > 0) {
        return {
          ...t,
          selectedQuantity: t.selectedQuantity - 1,
        };
      }
      return t;
    });
    setEventsTickets(updatedTickets);

    // Construct the selected ticket array after decrementing the quantity
    const selectedTicketArray = updatedTickets
      .filter((t) => t.selectedQuantity > 0)
      .map((t) => ({
        name: t.name,
        perTicketPrice: t.local_currency_price,
        quantity: t.selectedQuantity,
        totalPrice: t.selectedQuantity * t.local_currency_price,
        eventId: t.eventId,
        available_quantity: t.available_quantity,
      }));
    // Now, you can do whatever you want with the selected ticket array, like saving it to state or local storage
    setfirstArray(selectedTicketArray);
    console.log("Selected Ticket Array:", selectedTicketArray);
  };

  const calculateTotalPrice = () => {
    return eventsTickets.reduce((total, ticket) => {
      return total + ticket.selectedQuantity * ticket.local_currency_price;
    }, 0);
  };


  const [hoverIndex, setHoverIndex] = useState(null);

  // Function to handle hover
  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };


  const handleAddToCart = () => {
    // Construct the selected ticket array
    console.log(eventsTickets);
    const selectedTicketArray = eventsTickets
      .filter((ticket) => ticket.selectedQuantity > 0)
      .map((ticket) => ({
        ticket_name: ticket.name,
        ticket_per_order: ticket.ticket_per_order,
        perTicketPrice: ticket.local_currency_price,
        quantity: ticket.selectedQuantity,
        totalPrice: ticket.selectedQuantity * ticket.local_currency_price,
        eventId: ticket.event_id,
        ticket_id: ticket.id,
        event_name: eventWithticket.name,
        event_image: eventWithticket.imagePath + eventWithticket.image,
        people_allow: eventWithticket.people,
        available_quantity: ticket.available_quantity,
        
      }));

    // Show SweetAlert notification if no tickets are selected
    if (selectedTicketArray.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No Tickets Selected",
        text: "Please select at least one ticket to add to your cart.",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      });
      return; // Exit function if no tickets are selected
    }

    console.log("cart before update:", cart);
    setCart((prevCart) => [...prevCart, ...selectedTicketArray]);
    setShowModal(false);
  };

  // Use useEffect to log the updated cart state
  // useEffect(() => {
  //   console.log("cart after update:", cart);
 
  //   const grandTotal = cart.reduce((total, item) => total + item.totalPrice, 0);

  //   setgrandTotal(grandTotal);
    
  //   console.log("Grand Total:", grandTotal);
  // }, [cart]); 
  
  useEffect(() => {
    // Calculate total number of tickets and base subtotal from cart
    const totalTickets = cart.reduce((total, item) => total + item.quantity, 0);
    const subTotal = cart.reduce((total, item) => total + item.totalPrice, 0);
    
    // Calculate service fee
    const serviceFeeTotal = totalTickets * serviceFree;
    
    // Calculate processing fee
    const processingFeeTotal = (subTotal + serviceFeeTotal) * (processingFee / 100);
    
    // Calculate agent commission
    let agentCommissionTotal = 0;
    if (agent_commission_type === "amount") {
        if (agent_commission_about === "per_ticket") {
            agentCommissionTotal = totalTickets * agent_commissionCharge;
        } else {
            agentCommissionTotal = Number(agent_commissionCharge);
        }
    }
    
    // Calculate VAT if enabled
    let vatTotal = 0;
    if (vat_charge === 1) {
        vatTotal = (serviceFeeTotal + processingFeeTotal) * (VatFee / 100);
    }
    
    // Calculate total before percentage-based agent commission
    const subtotalWithFees = subTotal + serviceFeeTotal + processingFeeTotal + vatTotal;
    
    // Recalculate agent commission if it's percentage-based
    if (agent_commission_type === "percentage") {
        agentCommissionTotal = subtotalWithFees * (agent_commissionCharge / 100);
    }
    
    // Calculate final total
    const finalTotal = subtotalWithFees + agentCommissionTotal;
    
    // Update all state values
    setSelectedTickets(totalTickets);
    setSubTotal(subTotal);
    setServiceFeeTotal(serviceFeeTotal);
    setProcessingFeeTotal(processingFeeTotal);
    setvatTotal(vatTotal);
    setAgentCommisionTotal(agentCommissionTotal);
    setTotalPrice(finalTotal);
    
    console.log({
        totalTickets,
        subTotal,
        serviceFeeTotal,
        processingFeeTotal,
        vatTotal,
        agentCommissionTotal,
        finalTotal
    });

}, [cart, serviceFree, processingFee, VatFee, vat_charge,agent_commission_type, agent_commission_about, agent_commissionCharge]);

  if (pageLoading) {
    return (
      // <main
      //   ref={ref}
      //   style={{ width: "100%", height: "100%" }}
      //   className="main-content w-full px-[var(--margin-x)] pb-8"
      // >
      <div className="flex justify-center items-center h-screen">
        <CustomLoader
          visible={true}
          height={80}
          width={80}
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius={1}
        />
      </div>
      // </main>
    );
  }

  // const incrementOne = (item) => {
  //   console.log(item);
  //   const updatedCart = cart.map((cartItem) => {
  //     if (cartItem === item) {
  //       return {
  //         ...cartItem,
  //         quantity: cartItem.quantity + 1,
  //         totalPrice: (cartItem.quantity + 1) * cartItem.perTicketPrice,
  //       };
  //     }
  //     return cartItem;
  //   });
  //   setCart(updatedCart);
  // };

  const incrementOne = (item) => {
    const { available_quantity, ticket_per_order } = item;
    const newSelectedQuantity = item.quantity + 1;
    let errorMessage = "";
  
    // Check if the selected quantity plus 1 exceeds either the available quantity or ticket per order limit
    if (newSelectedQuantity > available_quantity) {
      errorMessage = `You can only add up to ${available_quantity} tickets per order.`;
    }
    if (newSelectedQuantity > ticket_per_order) {
      errorMessage += ` You can only add ${ticket_per_order} tickets per order.`;
    }
  
    // If there's an error message, show it in a SweetAlert notification
    if (errorMessage) {
      Swal.fire({
        icon: "warning",
        title: "Maximum Limit Reached",
        text: errorMessage,
        confirmButtonText: "OK",
        allowOutsideClick: false,
      });
      return; // Stop execution if there's an error
    }
  
    // Update the cart if there are no errors
    const updatedCart = cart.map((cartItem) => {
      if (cartItem === item) {
        return {
          ...cartItem,
          quantity: cartItem.quantity + 1,
          totalPrice: (cartItem.quantity + 1) * cartItem.perTicketPrice,
        };
      }
      return cartItem;
    });
    setCart(updatedCart);
  };
  

  // Define the decrement_one function
  const decrementOne = (item) => {

    const updatedCart = cart.map((cartItem) => {
      if (cartItem === item && cartItem.quantity > 1) {
        return {
          ...cartItem,
          quantity: cartItem.quantity - 1,
          totalPrice: (cartItem.quantity - 1) * cartItem.perTicketPrice,
        };
      }
      return cartItem;
    });
    setCart(updatedCart);
  };


  const handleCash = async () => {

    setPaymentMethod('cash')
  }







  const handleCheckout = async () => {
    try {
      // Check if payment method is selected
      if (paymentMethod === '') {
        Swal.fire({
          title: 'Payment Method Not Selected',
          text: 'Please select a payment method first',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return; // Return early if payment method is not selected
      }

      const userId = localStorage.getItem('AgentuserId');
      const userInfoString = localStorage.getItem('AgentSellTicket_userInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;





      // Check if user ID is present
      if (!userId) {
        Swal.fire({
          title: 'User ID not found',
          text: 'Please log in to continue',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return; // Return early if user ID is not found
      }

      // Check if cart is empty
      if (cart.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Cart is Empty',
          text: 'Please select tickets first.',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
        return; // Return early if cart is empty
      }

      // Display custom order detail card
      Swal.fire({
        title: 'Order Details', // Title of the modal
        html: `
        <div class="p-4 text-center">
          <!-- User Card -->
          ${userInfo ? `
          <div class="flex items-center justify-center flex-col mb-4">
              <!-- Ensure the image maintains aspect ratio and is not stretched. Add object-cover or object-contain depending on your need -->
              <img src="${userInfo.imagePath + userInfo.image}" class="w-20 h-20 mb-2 rounded-full object-cover" alt="User Image" />
              <div>
                  <p class="text-lg font-bold">${userInfo.name} ${userInfo.last_name}</p>
                  <p class="text-gray-600">#${userInfo.user_id}</p>
              </div>
          </div>
          ` : ''}
      </div>


        <table class="min-w-full leading-normal">
        <thead>
            <tr>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Event
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Details
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Price
                </th>
            </tr>
        </thead>
        <tbody>
            ${cart.map(item => `
                <tr class="hover:bg-gray-200">
                    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <img src="${item.event_image}" alt="${item.event_name} image" class="w-12 h-12 md:max-w-[122px] rounded-md">
                            </div>
                            <div class="ml-3">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    ${item.event_name}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">
                            ${item.ticket_name} - Quantity: ${item.quantity}
                        </p>
                    </td>
                    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">
                        ${symbol}${item.totalPrice.toFixed(2)}
                        </p>
                    </td>
                </tr>
            `).join('')}
        </tbody>
    </table>

    
        <!-- Grand Total -->
        <div class="mt-4">
          
            <p class="text-lg font-semibold text-right">  Total: ${symbol} ${totalPrice.toFixed(2)}</p>
            
        </div>
    </div>
    
`,
        showCancelButton: true, // Show a cancel button
        confirmButtonText: 'Confirm Order', // Text for the confirm button
        cancelButtonText: 'Cancel', // Text for the cancel button
        allowOutsideClick: false, // Prevent dismissing the modal by clicking outside
        width: '50%'
      }).then((result) => {
        if (result.isConfirmed) {
          // Prepare cart data
          const indexedCart = {};
          cart.forEach((item) => {
            indexedCart[item.ticket_id] = item;
          });

          // Create FormData object
          const formData = new FormData();
          formData.append('cart', JSON.stringify(indexedCart));
          formData.append('customer_id', userId);

          // Set loading state
          setisloading(true);

          // Send request to sell tickets
          sellTicketsAgent(formData)
            .then((response) => {
              if (response && response.success) {
                // Success case: Show success message
                Swal.fire({
                  icon: 'success',
                  title: 'Order Created',
                  text: 'Your order has been successfully created.',
                  confirmButtonText: 'OK',
                  allowOutsideClick: false
                }).then(() => {
                  // Reset states and local storage
                  setisloading(false);
                  setCart([]);
                  setpageNumber(1);
                  localStorage.removeItem('AgentuserId');
                  localStorage.removeItem('AgentSellTicket_userInfo');
                });
              } else {
                // Error case: Show error message
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: response.message || 'An error occurred while processing your order.',
                  confirmButtonText: 'OK',
                  allowOutsideClick: false
                });
              }
            })
            .catch((error) => {
              // Error case: Show error message
              console.error('Error fetching data:', error);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while processing your order.',
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
            })
            .finally(() => {
              // Reset loading state
              setTicketLoading(false);
            });
        }
      });


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleDeleteItem = (index) => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, remove item from cart
        const updatedCart = [...cart];
        updatedCart.splice(index, 1);
        setCart(updatedCart);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // If user cancels, do nothing
        Swal.fire("Cancelled", "Your item is safe :)", "info");
      }
    });
  };

  function truncateText(text, maxLength) {
    if (text.split(' ').length > maxLength) {
      return text.split(' ').slice(0, maxLength).join(' ') + '...';
    }
    return text;
  }


  const handleReset = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reset your POS. This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reset it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with reset
        setCart([]);
        setpageNumber(1);
        localStorage.removeItem('AgentuserId');
        localStorage.removeItem('AgentSellTicket_userInfo');
        Swal.fire(
          'Reset!',
          'Your POS has been reset.',
          'success'
        );
      }
    });
  };




  return (
    <>
      <SEO title="Sell Ticket - eticketing" />


      <div className="grid grid-cols-4 gap-4 mt-3">
        <div className="col-span-1">
          {user_Info && (
            <p className="text-base font-medium text-slate-700 dark:text-navy-100 mt-4">
              <span className="font-bold">{user_Info.name}</span>
              <span className="font-bold">{user_Info.last_name} - User Id #{user_Info.user_id}</span>
            </p>
          )}
        </div>
        <div className="col-span-2 searchPos">
          <div className="relative w-full">
            <input
              type="text"
              value={searchQuery}
              onChange={handleChange}
              placeholder="Search for events..."
              className="w-full search_input px-4 mt-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <p class="mt-1">Type 3 or more characters to search.</p>
            {searchQuery && (
              <span
                className="absolute inset-y-0 right-0 mb-5 flex items-center pr-3 cursor-pointer"
                onClick={handleClearSearch}
              >
                <i className="fa fa-times text-gray-400 mt-2" aria-hidden="true"></i>
              </span>
            )}
          </div>

        </div>
        {user_Info && (
          <div className="col-span-1 flex justify-end items-center">
            <button
              onClick={handleReset}
              className="btn h-11 px-4 bg-gray-200 font-medium text-gray-800 hover:bg-gray-300 focus:bg-gray-300 active:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:active:bg-gray-500"
            >
              Reset
            </button>
          </div>
        )}
      </div>
      <div class=" grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
        <div class="col-span-12 sm:col-span-6 lg:col-span-8">
          <CategorySwiper handleClick={handleClick} />
          <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-5 sm:grid-cols-2 sm:gap-5 lg:mt-14 lg:grid-cols-3 xl:grid-cols-4">
            {loading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CustomLoader
                  visible={true}
                  height={80}
                  width={80}
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius={1}
                />
              </div>
            ) : events.length === 0 ? (
              <div
                style={{
                  position: "fixed",
                  top: "60%",
                  left: "42%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <h4>No events found</h4>
              </div>
            ) : (
              events.map((event, index) => (
                <div
                  key={index}
                  className="card pointer-hand"
                  onClick={() => handleClickModel(event, index)}
                >
                  <img
                    className="rounded-top-lg object-cover"
                    src={`${event.imagePath}/${event.image}`}
                    alt={event.name}
                    style={{ height: "128px" }}
                  />
                  <div className="p-2">
                    <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                      {event.name}
                    </p>
                    <p className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300 mt-2">
                      <strong>Start Time:</strong>
                    </p>
                    <p className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300 mt-2">
                      {formatDateTime(event.start_time)}
                    </p>
                    <p className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300 mt-2">
                      <strong>End Time:</strong>
                    </p>
                    <p className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300 mt-2">
                      {formatDateTime(event.end_time)}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="hidden sm:col-span-6 sm:block lg:col-span-4">
          {/* <button onClick={handleReset} className="btn mt-5 h-11  justify-between bg-gray-200 font-medium text-gray-800 hover:bg-gray-300 focus:bg-gray-300 active:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:active:bg-gray-500"
          >
            Reset
          </button> */}
          <div className="card mt-5 p-4 sm:p-5">

            <div className="flex flex-col space-y-3.5">


              {cart.length === 0 ? (
                <div className="group flex items-center justify-between space-x-3">
                  <div className="flex items-center space-x-4">
                    <div className="relative flex">
                      <img src="/images/800x600.png" className="mask p025 is-star size-11 origin-center object-cover" alt="image" />
                      <div className="absolute top-0 right-0 -m-1 flex h-5 min-w-[1.25rem] items-center justify-center rounded-full border border-white bg-slate-200 px-1 text-tiny+ font-medium leading-none text-slate-800 dark:border-navy-700 dark:bg-navy-450 dark:text-white">
                        0
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                          Item not found!
                        </p>
                        <button className="btn size-6 rounded-full p-0 opacity-0 hover:bg-slate-300/20 focus:bg-slate-300/20 focus:opacity-100 active:bg-slate-300/25 group-hover:opacity-100 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="size-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                          </svg>
                        </button>
                      </div>
                      <p className="text-xs+ text-slate-400 dark:text-navy-300">
                        empty
                      </p>
                    </div>
                  </div>
                  <p className="font-inter font-semibold">{symbol}0.00</p>
                </div>
              ) : (
                <>
                  {cart.map((item, index) => (
                    <div
                      className="group flex items-center justify-between space-x-3 relative"

                    >
                      <div className="flex items-center space-x-4">
                        <div className="relative flex items-center justify-center w-16 h-16">
                          <img
                            src={item.event_image}
                            className="mask is-star size-11 origin-center object-cover w-16 h-58 !h-auto"
                            alt="image"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                          />

                          <div className="absolute top-0 right-0 -m-1 flex h-5 min-w-[1.25rem] items-center justify-center rounded-full border border-white bg-slate-200 px-1 text-tiny+ font-medium leading-none text-slate-800 dark:border-navy-700 dark:bg-navy-450 dark:text-white">

                            {truncateText(item.quantity.toString(), 2)}
                          </div>
                        </div>
                        <div className="relative">
                          <div className="flex items-center space-x-1 mt-2 group-hover:text-red-500 relative">
                            <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100"
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {item.event_name}
                            </p>
                            {/* {hoverIndex === index && (
                                <button
                                  className="btn p-0 w-5 h-5 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 absolute right-0"
                                  onClick={() => handleDeleteItem(index)}
                                >
                                  <i className="fas fa-trash cursor-pointer text-red-500"></i>
                                </button>
                              )} */}
                          </div>

                          <p className="text-xs+ text-slate-400 dark:text-navy-300">
                            {truncateText(item.ticket_name, 2)}
                          </p>
                          <div className="flex items-center space-x-1 mt-3 gap15">
                            <button
                              className="size-12 inline-flex justify-center items-center gap-x-2 
                              textxl font-medium rounded-md border border-gray-200 bg-white text-gray-800
                              shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                              dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                              dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                              onClick={() => decrementOne(item)}
                            >
                              -
                            </button>
                            <span className="textxl">{item.quantity}</span>
                            <button
                              className="size-12 inline-flex justify-center items-center gap-x-2 
                              textxl font-medium rounded-md border border-gray-200 bg-white text-gray-800
                              shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                              dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                              dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                              onClick={() => incrementOne(item)}
                            >
                              +
                            </button>
                          </div>

                        </div>
                      </div>
                      <div>
                        <p className="font-inter font-semibold">{symbol}{item.totalPrice.toFixed(2)}</p>
                        <button className="btn btn-sm" onClick={() => handleDeleteItem(index)}>
                          <i className="fas fa-trash cursor-pointer text-red-500 mt-3"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}



            </div>

            <div className="my-4 h-px bg-slate-200 dark:bg-navy-500" />

            <div className="space-y-2 font-inter mt-3">
              <div className="flex justify-between text-slate-600 dark:text-navy-100">
                <p>Subtotal</p>
                <p className="font-medium tracking-wide">{symbol}{subTotal.toFixed(2)}</p>
              </div>
               <div className="flex justify-between text-xs+">
                <p>Service Fee:</p>
                <p className="font-medium tracking-wide">{symbol} {serviceFeeTotal.toFixed(2)}</p>
              </div>
              <div className="flex justify-between text-xs+">
                <p> Processing Fee:</p>
                <p className="font-medium tracking-wide">{symbol} {processingFeeTotal.toFixed(2)}</p>
              </div>  
              <div className="flex justify-between text-xs+">
                <p>    Vat Tax:</p>
                <p className="font-medium tracking-wide"> {symbol} {vatTotal.toFixed(2)}</p>
              </div>  
              <div className="flex justify-between text-xs+">
                <p>  Agent Commission:</p>
                <p className="font-medium tracking-wide">      {symbol} {agentCommisionTotal.toFixed(2)}</p>
              </div>  
              <div className="flex justify-between text-base font-medium text-primary dark:text-accent-light">
                <p>Total</p>
                <p>{symbol}{totalPrice.toFixed(2)}</p>
                {/* <p>{symbol}{((grandTotal + (grandTotal * webfee_percentage) / 100)).toFixed(2)}</p> */}
                {/* <p>{symbol} {((grandTotal + (grandTotal * webfee_percentage / 100)) + ((grandTotal * webfee_percentage / 100) * vatfee_percentage / 100)).toFixed(2)}</p> */}
              </div>
            </div>
            <div className="mt-5 grid grid-cols-3 gap-4 text-center">
              <button
                className={`rounded-lg border border-slate-200 p-3 dark:border-navy-500 ${paymentMethod === 'cash' ? 'bg-yellow-500' : ''
                  }`}
                onClick={handleCash}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline size-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={1}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <span className="mt-1 font-medium text-primary dark:text-accent-light">
                  Cash
                </span>
              </button>
              <button className="rounded-lg border border-slate-200 p-3 dark:border-navy-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline size-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
                <span className="mt-1 font-medium text-primary dark:text-accent-light">
                  Debit
                </span>
              </button>
              <button className="rounded-lg border border-slate-200 p-3 dark:border-navy-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline size-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                  />
                </svg>
                <span className="mt-1 font-medium text-primary dark:text-accent-light">
                  Scan
                </span>
              </button>
            </div>


            <button
              onClick={handleCheckout}
              className={`btn mt-5 h-11 justify-between bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 ${loading ? 'cursor-wait' : ''}`}
              disabled={isloading} // Disable the button when loading
            >
              {isloading ? 'Processing...' : (
                <>
                  <span>Checkout</span>
                  {/* <span>{symbol} {((grandTotal + (grandTotal * webfee_percentage / 100)) + ((grandTotal * webfee_percentage / 100) * vatfee_percentage / 100)).toFixed(2)}</span> */}
                  <span>{symbol} {totalPrice.toFixed(2)}</span>
                </>
              )}
            </button>


          </div>
        </div>
      </div>
      {showModal && (
        <>

          <div className="fixed inset-0 z-50 bg-black bg-opacity-50"></div>

          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh] overflow-y-auto">
                <div className="relative p-6 flex-auto">
                  {ticketLoading ? (
                    <div className="flex justify-center items-center">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-1 flex">
                        <img
                          className="rounded-lg w-full max-h-[90vh] object-cover"
                          src={`${selectedEvent.imagePath}/${selectedEvent.image}`}
                          alt={selectedEvent.name}
                        />
                      </div>
                      <div className="col-span-1 relative">

                        <h2 className="text-2xl text-black font-semibold mb-2">
                          {selectedEvent.name}
                        </h2>
                        <h2 className="text-md text-black font-bold mb-4">
                          Category: {selectedEvent.cate_name}
                        </h2>

                        <p className="text-sm text-gray-600 mb-2">
                          <span className="font-bold"> Start Time</span>: {formatDateTime(selectedEvent.start_time)}
                        </p>
                        <p className="text-sm text-gray-600 mb-4">
                          <span className="font-bold">End Time</span>: {formatDateTime(selectedEvent.end_time)}
                        </p>

                      

                        <h4 className="text-xl font-bold mt-4">
                          Total Price:  {symbol}{calculateTotalPrice().toFixed(2)}
                        </h4>


                        <div class="my-4 h-px  bg-slate-200 dark:bg-navy-500"></div>



                        <div className="flex flex-col items-center ">
                          {eventsTickets.map((ticket, index) => (
                            <div
                              key={index}
                              className="border rounded-lg p-4 mb-4 shadow-md bg-white w-full max-w-md flex items-center justify-between"
                            >
                              <div>
                                <h3 className="text-lg font-bold">
                                  {ticket.name
                                    .split(" ")
                                    .slice(0, 4)
                                    .join(" ")}
                                  {ticket.name.split(" ").length > 4 && "..."}
                                </h3>
                                <p className="text-sm text-gray-700">
                                  Price: {symbol} {ticket.local_currency_price.toFixed(2)}
                                </p>
                              </div>

                              <div className="flex items-center space-x-2 gap15">
                                {ticket.available_quantity > 0 ? (
                                  <>
                                    <button
                                      className="size-12 inline-flex justify-center items-center gap-x-2 
                                      textxl font-medium rounded-md border border-gray-200 bg-white text-gray-800
                                      shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                                      dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                                      dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                      onClick={() => handleDecrement(ticket)}
                                    >
                                      -
                                    </button>
                                    <span className="textxl font-bold m0">
                                      {ticket.selectedQuantity}
                                    </span>
                                    <button
                                      className="size-12 inline-flex justify-center m0 items-center gap-x-2 
                                        textxl font-medium rounded-md border border-gray-200 bg-white text-gray-800
                                        shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                                        dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                                        dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                      onClick={() => handleIncrement(ticket)}
                                    >
                                      +
                                    </button>
                                  </>
                                ) : (
                                  <span className="text-xs text-red-500">Sold Out</span>
                                )}
                              </div>




                            </div>
                          ))}
                        </div>

                        <div class="my-4 h-px  bg-slate-200 dark:bg-navy-500"></div>

                        <div className="flex items-center justify-end py-6 rounded-b space-x-2">
                          <button
                            className="btn border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            type="button"
                            onClick={() => setShowModal(false)}
                            style={{ width: "30%" }}
                          >
                            Close
                          </button>

                          <button
                            onClick={handleAddToCart}
                            className="btn bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 w-2/3"
                          >
                            Add to Cart
                          </button>
                        </div>

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};
export default PosTickets;
