import axios from 'axios';
import { API_ENDPOINTS } from '../admin/api'; 

export const FeedbackFetch = async (currentPage,searchText) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
      const searchParam = searchText ? `&search=${searchText}` : '';
        const response = await axios.get(API_ENDPOINTS.FEEDBACK_LIST + `?page=${currentPage}${searchParam}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};
export const submitFeedback = async (formData) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS.SUBMIT_FEEDBACK, formData, {
       headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateFeedback = async (formData,id) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS.updateFeedbackData+id, formData, {
       headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const Edit_FeedbackData = async (id) => {
  
  try {
      const authToken = localStorage.getItem('authToken');
       console.log(API_ENDPOINTS.EditFeedbackData + `${id}`+'/edit');
        const response = await axios.get(API_ENDPOINTS.EditFeedbackData + `${id}`+'/edit', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};