import axios from 'axios';
import { API_ENDPOINTS } from './api';

const getProfileDetails = async () => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      throw new Error('No auth token found');
    }
    
    const response = await axios.get(API_ENDPOINTS.PROFILE_DETAIL, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      
    if (response.status !== 200) {
    throw new Error('Network response was not ok');
    }
    
    return response.data;
    
    
  }; 
  
  export { getProfileDetails };
  