import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const DashboardData = async () => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(API_ENDPOINTS.ADMIN_DASHBOARD_DATA, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};

export const graphData = async (selectionData) => {
  try {

    console.log(selectionData, 'call selectionData')
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(API_ENDPOINTS.ADMIN_DASHBOARD_DATA_GRAPH, selectionData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json', 
      },
    });

    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
    throw error;
  }
};