import React from "react";
import PageHeader from "../../../hooks/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import SEO from "../../../hooks/seo";
import { useState, useEffect } from "react";
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useRef } from 'react';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Edit_Page  ,updatePage } from "../../../services/admin/pageservice";
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import CustomLoader from '../Loaderfiles/customloader';
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
registerPlugin(FilePondPluginImagePreview);

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  status: yup.string().required('Status is required'),


  // Add more validation rules as needed
});


const PageEdit = () => {

  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const filePondRef = useRef(null);
  const { id } = useParams()

  {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

  
  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await Edit_Page(id);
    
        setLoading(false);
        setValue('title', response.data.title);
        setValue('description', response.data.description);
        setValue('status', response.data.status.toString());
        




      } catch (error) {
        toast.error(error);
        console.error("Error fetching data: ", error);
        //toast.success('This is a success message');
      } finally {

      }
    };

    fetchData();
  }, [id]);

  const statusOptions = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
    }),
  };




  const onSubmit = async (formData, e) => {
    setIsLoading(true);
    try {


      const response = await updatePage(formData, id);
 
      // if (response.status === 200) {
      //     toast.success(response.message);
      //       
      //   navigate('/admin/faq', { state: { message: response.data.message } });
      // }


      Swal.fire({
        title: 'Good Job',
        text: response.data.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked 'OK'
          navigate('/admin/pageList', { state: { message: response.data.message } });
        }
      });


      e.target.reset();
      //navigate('/login', { state: { message: 'Registration successful. Now you can log in with your credentials.' } });
    } catch (error) {

      console.error('Registration failed:', error);

      const errorMessage = error && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'An error occurred while submitting the role. Please try again.';


      Swal.fire({
        title: 'Error',
        text: errorMessage || 'An error occurred',
        icon: 'error',
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      });

    } finally {
      setIsLoading(false);
    }
  };
  const onBackButtonClick = () => {
    navigate('/admin/pageList');
  }


  return (
    <>
      <SEO
        title="Edit Page - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <ToastContainer />
        <PageHeader
          title="Edit Page"
          showBackButton={true}
          onBackButtonClick={onBackButtonClick}
        />
        <div className="mt-4 grid grid-cols-12 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex size-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                      <i className="fa-solid fa-layer-group" />
                    </div>
                    <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100 ml-2">
                      Edit Page
                    </h4>
                  </div>


                </div>
              </div>

              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center h-96">
                    <CustomLoader
                      visible={true}
                      height={80}
                      width={80}
                      color="#4FA94D"
                      ariaLabel="tail-spin-loading"
                      radius={1}
                    />
                  </div>
                </div>
              ) : (

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                      <span>Title</span>
                      <Controller
                        name="title"
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <input
                              className={`form-input mt-1.5 w-full rounded-lg border ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                                } bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent`}
                              placeholder="Title"
                              type="text"
                              {...field}
                            />
                            {fieldState.error && (
                              <p className="text-red-500">{fieldState.error.message}</p>
                            )}
                          </>
                        )}
                      />
                    </label>
                  </div>


                  <div className="space-y-4 p-4 sm:p-5" id="category">
                  <label className="block"></label>
                  <span>Description</span>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ReactQuill
                           className={`mt-1.5 w-full rounded-lg  ${fieldState.error ? 'border-red-500' : 'border-slate-300'
                          } bg-transparent mb-4 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent h-48`}
                          placeholder="Description"
                          value={field.value}
                          onChange={field.onChange}
                        />
                        {fieldState.error && (
                          <p className="text-red-500">{fieldState.error.message}</p>
                        )}
                      </>
                    )}
                  />
                
                  </div>


                    <div className="space-y-4 p-4 sm:p-5" id="category">
                    <label className="block">
                        <span>Status</span>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={statusOptions}
                              isSearchable={true}  
                              className="mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-1 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                              onChange={(selected) => field.onChange(selected.value)} // Update to set field value directly
                              value={statusOptions.find(option => option.value === field.value)} // Update to find the option based on field value
                              placeholder="Select Status"
                              styles={customStyles}
                            />
                          )}
                        />
                        {errors.status && <p className="text-red-500">{errors.status.message}</p>}
                      </label>
                </div>

                  <div className="space-y-4 p-4 sm:p-5" id="category">

                    <button
                      type="submit"
                      className={`btn bg-blue-700   font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 relative ${isLoading ? 'cursor-not-allowed' : ''}`}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center space-x-2">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>

                </form>

              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PageEdit;
