import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logoImage from "../assets/images/app-logo.svg";
import { register } from '../services/authService';
import FormErrorPopup from '../hooks/FormErrorPopup';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import CustomLoader from './customloader';
import logo from '../assets/images/logo.png';
import PhoneInput from 'react-phone-number-input';
import { useSetting } from '../hooks/SettingContext';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import SEO from "../hooks/seo";
import Swal from 'sweetalert2';
import {Link } from 'react-router-dom';
import Select from 'react-select';
import { Helmet } from 'react-helmet';

// Yup validation schema
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
  repeatPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
  organizationName: Yup.string().required("Organization Name is required"),
  // phoneNumber: Yup.string().matches(/^[0-9]+$/, "Phone Number must be only digits").required("Phone Number is required"),
  countryName: Yup.string().required("Country Name is required"),
   phoneNumber: Yup.string().required('Phone number is required'),
});

const SignupForm = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepPassword, setRepShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { settings, updateSettings } = useSetting(); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPageLoading(false);
    }, 2000); // Simulating 2 seconds loading time

    return () => clearTimeout(timeout);
  }, []);

  const defaultCountry = 'US';
    useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://api.tikkets.com/public/api/currencies');
        console.log('ok',response.data.currencies); 
        const countriesData = response.data.currencies.map(country => ({
          value: country.id,
          label: country.country
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

    console.log(countries);

      const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleRepPasswordVisibility= () => {
    setRepShowPassword(!showRepPassword);
  };
  const handleSubmit = async (values, setSubmitting) => {
    setIsLoading(true);
    try {
      const data = await register(values.firstName, values.lastName, values.email, values.password, values.organizationName, values.phoneNumber, values.countryName);

      console.log('Registration successful:', data);
       
      setIsLoading(false);
      Swal.fire({
        title: 'Good Job',
        text: 'Registration successful. Now you can login with your credentials',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#a23896',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        showCloseButton: false // Ensure the close button is shown
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked 'OK'
          navigate('/login');
        }
      });


      //navigate('/login', { state: { message: 'Registration successful. Now you can login with your credentials.' } });
    } catch (error) {
      setIsLoading(false);
      console.error('Registration failed:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setIsLoading(false);
        const errorsArray = Object.entries(error.response.data.error).flatMap(([key, value]) => value.map(v => `${key}: ${v}`));
        setFormErrors(errorsArray);
      } else {
        // Fallback 
        setIsLoading(false);
        setFormErrors(['An unexpected error occurred.']);
      }
      setIsErrorPopupOpen(false);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

   const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
  };

  // Function to close the error popup
  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    setFormErrors([]);
  };
  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
      existingFavicon.href = `https://api.tikkets.com/public/images/upload/${settings.favicon}`;
    }
  }, [settings.favicon]);
  if (pageLoading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
        <CustomLoader
          visible={true}
          height={80}
          width={80}
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius={1}
        />
      </div>
    );
  }
  return (
    <>
    <Helmet>
    <script>
      {`
        var existingFavicon = document.querySelector("link[rel*='icon']");
        if (existingFavicon) {
          existingFavicon.href = "${'https://api.tikkets.com/public/images/upload/'+ settings.favicon}";
        }
      `}
    </script>
  </Helmet>
          <SEO
      title="Register - eticketing"
    />
      <FormErrorPopup
        isOpen={isErrorPopupOpen}
        errors={formErrors}
        onClose={closeErrorPopup}
      />

      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <a href="/" className="flex items-center space-x-2">
        <img className="w-40 h-12 mx-auto" src={`https://api.tikkets.com/public/images/upload/${settings.logo}`} alt="logo" />
        </a>
      </div>
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img
            className="w-full"
            id="hero-image-light"
            src="images/illustrations/dashboard-check.svg"
            alt="image"
          />
          <img
            className="w-full hidden"
            id="hero-image-dark"
            src="images/illustrations/dashboard-check-dark.svg"
            alt="image"
          />
        </div>
      </div>
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700  lg:max-w-xl">
        <div className="flex w-full max-w-sm grow flex-col justify-center py-5">
          <div className="text-center">
              <img className="mx-auto w-44 h-16 lg:hidden" src={`https://api.tikkets.com/public/images/upload/${settings.logo}`} alt="logo" />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                Welcome Back
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                Please sign up to continue
              </p>
            </div>
          </div>
 

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              repeatPassword: "",
              organizationName: "",
              phoneNumber: "",
              countryName: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ errors, touched,setFieldValue  }) => (
              <Form className="flex w-full max-w-sm grow flex-col justify-center py-5">


              <div class="mt-4 space-y-4">
                
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <label class="block">
                    <span>First Name</span>
                    <span class="relative mt-1.5 flex">
                       
                      <Field
                        name="firstName"
                         class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="First Name"
                        type="text"
                      />

                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="far fa-user text-base"></i>
                      </span>
                    </span>

                      {touched.firstName && errors.firstName && (
                    <div className="text-red-500">{errors.firstName}</div>
                  )}

                  </label>
                  <label class="block">
                    <span>Last Name</span>
                    <span class="relative mt-1.5 flex">
                       
                      <Field
                        name="lastName"
                         class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Last Name"
                        type="text"
                      />

                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="far fa-user text-base"></i>
                      </span>
                    </span>

                      {touched.lastName && errors.lastName && (
                    <div className="text-red-500">{errors.lastName}</div>
                  )}
                      
                  </label>

                </div>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-12">
                  <label class="block col-span-12">
                    <span>Email Address</span>
                    <div class="relative mt-1.5 flex">
                       
                       <Field
                        name="email"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Email"
                        type="email"
                      />

                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa-regular fa-envelope text-base"></i>
                      </span>

                    
                    </div>
                      {touched.email && errors.email && (
                    <div className="text-red-500">{errors.email}</div>
                  )}
                  </label>
                  
                </div>

                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <label class="block">
                    <span>Password</span>
                    <span class="relative mt-1.5 flex">
                       

                      <Field
                        name="password"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        {showPassword ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                />
                            </svg>
                        )}
                    </button>


                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa fa-lock text-base"></i>
                      </span>



                    </span>

                    {touched.password && errors.password && (
                    <div className="text-red-500">{errors.password}</div>
                  )}
                  </label>
                  <label class="block">
                    <span>Repeat Password</span>
                    <span class="relative mt-1.5 flex">
                        

                       <Field
                        name="repeatPassword"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Repeat Password"
                        type={showRepPassword ? 'text' : 'password'}
                    />
                    <button
                        type="button"
                        onClick={toggleRepPasswordVisibility}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        {showRepPassword ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M2 12s5-8 10-8 10 8 10 8-5 8-10 8-10-8-10-8z"
                                />
                            </svg>
                        )}
                    </button>


                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa fa-lock text-base"></i>
                      </span>
                    </span>

                     {touched.repeatPassword && errors.repeatPassword && (
                    <div className="text-red-500">{errors.repeatPassword}</div>
                  )} 
                  </label>

                </div>


                <div class="grid grid-cols-1 gap-4 sm:grid-cols-12">
                  <label class="block col-span-12">
                    <span>Organization Name</span>
                    <div class="relative mt-1.5 flex">
                       
                      

                      <Field
                        name="organizationName"
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Organization Name"
                        type="text"
                      />


                      <span
                        class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                      >
                        <i class="fa-regular fa-user text-base"></i>
                      </span>

                    
                    </div>
                    {touched.organizationName && errors.organizationName && (
                    <div className="text-red-500">
                      {errors.organizationName}
                    </div>
                  )}
                  </label>
                  
                </div>

                  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <label class="block">
                    <span>Phone</span>
                    <span class="relative mt-1.5 flex relative mt-1.5 flex form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent">
                      

                      <PhoneInput
                      international
                      country={defaultCountry}
                      defaultCountry={defaultCountry}
                      name="phoneNumber"
                      value={value}
                      onChange={(phoneNumber) => {
                        setValue(phoneNumber);
                        setFieldValue('phoneNumber', phoneNumber); // Set Formik's field value
                      }}
                      // other props
                    />
                       
                    </span>

                     {touched.phoneNumber && errors.phoneNumber && (
                          <div className="text-red-500">{errors.phoneNumber}</div>
                        )}

                  </label>
                  <label class="block">
                    <span>Country Name</span>
                    <span class="relative mt-1.5 flex">
                       

                
                       <Select
                        id="country_Name"
                        name="countryName"
                        options={countries}
                        styles={customStyles}
                        class="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        isSearchable={true}
                        placeholder={<span dangerouslySetInnerHTML={{ __html: '<i class="fa-regular fa-flag text-base"></i> Select Country' }} />}
                        onChange={(option) => {
                         
                        setFieldValue('countryName', option.value); // Set Formik's field value
                      }}


                      />

 
                    </span>

                    {touched.countryName && errors.countryName && (
                    <div className="text-red-500">{errors.countryName}</div>
                  )} 
                  </label>

                </div>
                
              </div>

                <div className="mt-4 space-y-4">
                   
                  <button
                    type="submit"
                    className="btn mt-10 h-10 w-full bg-slate-700 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    disabled={isLoading}
                    >
                   

                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status"></div>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      "Sign Up"
                    )}

                  </button>
                  <p class="text-sm mt-2 font-light text-gray-500 dark:text-gray-400">
                      Already have an account? <Link to="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500"> Sign in</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <a href="#">Privacy Notice</a>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
          <a href="#">Term of service</a>
        </div>
      </main>
    </>
  );
};

export default SignupForm;
