import React, { useState, useEffect, useRef } from "react";
import { EventListFetch, deleteEvent } from "../../../services/admin/eventservice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import TableTools from "../../../hooks/TableTools";
import { toast, Toaster } from "react-hot-toast";
import defaultAvator from '../../../assets/images/200x200.png';
import { API_ENDPOINTS } from '../../../services/admin/api'
import { formatCurrency, formatDate } from "../../../helpers/Helpers";
import CustomLoader from '../Loaderfiles/customloader';
import SEO from "../../../hooks/seo";
import useResizeObserver from "../../../hooks/useResizeObserver";
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

const EventList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [activeButton, setActiveButton] = useState("all");
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [EventValue, SetEventValue] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  {/* useResizeObserver Start  */ }

  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);

  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };

  // Use the custom hook
  useResizeObserver(ref, handleResize);

  {/* useResizeObserver END  */ }



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async (value) => {
    setLoading(true);
    try {
      const response = await EventListFetch(currentPage, searchText, EventValue || value);
      setActiveButton(response.eventValue);
      if (response && response.data) {
        setTotalRecords(response.recordsTotal);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
    setPageLoading(true);
  };



  const showDeleteConfirmation = async (callback) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await callback();
      }
    } catch (error) {
      console.error('Error in showDeleteConfirmation:', error);
      Swal.fire('Error!', 'Failed to delete the item.', 'error');
    }
  };

  const handleDeleteClick = async (id) => {
    showDeleteConfirmation(async () => {
      try {
        const response = await deleteEvent(id);

        if (response && response.status === 200) {
          setData(data.filter(ticket => ticket.id !== id));
          Swal.fire('Deleted!', 'Your event has been deleted.', 'success');
        } else {
          console.log('Unexpected response:', response);
          Swal.fire('Error!', 'Failed to delete the item.', 'error');
        }
      } catch (error) {
        console.error('Error deleting item:', error.response.data.error);
        Swal.fire('Error!', error.response.data.error, 'error');
      }
    });
  };


  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const prevPageRef = useRef();
  const prevSearchTextRef = useRef();
  const prevEventValueRef = useRef();
  
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [debouncedEventValue, setDebouncedEventValue] = useState(EventValue);

  const location = useLocation();
  const message = location.state?.message;
  const prevMessageRef = useRef();
  const prevLocationKeyRef = useRef(location.key);

  useEffect(() => {
    // Check if the location is different from the previous one and if the message is different
    if (
      message &&
      (message !== prevMessageRef.current ||
        location.key !== prevLocationKeyRef.current)
    ) {
      // toast.success(message, { duration: 3000 });
      prevMessageRef.current = message;
      prevLocationKeyRef.current = location.key;

      // Clear the message in the state after showing the toast
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, message: null },
      });
    }
  }, [message, location, navigate]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setDebouncedEventValue(EventValue);
      setCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText,EventValue]);

  useEffect(() => {
    if (
      currentPage !== prevPageRef.current ||
      debouncedSearchText !== prevSearchTextRef.current ||
      debouncedEventValue !== prevEventValueRef.current
    ) {
      if (currentPage === 1 || currentPage !== prevPageRef.current) {
        // Fetch data only if currentPage is 1 or it has changed
        fetchData("all");
      }
    }
    // Update the previous values
    prevPageRef.current = currentPage;
    prevSearchTextRef.current = debouncedSearchText;
    prevEventValueRef.current = debouncedEventValue;
  }, [currentPage, debouncedSearchText, debouncedEventValue]);
  
  

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleImageError = (event) => {
    event.target.src = defaultAvator

  };
  const handleExportCSV = (value) => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.CSV_EVENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText) + `&event_value=${activeButton}`;
    window.location.href = urlWithToken;
  };

  const handleExportPDF = (value) => {
    const token = localStorage.getItem('authToken');
    const urlWithToken = API_ENDPOINTS.PDF_EVENT_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText) + `&event_value=${activeButton}`;
    window.location.href = urlWithToken;
  };
  if (!pageLoading) {
    return (
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-96">
            <CustomLoader
              visible={true}
              height={80}
              width={80}
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius={1}
            />
          </div>
        </div>
      </main>
    );
  }
  const handleButtonClick = async (value) => {
    setCurrentPage(1);
    SetEventValue(value);
    setActiveButton(value === activeButton ? "" : value); // Toggle active button
  };


  const onButtonClickCreate = () => {
    navigate("/admin/create-event");
  };

  const truncateWords = (text, maxWords) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    return truncatedWords.join(' ');
  };
  return (
    <>
      <SEO
        title="Event List - eticketing"
      />
      <main ref={ref} style={{ width: '100%', height: '100%' }} className="main-content w-full px-[var(--margin-x)] pb-8">
        <Toaster position="top-right" reverseOrder={false} />
        <PageHeader
          title="Event List"
          onButtonClick={onButtonClickCreate}
          showCreateButton={true}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
          <TableTools
              title="Event Rows"
              showEventsButtons={true}
              handleButtonClick={handleButtonClick}
              activeButton={activeButton}
              onSearchChange={handleSearchChange}
              showSearch={true}
              showExportButtons={true}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
       

            <div className="lg:mt-5 md:mt-28  mt-32 scroll-x-auto">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Image
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Event Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Date
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5 text-center">
                          Max
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Category
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Organization
                        </th>
                        {/* <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase     text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Type
                        </th> */}
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Status
                        </th>
                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <div class="avatar flex size-10">
                              <img
                                className="mask is-squircle"
                                src={(item.imagePath ? item.imagePath : 'https://api.tikkets.com/public/images/upload/') + item.image}
                                alt={item.name}
                                onError={handleImageError}
                              />

                            </div>
                          </td>

                          <td className="whitespace-nowrap  px-4 py-3 sm:px-5">

                            <p dangerouslySetInnerHTML={{ __html: truncateWords(item.name, 5) || 'NULL' }} />

                          </td>
                          <td className="whitespace-nowrap  px-4 py-3 sm:px-5 text-center">
                            {formatDate(item.end_time)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                            {item.people}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.category_name}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.first_name + '  ' + item.last_name}
                          </td>
                        

                          {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.exclusive === 1 ? (
                              <div className="badge space-x-2.5 px-0 text-secondary dark:text-secondary-light">
                                <div className="size-2 rounded-full bg-current" />
                                <span>Exclusive</span>
                              </div>
                            ) : (
                              <div className="badge space-x-2.5 px-0 text-primary dark:text-accent-light">
                                <div className="size-2 rounded-full bg-current" />
                                <span>Inclusive</span>
                              </div>
                            )}
                          </td> */}


                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">


                            {item.status === 1 ? (
                              <div className="badge space-x-2.5 px-0 text-success">
                                <div className="size-2 rounded-full bg-current" />
                                <span>Active</span>
                              </div>
                            ) : (
                              <div className="badge space-x-2.5 px-0 text-error">
                                <div className="size-2 rounded-full bg-current" />
                                <span>Inactive</span>
                              </div>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 relative">
    <div className="relative inline-block text-left">
        <button
            onClick={() => toggleDropdown(item.id)}
            className="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="size-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
            </svg>
        </button>
        {openDropdownId === item.id && (
            <div className={`dropdown-content absolute bg-white shadow-lg rounded mt-2 z-10 right-0 ${index !== 0 && index !== 1 ? 'bottom-full' : ''}`}  style={{ width: '150px' }}>
                <ul className="list-none p-0">
                    <li className="mb-1">
                        <Link to={`/admin/view-event/${item.id}`} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">View Event</Link>
                    </li>
                    <li className="mb-1">
                        <Link to={`/admin/eventgallery/${item.id}`} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">View Gallery</Link>
                    </li>
                    <li className="mb-1">
                        <Link to={`/admin/editEvent/${item.id}`} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Edit</Link>
                    </li>
                    <li className="mb-1">
                        <Link onClick={() => handleDeleteClick(item.id)} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Delete</Link>
                    </li>
                </ul>
            </div>
        )}
    </div>
</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}


                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EventList;
