import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const OrderList = async (currentPage,searchText, Filterdata) => {
  
   const event_name = Filterdata.event_name;
  const category = Filterdata.categories;
  const country = Filterdata.country;
  const coupon = Filterdata.coupon;
  const customer_name = Filterdata.name;
  const order_id = Filterdata.order_id;
  const user_id = Filterdata.user_id;
  const payment_type = Filterdata.payment_type;
  const ticket_type = Filterdata.ticket_type;
  const startDate = Filterdata.date?.startDate;
  const endDate = Filterdata.date?.endDate;

  try {
    const authToken = localStorage.getItem('authToken');
    let apiUrl = API_ENDPOINTS.ORG_ORDERS_LIST + `?page=${currentPage}`;
    
   // Add search parameter if searchText is provided
   if (searchText) {
    apiUrl += `&search=${searchText}`;
}

// Add event_name parameter if its value is not equal to zero
if (event_name !== undefined && event_name !== null) {
  apiUrl += `&event_name=${event_name}`;
}

// Add categories parameter if it exists
   if (category !== undefined && category !== null) {

    apiUrl += `&category=${category}`;
}

// Add country parameter if it exists
if (country) {
    apiUrl += `&country=${country}`;
}

// Add coupon parameter if it exists
if (coupon) {
    apiUrl += `&coupon=${coupon}`;
}

// Add name parameter if it exists
if (customer_name) {
    apiUrl += `&customer_name=${customer_name}`;
}

// Add order_id parameter if it exists
if (order_id) {
    apiUrl += `&order_id=${order_id}`;
}

// Add user_id parameter if it exists
if (user_id) {
    apiUrl += `&user_id=${user_id}`;
}
if (ticket_type) {
    apiUrl += `&ticket_type=${ticket_type}`;
}

if (payment_type) {
apiUrl += `&payment_type=${payment_type}`;
}
if (startDate) {
apiUrl += `&start_date=${startDate}`;
}

// Add endDate parameter if it exists
if (endDate) {
apiUrl += `&end_date=${endDate}`;
}
    
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`  
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('scanner error:', error);
     throw error;
  }
};
