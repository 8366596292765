import React from "react";
import { RoleListFetch } from "../../../services/admin/roleservice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import TableTools from "../../../hooks/TableTools";
import CustomLoader from '../Loaderfiles/customloader';
import { useState, useEffect,useRef } from "react";
import { API_ENDPOINTS }  from '../../../services/admin/api'
import Swal from 'sweetalert2';
import useResizeObserver from "../../../hooks/useResizeObserver";
 

const RoleList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [messageShown, setMessageShown] = useState(false);
    const [searchText, setSearchText] = useState('');
    const recordsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    // const location = useLocation();
    // const message = location.state && location.state.message;
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

    {/* useResizeObserver Start  */ }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {/* useResizeObserver END  */ }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchText(searchText);
          setCurrentPage(1);
        }, 300);
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchText]);
      
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await RoleListFetch(currentPage, searchText);
                if (response && response.data) {
                    setTotalRecords(response.recordsTotal);
                    setData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
            setPageLoading(true);
        };
        // if (message && !messageShown) {
              
        //   setMessageShown(true); 
        //      Swal.fire('Update!',message, 'success');
        //         }
        fetchData();
    }, [currentPage, searchText]);
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleExportCSV = () => {
         const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.CSV_ROLE_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;

    };
  
    const handleExportPDF = () => {
         const token = localStorage.getItem('authToken');
        const urlWithToken = API_ENDPOINTS.PDF_ROLE_EXPORT + "?token=" + encodeURIComponent(token) + "&search=" + encodeURIComponent(searchText);
        window.location.href = urlWithToken;
    };
    
    const onButtonClickCreate = () => {
        navigate('/admin/create-role');
    }
        if (!pageLoading) {
        return (
            <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
                <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                        <CustomLoader
                            visible={true}
                            height={80}
                            width={80}
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius={1}
                        />
                    </div>
                </div>
            </main>
        );
    }
    return (
        <>
        <SEO
        title="All Roles List - eticketing"
      />
        <main ref={ref} style={{ width: '100%', height: '100%'}} className="main-content w-full px-[var(--margin-x)] pb-8">
            <PageHeader
                title="All Roles List"
                onButtonClick={onButtonClickCreate}
                showCreateButton={true}
            />
            <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
                <div className="card px-4 pb-4 sm:px-5">
                    <TableTools
                        title="Roles Rows"
                         showSearch={true}
                         showExportButtons={true}
                        onSearchChange={handleSearchChange}
                        onExportCSV={handleExportCSV}
                        onExportPDF={handleExportPDF}
                    />
                    <div className="lg:mt-5 md:mt-5 mt-16">
                          
                        <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                            {loading ? (
                                <div className="flex justify-center items-center">
                                    <div className="flex justify-center items-center h-96">
                                        <CustomLoader
                                            visible={true}
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            ariaLabel="tail-spin-loading"
                                            radius={1}
                                        />
                                    </div>
                                </div>
                            ) : data.length === 0 ? (
                                <div className="text-center text-gray-500 mt-8">
                                     <h1 className="xl text-3xl">No records found.</h1>
                                </div>
                            ) : (
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Name
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Permissions
                                        </th>
                                        <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                        >
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                {item.name}
                                            </td>

                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                <ul className="flex flex-wrap list-none p-0 m-0">
                                                    {item.permissions && item.permissions.length > 0 ? (
                                                        item.permissions.map((permission, index) => (
                                                            <li
                                                                key={permission.id}
                                                                className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15 mb-2 mr-2"
                                                            >
                                                                <div className="size-2 rounded-full bg-current"></div>
                                                                <span>{permission.name}</span>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15">
                                                            <div className="size-2 rounded-full bg-current"></div>
                                                            <span>No permissions</span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                                <Link className="btn size-8 p-0 text-info hover:bg-info/20 focus:bg-info/20 active:bg-info/25" to={`/admin/roles/${item.id}/edit`}>
                                                   <i className="fa fa-edit" />
                                                </Link>
                                                 
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                       )}
                        <Pagination
                            totalRecords={totalRecords}
                            perPage={recordsPerPage}
                            onPageChange={handlePageChange}
                            />
                            </div>
                                     
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default RoleList;
