import React from "react";
import { CashSettlementReportFetch } from "../../../services/admin/cash-report-service";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../hooks/Pagination";
import PageHeader from "../../../hooks/PageHeader";
import SEO from "../../../hooks/seo";
import TableTools from "../../../hooks/TableTools";
import { useState, useEffect, useRef } from "react";
import CustomLoader from "../Loaderfiles/customloader";
import { API_ENDPOINTS } from "../../../services/admin/api";
import useResizeObserver from "../../../hooks/useResizeObserver";

const SettlementReportCash = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  {
    /* useResizeObserver Start  */
  }
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  // Implement the callback function
  const handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  };
  // Use the custom hook
  useResizeObserver(ref, handleResize);
  {
    /* useResizeObserver END  */
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CashSettlementReportFetch(
          currentPage,
          searchText
        );
        if (response && response.data) {
          setTotalRecords(response.recordsTotal);
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchText]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleExportCSV = async () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.CSV_CASH_SETTLEMENT_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };

  const handleExportPDF = () => {
    const token = localStorage.getItem("authToken");
    const urlWithToken =
      API_ENDPOINTS.PDF_CASH_SETTLEMENT_EXPORT +
      "?token=" +
      encodeURIComponent(token) +
      "&search=" +
      encodeURIComponent(searchText);
    window.location.href = urlWithToken;
  };
  const onButtonClickCreate = () => {
    navigate("/admin/create-role");
  };
  return (
    <>
      <SEO title="Settlement Report - eticketing" />
      <main
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        className="main-content w-full px-[var(--margin-x)] pb-8"
      >
        <PageHeader
          title="Settlement Report"
          onButtonClick={onButtonClickCreate}
          showCreateButton={false}
        />
        <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
          <div className="card px-4 pb-4 sm:px-5">
            <TableTools
              title="Settlement Report Rows"
              onSearchChange={handleSearchChange}
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
              showSearch={true}
              showExportButtons={true}
            />
            <div className="lg:mt-5 md:mt-5 mt-16">
              <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center h-96">
                      <CustomLoader
                        visible={true}
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                      />
                    </div>
                  </div>
                ) : data.length === 0 ? (
                  <div className="text-center text-gray-500 mt-8">
                    <h1 className="xl text-3xl">No records found.</h1>
                  </div>
                ) : (
                  <table className="is-hoverable w-full text-left">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Organization Name
                        </th>
                        <th className="whitespace-nowrap text-center  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total Orders
                        </th>
                        <th className="whitespace-nowrap text-center bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Total Sales
                        </th>
                        <th className="whitespace-nowrap  bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Paid-to-Date
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Balance Due
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                        >
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              <div>
                                <p className="text-sm font-medium text-gray-900">
                                  {item.first_name + " " + item.last_name ||
                                    "N/A"}
                                </p>
                                <p className="text-xs text-gray-500">
                                  {item.email}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center ">
                            {item.total_orders}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-center">
                            {item.currency_symbol === null
                              ? "TT$ " + item.total_commission.toFixed(2)
                              : item.currency_symbol +
                                " " +
                                item.total_commission.toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.currency_symbol === null ? "TT$ " : "TT$"}{" "}
                            {item.organization_commission_paid.toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            {item.currency_symbol === null ? "TT$ " : "TT$"}{" "}
                            {item.total_commission.toFixed(2) -
                              item.organization_commission_paid.toFixed(2) ||
                              "0"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                            <Link
                              className="btn size-8 p-0 text-success hover:bg-success/20 focus:bg-success/20 active:bg-success/25"
                              to={`/admin/view-settlement/cash/${item.id}`}
                            >
                              <i className="fa fa-eye" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  perPage={recordsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SettlementReportCash;
